import React from 'react'
import "./Projects.css"
import ProjectFrame from "./ProjectFrame"

function Projects() {
    return (
        <div className="Container">
            <ProjectFrame title="DuolinGoFaster" imgPath={require("../../imgs/DGF.png")} alt="Screenshot of DuolinGoFaster" caption="A tool to stop wasting so much time on Duolingo." links={{'GitHub': "https://github.com/Kyle-Pu/DuolinGoFaster"}}/>

            <ProjectFrame title="FriendInNeed" imgPath={require("../../imgs/FriendInNeed.png")} alt="Login Screen of FriendInNeed" caption="Ever need a charger in Powell? FriendInNeed is a community-based platform that connects people in need with people in their immediate surroundings, making students' daily lives easier and less stressful." links={{'Website': "https://celebilaw.github.io/lahacks22", 'GitHub': "https://github.com/celebilaw/lahacks22", 'DevPost': "https://devpost.com/software/friend-in-need-67l9od"}}/>

            <ProjectFrame title="GreenRide" imgPath={require("../../imgs/greenride.png")} alt="Splash page of GreenRide" caption="Google Maps, but with a CO2 emissions calculator that takes the route, car, and number of passengers to show how much CO2 each person is emitting to inspire more environmentally conscious traveling." award="Won Best in Sustainability at UCLA ACM's Hack on the Hill 2022" links={{'Website': 'https://jakegreenbergbell.github.io/greenride/', 'GitHub': 'https://github.com/jakegreenbergbell/greenride', 'DevPost': 'https://devpost.com/software/greenride'}}/>

            <ProjectFrame title="Selector Safari" imgPath={require("../../imgs/selector-safari.png")} alt="First level of Selector Safari" caption="As part of TeachLA's Dev Team, I help build a game to teach kids around LA about CSS selectors." links={{'Website': 'https://selector-safari.uclaacm.com/level/1', 'GitHub': 'https://github.com/uclaacm/selector-safari', "UCLA ACM's TeachLA": "https://teachla.uclaacm.com/"}}/>

            <ProjectFrame title="Nutrition Dashboard" imgPath={require("../../imgs/nutrition.png")} alt="Starting screen of dashboard" caption="An interactive visualization dashboard of a selection of foods and their nutritional contents (sourced from the United States Department of Agriculture (USDA) Food Data Central resource)." award="Developed at the NIH's Summer Internship Program's Virtual Codeathon 2021" links={{'Website': 'https://share.streamlit.io/tantar/uncovering-the-nutritional-landscape-of-food/main/UNLF.py', 'GitHub': 'https://github.com/STRIDES-Codes/Uncovering-the-Nutritional-Landscape-of-Food'}}/>

        </div>
    );
}

export default Projects;
