import React, { useState } from 'react';
import './modal.css';

function Modal({message, visible, onClose}) {

    return (

        <>
            {visible &&
                <div className="modal-overlay">
                    <div className="modal">
                        <button class="post-button modal-close" onClick={onClose}>Close</button>
                        <h2>{message.title}</h2>
                        <p>{message.content}</p>
                    </div>
                </div>
            }
        </>
    );
}

export default Modal;
