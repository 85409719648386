import React, { useState, useEffect } from 'react'
import "./Blog.css"
import blogposts from "./blogposts"
import { db } from "../../firebase"
import { collection, addDoc, Timestamp, getDocs } from 'firebase/firestore'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, getAuth, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth';
import Modal from "./Modal"

function Blog() {
  const auth = getAuth();

  let [showFilters, setShowFilters] = useState("");
  let [filter, setFilter] = useState([]);
  let [person, setPerson] = useState(""); // Name of person who wants to subscribe to blog
  let [phone_number, setPhoneNumber] = useState(""); // Phone number of subscriber
  let [showSubscribe, setShowSubscribe] = useState(false); // Whether or not to show the subscription box

  let [userEmail, setUserEmail] = useState("");
  let [userPassword, setUserPassword] = useState("");

  let [modalMessage, setModalMessage] = useState("");
  let [showModal, setShowModal] = useState(false);

  // A person can only comment on one post at a time
  let [commentpost, setCommentpost] = useState("") // The post currently being commented on
  let [comment, setComment] = useState("")

  let [allComments, setAllComments] = useState({})
  let [signedIn, setSignedIn] = useState(false);

  // Sort blogposts by date of publication (descending order)
  let bpDate = []; // Dates as strings
  for (let k = 0; k < blogposts.length; k++) {
    bpDate.push(blogposts[k]["date"]);
    let parsedDate = bpDate[k].split("/").map((num) => parseInt(num));
    let month = parsedDate[0];
    let day = parsedDate[1];
    let year = parsedDate[2];
    let encoding = day + month * 31 + year * 12 * 31; // A numerical value to represent each date. One to one mapping of date to encoding (I think lol).
    //console.log(encoding);
    blogposts[k]["encoding"] = encoding; // Associate each encoding with its respective blog post
  }

  blogposts.sort((a, b) => {
    return (a.encoding > b.encoding) ? -1 : ((a.encoding === b.encoding) ? 0 : 1); // -1 means blogpost a shows up at a lower index (before) blogpost b if and only if a is newer. 0 == equal, we don't care. 1 == a is older so it should go later down in the list
  })

  let titles = blogposts.map((bPost) => bPost.title)
  let dates = blogposts.map((bPost) => bPost.date)
  let tags = blogposts.map((bPost) => bPost.tags)
  let content = blogposts.map((bPost) => bPost.content)

  // Get tags data to enable filtering by tags
  const allTags = new Set()
  tags.forEach((tagsList) => tagsList.forEach((t) => allTags.add(t))) // Add all tags to set
  const tagFilters = Array.from(allTags)

  const handleClick = () => {
    showFilters === "ShowDropdown" ? setShowFilters("") : setShowFilters("ShowDropdown"); // Toggle dropdown visibility. Show dropdown when "Filter" button is clicked. Hide when clicked again
  }

  const handleFilter = (val) => {
    // Normal tags
    if (filter.includes(val)) {
      let indRemove = filter.indexOf(val) // Toggle specific filter off if clicked again
      setFilter(filter.filter((t, ind) => {
        if (ind !== indRemove) {
          return t
        }
      }));
    } else {
      setFilter([...filter, val]) // Add selected filter to filters list if not already there
    }

    // Check for clear all. Note: this check MUST come AFTER the check for normal tags to avoid adding a "Clear All" filter
    if (val === "Clear All") {
      setFilter([])
    }

    setShowFilters(""); // Make filters list disappear if a filter is clicked (most common scenario is user selects just one tag)
  }

  // Submit phone number for updates when blog posts come out
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'phone_numbers'), {
        name: person,
        phone_number: phone_number,
        created: Timestamp.now()
      })
    } catch (err) {
      alert(err)
    }

    // Reset subscription box
    setShowSubscribe(false)
    setPerson("")
    setPhoneNumber("")

  }

  const handleName = (e) => {
    setPerson(e.target.value)
  }

  const handleNumber = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleSubClick = (e) => {
    setShowSubscribe(!showSubscribe)
  }

  const handleModalUpdate = message => {
    setModalMessage(message);
    setShowModal(true);
  }

  // Sign up a user
  const sendEmail = async () => {
    await sendEmailVerification(auth.currentUser)
      .then(() => {
        // Verification email sent successfully
      })
      .catch((error) => {
        // Handle errors
      });
  }

  const signUp = async (email, password, name) => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up user
        console.log("User signed up:", userCredential.user);
        sendEmail();
        setUserEmail("");
        setUserPassword("");
        setSignedIn(true);
      })
      .catch((error) => {
        // Handle errors
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error signing up user:", errorCode, errorMessage);

        if (errorCode === "auth/email-already-in-use") {
          handleModalUpdate("The email address you provided is already in use.")
        } else if (errorCode === "auth/invalid-email") {
          handleModalUpdate("Please ensure your email is valid.");
        } else {
          handleModalUpdate("Please ensure your password is at least 6 characters long.");
        }

      });
  };

  const logIn = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in user
        console.log("User signed in:", userCredential.user);
        setSignedIn(true);
      }).catch((error) => {
        // Handle errors
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error signing in user:", errorMessage);

        if (errorCode === "auth/invalid-email") {
          handleModalUpdate("The email address you've entered does not match any accounts.");
        } else if (errorCode === "auth/wrong-password") {
          handleModalUpdate("Your password is incorrect.");
        } else if (errorCode === "auth/too-many-requests") {
          handleModalUpdate("Access to this account has been temporarily disabled due to many failed login attempts. Try again later.")
        }

      });
  };

  const logOut = async () => {
    await signOut(auth)
      .then(() => {
        // User signed out
        console.log("User signed out");
        console.log(auth.currentUser)
      }).then(() => {
        setSignedIn(false);
        window.location.reload();
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        // Handle errors
        const errorMessage = error.message;
        console.error("Error signing out user:", errorMessage);
      });
  };

  const submitComment = async (title) => {
    if (auth.currentUser === null || !auth.currentUser.emailVerified) {
      sendEmail();
      handleModalUpdate("Before we post your comment, please verify your email address by clicking the link we just sent you! Please note if you just verified your email, you may have to log out and log back in.")
    } else { // User is authenticated

      // Clear the comment box, state update refreshes page so comment now shows on the live site!
      setComment("")

      try {
        // Hierarchical data, see documentation (https://firebase.google.com/docs/firestore/data-model#hierarchical-data)
        await addDoc(collection(db, "comments", title, "posts"), {
          author: person,
          comment: comment,
          created: Timestamp.now()
        });
        window.location.reload();
      } catch (err) {
        handleModalUpdate(err);
      }
    }
  }

  // Keep track of which post is being commented on so that it only appears in the currently typed in comment box. We don't need the same behavior for name, since that should be same across all comments
  const handleChangingComment = (e, title) => {
    setCommentpost(title)
    setComment(e.target.value)
  }

  const handleEmail = (e) => {
    setUserEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setUserPassword(e.target.value)
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  useEffect(() => {

    // Fetch all comments
    let comms = {}

    titles.forEach(async (title) => {
      var querySnapshot = await getDocs(collection(db, "comments", title, "posts"))
      var all = querySnapshot.docs.map((doc) => "\"" + doc.data().comment + "\"" + " -" + doc.data().author)

      if (typeof all !== 'undefined' && all.length > 0) {
        comms = { ...comms, [title]: all }

      } else {
        comms = { ...comms, [title]: ["No comments yet!"] }
      }

      // YOU HAVE TO HAVE TO put this part INSIDE of the async function, or else things don't work. Spent a lot of time debugging this haha.
      setAllComments(comms)
    })

    // Google AdSense
    const script = document.createElement("script")
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7288742389789708"
    script.async = true
    script.crossOrigin = "anonymous"
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script);
    }
  }, [])

  return (
    <div className="Container">
      <Modal visible={showModal} message={{ title: "Warning", content: modalMessage }} onClose={handleCloseModal} />

      {/*
      <div className="SubscriptionContainer">
        <button className="DropdownButton" onClick={handleSubClick}>Subscribe!</button>

        <div className={showSubscribe ? "popup" : "nopopup"}>
          <p>Wanna stay in the loop? Subscribe to get notified when new blog posts come out!</p>
          <p>Your name and number are stored securely and never given to any third party. Actual text notifications are a work in progress at the moment (09/23/2022)</p>
          <form onSubmit={handleSubmit}>
            <label>Name</label><br />
            <input type="text" id="name" name="name" value={person} onChange={handleName} /><br />
            <label>Phone Number</label><br />
            <input type="text" id="number" name="number" value={phone_number} onChange={handleNumber} /><br />
            <input type="submit"></input>
          </form>
        </div>
      </div>
       */}
      <br />

      <div className="TagsContainer">
        <button onClick={handleClick} className="DropdownButton">{showFilters === "" ? <txt>Filter</txt> : <txt>Close</txt>}</button>
        <div className={"Dropdown" + " " + showFilters}>
          {tagFilters.map((t) => <p className="tagFilter" onClick={() => handleFilter(t)} value="{t}">#{t}</p>)}
          <p className="tagFilter" style={{ "border": "1px solid blue" }} value="Clear All" onClick={() => handleFilter("Clear All")}>Clear All</p>
        </div>
        {filter.length !== 0 ? filter.map((f) => <txt className="FilterStatus">#{f}</txt>) : ""}
      </div>

      <br /><br />

      {titles.map((title, index) => {
        // Only display blog post if it matches the current filter(s), if any
        if ((filter.length == 0) || filter.every(t => tags[index].includes(t))) {
          return (
            <div className="BPost" key={index}>
              <h1 className="Title">{title}</h1>
              <h2 className="Date">{dates[index]}</h2>
              <h2 className="Tag"> #{tags[index].join(" #")}</h2>
              <div className="Content">{content[index]}</div>

              <div className="Comments">

                <h3>Comments</h3>

                {typeof allComments[title] !== 'undefined' ? allComments[title].map((com) => <p>{com}</p>) : <p>No comments yet!</p>}

                {auth.currentUser !== null ?
                  <div>
                    <form onSubmit={() => submitComment(title)}>
                      <label>Comment</label><br />
                      <textarea class="comment-box" cols="100" rows="5" value={commentpost === title ? comment : ""} onChange={event => handleChangingComment(event, title)}></textarea><br />

                      <label>Name</label><br />
                      <input class="comment-box" type="text" value={person} onChange={handleName}></input><br /><br />

                      <input class="post-button" type="submit" value="Post!"></input>
                    </form>
                    <form onSubmit={logOut}>
                      <input class="post-button" type="submit" value="Log Out"></input>
                    </form>
                  </div>
                  :
                  <div>
                    <form onSubmit={() => logIn(userEmail, userPassword)}>
                      <label>Email</label>
                      <textarea class="comment-box" value={userEmail} onChange={handleEmail}></textarea><br />

                      <label>Password</label>
                      <input class="comment-box" type="password" value={userPassword} onChange={handlePassword}></input><br /><br />

                      <label>Log in to leave a comment</label><br />
                      <input class="post-button" type="submit" value="Log In"></input> {" "}
                    </form>
                    <form onSubmit={() => signUp(userEmail, userPassword)}>
                      <input class="post-button" type="submit" value="Sign Up"></input>
                    </form>
                  </div>
                }


              </div>
              <hr></hr>
            </div>
          )
        }
      })}
    </div>
  );
}

export default Blog;
