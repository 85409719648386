import React from 'react'
import { findAllInRenderedTree } from 'react-dom/test-utils'

let blogposts = [
    {
        title: "Day 1: First Impressions",
        date: "06/13/2022",
        tags: ["NYC", "MSK"],
        content:
            <div>
                Hello, and welcome to my Summer 2022 blogs page! I'll try to keep track
                of what I'm doing day to day, and how things have been going.
                <br></br><br></br>
                I flew in on Friday to EWR. I thought the flight would be about 6 hours,
                so I'd get enough sleep, but it ended up being only 4 hours and 45 minutes.
                It was a pretty uncomfortable flight, so I ended up getting only a couple
                hours of sleep.
                <br></br><br></br>
                Once I landed in Newark, I was pretty confused how to get to the rail station.
                There's an AirBus that whips people around to the different terminals as well
                as the station, but I thought you had to pay. A kind lady I asked told me
                that she's ridden on it before, and we don't need tickets (thank God).
                <br></br><br></br>
                My short time in New Jersey was beautiful. The AirBus rides high above
                the highways, so you have a nice view of Newark.
                <figure className="right">
                    <img src={require("./NY_Images/Newark.jpeg")} alt="Newark view from the AirBus" />
                    <figcaption>View from the AirBus</figcaption>
                </figure>
                <br></br><br></br>
                I took the train from EWR straight to Penn Station, and arrived at my place
                in Wyndham The New Yorker shortly after. Took a Target trip to buy some
                essentials (shampoo, lotion, laundry detergent, and some mouthwash). The
                Target is really nice. It's really bright and peaceful, so it doesn't
                really feel like it's in NYC. Such a contrast from the busyness (and
                sort of nastiness) of the streets.
                <br></br><br></br>
                Picked up some pizza from Suprema, because I couldn't think of anything
                more fitting to start off my New York food adventures.
                <br></br><br></br>
                I was already exhausted, but felt too dirty to get in bed without showering
                (after all, I did just move out of my dorm on Friday and didn't get a chance
                to shower then), so I showered and then slept for a good hour and a half.
                <br></br><br></br>
                Hung out in my dorm for a bit, waiting for my roommate, who came after
                work hours shortly after.
                <br></br><br></br>
                We looked for somewhere to go for dinner, and I found out Koreatown is
                really, REALLY close to where I live - like half a mile, which is about
                as long as the distance is from my dorm to my classes back at UCLA.
                <br></br><br></br>
                Walked around there and was happy to see such a big Asian community
                in the middle of NYC. There was even an HMart, which my roommate had never
                been to. We found a restaurant without a ridiculously long line, and ended
                up having udon for dinner. We went to HMart after, where I saw Sachima
                and thought it'd be the perfect breakfast snack (something fast and easy
                to eat). My rooommate has never had Sachima, so he bought some too (but he
                bought the matcha flavor, which honestly isn't that good haha. He
                likes it more than the brown sugar flavor though).
                <br></br><br></br>
                That was about it for my first day. Slept early and got a good 9, 10 hours
                of sleep that night.
                <figure className="left">
                    <img src={require("./NY_Images/KTown.jpeg")} alt="A street in KTown" />
                    <figcaption>A street in KTown</figcaption>
                </figure>
                <br/><br/>
                On Saturday, the adventure began! Had a quiet morning in my dorm, just
                trying to figure out what I should read up on before starting.
                I wanted to study the papers my PI sent way before summer, but the quarter
                system really keeps us busy as hell. Too busy to think about anything
                besides class (and tennis :D)!
                <br/><br/>
                My roommate and I went to Soho in the afternoon. Soho is one of the only
                areas I know in NYC, because of some Asian YouTubers I used to watch
                that live there. Also, Pearl River Mart is in Soho! I've bought so much
                stuff from Pearl River Mart, and never thought I'd get the chance to see it in person,
                but there I was, Saturday afternoon, AT PEARL RIVER MART!
                <br/><br/>
                Their collection of rice grain dishes is beautiful. So many dishes, all of which
                I've probably bought at least 1 of at this point. They don't have the teapots anymore,
                which sucks, but they DO have this little tiny shot glass type of cup which cost $2.
                I bought one, but I didn't tell you yet ahaha!
                <br/><br/>
                After that, Mitchell (my roommate) and I took a LONG walk to a bookstore he wanted to go to.
                It's a beautiful store, so beautiful that people even host weddings in it. When we went,
                we saw a sign saying it was temporarily closed for a private event, which Mitchell
                was really disappointed about.
                <br/><br/>
                We spent a bit of time in the thrift shop adjacent to the bookstore, and then
                walked another LONG distance (at least a mile) to another bookstore he wanted to check out.
                We spent a good hour in there. I wasn't too interested, but found a couple books
                that had some cool stuff I read for a short bit of time.
                <br/><br/>
                They only accept cash, so Mitchell and I had to walk all over the place looking for an ATM
                so he could buy his books.
                <br/><br/>
                After Soho, we went back, and I headed to dinner by myself to buy some jian bing from Jian
                Bing Man. Mom recommended the place, which ended up being in KTown again, in a building
                directly behind the Empire State Building (it's kind of crazy that so many famous buildings
                are right here within walking distance of us).
                <br/><br/>
                I asked the guy taking my order if the 油条 in the bing I ordered was put inside the bing,
                because I was confused why they'd wrap carbs with carbs. I also felt very cool when I said
                油条 because it was a chance to demonstrate my professional-level Chinese language skills.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JBM.jpeg")} alt="JBM" />
                    <figcaption>Entrance to Jian Bing Man</figcaption>
                </figure>
                Went back to HMart, since it has all sorts of really nice Asian goodies in there
                and bought some almonds so I can poo easier (more fiber!). Also got some
                Mandarins since I need good fruit. And that was about it for Saturday.
                <br/><br/>
                Then Sunday rolled around. I still felt like I was getting settled in on Sunday.
                Not too sure what to do, and didn't know many people, so I stayed in my dorm
                most of the day. For lunch, I ate at a pasta place which was pretty good
                and very close to the hotel, but severely overpriced! I could make their crap
                for like $3 in the kitchen on the 15th floor!
                <br/><br/>
                For dinner, I asked our MSK CBSP group chat if anyone wanted to go out to eat,
                and met a few people. We went to Friedham, which is an overpriced American diner.
                The food was good, but I wouldn't go there again. Nothing special about a fried
                chicken sandwich and soggy sweet potato fries!
                <br/><br/>
                The people I met yesterday night were really cool. One from UC Berkeley
                who knows Anya because they were in the same OChem lab! She was really chill,
                as was another guy I met from China (who went to private high school in the
                States before going to Duke, where he is now). Also met the most active
                member of the GroupMe, someone from the fake USC - University of South Carolina.
                <br/><br/>
                Overall a very good night. It was really cool to finally meet some people in the program.
                Being a late arrival because of finals sucked, but it seems that the people in CBSP
                this year aren't all that connected yet. Seems like they haven't done many events together
                or organized many outings after talking to them, which surprised me.
                <br/><br/>
                But anyways, it was a good night and I got some good sleep before my first day.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Mural.jpeg")} alt="A Mural" />
                    <figcaption>A Mural Near Madison Square Garden</figcaption>
                </figure>
                And now we're at today. Today was my first day at MSK!
                The subway ride there was nice and smooth. I walked to main campus
                to pick up my ID, and waited at security for quite a bit, about 15
                to 20 minutes, before having my turn and actually getting my ID.
                While I was in line, I met a Tri-I (MSK, Weill Cornell, and Rockefeller
                University) MD/PhD student coming here as well as another late arrival for
                CBSP, which was nice.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MSK_Main_Campus.jpeg")} alt="MSK" />
                    <figcaption>Main Campus Entrance!</figcaption>
                </figure>
                Took a dump in MSK's very clean bathroom before heading off to Joy,
                where my lab and desk actually is. Joy is a newly constructed building
                that's about half a mile away from main campus. The walk is peaceful
                and pretty beautiful. A nice little chunk of NYC that's not overpopulated
                with visitors and smokers at every corner.
                <br/><br/>
                I got to Joy and my badge didn't scan in, which happened to all the other
                interns at Joy haha. Found my desk space and was nicely surprised with
                two big ass monitors and a really nice chair.
                <br/><br/>
                Got settled in, and met my mentor for this summer. First impressions?
                He's really, REALLY tall and I noticed he had a basketball and a big
                bucket of protein powder. Must be a really athletic guy! He's really nice,
                explains things well (he went over background details about some of the
                lab's work relevant to the project I'll be working on), and gives an
                overall very supportive vibe. I'm excited to be here.
                <br/><br/>
                Got started on some work, and took care of a lot of administrative things
                related to getting set up. Also got to see some more of the Joy building
                since they had an event in the basement and the PI hosting the event
                invited us down to take some leftover food.
                <br/><br/>
                Oh, food! I had lunch at the surgery center right before that, so I didn't get
                any of their leftover food. But let me tell you - the surgery center prices are
                SOO GOOD. You can get a filling lunch (and healthy lunch) for roughly $6. They
                also have free coffee and milk, but I got whole milk since they ran out of 
                2% milk, and was disgusted. Whole milk is basically cream that tastes like rotten
                cheese. Nasty! I added decaf to it, but it still tasted bad.
                <figure className="left">
                    <img src={require("./NY_Images/Lunch.jpeg")} alt="Lunch" />
                    <figcaption>Lunch View</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/Lunch2.jpeg")} alt="Lunch2" />
                    <figcaption>Another Lunch View Because It's Just So Beautiful</figcaption>
                </figure>
                <br/><br/>
                The surgery center cafeteria has a nice interior, but we ate outside, which is
                even more beautiful. There's a giant bridge, not sure which one, right outside that
                stretches over a river with an island in the middle. Roosevelt Island. Beautiful
                place to relax and eat lunch with a couple interns working on the same desk row as me.
                <br/><br/>
                In the afternoon, I made some progress towards one of the tasks my PI
                gave me, and we called it a day around 5. The subway ride back was one of the
                smoothest rides I've had so far - not many people, and the first time I've gotten a seat
                since I've arrived.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Joy.jpeg")} alt="Joy" />
                    <figcaption>Outside Joy</figcaption>
                </figure>
                Had dinner at Brant Park, a nice area of NYC that was hosting a free movie today
                (so it was absolutely packed), with a few people from the program. Nice to keep
                meeting new people and having more familiar faces around here.
                <br/><br/>
                On a side note,
                I like to do my work in the lounge at night since a lot of people come walking
                in and out, so there's people to talk to if I see someone familiar.
                Also met someone new from UCSD, who's in MSK's Engineering Summer Program.
                Nice guy who I talked to for a bit of time while he cooked his dinner.
                He won soccer intramurals at San Diego! That was actually the reason I talked to him
                - he wore a UCSD shirt that said Intramual Champion, and I wanted to know what sport he played.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Joy2.jpeg")} alt="Joy2" />
                    <figcaption>The Sign Outside Joy</figcaption>
                </figure>
                Anyways, now I'm here, writing this blog post instead of reading the paper for our CBSP journal club tomorrow. Thankfully it appears the people I've talked to also haven't
                read it yet, and for the most part, we're just each reading some sections we think
                are relevant and calling it a day. It's too much work to read a research paper on a 
                topic we've never heard of while we're all trying to balance our own work haha.
                Our journal club is only required for CBSP (no other programs have one) because of the
                grant they have for CBSP, which they "wrote to sound like the most amazing program ever" haha.
                The tradeoff for our grant is that we have to do a bunch of extra things that the other
                interns don't have to, but it seems like all these extra things are very worth our time and will be a great learning experience, so I'm not complaining.
                <br/><br/>
                It was a great first day, I'm excited to be back in the office, and I can't
                wait to update you on more soon enough! See you later!
            </div>
    },  
    {
        title: "Day 2: 🎶 Strawberry Fields 🎶 ",
        date: "06/14/2022",
        tags: ["Central Park"],
        content:
            <div>
            To start off Day 2, all of CBSP headed to Zuckerman for our first journal club. It seemed like most of us didn't have the background to understand the paper at all, and also didn't have too much interest initially. Journal club turned out different than we imagined. Instead of sitting
            around and talking about the paper, one of the authors gave a seminar type of presentation
            about the background of the project and talked for a tiny bit about the paper.
            It was actually interesting when she explained the background, so I was happy it turned out that way.
            <br/><br/>
            <figure className="right">
                    <img src={require("./NY_Images/KPonarock.jpeg")} alt="Central Park Rock" />
                    <figcaption>KP on a Rock Near Where We Entered the Park From</figcaption>
            </figure>
            Took head shots for the late arrivals and hung out for a bit on the sixth floor where a couple of the CBSP people work before heading over to Joy. The walk is nice. MSK's main campus (and Joy, Weill Cornell, Rockefeller, all the good stuff) is located in a really nice part of NYC. It's much more peaceful than Midtown, where we live, it isn't crowded by tourists, and it's a genuinely nice looking place where people can live what seems to be a less busy lifestyle.
            <br/><br/>
            I happily arrived at my desk and made some progress with work. The office space is really nice. I feel like I could stay in Joy for way longer than a workday, since I just feel so comfy there. And we have little noise cancelling pods where one person can sit and have a meeting, as well as fancy meeting rooms where people can take their conference calls.
            <br/><br/>
            Today's Tuesday, so we had our weekly lab tutorial event. I don't know what it's actually called, but it's basically where someone gives a 2 hour walkthrough of some software that could be useful to us. We went over PyTorch today and it was cool (fun presenter), but I feel like I don't understand a lot of the ML stuff enough to really think about what's going on at a deeper level. <br/><br/>
            <figure className="left">
                    <img src={require("./NY_Images/CP_Tunnel.jpeg")} alt="CP_Tunnel" />
                    <figcaption>A Nice Tunnel Previewing The Nature to Come</figcaption>
            </figure>
            I recognize all the keywords and can follow the overall idea of what's happening, but I didn't understand some of the questions people were asking, or some of the techniques used. But hey, they're all grad students and professionals and I'm a dumbass undergrad. So I guess it's okay for now. Gives me more motivation to study more outside of the office.
            <br/><br/>
            Also, on a side note, no one wears a mask inside. I guess it's a New York thing, because I can't remember the last time I've seen an entire room of people without masks. I was the only one at the meeting with a mask, so I just took it off so people wouldn't think I'm an uptight asshole haha.
            <br/><br/>
            After the meeting, I got back to work. A lot of technical issues trying to access MSK's High Performance Cluster, but hopefully that'll be over soon. I don't really need it right now anyways, so all is well.
            <br/><br/>
            <figure className="right">
                    <img src={require("./NY_Images/CP_Lake.jpeg")} alt="Lake" />
                    <figcaption>The Lake</figcaption>
            </figure>
            After work, I headed to Central Park with Jaden, another intern on the same floor at Joy. The walk there is like 7 blocks west of Joy. I was SO EXCITED. The walk there was beautiful - nice places to live and nice trees, flowers, and other decorations all over the place. I haven't been in Central Park since the DC trip in 8th grade, so I was excited to see if I remembered anything.
            <br/><br/>
            The Park is a lot different than I remember or imagined it to be. But honestly, that might be because it's so huge and I saw a different part of it last time. Anyways, it was filled with people walking, biking, and just resting on the grass and rocks. There's nice places to walk everywhere, but I was hoping it felt more like a botanical garden. Instead, the area we were in felt very much like just a giant park in th middle of a city. I guess what I mean is that I hoped it was a more closed space, like its own little bubble.
            <br/><br/>
            But it was still really pretty. Lots of nature to see, and some peace. Less noise than walking around NYC, that's for sure.
            <br/><br/>
            <figure className="right">
                    <img src={require("./NY_Images/Imagine.jpeg")} alt="Imagine" />
                    <figcaption>A Tribute to John Lennon, and a Sign I Saw in Musicology 68</figcaption>
            </figure>
            Went back to the New Yorker after about an hour at the park, and got dinner at Five Sense in KTown with the same co-intern. I like KTown a lot because there's lots of Asians. Just feels more like we're back at UCLA. But the crap I ordered today was so not good. I couldn't decide whether to get a soup dish with noodles, dumplings, or just a soup meat dish. I ended up getting ox bone beef tender soup. That sounds SO GOOD RIGHT?!?!?! NO NO NO NO NO DON'T ORDER THAT CRAP IT'S SO NASTY.
            <br/><br/>
            Well, not nasty. It just has absolutely no taste. It's like you're drinking a huge bowl of water and green onions, with some pieces of beef tender that also taste like nothing. Probably the worst dish I'll have in NYC, assuming I don't have something that's actually gross.
            <br/><br/>
            <figure className="left">
                    <img src={require("./NY_Images/Water_for_dinner.jpeg")} alt="Water for Dinner" />
                    <figcaption>Water for Dinner</figcaption>
            </figure>
            Had a nice time with my co-intern walking around Midtown. We visited a couple shops and headed back home. Now, I'm back in the kitchen area (the lounge outside it), where I like to be at night, since it's a nicer place to get some work done than my dorm. More light and also just a nicer environment because there's people around. My only complaint about this place is that the AC is so so loud. But it's okay. I don't really notice it if I get into my work.
            <br/><br/>
            <figure className="right">
                    <img src={require("./NY_Images/NY_StreetView.jpeg")} alt="Street View" />
                    <figcaption>NYC and Its Cabs</figcaption>
            </figure>
            That's about it for today. Excited for what's to come in Day 3, where I can FINALLY go directly to Joy instead of going to main campus first!
            </div>
    },

    {
        title: "Day 3: The Imposter Statistician",
        date: "06/15/2022",
        tags: ["Work", "Hanging Out"],
        content:
            <div>
                Today, I finally got to get off the subway and head directly to Joy. It's a really nice walk - I really like the area MSK is in.
                <br/><br/>
                Got down at my desk and picked up on work from yesterday. I have a pretty straightforward task - plot a bunch of data. But it took hours longer than I expected, because I've never bothered to seriously read the documentation of matplotlib and didn't know how the sizing worked if I wanted to have hundreds of subplots in a figure.
                <br/><br/>
                Anyways, I was slowed down quite a bit by trial and error until I finally figured out how to generate plots that were actually readable, and it felt like a semi-waste of time, but at least now I'm relatively familiar with how these damn plots work. It probably wasn't worth the time since having the plots separated works just fine too but oh well. Note for the future - just do what makes sense and is good enough in terms of getting what we need out of it. Don't have to spend so much time making such a huge plot that's honestly not too useful, at least as of now haha.
                <br/><br/>
                We had a lab meeting today, where someone discussed CRVs (Conditional Randomization Tests, not Cathode Ray Tubes!). It went... way over my head. Even though I've taken a probability class and read a bit of a Machine Learning Probability book, I barely understood what was going on.
                <br/><br/>
                I think it'll take just a bit of time to get comfortable with not knowing what's happening, and being okay with it. Right now, I just feel like a dumbass, like I'm in the wrong place haha. But again, it's good encouragement to think about what types of classes I need to take more of when I'm back at UCLA. I appreciate the exposure I get here, and I'm hopeful that when I'm a grad student, I can be a productive part of the conversation. And hence the name of today's installment of my summer blogs: the imposter statistician (that's me)!
                <br/><br/>
                The lab orders Sweetgreen for us on lab meetings, it seems (today our lab manager asked me what I wanted from there. The name "Sweetgreens" rung a bell, so I dug through my emails and saw that I got an email from our lab manager asking for Sweetgreen orders for a lab meeting going on while I was still in school). It was pretty okay - very satisfyingly healthy, but not filling at all. I appreciate the lab a lot for getting lunch for us. It feels unbelievably nice to save $15. Every dollar counts, especially here in NYC where everything is so god damn expensive!
                <br/><br/>
                Spent the afternoon doing a bit more work, and I still feel like it's a brand new experience! I still feel really appreciative of the desk we have and the nice office space we're in haha. It's just such a nice place to work, and a nice place to be.
                <br/><br/>
                The people here are nice too, although it seems like most people are a bit introverted. No one really talks to each other beyond a basic hello, if you even get that haha.
                <figure className="right">
                    <img src={require("./NY_Images/NearMSK.jpeg")} alt="NearMSK" />
                    <figcaption>A Street Corner Near MSK</figcaption>
                </figure>
                <br/><br/>
                I stayed at work for just a bit of time, waiting for a friend who ended up staying overtime so he could grab dinner with an MSK employee, someone he met at his home college of Duke! That guy has bumped into SO many people here that he knows haha. His math professor sat in front of him on the plane, and he met a student in an algorithms class he TA'd ahaha. Very cool for that guy. Whenever I'm out walking on the NYC streets, I'm secretly hoping that I bump into someone I know haha.
                <br/><br/>
                The highlight of today really was dinner. I expected to eat alone today, but someone asked the GroupMe who wanted to go to dinner, and I ended up going out with a few people including my roommate! My roommate is usually gone because he picks up food from Too Good to Go, an app that gives you pretty big discounts on food approaching their "Use By" dates since stores don't want that stuff to go to waste. He said today he decided not to do it because he realized he was missing out by doing that, missing all our nights out haha. Wholesome moment.
                <br/><br/>
                We walked down South a bit to a taco shop run by Asians (yay!), and had a nice, affordable meal together. Then we headed to Penn Station to get donuts because one of the people in our group (who's an international) has never had Krispy Kreme! Penn Station is really nice inside - lots of stores, it feels just like a mall. I feel like I wasn't able to fully appreciate it when we just arrived here because I was so exhausted haha. But today I saw how nice it really is.
                <br/><br/>
                Got donuts, ate some on our walk back together, and then went to the 15th floor to heat up the donuts so we could enjoy them while they were hot too.
                <br/><br/>
                Afterwards, a few of the people came back to my dorm and just talked, hung out for about an hour. It was a really fun time. Probably the first time here where I feel like we're all really getting alone, having fun, and just genuinely have a good time together. It feels like we're starting to build that community within some of the CBSP kids, and it's really nice.
                <br/><br/>
                That's about it that happened today. I'm doing my laundry now, and my wash cycle actually finished while I was writing this haha, but I wanted to get this pumped out. Goodbye for now blog, 'till tomorrow!
                <br/><br/>
                It's 1 AM now. Coming back late since I was talking to the other 2 UC kids on the 15th floor lounge all night. Very fun time just talking and hangout out with them. Folded my laundry and about to go to bed. Probably not gonna get my 7 hours tonight, but that's okay haha.
            </div>
    },
    {
        title: "Day 4: A Truly American Workday",
        date: "06/16/2022",
        tags: ["Work"],
        content:
            <div>
                We started the day at Zuckerman, attending our second CBSP lecture (I missed the first one last week). Today's lecture was about immunology and immunotherapy. There were actually some familiar topics from Biomed Research 5HA, a seminar I took in Fall quarter this year, and it was overall a very interesting lecture. Great presenter (who's a PI at MSK) teaching us about immunology and immunotherapies, as well as some of the actual research in the area at MSK. Cool stuff.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Zuckerman.jpeg")} alt="Zuckerman" />
                    <figcaption>An Entrance of Zuckerman</figcaption>
                </figure>
                Afterwards, a lot of the CBSP interns stayed around the 6th floor to get coffee and chill. By the time we got to Joy, it was around 11:30 AM, so I made a few adjustments to the plots from before and called it a morning.
                <br/><br/>
                Had a nice lunch at the surgery center as usual. Today's special was BBQ Ribs with corn and roasted potatoes. Besides the potatoes, it was SO good. Can't believe we can get such good meals for less than $10 around here for lunch. Great deal. Also, I asked the cashier lady in the cafeteria, and they don't reuse chefs' specials! They cook something new for the special every single day! How cool is that ahaha.
                <figure className="left">
                    <img src={require("./NY_Images/Cafeteria.jpeg")} alt="Cafeteria" />
                    <figcaption>Cooking at the Cafeteria</figcaption>
                </figure>
                <br/><br/>
                I thought I would have to sit through another meeting where I don't understand a thing (our lab's Thursday journal club), but Ushma (the Director of Scientific Training and Education at MSK) hosted a talk about all those fellowships to study abroad in the UK after undergrad. I'm not really interested in those opportunities, so I went to meet Ushma (first time got to see her in person) and to get some more time with the other interns.
                <br/><br/>
                Fun talk. We were in the graduate student lounge on the sixth floor of Zuckerman, and Ushma just stood there talking about everything she could think about. She's a Marshall scholar and is on the selection board for the New York Marshall Scholar panel, so she knows all about the Marshall stuff.
                <br/><br/>
                Very relaxed enviroment - she cussed a lot, and talked about how she fired an intern one year because he went up to the roof of Zuckerman and dropped a glove full of liquid nitrogen down haha. She was like "no one has done dumb shit as bad as that since then, but that's a really low bar haha!"
                <br/><br/>
                Got back to Joy around 3 PM, and spent the rest of the day trying to troubleshoot my MSK services problems. On my laptop, even though I'm on the internal secure network, I can't connect to some MSK private domains and I also don't have the features enabled on certain websites that I should while I'm onsite (like being able to Quick Badge in through MSKTime).
                <br/><br/>
                I called the help desk, explained my problems, and the lady on the other end was like "uhh  are you an employee?!? This is the patient help desk!" ahaha. She redirected me to the employee help desk, where they told me I just have to use my virtual desktop machine because I'm on my personal computer. But I don't think that's true! Because the other interns don't have my problems! So, another day, another couple hours of wasted time on this BS haha.
                <br/><br/>
                I took the call in one of our soundproof pods in Joy. They are SO cool. You can't hear anything from outside while you're in one, and no one can hear you (we tested them out).
                <figure>
                    <img src={require("./NY_Images/Soundproof_Pod.jpeg")} alt="Soundproof Pod" />
                    <figcaption>A Soundproof Pod!</figcaption>
                </figure>
                <br/><br/>
                I honestly didn't do much at all today. Kind of sad, but it's okay. I figured it's basically what most Americans do when they head to work anyways. Go to our desk, don't talk to any managers the entire time, and just do nothing. Although I guess I didn't really do nothing - I just got nothing accomplished today haha.
                <br/><br/>
                Came home after buying baking soda for my smelly shoes, and toilet paper + body wash for the bathroom. Ate dinner at Bao Bao Cafe by myself, but it was really good so I didn't mind too much in the end. Not sure what I'll do the rest of the night, but I'll probably just relax and see if I can figure some stuff out for work. 'Till tomorrow!
                <br/><br/>
                Oh! I should also mention - Jaden and I were talking about the types of people at CBSP. He said the girl that sits next to me in the office is like at the extreme end where she's not social at all. Anyways, she left today and said "bai bai!", and when I went to Jaden afterwards (he sits at the very left end of our row), he was like "oh wow she said bye today!"
                <br/><br/>
                I thought it was really funny because she actually talked a lot today ahaha. We were walking back from Joy in the morning, and we had a nice conversation (she's from Xi'an and was talking about some Xi'an restaurant and stuff like how she's not offended when people ask where she's from). Seems like she's just more comfortable talking in smaller groups haha. During lunch, I heard her and Jaden talking in Chinese (they're both internationals). So, it was a semi-fun day at work. Nice talking with the other interns, but honestly didn't get much productive done :D
            </div>
    },
    {
        title: "Day 5: Zuckerman Hangouts",
        date: "06/17/2022",
        tags: ["Work"],
        content:
            <div>
                Today we had our third lecture (of five). It was on Clinical Cancer Genomics - very interesting talk by Michael Berger, head of the CBSP program (I think). Really highlighted some of the groundbreaking work MSK is at the forefront of.
                <br/><br/>
                After the talk, me and a few others headed to the Zuckerman Auditorium to attend today's Immunology Symposium. Some talks were interesting, and some were boring since I had no idea what was going on.
                <br/><br/>
                We were excited for the keynote at 2 PM, since the speaker is the CEO of BioNTech, and he was going to talk about mRNA Immunotherapies. I thought it was gonna be on covid, but it was all about cancer stuff which I didn't understand all that much.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Clouds.jpeg")} alt="Clouds" />
                    <figcaption>The Empire State This Morning</figcaption>
                </figure>
                After the keynote, we got Japanese food from a food cart on the street. I finally paid Jaden back by buying him his lunch today haha. We brought it back to Zuckerman and ate it on the 6th floor coffee lounge, Josh, Jaden, Julia, and me. We talked and ended up staying there for like 3 hours. Arielle and Aziz came in later on and stayed for quite a bit too. Really fun time talking and getting to know them, as usual.
                <br/><br/>
                We bumped into Ushma when we left the Zuckerman Auditorium after we listened to the talks we wanted to listen to. So, she knew when we went off on our lunch break, since we told her we were going to lunch. Then, she came in around 1:30 to get coffee in the sixth floor coffee room, talked to us for a while again, and then left.
                <br/><br/>
                Then at like 3 she came in AGAIN and left pretty quickly, and one of the CBSP people said she looked like she wasn't too happy, and we were like she probably noticed that none of us were working at all today haha. So, after that, we all dispersed and went to our own offices.
                <br/><br/>
                I'm a little stressed that I haven't been getting as much time in the office as I had hoped this first week, but these hangouts at Zuckerman have been some of the most fun times. Talking about data, science, etc., but also talking about stuff not related to what we're doing. Funny and happy times.
                <br/><br/>
                Someone asked what we thought about animal experimentation, and someone else mentioned how some cosmetic products like shampoos are tested on animals. Then Jaden was like, "what? What do they do? Get a bunny dirty and use shampoo on it?" Ahahaha that was really funny. The real reason is apparently to test if there's any bad skin reactions. I think that's considered unethical now because I see products that say something like "never tested on animals in the lab."
                <br/><br/>
                When I got back to our office, Joy was empty. The other CBSP interns left early, so I sat there quietly wrapping up some work from the week and reading a paper. I wanted to print out the papers on our office printer (this gigantic, beautiful machine), but it didn't print at first. I realized there was a paper jam, and opened up the printer and cleared it up (there actually ended up being 2). Kind of stressful since I didn't want to break anything. But anyways, stuff printed fine after that, so basically I should be hired to lead the IT Department now.
                <figure className="right">
                    <img src={require("./NY_Images/Walk.jpeg")} alt="Walk" />
                    <figcaption>Got Off on the Wrong Subway <br/>Station, But Beautiful Scenery</figcaption>
                </figure>
                <br/><br/>
                I headed back to my room after work, and had dinner with a new person (Yutaro) from CBSP. At this point, I've met and talked with everyone except for one person who's a New York local and who I heard never even comes to their room haha.
                <br/><br/>
                Spent the night in the kitchen lounge as usual, and bumped into one CBSP person who I talked with for a couple hours. Very nice time, got invited to go to the bars but didn't because I'm exhausted, and now I'm here, getting ready to watch some tennis videos before I go to sleep. Good night blog.
            </div>
    },
    {
        title: "Day 6: First Weekend in NYC Awake",
        date: "06/18/2022",
        tags: ["Exploring", "Central Park"],
        content:
            <div>
                Went to bed late last night, around 2 AM and woke up at 9:30 AM feeling pretty refreshed haha. Getting into a routine around here in NYC and I feel pretty well adjusted to the environment and The New Yorker.
                <br/><br/>
                In the morning, I bumped into Mitch (my roommate) in the kitchen lounge while I was eating, and we also bumped into Sydney, another CBSP intern from UC Davis. She talked about the events going on Central Park today, we decided to go.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Archery.jpeg")} alt="Archery" />
                    <figcaption>Archery in the Park</figcaption>
                </figure>
                The subway ride was smooth as usual, and we entered into Central Park from the Strawberry Fields side towards 72nd Street. Beautiful walk there. It's really nice to see all the activity around in such a peaceful and generally clean place.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FreeWipes.jpeg")} alt="Free Wipes" />
                    <figcaption>Free Wipes From<br/>A Booth From Some School<br/>System in NYC</figcaption>
                </figure>
                The NYC Parks department was hosting a big event today - all sorts of events like archery, ziplining, biking, a little "reading room" where the public library was registering people for cards, and stuff like that. We waited in line for archery because it was one of the ones closest to us as we walked into the event space.
                <br/><br/>
                The "archery" is really just a safe archery (kid friendly) setup where you shoot "arrows" that have just a velcro piece at the head instead of an actual pointy triangle thing haha. The line didn't look too long, but we ended up waiting for like an hour. It was fun to actually do it, but it was very fast and definitely not worth the wait haha.
                <br/><br/>
                Afterwards, we walked around a bit, signed up for library cards (what a nice little souveneir haha), and got some free cheese from a cheese stand and some stuff from NYPD Transportation division (some reflective patches for making us visible to drivers at night, and a little notepad with a pen).
                <figure className="right">
                    <img src={require("./NY_Images/Central_Park.jpeg")} alt="Central Park" />
                    <figcaption>Our Little Group!<br/>We Climbed a Tall Rock</figcaption>
                </figure>
                <br/><br/>
                After Central Park, we walked around and went to a little cafe since Sydney likes coffee. I got lunch there, and then we walked around without any plan. Mitchell got lunch at a Halal food cart, and we ended up staying in a really nice Starbucks for a bit so he could eat. I was looking at the maps on my phone, and saw we were like 2 blocks away from Radio City Music Hall.
                <br/><br/>
                So, we walked over there and to Rockefeller Center, which is right behind Radio City. We couldn't go into most of the cool places, which is unfortunate. I tried going into a really nice looking lobby area at a fancy building, but someone at a desk told me it was private property so we had to leave haha.
                <br/><br/>
                In Rockefeller Center, we got to see some cool signs and stuff talking about SNL and The Tonight Show, so I guess a lot of the famous TV shows are filmed right there. We managed to get onto the second floor, and found an elevator I wanted to go up to the top on. We got in the elevator, and I was really excited until we saw a security guard sitting next to the buttons who clicked on ground floor instead of going up. So sad haha. We left Rockefeller and then just kept walking, so far down from Central Park that we got to Times Square.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Rockefeller.jpeg")} alt="Rockefeller Center" />
                    <figcaption>Exploring Rockefeller Center!</figcaption>
                </figure>
                It was my first time in Times Square this summer, and it was cool to see all the huge TV screen billboards and all the tourists. But honestly, it wasn't anything too special since it feels pretty similar to the rest of Midtown.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TimesSquare.jpeg")} alt="Times Square" />
                    <figcaption>Times Square!</figcaption>
                </figure>
                Got back to our rooms and then I chilled in my room until dinner. Went to a nice dumpling place and brought it back to eat in the kitchen of The New Yorker. Pretty good, but definitely not as good as the dumplings we make at home! I was thinking we could open a damn dumpling shop better than that! But honestly, it was really good haha. Would go again.
                <br/><br/>
                While I was eating, another CBSP intern came in and we talked for a bit before going to Trader Joe's. I normally wouldn't go out after dinner, but since I ate so early, there was so much time that it seemed fine.
                <br/><br/>
                Got a couple weird milk things - cocoa oat milk and soybean milk that has edamame beans on the packaging. Excited to try it, and if it's good, I'll definitely get it again. Also got 5 bananas, and the total came out to be less than $6. Pretty good deal! And it was nice to walk around that area. A lot more peaceful than Midtown, and the way the sunset looks reflecting off the tall buildings in the area is really beautiful.
                <br/><br/>
                Not too much else to do tonight. Just gonna chill and maybe keep reading some papers, although it's nice to not have to actually do work when the work day is over. Tomorrow Mitch and I will go to the Museum of Modern Art with EHS (Eductional Housing Services), since it's the event they're hosting for Sunday (they usually host events on weekends).
            </div>
    },
    {
        title: "Day 7: Glutinous Rice Flour",
        date: "06/19/2022",
        tags: ["The Met", "Central Park"],
        content:
            <div>
                Well, yesterday ended up being a much longer day than I expected. When I went down to the lounge at night (aroud 11) with Mitchell to play pool, I saw Julia the Columbia CBSP person and we talked for a while. Mitchell joined us a short time after wondering why I still wasn't at the pool table after filling up my water haha.
                <br/><br/>
                We hung out until like 12:30 ish and I expected not to play pool anymore, but then Julia asked if we were still playing pool, so we played ended up playing a couple games. It was Julia and me vs. Mitchell because Mitchell is good and we're both ass.
                <br/><br/>
                I woke up pretty late - around 10 AM. Had a quick breakfast - just a banana and some of the chocolate oat milk. Both pretty good. Breakfast is really cheap if I go to the store. I got 5 bananas and two milk cartons for less than $6, and I think it should last me about 6 or 7 days. Pretty nice!
                <figure className="right">
                    <img src={require("./NY_Images/The_Met.jpeg")} alt="The Met" />
                    <figcaption>The Metropolitan Museum<br/>Of Art!</figcaption>
                </figure>
                <br/><br/>
                Then, I stayed in my dorm and watched the 2015 Australian Open Women's Final of Serena Williams vs. Maria Sharapova. I was watching that until I had to go get lunch, but I ended up going to the same dumpling place as last night and ordering vegan dumplings and cong you bing to eat in my dorm.
                <br/><br/>
                After lunch, Mitchell and I headed to the Met Museum of Arts with EHS. When we got to Floor 15 (we meet in front of the EHS office for their events), we saw another CBSP person as well as someone else who was with her. When he introduced himself, I realized it was someone that another CBSP person warned me about.
                <br/><br/>
                APPARENTLY there was some guy in the classes during Week 1 (when I wasn't there) that asked annoying questions that were clearly just to show off (among other things like playing with his hair while he was talking and some other crap haha). Since I didn't have a picture in our group chat, somebody in CBSP thought that person was Kyle Pu! They only found out it wasn't me when they mentioned it to someone else, and that someone else said he met me and thought I was cool, and THEN it got cleared up hahhaa. Anyways, I don't like that guy because he temporarily gave me a bad reputation! And also, my friend doesn't really like him. So by the transitive property, I can't like him either.
                <br/><br/>
                Anyways, we walked to a subway station that's a bit far from where we live (just down the street towards the direction of the Empire State), and it was weird how quickly it went from a busy tourist location, to a more peaceful, nice looking area. We rode to the Met, which is close by to Central Park, and had about an hour and a half to tour around.
                <figure className="left">
                    <img src={require("./NY_Images/Egypt.jpeg")} alt="Egypt" />
                    <figcaption>Egyptian Room!</figcaption>
                </figure>
                <br/><br/>
                I've never really liked museums, so I brought my AirPods along in case I just wanted to listen to music. But I ended up not using them! There was actually a lot of cool stuff. Really old sculptures, armor, and weapons from places all around Earth and throughout history - from the Byzantine Empire, from Ancient China, and from all sorts of places in Europe. I honestly wouldn't go back again, though, just because I'm not too interested in that stuff ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/America.jpeg")} alt="America" />
                    <figcaption>American Exhibit</figcaption>
                </figure>
                Mitchell and I couldn't find where the EHS meet up spot to go back to The New Yorker was, so we ended up going on our way through Central Park and back to our room. We walked from one end of Central Park to the other (the shorter direction of the rectangle), and got to see The Reservoir. It's a really nice, calm lake area with buildings on the other end that give it the classic NYC skyline view.
                <figure className="left">
                    <img src={require("./NY_Images/Reservoir.jpeg")} alt="Reservoir" />
                    <figcaption>The Reservoir in Central Park</figcaption>
                </figure>
                <br/><br/>
                We got back to our hotel and went to the dumpling place for dinner, where I had a Red Bean Mochi Cake desert to celebrate Juneteenth! Haha just kidding. To celebrate Father's Day! Happy Father's Day Dad!
                <br/><br/>
                It doesn't really remind me of mochi, so I thought it was a weird name, until I realized it's probably just a way to say what it is in a way Americans will understand. Glutinous rice cake with red beans would probably sound weird to people that aren't familiar with it!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Horse.jpeg")} alt="Horse" />
                    <figcaption>Popo on Horse in NYC</figcaption>
                </figure>
                And now I'm back in the kitchen lounge, pretty tired from all the walking of the day, and getting ready to relax a bit. Play some pool with the Caltech person because I bumped into her yesterday and she said she likes to play pool (and then Mitchell and I cancelled on her because I went to Trader Joe's), and probably just go back and relax in my dorm before heading into my second week here. See ya later!
                <br/><br/>
            </div>
    },
    {
        title: "Day 8: The Dark and The Quiet",
        date: "06/20/2022",
        tags: ["Work"],
        content:
            <div>
                Today is the day we get off for Juneteenth, but I went to Joy anyways. I like the environment there, and I didn't really want to go anywhere today or stay in my dorm. It was all dark when I entered... very creepy! Sat down to do some work in the dark just to see how it was (when else will I see that quietness again)! After like five minutes I got too creeped out, so I found the light switch and it felt just like what Joy always was, once again.
                <br/><br/>
                Met with Wes in the morning and have some new stuff to do for the next couple days (hopefully it only takes a couple days to finish). It was nice to have the office essentially to myself. There were a couple other people so I wasn't completely alone, but it was for the most part a relaxing time where it felt like I had the space to myself.
                <br/><br/>
                The work I have to do right now is supposed to be kind of trivial - just apply a previous software library Wes developed to some data we have now. Basically, I just have to set things up right and it'll run smoothly. The first part is mostly done, but since there's so much data, I haven't been able to get the actual plots back because there's too many to generate all at once. Left it running for a couple hours on my laptop, but it didn't finish, so oh well.
                <br/><br/>
                After work, I headed back and put some sugar packets I saw in the office in my Trader Joe's soybean milk. The milk is really good when it's heated up. Tastes just like actual soybean milk. I was excited to try the sugar in it, but that type of pure cane sugar doesn't seem to dissolve well in the milk. Most of it gathered at the bottom of the cup, and the drink wasn't sweetened much haha. Need to find some other type of white sugar or something.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Soy.jpeg")} alt="Soy" />
                    <figcaption>Soy Milk and the Kitchen Area</figcaption>
                </figure>
                Tonight, EHS was hosting a hot dog night. They were cooking while I was drinking my soymilk, and it smelled really good. But the guy cooking the sausages took them out of the package with his bare hands, so I felt a little disgusted haha.
                <br/><br/>
                Went back to my room and sat around for a while until I decided to go to a Thai food place for dinner by myself. When I went in, the waitress asked if I wanted to stay in or have it to go, but I couldn't decide yet, so I just said I would stay in "for now." Then she got all excited, sat me down, brought menus over, and got a ton of water, and started to talk to me in Chinese haha.
                <br/><br/>
                It's strange she just assumes I know Mandarin ahaha. But anyways, I ate the Pad Thai and it was pretty good but a little too sweet. Their chicken, though, is REALLY tender. So tender that I thought they were little beef strips at first. Tipped $2.88 to support my fellow Chinese, and then left back to my room. The actual restaurant is less than 1000 feet from the hotel, so it's really convenient. Will probably go back some time and order more interesting foods.
                <br/><br/>
                Overall had a pretty boring day until night time. At night, I went into the kitchen area as usual to sit down, drink my Miralax, and work on my blog. On a side note, so many people have seen me in the kitchen now and asked what I'm doing, so quite a few people know that I blog haha.
                <br/><br/>
                Anyways, as I was going down the stairs to the kitchen (the elevators always take too long), I heard someone call from behind me, but didn't register it was my name. When I got out of the staircase, I heard my name, looked back, and saw Sydney, the UC Davis CBSP person. We sat in the kitchen for a while talking about what we've been up to, and then Sanjana came in, the UC Berkeley CBSP person, who was gone all weekend in Jersey visiting her cousins.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Thai.jpeg")} alt="Thai" />
                    <figcaption>The Thai Restaurant<br/>Around the Block (Blurry)</figcaption>
                </figure>
                We were talking about other people in the program, having a good time, and talking about what we want to do while we're here. At some point, Sydney wanted to get something to eat, so we went to McDonald's, which is right across the street from us. I wanted to stay inside and work on my blog while they went to pick up some dessert, but I ended up joining them.
                <br/><br/>
                We came back and Sanjana kept talking about wanting to go to Central Park after work, so we thought about how to pitch the idea to the group. Central Park is close to MSK, but it's hard to bring all our stuff around in our backpacks, so we want to come back to the hotel before going to Central Park. We decided on doing a picnic, since the sun sets at like 8:30 around NYC, so we can have some quality time outside.
                <br/><br/>
                We were planning how to pitch the outing to our CBSP group chat, since a lot of times we just get ignored when we pitch events in the chat. Sydney pointed out that it's always just been us 3 who's trying to get people to go out, and we were like yeah it's kind of sad but also cool how we're trying to actively get people together.
                <br/><br/>
                Since Sanjana wanted to go to Central Park in the first place, she pitched it to the group chat and Sydney and I were coming up with ways to support her while pretending like we weren't all together, planning the event right next to each other haha. See the picture for what we ended up doing.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Chat.jpeg")} alt="Chat" />
                    <figcaption>The Beautifully Orchestrated<br/>Event Organization</figcaption>
                </figure>
                Excited for the outing tomorrow! Finally an event that's not just going to eat somewhere. It'll be nice to hang out for a bit. This time, we have a couple people that don't usually come out, so it'll be cool. But also, there's other people that just never or almost never come out with us, which is kind of sad.
                <br/><br/>
                Tomorrow, I hope to be a bit more productive at work. We have our Tuesday lab tutorial session from 11:30 AM to 1:30 AM which is honestly pretty draining. I like the idea of everyone learning something from someone in the lab together, but it just feels too much like a college lecture. It's hard to sit there for a couple hours listening to someone explain something. It'll get better if I actively participate more though. Last time, I didn't say a word the whole time haha.
                <br/><br/>
                Other than that, there's a cool talk by a Caltech professor at 10 AM. Not sure if he's actually flying in for this, so I'll just attend the Zoom meeting instead of walking all the way to ZRC (Zuckerman Research Center on Main Campus) to possibly just watch a Zoom presentation in the auditorium. Plus, I can hopefully get some other stuff done while he talks, if I end up not being that interested. The talk topic is really cool, though, so I honestly might just end up walking over to ZRC anyways tomorrow morning (after reading the talk's abstract, I probably will haha. Seems like really cool stuff).
                <br/><br/>
                Excited to see what this week brings. Oh! And our group at night is already planning to explore around the Chinatown, Soho, and Little Italy area on Saturday after we go to the Brooklyn Bridge event with EHS. This weekend should be packed with a lot of fun stuff to do! Very excited! This past week, I've honestly felt like this whole CBSP thing has been cooler for the people I've met and the things we can do together instead of the actual work. That's kind of sad, but also kind of pretty cool haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Yorker.jpeg")} alt="NYer" />
                    <figcaption>The New Yorker @ Night</figcaption>
                </figure>
                Maybe it's because I'm realizing I probably don't want to go into data science, so I don't know if computational bio is really the right career for me. But, I have time to decide, and the bioinformatics minor will really help me decide once and for all haha. I'll also probably reach out to my CS 180 professor after the summer and talk to him about this type of stuff to see what he thinks, since he runs UCLA's eHealth lab, which is a lot of data sciencey stuff. He probably knows the spectrum of CS and computational bio careers well, and can provide good guidance on where to go if I don't want to do heavy stats, probability, data sciencey stuff, and instead want to build new algos and build new stuff.
                <br/><br/>
                I guess that's the difference - I like building new stuff. The projects in comp bio I've always done have been a lot of just fitting different models to data with the goal of answering some biological question. I enjoy the actual questions asked in comp bio, but I don't entirely enjoy the actual work it seems.
                <br/><br/>
                These next couple months will help me understand more, so I'm excited. But boy oh boy - every time I write stuff in this blog, I hope to God no one from MSK sees it yet haha. THIS IS FOR FAMILY EYES ONLY!
                <br/><br/>
                Till tomorrow. Good night!
            </div>
    },
    {
        title: "Day 9: Xi'an 牛肉",
        date: "06/21/2022",
        tags: ["Work"],
        content:
            <div>
                Today, the office was lively - completely filled with people! I haven't ever seen so many people on our floor. It feels like a bunch of people came to lab today for some reason. Actually, it might be because there's a celebration today for a lot of graduating PhD students. The people in the lab behind me (Sorab Shah's Lab) probably had to all come in person because of that. That'd actually make sense. A lot of the labs around here don't actually require their people to come in person most days.
                <br/><br/>
                I started the day pretty tired. Got plenty of sleep, but woke up in the middle of what felt like a really deep sleep haha. I couldn't go poo in the morning like I usually do, so I just went at Joy (first thing in the morning)!
                <br/><br/>
                Had a little instant oatmeal cup I got from EHS's Breakfast To-Go event last week (they seem to host one every 8 days), which was pretty filling. Then, I headed over to ZRC to attend Dr. Van Valen's talk. He's a professor at Caltech, and gave the last talk in the computational oncoloy seminar series for this summer. A talk on how he believes the future of computational biology data lies in images, as well as his lab's work in the field, deep learning models, etc. etc. (he says "etc. etc." a lot haha).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Xi'an.jpeg")} alt="Xi'an" />
                    <figcaption>Xi'an Famous Foods</figcaption>
                </figure>
                The talk was pretty interesting, and I sat with the CBSP person from Caltech, Arielle. She wore her Caltech shirt and jacket. Must've been so cool to be her during the talk haha. I was imagining what it'd be like to see a UCLA professor give a talk here. Arielle said it felt like she was an insider, which made sense haha. Very cool.
                <br/><br/>
                He mentioned an undergrad from his lab, and he said he was losing them to us, since he's coming to MSK for grad school haha. Dr. Van Valen said he likes it at MSK cuz we do cool work, and the people here always ask interesting questions. On a side note, I saw him on my floor in Joy later in the afternoon. Seemed like he was being given a tour of the entire place ahaha.
                <br/><br/>
                I was lucky to walk with a few people from the Tansey Lab on the way to the talk. Bumped into them as we were all going down the elevator. Talked to a couple of the PhD students and got to know them, which was nice, but what was even cooler was how I met a guy from CSIP, the Computer Science Internship Program, who said he's from San Jose, CA! When he said that as we were going down the elevator (one of the PhD students asked him where he's from, since he just came in a couple days ago), I shouted out "SAN JOSE CALIFORNIA?!?! THAT'S WHERE I'M FROM TOO!"
                <br/><br/>
                I asked where he went to high school, and it was BASIS!!! JESUS CHRIST BASIS TUITION IS OVER $30,000 A YEAR CAN YOU BELIEVE IT AHAHA. I told him I went to ST, and he's like "oh yeah, I live really close to there." What a small world! I can't believe we both ended up at MSK, in the same damn lab ahaha. What are the odds. Kind of insane! He went to Stanford for his undergrad, and is going back for a Masters in Stats before he does a PhD.
                <br/><br/>
                After the talk, I pretty much immediately went to our lab's modeling group tutorial. They served lunch this time! I heard Wes say that the department is paying for the food, and he hopes people other than the people in our lab show up to these meetings, since he'd feel bad making the department just pay for our lunches ahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Google.jpeg")} alt="Google" />
                    <figcaption>Google in Chelsea, NYC</figcaption>
                </figure>
                I finally got to get some quality work time in during the rest of the afternoon. Again, what I'm doing right now isn't supposed to be that hard, but I spent some time figuring out how to get all the plots generated. There's a massive number of plots we need, so there's just some slight tricks we have to play to get them all efficiently. Other than that, I still feel like an outsider to what's actually going on with modeling the data and stuff like that, because I just don't have the stats foundations. It's okay though. I'm here to learn!
                <br/><br/>
                It was projected to rain tonight in NYC (which it did), so we had to call off our Central Park picnic plans for the day. We're postponing it to Friday, which it seems like more people will be able to come to anyways. So that's great!
                <br/><br/>
                We instead went out with a smaller group (Sanjana, Aziz, and Mitchell). Sanjana suggested going to Xi'an Famous Foods since she said she heard a lot about that Chinese spot. But first, we headed over to Just Salads, where Mitchell had a $5 Too Good to Go meal to pick up (he inspired us to all just get it tomorrow for dinner haha).
                <br/><br/>
                It was raining pretty hard, so thank God I have an umbrella now! When we were outside, I was putting my umbrella over some random people walking by, and only one of them said thank you to me! The audacity! People around here don't appreciate other people enough! It's okay though. Such a busy city, and everyone around here seems like they're in a rush to do something. The lifestyle does not seem like the lifestyle to me haha. I like the peace and quiet.
                <br/><br/>
                But on the bright side, it's nice and chilly here in NYC, even during the summer. It's kind of insane how different the climate here is compared to home. It doesn't even feel like summer haha.
                <br/><br/>
                The dish I ate for dinner (spicy and tingly beef hand-ripped noodles). It's basically one LOONNNNG ass noodle with really nice pieces of beef in a mildly spicy soup. I ordered the least spicy level, expecting it to still be terrible (since that's how it goes at all the Chinese and Sichuan restaurants I've been to in LA), but it genuinely was mild this time haha. That was a nice surprise. It literally might've been the best meal I've eaten in NYC so far. So refreshing to have a good noodle dish for once haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Noodles.jpeg")} alt="Noodles" />
                    <figcaption>Hand-Ripped Noodles!</figcaption>
                </figure>
                Fun night too. I really like the people in the group we were with today, and want to go out with them more for sure.
                <br/><br/>
                The rest of tonight should be pretty peaceful. Don't plan on doing much, except getting started on the UCLA scholarship application. The engineering application is due ridiculously early this year (like July 7th or something), and they never announced it (I just found out because the general application's priority deadline is June 30th, and I saw in my portal that the Engineering Application is already open).
                <br/><br/>
                Let's hope and pray that this year I actually get something cool haha. It was tough and so sad to get rejected for an engineering scholarship last year. I like to end these blogs on a good note, so let me just say that 西安名吃 will DEFINITELY have my business again!!!
            </div>
    },
    {
        title: "Day 10: Not Really Working",
        date: "06/22/2022",
        tags: ["Work"],
        content:
            <div>
                Wednesday! It's already the middle of the week. I don't know how the days are going by so fast! I'm having too much fun here.
                <br/><br/>
                Today I headed into work and had a mostly uninterrupted workday. I spent the morning fixing up some work I talked to my PI about on Monday, but since it takes so long to run on my machine, I had extra time to work on other things while I waited for that.
                <br/><br/>
                We wanted to have a figure with a huge number of plots, like a grid of about 300 by 100 many plots. That's too many plots for commonly used plotting software to generate efficiently, so I wanted to find my own way to do it efficiently.
                <br/><br/>
                Ended up spending most of the day working on this side project, and I felt pretty engaged. Very energized building it and getting it to work. I was so focused that I held in my pee for so long that it felt like I was about to start leaking.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Saw.jpeg")} alt="Saw" />
                    <figcaption>We Saw the Saw Lady<br/>In the Herald Square Subway!</figcaption>
                </figure>
                Haven't focused like that in a long time haha. I feel like the most recent time I worked on something for that long at once was in CS 35L this past fall quarter. Just working non stop on a project without thinking of anything else. It was nice, but not too relevant to what I should be doing. I wonder if my PI is gonna be upset haha. It feels like things are moving slower than they should be.
                <br/><br/>
                Today we had our lab meeting, but I went late because of a pan-internship lecture that gave a broad overview of current cancer research. It was only mildly interesting, and I had more fun chatting with some MSK interns I know over Zoom during the meeting haha.
                <br/><br/>
                Joined my lab meeting late because of the lecture, and once again, I had no clue what was going on. It was a PhD student (a lot of PhD students here from universities, just visiting for the summer) explaining models for image generation. So much stats. I couldn't really follow anything haha.
                <br/><br/>
                Talked to the Stanford Masters student in our pantry afterwards, and he said he also couldn't really follow in the second half. That made me feel a lot better, since he said he did a lot of ML classes in his undergrad, since he could basically specialize in it. He also did a pure math major! Now he's doing a Masters in stats haha. Very well rounded guy in all things comp bio, it seems.
                <br/><br/>
                The rest of the work was much of the same thing. At the end of work, Jaden came over and we talked about what I was doing. He gave me a few pointers on how to actually open the resulting image file I generated, which was really helpful, since I don't think I would've figured it out if I didn't talk to him. He knows some stuff about various image formats that I didn't even know existed haha.
                <br/><br/>
                I also did find out yesterday that Carnegie Hall is right near us here in NYC. Jaden has been going to a lot of the concerts. Definitely on my NYC bucket list now!
                <br/><br/>
                After work, I headed back and went out with Aziz and Mitchell to pick up Too Good to Go orders. Aziz and I got Just Salad, a place Mitchell got yesterday. The serving size was HUGE - a very heavy bowl. I was so excited, because they give you so much and even let you choose your own dressing.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Train.jpeg")} alt="Train" />
                    <figcaption>The Train Station!</figcaption>
                </figure>
                Then we went to the train station next to us to pick up Mitchell's food. The contrast between how dirty and smelly it can be outside these buildings and how nice and beautiful the insides are always surprises me. NYC is so nice when you're inside buildings, but not so much when you're outside. At least in Midtown, the area we're in haha.
                <br/><br/>
                We went back to our 15th floor to eat dinner with Sanjana, the same group as last night's dinner. Very nice to have people to eat with.
                <br/><br/>
                The rest of the night I was just in the lounge, talking with Josh (an ESP kid) and Julia. That's why this blog is about an hour late! It's almost 12 AM geez! I've been pretty tired at work these past couple days, so I'll try to get to bed earlier. I've been averaging around a bit less than 7 hours of sleep per night, which isn't ideal, but isn't bad either.
                <br/><br/>
                Tomorrow I have a CBSP class to attend in the morning, and then hopefully a productive workday ahead!
            </div>
    },
    {
        title: "Day 11: Chef's Special",
        date: "06/23/2022",
        tags: ["Work", "Cooking"],
        content:
            <div>
                We started the day in ZRC. Our 4th of 5 classes. Today's lecture was on evolutionary biology and how it relates to cancer. I was more entertained than I expected to be! A lot of it was intro genetics review (from like LS 7B), but I enjoyed learning more about how it all relates to cancers.
                <br/><br/>
                Aziz almost convinced me to go hang out in the 6th floor coffee room after the lecture, but I said I really had to go to Joy, since I feel a bit behind on work.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Church.jpeg")} alt="Church" />
                    <figcaption>A Church on the Way<br/>From ZRC to Joy</figcaption>
                </figure>
                Walked over there and got started on my day! First thing: my PI thought it was cool what I did yesterday, and had some suggestions on what could be done better. I think he was more excited about it before actually opening the image, since I think he thought I went into the actual matplotlib internals and optimized that code, but I was really just using a relatively straightforward approach that works one layer above matplotlib.
                <br/><br/>
                But anyways, I'm happy I have that tool for myself. In the afternoon, I went to the reading group. James, a stats (I think stats haha) PhD student from CMU led us through a paper on causal inference: the PC algorithm and stuff like that. Never heard of this type of stuff, and I thought James did a great job stepping through an intro of how everything worked.
                <br/><br/>
                I followed it for the most part, but got lost towards the end. Good enough for  me! I read a good portion of the beginning of the paper, and also a related paper, so I feel like I went in a bit more motivated than I expected to be.
                <br/><br/>
                At the end of our lab's reading group, I had about an hour left of work. The program I need to run keeps crashing on my computer, so I've been trying to get set up on MSK's high performance cluster. Finally figured out why I wasn't able to reach some of MKS's internal domains (it was because I manually configured my DNS servers a long time ago and forgot), so I can finally clock in and out easily on MSK Time (intead of going through a virtual desktop).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Bike.jpeg")} alt="Bike" />
                    <figcaption>Bikes on the Way</figcaption>
                </figure>
                The only problem is that I still can't access HPC (high performance cluster). I realized (and the software guy Jeff) that I sent the HPC request people the wrong data for my SSH public key. I didn't send the whole thing, so my authentication wasn't working out. Hopefully they fix it soon so I can actually make progress.
                <br/><br/>
                Once that's fixed up, I'll use the plotting thing I built to get all the plots generated for this next step we talked about on Monday. Every day I feel like things are moving slower than they should, but I've also realized it's because there are a lot of distractions in our workdays (CBSP lectures, walking from ZRC to Joy, reading groups, etc.).
                <br/><br/>
                After work, I didn't have any dinner plans, and Jaden mentioned he was going to start cooking. So, we went to HMart together to buy a pot and some other supplies. On the subway ride there (same stop we usually get off at), we talked about what's good in dumplings, and how 韭菜 is really good.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Chop.jpeg")} alt="Chop" />
                    <figcaption>Shopping Spree</figcaption>
                </figure>
                So, at HMart, after he found some dumplings he said come from a good brand, I saw a package that said they were chive dumplings. We ended up buying two of those, and brought it back to the 15th to cook it up! The electric stoves are pretty nice. There's a fast boiler spot on each electric stove that got our water boiling really fast. We boiled it to clean it first, since we're very sanitary people!
                <br/><br/>
                The dumplings turned out to be okay. They tasted really good, and the wrappers are surprisingly QQ, but there's barely anything on the inside. We each ate 15 and called it a night. Happy to be saving a good amount of money, though!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Dumpling.jpeg")} alt="Dumpling" />
                    <figcaption>Dumplings I "Cooked"</figcaption>
                </figure>
                Jaden will keep all the kitchen supplies, so he paid for them. When we were walking back, I mentioned how it's so nice we'll be saving money, and he made a comment like, "yeah. You'll be saving money starting now, but it'll be a while before I start saving money!" So then I was like, fine fine haha, I'll pay for like half of the pot's price. I'm fine with that because honestly, we'll both be using it a lot, and the pot was only like $26. Pretty nice pot too!
                <br/><br/>
                Tomorrow, we start the day with our last class, and then I have a full workday ahead. I really hope I can get on HPC! Till then! Oh, and tomorrow is Central Park!!! How exciting to finally have a day most of the CBSP people are coming out haha.
                <br/><br/>
                Just came back from like 3 hours talking with Sanjana, Julia, Josh, and Arielle. Cool people! Arielle, Julia, and I bonded over our bowel problems.
            </div>
    },
    {
        title: "Day 12: Late Nights",
        date: "06/24/2022",
        tags: ["Central Park"],
        content:
            <div>
                We started off the day at CBSP's last class, a class on metastasis. It was a semi-interesting lecture, but I thought the presenter was a little rude. Someone took a picture of her slide and she was like, "PLEASE don't take pictures of my slides. In general, you should never take pictures of scientific presentations." The sentiment is fine, but she said it so arrogantly it annoyed me.
                <br/><br/>
                After the class, my deskmates at Joy walked over to Matto's and we had a light breakfast outside. I got a spinach boureka, thinking it would be really good, but it was just too much dough.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Subway.jpeg")} alt="Subway" />
                    <figcaption>Escalator Up to Sunshine</figcaption>
                </figure>
                Got to Joy and started bothering IT to help me fix my access to HPC again, and it finally started working! Tried setting up my environment on HPC, but ran into a lot of complications. What I did figure out, I wrote into a guide on our lab's GitHub Wiki repo. Hopefully it'll save some future members some headache if they ever choose to use the type of package manager I use, although I hear that a different manager is more popular in our lab.
                <br/><br/>
                Didn't do much after that, since one of the packages I need to run what I need to run isn't easily installable on HPC.
                <br/><br/>
                I left work a bit early to go to the MSK outpatient and research building on 74th street, which is 13 streets down from Joy. A really nice walk. That area of NYC is really a high-end, expensive place to live.
                <br/><br/>
                I walked there since a couple of the CBSP people, Julia and Sanjana, and our ESP friend Josh wanted to go to the gift store there (we knew about it cuz Josh works in the building). We looked around the building first, which has a really nice view of Roosevelt Island and the part of NYC on the other side of the river.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Roosevelt.jpeg")} alt="Roosevelt" />
                    <figcaption>View From the Building</figcaption>
                </figure>
                They have all sorts of stuff at the little gift shop, but I was only interested in getting an MSK shirt, which I did!
                <br/><br/>
                Afterwards, we walked back to a subway station to head back to the New Yorker to drop our stuff off before heading to Central Park. We ended up deciding to go to Columbus Circle, which is a really nice area with lots of shops at the edge of Central Park. Also saw Trump International Hotel there haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Food.jpeg")} alt="Food" />
                    <figcaption>Food in the Subway</figcaption>
                </figure>
                There's a subway station off of Columbus Circle with like 20 restaurants inside. It's a really cool alleyway that actually has quite a lot of Asian restaurants. I ended up buying a pork bowl with rice and pickled vegetables that was really good. Would definitely go there again!
                <br/><br/>
                About like 8 people total were there, all CBSP except for Josh. It was a nice time sitting in some of the grass area at Central Park at a time of night when there were a lot of fireflies near us starting to light up. Very nice vibes.
                <br/><br/>
                When we got back to the New Yorker, Josh wanted to play pool, so a few of us went to the 15th floor to play. Had a fun time there practicing our pool skills. Only Aziz is actually really good haha. It was Aziz, Sanjana, Kiara, Josh, and me. Julia joined us later in the night, when we were all pretty tired of pool haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CC.jpeg")} alt="CC" />
                    <figcaption>Columbus Circle</figcaption>
                </figure>
                We were hanging around the lounge area talking and then decided to move to the kitchen area where I usually sit to take my Miralax. We ended up going to CVS first to buy some drinks - this crisp apple beer type of thing. It tasted kind of nasty, I only had a gulp haha. It was only 5.something% alcohol. Basically Heinekin with some sparkling apple cider taste. Not that good!
                <br/><br/>
                Then, we all just sat there and hung out until almost 2 AM. Fun times.
            </div>
    },
    {
        title: "Day 13: The Boroughs",
        date: "06/25/2022",
        tags: ["Brooklyn", "Statue of Liberty", "Cooking"],
        content:
            <div>
                Saturday! It's finally the weekend! I ended up sleeping around 3 AM last night, so I woke up at 10 AM. Felt pretty refreshed and ready for the weekend!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Lunch15.jpeg")} alt="Lunch15" />
                    <figcaption>Lunch Room!</figcaption>
                </figure>
                I started the day out slow. Just in my room trying to see who would be interested in going to EHS's Brooklyn Bridge event tonight. I didn't have the energy to go outside to pick something up to eat, so I decided to grab the pot Jaden and I bought to cook up some dumplings.
                <br/><br/>
                It was a nice, quick, and easy lunch. I ate in one of the TV rooms on the 15th floor. It's actually a really comfortable room with a TV, sofas, pillows, etc. Really nice place honestly. This entire EHS organization is pretty good. Aziz and Josh were watching a TV show, so I just watched part of it while I ate my lunch. Also shared a few dumplings with them.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Wholesome_Subway.jpeg")} alt="Wholesome_Subway" />
                    <figcaption>Wholesome Subway Picture</figcaption>
                </figure>'
                Afterwards, we started getting ready for Brooklyn Bridge. Since the weather here in NYC has been really nice the entire time I've been here, I wore my usual outfit - a T shirt and long pants. When we all met up on the 15th floor, Josh mentioned how hot it would be and suggested I get some shorts, so I went back up and got my tennis shorts. Ended up being a great suggestion because it made it SO much easier to walk around, and so much more comfortable. It really WAS burning hot today.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Hair.jpeg")} alt="Hair" />
                    <figcaption>Hair in the Morning<br/>Because I Showered Before Sleeping</figcaption>
                </figure>
                We headed to the subway, which has been having a lot of delays recently. I think they're doing some sort of construction, so some of the routes have been getting detours. We ended up taking about an hour to get to near the Brooklyn Bridge! The subways were absolutely packed. So packed that we were standing one right after the other, leaning on each other as the subway shook left and right while speeding along. I was thinking about those videos you see every now and then of Japanese people cramming into the subway cars until there's absolutely no space left.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SubSelfie.jpeg")} alt="SubSelfie" />
                    <figcaption>Subway Selfie!</figcaption>
                </figure>
                Once we got to Brooklyn and stepped outside, it felt SO hot. Like that wave of hot air you get when you open the oven door after baking. It just felt absolutely insane, but it was probably only like 80 something degrees haha. Felt so strange for NYC, since it's been relatively cool around here.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Brooklyn_Bridge.jpeg")} alt="Brooklyn_Bridge" />
                    <figcaption>The Brooklyn Bridge!</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/Statue of Liberty.jpeg")} alt="Statue of Liberty" />
                    <figcaption>Ms. Liberty</figcaption>
                </figure>
                I met a couple of new people, one Sanjana knew, and the other, the friend of Sanjana's friend. We walked along the bridge together, which had absolutely beautiful views. We could see the Manhattan skyline - tall glass buildings, clouds in the sky, and a shiny, hot sun. Water stretched in both ways far far away. Islands scattered around. Even the freaking STATUE OF LIBERTY was visible from the Bridge. Truly a beautiful moment.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Candid.jpeg")} alt="Candid" />
                    <figcaption>One of the Best Pictures<br/>I've Ever Taken</figcaption>
                </figure>
                After Sydney pointed out that we could see The Statue of Liberty from where we were, I kind of had a moment where I was thinking, "damn. That's literally such an American icon. Something that everyone's seen, that literally represents the country we live in." And it was just right there. Kind of small because of how far away we were, but it still felt so grand.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Group.jpeg")} alt="Group" />
                    <figcaption>Group Pic on the Bridge</figcaption>
                </figure>
                On the way down from the Bridge, I saw an Asian man walk past me and I realized it was Haoran, a PhD student in my lab haha. He was with a woman, so I guess he's also exploring NYC during his time here (he's from UT Austin I think. Some school in Texas for sure).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Aziz.jpeg")} alt="Aziz" />
                    <figcaption>"Moderately Positive" Aziz</figcaption>
                </figure>
                Our EHS staff leader went back to The New Yorker, and most of us stayed in Brooklyn to explore. Oh and on that note - it kind of felt like we were on a field trip because we were all just following this EHS staff person haha. She was cool and really nice though. I recognized her because she's the RA that told our group last night that The New Yorker is a "dry building," so we're not actually allowed to have alcohol visible ahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Aziz_Hug.jpeg")} alt="Aziz_Hug" />
                    <figcaption>Happy Picture</figcaption>
                </figure>
                Anyways, I stayed in Brooklyn with Sydney, Josh, Sanjana, Aziz, and a new person Lauren. Sydney had a flea market she's been wanting to go to, so we went there. A nice little outdoor area that's essentially an outside thrift store. Basically the Farmer's Market, but instead of food, everyone was selling stuff like clothes, tote bags, canvas paintings, jewelry, etc. A cool little area in Brooklyn called Dumbo that seemed to have a good amount of activity.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Touch.jpeg")} alt="Touch" />
                    <figcaption>I Touched the Brooklyn Bridge!</figcaption>
                </figure>
                Soon after, Josh and Sydney split off. Sydney went to a book store and Josh went somewhere else in Brooklyn to meet up with a friend. The rest of us headed to an area in NYC I've been wanting to go to for a long long time - Chinatown!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Dumbo.jpeg")} alt="Dumbo" />
                    <figcaption>Dumbo</figcaption>
                </figure>
                We took the subway to Chinatown, but had to take a transfer to a different station to get into the most active street of Chinatown, Canal Street (according to Josh). The train transfer we had to take was 19 minutes away, so we were a little frustrated. Luckily, this station is probably the only station that has a few seats, so all 4 of us were able to grab a seat. A much needed break since my feet were hurting and we were all just plain exhausted from walking around in the heat.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Subway_Man.jpeg")} alt="Subway_Man" />
                    <figcaption>The Subway Was Packed</figcaption>
                </figure>
                We looked around on Maps and Yelp to see good restaurants nearby. Sanjana found a place Shu Jiao Fu Zhou. It was actually close to the station we were waiting in, so we just ended up walking there.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Fuzhou_Menu.jpeg")} alt="Fuzhou_Menu" />
                    <figcaption>Fuzhou Restaurant Menu</figcaption>
                </figure>
                It was really nice seeing such a Chinese area around NYC. Felt like home, where I was born in Tianjin. When we went in and looked at the menu, I was absolutely shocked at how cheap it was. You could literally get 6 dumplings for THREE DOLLARS. Literally the cost of a subway ride can buy you dinner in Chinatown! They also sell frozen dumplings, 50 for $12. Definitely gonna buy that once I finish my HMart ones haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CT_Receipt.jpeg")} alt="CT_Receipt" />
                    <figcaption>4 People's Dinner</figcaption>
                </figure>
                After dinner, I saw a Chinese lady selling clothes on the street and I tried on a bucket hat. I asked her, “很帅吗？“ And then she responded, ”很帅。“ I was touched by her compliment, so I put the hat back on the table and didn't buy it! In all seriousness, I didn't buy the hat because it didn't fit my head. I still haven't found a hat that can fit my head. So sad.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CT_Trash.jpeg")} alt="CT_Trash" />
                    <figcaption>Cool Trash Can in Chinatown</figcaption>
                </figure>
                We walked around and found a boba shop. Aziz wanted some milk tea, so we went in and got some stuff. I was originally going to get some ice cream because I have yet to eat ice cream in NYC, but then I saw that they had milkshakes and ordered a Madagascar Vanilla milkshake. It was kind of watery - not too sweet, and the vanilla flavor was not too strong. Honestly a good milkshake since I didn't want to feel too unhealthy haha. That milkshake cost more than how much I spent on dinner for 6 pork and chive dumplings and a beef tripe noodle soup. Insane!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/88.jpeg")} alt="88" />
                    <figcaption>88</figcaption>
                </figure>
                There was a guy in the boba shop that I heard a couple people say happy birthday to, so on the way out, I said happy birthday to him. He seemed confused but then really happy haha. We walked along without a specific place in mind to go to, and ended up realizing we were in Little Italy! Very close to Chinatown, which is pretty cool.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Chinatown.jpeg")} alt="Chinatown" />
                    <figcaption>Group in Chinatown</figcaption>
                </figure>
                We walked down some really pretty streets in Little Italy and ended up going to a little store called Patches and Pins, where Sanjana bought a sweatshirt and I bought a cool pin that says "Carpe the fuck out of this diem." Very excited to put it on my backpack and go back to school, so I can tell everyone I got it in NYC :D
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Canal_Station.jpeg")} alt="Canal_Station" />
                    <figcaption>Our Subway Station<br/>Going Back Home!</figcaption>
                </figure>
                I don't think I would've bought it honestly, but Sanjana said it seemed like something I would say haha. Afterwards, we walked to the station on the main street we would've gone had the train not been 19 minutes away haha. We rode it right back to Penn Station, and now I'm here, exhausted, on the 15th floor, probably gonna hang out for a bit with friends at night, before going to bed. Very packed and fun day! See you tomorrow!
            </div>
    },
    {
        title: "Day 14: Freedom at Last",
        date: "06/26/2022",
        tags: ["New York", "Statue of Liberty", "Staten Island"],
        content:
            <div>
                Today was a CRAZY day. Woke up around 10 again, and had to figure out what to do for the day. I knew there were a bunch of us interested in going to NYC's Pride March, but we didn't have a plan for how we were all going yet.
                <br/><br/>
                We ended up getting a last minute plan of Sanjana, her roommate Kiara, and I going out to lunch, then meeting up with everyone else at the march. Lunch took a little longer than expected, so it turned out that Sydney could also join us on our way there. Josh was too tired from playing soccer in Brooklyn to come with us, and Julia was just tired haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/March.jpeg")} alt="March" />
                    <figcaption>Packed Sidewalk @ The March</figcaption>
                </figure>
                It was a little sad at first that we lost so many people, but spirits picked up again once we started walking around. The amount of activity once we got to the KTown area was absolutely insane. The streets were FILLED with marchers, DJ's, performers, and people walking along.
                <br/><br/>
                The sidewalks were so full that it could take literally half an hour to cross a street. It was extremely crowded, and I lost our group a couple times throughout, but we were able to regroup each time haha.
                <br/><br/>
                The march was different than I expected. People handing out free stuff like bead necklaces and signs, other people selling shirts, and others selling alcohol haha. The march was more of a parade than an actual march. Only certain groups were allowed to walk on the streets, and the majority of us were barricaded along the sidewalks, making the congestion terrible. I was hoping we would all be able to march on the streets together and shout, but nope haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FerryTerminal.jpeg")} alt="FerryTerminal" />
                    <figcaption>Ferry Terminal</figcaption>
                </figure>
                We spent most of our time there trying to navigate to Ushma's place so we could meet her children (she sent out an email earlier last week about how they'd have a lemonade stand set up there).
                <br/><br/>
                When we got there, she recognized us and we had a happy little chit chat. She told her kids to give us lemonade for FREE! How kind haha. They had freshly squeezed watermelon juice, so we all got watermelon lemonade, which is just the regular lemonade they made mixed with watermelon juice. It was pretty good. Reminded me of all those times in China when we went to restaurants and the norm was watermelon juice instead of water.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Skyline.jpeg")} alt="Skyline" />
                    <figcaption>The Manhattan Skyline</figcaption>
                </figure>
                At one point while we were hanging out in front of Ushma's house, someone tapped me on the arm and it was Arielle! She couldn't join us on our way there because she was meeting up with another one of her Caltech friends who was surprisingly social. I was thrown off when her friend said she also went to Caltech, because I just don't expect social people to go to Caltech haha. Both cool people.
                <br/><br/>
                Ushma told us to be careful with what we decide to take, to get fentanyl strips to make sure what we take is safe, and told us not to get too drunk haha. She then gave me her number in case any of us needed help. She was like, "if someone faints or something, just let me know and I can help. Whether it's because you're dehydrated, or did something else, I won't ask!"
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Lib.jpeg")} alt="Lib" />
                    <figcaption>Closer View of<br/>The Statue of Liberty</figcaption>
                </figure>
                Afterwards, Sydney went to hang out with other friends, and Sanjana, Kiara, and I went to a Bed Bath and Beyond to take a break before deciding what to do next. We were sitting, loitering in the store, when Sanjana was like, "do you guys want to go to the Statue of Liberty?" We didn't have much else to do and it was only like 2 PM, so I was down.
                <br/><br/>
                Kiara ended up leaving because she felt woozy, but luckily Aziz was done with calling his parents by the time we were about to make our plans. So, Aziz decided to join us and go directly from The New Yorker to the station that leads to the Staten Isand Ferry.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FerrySelfie.jpeg")} alt="FerrySelfie" />
                    <figcaption>Ferry Selfie!</figcaption>
                </figure>
                We got there after a smooth subway ride. The train stopped in the middle of one of the tunnels, and I got scared since we were just not moving and it was a little weird haha. But anyways, we made it to the ferry fine and it was a surprisingly nice place! The most surprising part of the entire trip is that the ferry is completely free! A new ferry comes every 30 mintues, so there's always a time you can go.
                <br/><br/>
                The ferry ride was absolutely beautiful. You can see the entire Manhattan skyline as the ferry is coasting away from the dock, and the Statue of Liberty becomes pretty clear as we ride closer to Staten Island. Staten Island itself was also really pretty, at least the part we were on. We were in a mall-like area that felt like Santana Row back home.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Ferry.jpeg")} alt="Ferry" />
                    <figcaption>Another Selfie on the Ferry</figcaption>
                </figure>
                Aziz, Sanjana, and I were all having a really nice time. We were talking about how nice it is to have a small group of people that are willing to go out and just have fun and explore every weekend. Definitely want to hang out with them as much as possible in the time we have left here.
                <br/><br/>
                Aziz had plans for dinner with a friend, so we left on the ferry back to NYC at 5:30. On the ride back, though, his friend cancelled on him. It was good news for us though, because we got to spend more time with him haha. 
                <br/><br/>
                Side note since I'm talking to them: Josh is from Hong Kong and his real name is Lau Shing Jun which I like saying (Lau Sang Jun) and Julia's summer nickname is Veronica since I said she looks like a Veronica.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Aziz_Ferry.jpeg")} alt="Aziz_Ferry" />
                    <figcaption>Aziz and the Skyline</figcaption>
                </figure>
                Anyways, we got back to main land and then decided to either go to Indian food or Italian food. After a bit of searching on Yelp, we settled on John's on 12th Street, a nice Italian place that's in the south side of Manhattan.
                <br/><br/>
                We took a long walk there after the subway station, and got to see a few of the NYU buildings. It's a very weird college, since their "campus" is just buildings spread out throughout a community in NYC. It's not a real college campus, and apparently the people there say "the city is our campus." I didn't like it much, honestly haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Chair.jpeg")} alt="Chair" />
                    <figcaption>A Chair on Staten Island</figcaption>
                </figure>
                The actual Italian restaurant was really nice - lit by candles, and the portion sizes were pretty big. Good prices! Also got a vegetable dish, fresh greens stir fried in oil and garlic, that was pretty good. Tasted kind of Asian haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Staged.jpeg")} alt="Staged" />
                    <figcaption>Staged Pic on Return Trip</figcaption>
                </figure>
                After dinner, we took another walk back to the subway, and got right back to the hotel. We stopped by CVS to buy some hand soap, since I'm runningo out in my dorm, and then all went to go shower off for the day. So nice to feel clean after a day scrunched up with a bunch of people at the march haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Miralax.jpeg")} alt="Miralax" />
                    <figcaption>Julia Taking My Drugs</figcaption>
                </figure>
                We again spent our night time together on the 15th floor, but joined by Julia for a bit, and Josh for most of the night. Nice to sit around and talk again to end the day before heading into another week of work. My favorite part of CBSP is honestly just hanging out and exploring NYC together with our new friends.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/NightSelfie.jpeg")} alt="NightSelfie" />
                    <figcaption>Our Night Group</figcaption>
                </figure>
                Another side note - I'm picking up a lot of new lingo here haha. Sanjana likes calling things "iconic" and also likes calling people a "menace" haha. It's a popular-ish thing on social media, where commenters call people a "meance to society" if they do something dumb or slightly bad but not actually bad haha. She says I'm a menace in public. Apparently I act like a kid outside. Anyways, excited for the week ahead!
            </div>
    },
    {
        title: "Day 15: High Performance and Low Energy",
        date: "06/27/2022",
        tags: ["Work"],
        content:
            <div>
                Happy birthday Father! What a special day today. My first holiday in New York City!
                <br/><br/>
                Today I woke up really tired. I remember hearing my alarm, but thinking I was dreaming, so I slept through it for a while until I realized it was my actual alarm. I don't have any soy milk or oat milk left, so I just ate some almonds for "breakfast". I want to try to find a cafe near where I work so maybe I can get like a bagel with eggs and cheese some days.
                <br/><br/>
                Luckily, I have some snacks left from previous snack days, like a mini cake thing and a couple Sachima's. Today I ate my apple and cinammon oatmeal cup. I thought it was pretty nasty, since the Apple pieces are just really weird, but I ate most of it before throwing it away. At least it smelled good!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Oatmeal.jpeg")} alt="Oatmeal" />
                    <figcaption>Morning Oatmeal</figcaption>
                </figure>
                Then, I got straight to work. I was re-running the program from last week that should've been done a while ago, and in the meanwhile I read some of the paper for tomorrow's journal club. A more interesting paper than last week's, but still not interesting enough for me to actually get into the paper and read the details.
                <br/><br/>
                I decided to give HPC another crack to see if I could get the software I needed running. After some experimentation, I finally got it to work. It was actually just a few very simple fixes, but I've never used the package manager needed to make it work, so it all felt very foreign. I added some details in an issues post on Wesley's GitHub repo for the software I needed, but then I scrolled through his documentation later and saw that he actually wrote it there before. I felt dumb, but honestly, if I didn't see that documentation when I needed it, it was in the wrong place! That's UI/UX design logic for you :D
                <br/><br/>
                I didn't do much else at work, besides figuring out how to run and submit jobs on HPC. Very cool to have an interactive notebook open to run code on someone else's computer. HPC is just a very cool concept haha.
                <br/><br/>
                I was super tired, so tired that I considered just napping in one of the comfy chairs in our office. But I thought it would look bad if someone saw me sleeping. In retrospect, I should've just taken the nap, since I ended up falling asleep for a bit on my chair anyways ahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Workout.jpeg")} alt="Workout" />
                    <figcaption>Josh Working Out</figcaption>
                </figure>
                After work, I had to enroll in my class for second pass. I had a cool public affairs class on Social Problems and Social Change I wanted to take, but when it came time for enrollment, I realized that only certain majors and minors can enroll. No wonder such a highly rated professor had barely any seats fill up ahaha.
                <br/><br/>
                While I was panicking trying to find another class, someone walked past my desk, did a double take, and came back. "Do you go to UCLA?!?" I was so excited haha, I was like "YES! DO YOU GO TO UCLA TOO?!?!?" And then he was like, "no, but I went there!" Really cool moment haha. I explained to him that I was searching for a class since I realized the class I wanted had major restrictions, and he was like "I don't miss that" ahahaha. Very nice to know a guy sitting near me also went to UCLA!
                <br/><br/>
                I headed back home and went to the gym with Josh, Aziz, and Sanjana after some delays. We all agreed last night to work out today, just because Josh works out and Sanjana has always wanted to know what the different machines in the gym are.
                <br/><br/>
                We worked on some leg machines, and then I tried doing a pull up. I completely failed, but luckily there's another machine that's supposed to help you train for pull ups. I think if I do it every day, I'll be able to do a pull up by the end of this summer. :D :D :D
                <br/><br/>
                It was nice to get active for a bit. I only stayed for like half an hour because I wanted to cook some dumplings tonight, but it was very refreshing to sweat a little ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Dumplings.jpeg")} alt="Dumplings" />
                    <figcaption>Made Way Too Much</figcaption>
                </figure>
                For dinner, I heated up WAY too many dumplings, but had fun. It's cool to boil water. Very fun. Makes me feel like a real chef. Then I just ate with Sanjana and Josh. Sydney and Aziz came along later, so they joined us. Very nice to always have familiar faces around here.
                <br/><br/>
                We hung out for a bit, and then we all went our ways for the night since it seems most of us are pretty tired haha. I feel like this past weekend was super satisfying and filled with exploration, so it's jarring to suddenly have to get back into the work routine.
                <br/><br/>
                The rest of tonight should be pretty relaxing. I don't intend on hanging out too much since we all need rest, and there's not too many people around now anyways. It just seems the weekend is full of people wandering around and making spontaneous plans, and the weekdays get all stressful again since everyone in EHS is an intern somewhere.
                <br/><br/>
                Excited for the week ahead and to hopefully get some productive work done this week. Very excited that I have good access to HPC now!
                <br/><br/>
                And finally, Happy Birthday Father! It's sad we can't be together today, but I'm doing well out here and having a good time. Thinking of you guys a lot and hope everything has been going good at home! Enjoy your new dresser! That stranger definitely put it there for you to take as a birthday gift. I have so much stuff anyways. It's good to have something to store more stuff in. I love you Father. Thank you for everything you've done for us, and hope you have a great birthday week! Excited to reunite in less than 2 months!
            </div>
    },
    {
        title: "Day 16: An Iconic American Experience for a Newly Branded Bayesian Statistician",
        date: "06/28/2022",
        tags: ["Work", "Broadway", "Bayesian Statistics"],
        content:
            <div>
                Tuesday! We started the day with our second journal club, this time led by 3 postdocs that seemed like good bros. They made it very fun and easygoing, and led us through some cool details about a paper on somatic mutations and aging. Pretty cool.
                <br/><br/>
                We all introduces ourselves, and this guy that isn't in CBSP was there and said he went to UCLA! So, I got really excited and the Asian postdoc asked if we knew each other, and we both said no haha. But later in the day when I was sitting at my desk, I was thinking about how he looked familiar, and then realized that I think he's friends with Perla, someone from my floor in Sproul Hall last year hahaha. I'll try to see where I can find him around so I can talk to him.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Macys.jpeg")} alt="Macys" />
                    <figcaption>World's Largest Store</figcaption>
                </figure>
                The other main takeaway from the journal club is the difference between Nature, Nature Methods, Nature Aging, etc. The postdocs were questioning how the paper we were reviewing got into Nature, since Nature is supposed to be for science that everyone, even non-researchers should care about. It's for the most groundbreaking work that would appear in like New York Times or something, and the postdocs didn't feel like the authors of the paper contributed anything deeply influential.
                <br/><br/>
                When we were talking about where it was published, I asked, "so, where WAS this published? Nature Methods? Or JUST Nature?" And they all laughed, and said it was in Nature, just Nature. And then I was like "oh I think similar papers would appear in like Nature Aging." And as they were laughing, I was like, "I don't know the difference" in an offhand way.
                <br/><br/>
                I read an article later on explaining the difference between Nature and Nature [genetics/aging/methods/etc.], and apparently Nature is a significant step in prestige above all the other Nature titles, so the postdocs all thought I was going along with them and roasting the paper hahahaha. Nice to know.
                <br/><br/>
                Soon after, I headed to our lab's probabilistic modeling group meeting. It was an interesting talk today, and I feel like I understood most of what was going on... until the pizza arrived. Once the pizza came, I moved seats to sit with the guy that graduated from Stanford to talk to him and eat this great vegetable pizza that was absolutely loaded with crap. When the talk resumed after a brief lunch break, I couldn't focus and pretty much just lost attention. At least I had a good, free lunch. I appreciate the computational oncology department for relieving some financial burden in these tough, tough times.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Wok.jpeg")} alt="Wok" />
                    <figcaption>Woks in NYC!</figcaption>
                </figure>
                The meeting ended and I got right back to work. Oh! And I talked for the first time in a group meeting! I asked what a simple bit of code meant since I had no idea what it was there for. Just a tiny clarification haha. But at least I made some contribution. Also, I talked to the guy that went to UCLA. He's a third year grad student at MSK, and I also asked if anyone has ever told him he looks like Colin Jost. He said yes haha! He looks just like Colin Jost!
                <br/><br/>
                Anyways, when I got back to work, I asked another question in our Slack about why something called left censoring data exists (basically when a treatment doesn't have a recorded start date), and Karl, a former Google engineer in our lab, responded that he was just reading a paper about this type of stuff. He shared some more info, and it helped answer my question which was nice.
                <br/><br/>
                I went to get some hot water in the lounge room afterwards, and I saw Armaun, the Stanford guy in there eating another lunch (he only had 1 slice of free pizza). We had a nice convo about past research experiences, and he's only done research in the summers (and sometimes continued during the school year). He said one thing he regrets from previous things is trying to get things done ASAP instead of taking the time to do background reading to understand his topic. He says it would've saved him time later on to establish those fundamentals, and I thought that was a pretty good point. I enjoyed our conversation and will look to talk to him and the other ungrad more.
                <br/><br/>
                Then I asked Wesley for some introductory readings on Bayesian statistics, since I literally get lost in all the details once the stats comes at our group meetings. He said I could borrow his probabilistic ML book and asked if he gave me his 1 hour intro whiteboard talk he gives all new members. There's a lot of new people joining this summer, so he lost track of who he gave it to ahaha.
                <br/><br/>
                Anyways, we ended up going to the conference room with Anneliese, the other undergrad intern (she's in QSURE), and he gave a really nice intro to traditional/frequentist statistics, and went more in depth into an overview of Bayesian statistics. Very helpful background on all sorts of stuff that the lab people are constantly talking about, so I really appreciated it. Made me a bit more interested in the stats behind it all, and I definitely feel a bit more motivated now haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Venue.jpeg")} alt="Venue" />
                    <figcaption>The Al Hirschfeld Theatre</figcaption>
                </figure>
                I asked a couple questions, one of which Wesley said didn't have an objective answer since it's a very philosophical question (he started his talk with explaining stats resesarch from around 1925, and explained how they had a bunch of data and wanted to model it using a normal distribution). He explained that since it was a nice derivation to show an example of developing a model, but I was just confused how people knew in the first place, that, given a whole bunch of data, they would want to model it using a normal distribution of all distributions.
                <br/><br/>
                It was 5 PM by the time our little lecture ended (I'm so happy I got to get paid to listen to a lecture hah), and I dashed out of there to go prepare for our Broadway show tonight!
                <br/><br/>
                Once I got back, I chilled in my room for a bit before going out to dinner with Arielle and Sanjana. We headed towards the Broadway show, which is on 45th street, just 11 streets south of us. We stumbled upon a Wok the Walk restaurant, which has really nice, freshly fried wok dishes you customize. I got a rice noodle dish with shrimp and bok choi that was really nice.
                <br/><br/>
                Once we walked to the Broadway venue, I was very excited. An overall very fancy and nice place. It's strange how different it is from the dirty NYC streets outside haha. They took us through a quick security check and metal detector, and then we got into the theater. SUCH A BEAUTIFUL THEATER. Their set design is amazing, very pretty. Really feels like a grand production.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Opening_Set.jpeg")} alt="Opening_Set" />
                    <figcaption>A Very Elaborate Opening Set</figcaption>
                </figure>
                We found our seats for the discounted tickets we got from EHS, and they were in the fourth row from the top. Not the best view, but good enough. You just can't see the faces of the actors very clearly. The person to the left of Sanjana was on a laptop looking at R code, and then I realized it's Zhiyue from CBSP! This is the second time I've seen her at an EHS event with other people, the first time being at The Met ahaha.
                <br/><br/>
                We watched Moulin Rouge, a musical and romance that was pretty nice. My mind wandered quite a bit during the show, because there was a lot of dancing and songs I could barely make out the words of haha. Also, the first 10-ish minutes of the show was a speaker who was being very theatrical and talking in like a British accent, so I couldn't understand anything haha.
                <br/><br/>
                The music was really good, and both lead actors had very very beautiful voices. The live band is insane. All Broadway music is conducted and played live, right below the stage. It's pretty insane, very nice crossing this iconic American experience off my NYC summer bucket list!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Group_Selfie.jpeg")} alt="Group_Selfie" />
                    <figcaption>Group Selfie!</figcaption>
                </figure>
                We walked back and decided to go to Tick Tock Diner, a diner right below The New Yorker, for some milkshakes. It was expensive, but a pretty nice and light milkshake. At one point I did an 印度口音 and Arielle said it's not okay how good it was ahaha. Keeping up my reputation of being good at it!
                <br/><br/>
                Now, it's 12:57 AM, and I'm just finishing my blog haha. Very satisfying to spend the night going out in NYC, even on a week day. I love how we can just not work once work hours are over. It's so beautiful ahaha. Gonna enjoy the rest of this week. Might work out tomorrow! See you then!
            </div>
    },
    {
        title: "Day 17: Like Father Like Son",
        date: "06/29/2022",
        tags: ["Working", "Cooking"],
        content:
            <div>
                It's 10:23 AM and I just got to Joy a bit after 10. Writing this up now because I stayed up late last night just hanging out and didn't get a chance to write it then.
                <br/><br/>
                Yesterday was probably one of my most productive days yet. Started my day off by finally getting into the second part of what my assignment from last Monday, and made good progress. A bit before the lab meeting at lunch, Wesley checked in with how I'm doing, and I was pretty much wrapping up what needs to be written for the second part. Just running and debugging the code, but that takes forever because of how much data we have.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sweetgreen.jpeg")} alt="Sweetgreen" />
                    <figcaption>Free Lunch</figcaption>
                </figure>
                We chatted a bit and he clarified something I was stuck on, and that afelt like a major roadblock lifted. I didn't fully understand what the software package he wrote did since the stats is beyond me, but it made more sense after our chat, so I felt more motivation and direction after feeling stuck for too long haha.
                <br/><br/>
                Our lab meeting was nice - cool to hear about other people's work and I like the free lunch. At first, I didn't like Sweetgreen much because it's such a light lunch, but I appreciate how free it is. And it's honestly pretty good haha.
                <br/><br/>
                After lab meeting, I had a really productive few hours setting up the code to run on more intense settings, and submitted it to be run non-interactively on HPC overnight. Fun stuff. The first thing I did when I came into the office today (the 30th) was checking the results, but I realized the script crashed and didn't actually do anything. So sad haha. But just submitted it again and it's running fine.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BreakRoom.jpeg")} alt="BreakRoom" />
                    <figcaption>Inside the Coffee Machine</figcaption>
                </figure>
                The four of us that consistently show up to Joy (Jaden, Zhiyue, Sydney, and me) all went back to our place at the same time, for what I think is the first time ever! It was a nice little trip, and Sydney and Zhiyue took me and Jaden to the subway station they use, which is a different direction from the station that we normally use haha. Nice to see different areas of the area.
                <br/><br/>
                I got back and relaxed for a bit before heading to the gym with Josh and Sanjana. Did some light workouts there, and now I feel like I know what I want to do in the gym. Probably shoulder, chest, and leg exercises to get stronger so I can be more powerful in tennis.
                <br/><br/>
                EHS hosted a grilled cheese night last night, so we all got a grilled cheese after working out as a little snack before cooking dinner. That was a MAJOR regret. Literally just a salty piece of bread with some cheese inside. So unhealthy and nutrionless. And my stomach hurt after! What a lose lose situation haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DumbCook.jpeg")} alt="DumbCook" />
                    <figcaption>Our Food</figcaption>
                </figure>
                Anyways, we got our cooking supplies and reunited in the kitchen. Josh made eggs and tomatoes with rice, and Sanjana made some quinoa dish with tomatoes, corn, and some other crap. It was fun helping out with that, but it smelled kind of sour haha. I heated up my usual dumplings, but since Sanjana had oil and diced garlic, I got some of that and fried my dumplings after boiling them. Pretty good! Not all of the dumplings touched the bottom of the pan, so a lot of them weren't really fried, but the ones that WERE fried were very good haha.
                <br/><br/>
                It didn't taste like garlic, but at least it was something a bit different from what I've normally been eating. Cooking was pretty fun, even though it was mostly just throwing crap into a pot and boiling it. I decided to try to cook more, so I'll go to HMart after work today and get some fresh noodles, eggs, and veggies so I can cook more nutritious meals for myself. Excited to do that!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Garlic.jpeg")} alt="Garlic" />
                    <figcaption>Garlic! Haven't Had Garlic<br/>In So Long Ahaha</figcaption>
                </figure>
                After dinner, we were just hanging out in the TV room. We didn't know how to operate the TV and couldn't find the remote anywhere in the room, but eventually I just tried looking on the TV, and found some buttons at the back that let us switch TV inputs to AirPlay!!! It's a Smart TV with AirPlay, so you can wirelessly connect Apple devices' screens and audio output to the TV. It's so convenient and cool haha. Listened to some music and had a nice night.
                <br/><br/>
                Ended up sleeping at like 1:30 and decided not to get up early and make it to work on time. I thought it would be more worth my time if I just get enough sleep and come to work a bit late so I can actually focus. Pretty good decision since I feel great right now ahaha. Plus, only Jaden is in the office right now. The other interns tend to come pretty late many of the days haha. And on my trip to the office, I saw Sanjana in the subway and Sydney said she was still in the hotel haha. I'm not that late!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/TV.jpeg")} alt="TV" />
                    <figcaption>The Comfy TV Room</figcaption>
                </figure>
                I ended up waking up at around 8:30, but lied in bed till around 9. Mitchell also left late, at around 8:45 AM which is like 25 minutes later than when we normally leave haha. Today just seems like a late day for everyone.
                <br/><br/>
                Oh, and last night, when I was going to the basement to get my laundry, I took the service elevators which you're not supposed to do unless you're moving in/out. There was a security guard walking around when I went in, and another staff member in the actual elevator, so I was scared at first haha. But the guy in the elevator asked, "going down?" and I said yeah, the basement.
                <br/><br/>
                I noticed a floor below the basement, 1C, and I asked him what was there. He said that's where the offices are, like 5 stories underground. He said they even have tunnels to Penn Station directly from there!!! How cool is that ahaha. And then he said there are even OTHER tunnels, but those ones are secret and he can't tell me about those ahaha. How cool. Secrets of Wydnham The New Yorker revealed.``
            </div>
    },
    {
        title: "Day 18: Getting Enough Sleep",
        date: "06/30/2022",
        tags: ["Work", "Cooking"],
        content:
            <div>
                Toady I set an alarm for 8:30 AM so I could get my beauty sleep, but ended up sleeping in till like 9 AM. I noticed Mitchell also left late, since he was still standing around at like 8:45 AM haha. Very nice getting up and not having to rush to get to work. Why stress when no one actually cares that I come in on time every day haha? But anyways, I worked after 5 to make up for some of the lost time.
                <br/><br/>
                The day started and the first thing I checked was whether the job I submitted last night ran successfully. I want results! Unfortunately, I realized the script crashed when it finally got its turn to execute, because I had the wrong version of Python specified at the top of the file haha. Oh well.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/RawEgg.jpeg")} alt="RawEgg" />
                    <figcaption>My Raw Eggs</figcaption>
                </figure>
                Did some testing and got my stuff submitted again, and off I went with the rest of my day. Read some of the paper on graph neural networks, and actually thought it was kind of interesting. I was happy that I could actually understand something, since usually when I read papers/chapters, I don't understand what's going on haha. The reading was for our reading group today, since our lab has one every Thursday.
                <br/><br/>
                Had a nice lunch at the surgery center with Aziz and Sanjana, which has become our regular lunch crew when I don't get free lunches from my lab (Mondays, Thursdays, and Fridays). It's a nice break from the work day, since I get a bit of a walk in, and I also get to get some good food haha.
                <br/><br/>
                After lunch, I relaxed a bit and then headed off to our lab's journal club. From 2 PM to 4 PM, we listened to a lecture and asked questions all about the chapter we had to read. I wish I read more before going in, since I only ended upr reading the intro of the chpater, but I actually underestood quite a bit of today's reading group. Really interesting stuff haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Boiling.jpeg")} alt="Boiling" />
                    <figcaption>Boiling Shanxi Noodles!</figcaption>
                </figure>
                From 4 PM to 5:30 PM ish, the rest of my work day, I submitted new jobs since my script crashed after running for 4 and a half hours. I sent the error to my PI since I had no clue what it meant, and it's apparently a known bug, so I should be able to run it smoothly tomororw if it fails again tonight.
                <br/><br/>
                I also wrote some documentation on how to set things up on HPC. MSK's documentation for how to use Juno, our HPC system, is surprisingly terrible. Some of the instructions are unclear, they don't define things well, and some instructions just plain don't work haha. Hopefully the stuff I wrote up will help future Weslabbers, the name I've given the lab members of Wesley Tansey's lab haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Final_Product.jpeg")} alt="Final_Product" />
                    <figcaption>My First Dish</figcaption>
                </figure>
                After work, I headed right over to HMart to buy some food. I want to get into cooking more during my time here, so I bought all sorts of stuff! Spinach, eggs, sesame oil, soy sauce, tomatoes, and fresh, Shanxi noodles!
                <br/><br/>
                A lot of good stuff, and I got right into cooking when I got back. The pan wasn't that hot when I added the eggs in, so it looked really watery for a long time haha. I thought I wasn't going to be able to scramble it, but it eventually looked fine after a lot of heating haha.
                <br/><br/>
                Tossed in the tomatoes and cilantro, and it sounded so cool haha. I love the sound of stir fry. I boiled the noodles after, and while I was trying to figure out if it was cooked yet, I tasted the noodles and they were SO GOOD. Very QQ and had a nice, fresh taste.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Yawning.jpeg")} alt="Yawning" />
                    <figcaption>We're All Very Tired Ahaha</figcaption>
                </figure>
                I stirred everything together with more sesame oil, added some soy suace, and it looked SO GOOD. It looked absolutely amazing, but it didn't really taste like anything haha. Looking back, the noodles actually tasted better without anything on them than mixed with all my stuff haha. But it was a fun experience, and Alyssa and Aziz came along I chatted with while I did everything (Alyssa is a fashion design major who seems to know everyone in EHS haha. She's very social).
                <br/><br/>
                Now I've just been chilling, hanging out a bunch of people in CBSP. Aziz, Sydney, Julia, Arielle, Sanjana, and Josh from ESP (who always hangs out with us at this point haha) are all here now. Signing off from my blog for today, see you tomorrow!
            </div>
    },
    {
        title: "Day 19: A Busy Day and a Late Night",
        date: "07/01/2022",
        tags: ["Seaport", "Chinatown"],
        content:
            <div>
                It's already Friday. The weeks have been passing by faster and faster as we've gotten into things. It feels like the busier we are, the faster stuff moves along.
                <br/><br/>
                i started the day by actually making it to work around 9 AM, once again back to my normal schedule. Only Jaden was there, and it was just me and Jaden until around noon. I had a cappuccino from the pantry with a mini cake I got from EHS's Breakfast To Go, which was nice and filling. Need to find some other source of breakfasts soon, since I've run out of snacks in my desk.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Signing Off.jpeg")} alt="Signing Off" />
                    <figcaption>Leaving My Desk Till Next Week</figcaption>
                </figure>
                Got right to work soon after. I showed Wesley some preliminary results from a job I submitted yesterday, and had some back and forth to determine what changes to make. Had some significant changes to make for one part, so I spent the entirety of my day editing the program, doing various checks, and submitting several more jobs that will hopefully have results when we come back next week.
                <br/><br/>
                Sort of a stressful day because I really wanted to get a bunch of jobs submitted to HPC so that they can run over the weekend, but we made it through! I ended up staying till around 5:30, and getting back to The New Yorker at 6.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Seaport_Welcome.jpeg")} alt="Seaport_Welcome" />
                    <figcaption>A Welcome to Seaport</figcaption>
                </figure>
                For lunch, I met up with Aziz, Sanjana, and Mitchell at the surgery center. They walk half a mile to come to the surgery center, because the food is apparently better than the main campus's cafeteria, and it's also just as good a price. Nice to have company during lunch.
                <br/><br/>
                Anyways, when I got back to The New Yorker, I pretty much immediately met up with Aziz, Sanjana, Josh, and Mitchell, since during lunch Mitchell mentioned wanting to go to Seaport, which Sanjana had also heard of.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Seaport.jpeg")} alt="Seaport" />
                    <figcaption>The Initial Seaport View</figcaption>
                </figure>
                We gathered our stuff and left on the subway ride there. It's in lower Manhattan, really close to the World Trade Center and the Brooklyn Bridge. We thought there was going to be a beach there, but it ended up just being a couple of artificial pits built on top of the concrete near the port, filled up with sand haha. Not that cool. The view, though, was amazing.
                <br/><br/>
                On one side of the port, you could see the Manhattan skyline, and on the other, you could see the skyline AND the Brooklyn Bridge. The angle was really cool, especially after having been on the Brooklyn Bridge. It looks really grand from the side, and it's just amazing to see how Manhattan and Brooklyn are connected by this one, gigantic bridge.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BridgeView.jpeg")} alt="BridgeView" />
                    <figcaption>The Brooklyn Bridge</figcaption>
                </figure>
                We toured the fancy shopping mall type of area at Seaport, but it was just really expensive restaurants and events that needed reservations, so we left pretty quickly. Chinatown is close by, so we walked over there.
                <br/><br/>
                We were walking around trying to find a place to eat around 8 PM, and eventually settled on a Shanghai place. I don't remember what it was called haha. I had a 上海汤面 which wasn't amazing, but the soup was really nice.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BrooklynSelfie.jpeg")} alt="BrooklynSelfie" />
                    <figcaption>Seaport Selfie!</figcaption>
                </figure>
                Sanjana, Aziz, and Mitchell had rice cakes for the first time! I was kind of shocked that Sanjana hasn't had rice cakes before, since she's from the Bay and I feel like there's enough Asians there that she would've at least heard of rice cakes. But she hasn't!
                <br/><br/>
                They all liked the rice cakes a lot, and Sanjana made a good comparison I don't think I ever would've thought of. She said the rice cakes are basically like boba haha. Mitchell also really enjoyed it and had a bean curd puff thing that I've never seen before, but looked good. We also ordered some crab meat and pork 小笼包 that was very okay to me. I didn't like it much since I've had much better, but the others all enjoyed it ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BrooklynSitcom.jpeg")} alt="BrooklynSitcom" />
                    <figcaption>A Picture That Looks<br/>Like It Came<br/>Out of a Sitcom</figcaption>
                </figure>
                The restaurant was a nice break from NYC. The staff was all Chinese people that speak Cantonese and Mandarin, so they spoke in Chinese to Josh and I and in English to the rest haha. At the very beginning, Aziz went to the bathroom and couldn't unlock the door, so he texted and called all of us to rescue him. No one realized he texted us, but luckily he called Sanjana who picked up (he also called me, but I'm always on Do Not Disturb, so it goes to voicemail if you don't call twice).
                <br/><br/>
                They sent me to go rescue him, but I couldn't open the door from the outside either haha. I ended up telling one of the waitresses, "那个厕所打不开", and she was like "有人吗？" and I said "有人，但是是我们的朋友". And then she went down with the key and opened it, and yelled at Aziz about which way to turn the lock to unlock it haha. "ZIS WAY TO UNLOCK! ZIS WAS LOCK!"
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WorldMapBall.jpeg")} alt="WorldMapBall" />
                    <figcaption>A World Map Ball<br/>That Matches UCLA's<br/>Botanical Garden Ball</figcaption>
                </figure>
                At the end, since we don't ever have enough cash on us, we Venmo'd the restaurant (luckily they take Venmo). When we finished paying and were leaving, the waitresses got all nice, smiled at us, and cleaned up. They even took Josh's bottle (since he just reuses a glass bottle from a kombucha drink he bought before), and then we were like "oh no that's ours!" and then Josh joked to them, asking in Chinese if they wanted to keep it.
                <br/><br/>
                We had a nice little trip back to our hotel after. In the subway station, our subway just wasn't coming, and eventually we saw the line 6 train come twice. At that point, Josh was like, what the hell! And then we realized that we were at the wrong part of the station haha. We re-navigated to the uptown train lines and got back home smoothly.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/China.jpeg")} alt="China" />
                    <figcaption>An Entrance to a Chinatown Street</figcaption>
                </figure>
                We all cleaned up in our dorms, showered, and did all that, and reunited on the 15th shortly after. It started with just hanging out near the pool tables and talking while a couple of us practice our pool skills, but then we moved to the sofas on the 15th and played Psych, an Ellen game that asks questions about the people in the game, makes everyone answer, and then makes everyone vote on the funniest one. Fun game, but it was a little awkward for a couple people that haven't hung out with some of us too much, so don't understand the references we're making in our answers haha.
                <br/><br/>
                More people came later in the night, and we eventually ended up playing hot seat, where one person sits at a chair and everyone else around the sofas asks questions the person on the hot seat has to answer honestly. Very scary, but a good way to get to know people haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/上海汤面.jpeg")} alt="上海汤面" />
                    <figcaption>My Dinner</figcaption>
                </figure>
                It ended up being a very wholesome night since one of the questions that came up for everyone was to say their favorite thing about everyone else hahaha. We stayed up till 2 AM and then all went to bed pretty late, but on the bright side, I woke up at like 10 and kept falling asleep until I actually got up around 11 AM. I'm now finishing up my blog and gonna head to make lunch soon. See ya later!
            </div>
    },
    {
        title: "Day 20: 10 Miles of Walking",
        date: "07/02/2022",
        tags: ["Highline", "Little Island", "Chelsea Market"],
        content:
            <div>
                I started my day by making lunch. Egg drop soup Shanxi noodles! I put a lot of spinach and cilantro in it, so I had a pretty healthy lunch. But it didn't taste like much, even though I used Sanjana's vegetable broth as my soup base haha. Also, the eggs turned out to not be very soft and feathery, probably because I was moving around my chopsticks way too much when I put the egg in. I think I should've just swirled the water, and then poured the egg in without stirring while I poured the egg in.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LunchNoodle.jpeg")} alt="LunchNoodle" />
                    <figcaption>My Lunch</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/Vessel.jpeg")} alt="Vessel" />
                    <figcaption>The Vessel! Just Stairs Haha</figcaption>
                </figure>
                I was very happy I had an affordable and very healthy lunch full of veggies, but I was a bit disappointed at how non-tasty it was haha. The soup didn't taste like anything, and nothing else really had flavor except for the tomatoes haha. Definitely want to go to HMart and buy some more Asian sauces so I can cook nicer food soon.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/HighlineStart.jpeg")} alt="HighlineStart" />
                    <figcaption>The Start of the<br/>Highline Walk</figcaption>
                </figure>
                The weather forecast said there would be thunderstorms and rain all day today, so we kind of didn't plan on doing anything outside today. So, during lunch, we tried brainstorming what to do. Sanjana and Lauren were in the kitchen area (Lauren goes to UPenn and is interning at a museum (she's a classical studies major)), and we decided to walk the highline, a beautiful garden area in Hudson Yards.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Highline.jpeg")} alt="Highline" />
                    <figcaption>Highline Walk</figcaption>
                </figure>
                We waited till around 3 to go out, since Aziz was coming back from meeting a friend in the area. When we finally got outside, it was SOOO hot. Very very stuffy temperature today. The highline walk is relatively close to The New Yorker, so we walked there. It's a stretch of just over a mile of a beautiful garden space that's elevated above the busy Manhattan streets. Plenty of good views of the city and just an overall very beautiful little cut of nature.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Highline2.jpeg")} alt="Highline2" />
                    <figcaption>More Highline Walk</figcaption>
                </figure>
                It's kind of amazing they have a nicely maintained garden in the middle of Manhattan. You could even hear bird's chirping there! Kind of insane. I feel like I haven't heard or seen birds except for our lovely NYC street pigeons.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/HighlineView.jpeg")} alt="HighlineView" />
                    <figcaption>A View From Highline</figcaption>
                </figure>
                We took a couple rests on the walk since it was so overwhelming hot. I personally felt really drained after just a bit of walking because of how uncomfortable it was outside. We decided we wanted to go to an ice cream place, but we wanted to finish our walk on the highline first.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LittleEntrance.jpeg")} alt="LittleEntrance" />
                    <figcaption>Entrance to Little Island</figcaption>
                </figure>
                The end of the highline, at least the side we ended on, is down south from Midtown, in Chelsea. A beautiful area that's really close to Little Island, a place Sanjana has been talking about and wanting to go to, so we went there first before ice cream.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Little.jpeg")} alt="Little" />
                    <figcaption>Little Island!</figcaption>
                </figure>
                We thought you might need a reservation to go on the island, but it's actually an open space anyone can walk to. VERY beautiful area. It's like a beautiful, tropical kind of space that looks almost like it came out of a movie scene. The hills and flowers just look so perfect haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LittleSelfie.jpeg")} alt="LittleSelfie" />
                    <figcaption>Little Island Selfie</figcaption>
                </figure>
                After spending some time on the island, we headed over to the Chelsea Market area, which is within walking distance from the island. By that time, I just wanted to lie down and rest, but we powered on. We walked to an ice cream shop that had sit down areas with AC, and we realized it's actually really close to Google in Chelsea, which was across the street from the Xi'an restaurant we went to recently! Very cool haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IceCream.jpeg")} alt="IceCream" />
                    <figcaption>PB and Choco Milk + Cookies</figcaption>
                </figure>
                We sat down and had a nice, relaxed time eating delicious ice cream at like 5:30 PM. Kind of insane! I feel like I've never eaten dessert right before dinner like that haha. We decided where to go for dinner, and were joined by Sydney later. We invited Sydney to go out with us, but she was in Brooklyn. She's really independent and goes out a lot by herself haha. I respect it.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Dinner.jpeg")} alt="Dinner" />
                    <figcaption>Dinner Selfie</figcaption>
                </figure>
                We got to the Thai restaurant and luckily there was an open table that was unreserved (there were no more reservations available online). We all sat down and had a really nice dinner in this kind of dark, candle-lit, bar and restaurant. It was really busy, and rightfully so! Very large portion sizes and very good food. I traditionally wouldn't say it's worth the $20 I paid for it, but after seeing how hard it is to make tasty food, I am happy to pay $20.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SunsetBuildings.jpeg")} alt="SunsetBuildings" />
                    <figcaption>Buildings at Sunset</figcaption>
                </figure>
                We were kind of far from The New Yorker at this point, but we decided in the end that we would just walk back haha. We had a really nice walk through Broadway (the street) and the streets down from Koreatown. Generally a nice area that feels not too busy, but still has that classic, high-rise NYC vibe. SOOOO tiring but also really nice to get active and walk off how heavy the dinner was haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SydneyNYC.jpeg")} alt="SydneyNYC" />
                    <figcaption>Sydney in the City</figcaption>
                </figure>
                When we got back, I was so excited to shower and relax, but Sydney mentioned wanting to go see the sunset. She wanted to drop her stuff off at her room first, so by the time we all reunited in the lobby, it was like 8:23 PM ish.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SunsetSelfie.jpeg")} alt="SunsetSelfie" />
                    <figcaption>Sunset Selfie!</figcaption>
                </figure>
                We walked down from The New Yorker over to the area near the Lincoln Tunnel at the edge of Manhattan, and we were greeted with an AMAZING view. A red sky, beautiful clouds engulfed in light, and a sliver of the moon that looked like the crescent logo in Dreamworks. We chatted there until the sunset went down, and then finally walked back to the hotel and all got washed up.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ViewFromLittleIsland.jpeg")} alt="ViewFromLittleIsland" />
                    <figcaption>View From Little Island</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/Sunset2.jpeg")} alt="Sunset2" />
                    <figcaption>The Sunset</figcaption>
                </figure>
                Now I'm sitting in the TV room, and Aziz wants to watch Jimmy O Yang's comedy special, which we'll probably do after I finish typing this out. Tomorrow we're hoping to go to Governor's island and rent some bikes, and we'll see where adventure takes us from there. It's funny how fast we plan things around here now, since we'll start the day without really anything in mind to do, and then end up spending the day doing all sorts of fun stuff. But I'm really tired.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sunset.jpeg")} alt="Sunset" />
                    <figcaption>Clouds and Sunset</figcaption>
                </figure>
                My FitBit says I 10.3 miles today, 22,200 steps so far! I can't believe we went the whole entire day without going underground to the subways haha. Kind of insane we just walked all over Manhattan like that. Very satisfying to get all that physical activity in today!
            </div>
    },
    {
        title: "Day 21: Surreys on Governor's Island",
        date: "07/03/2022",
        tags: ["Starbucks", "Governor's Island", "Cooking"],
        content:
            <div>
                Today we had to start the day a bit earlier. Our plan was to all meet up at 10 on the 15th floor so we could get headed to a quick breakfast before catching our ferry to Governor's Island at 11:20 AM. We ended up meeting together a bit later than expected, so were rushed for breakfast. We ended up going to the Starbucks across the street from The New Yorker, which was honestly pretty nice.
                <br/><br/>
                I had an egg and sausage English breakfast sandwich and a grande cappuccino. Pretty good! I like the Starbucks coffee a lot. I feel like it's a little smoother than the free coffee from Joy, but maybe that's just because the Joy coffee is a bit more bitter.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Gov.jpeg")} alt="Gov" />
                    <figcaption>Governor's Island</figcaption>
                </figure>
                We fast walked over to one of the many entrances to Penn Station (Penn Station is HUGGEE), and ran through the subway systems to get to the right track for our subway. By the time we got to our track, the subway was already there, and we were scared the whole group wouldn't make it onto the train, but we DID! Ahaha!
                <br/><br/>
                We had a nice and smooth ride to the South Ferry terminal, which is now a familiar place to me because I've gone there for the Staten Island Ferry before. The morning of panic wasn't just over yet, though. When we looked over to where the Governor's Island Ferry line was, we realized a TON of people were waiting there. A line stretched out from the ferry terminal several blocks down the road, but the guard at the front said we would make the 11:20 AM ferry as planned just fine... and we did!
                <br/><br/>
                We headed off on the ferry to Governor's Island, which is maybe a 5 minute ferry ride from the Manhattan mainland - much closer than Staten Island, which is nice, but less time on the water and less time seeing Lady Liberty.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Path.jpeg")} alt="Path" />
                    <figcaption>A Path on the Island</figcaption>
                </figure>
                We got off the ferry in no time, and were greeted with the terribly hot sun shining over Governor's Island. Who's the Governor? We don't know, and the day didn't help us find out. But we got a nice welcome to a beautiful, green-filled island that looked a bit like a college town paradise. Some areas have fields dotted with trees stretching long distances, while others have nice brick houses and views of the Manhattan skyline.
                <br/><br/>
                We all wanted to bike around the island, but first we had to meet up with Josh! Josh was in Brooklyn playing soccer, so he got to the island straight from Brooklyn, to a different port than the one everyone else came on. Everyone else was Sydney, Sanjana, Mitchell, Aziz, and Lauren. We met up at one of the nice houses, and planned our day from there.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/House.jpeg")} alt="House" />
                    <figcaption>A House With Cannons</figcaption>
                </figure>
                Our first stop was the Citi Bikes (for some reason, NYC is filled with bike racks sponsored by Citi bank). We wanted to rent a bike for each of us, and there were perfectly 7 bikes for the 7 of us. The problem is we weren't actually able to unlock the bikes, so that was a bit disappointing at first. Josh and I messed around with a couple golf carts, which are for only the staff there, and we think the cars actually moved without keys! But we decided not to drive them around because we didn't want to face the consequences haha.
                <br/><br/>
                We walked around a bit more before finding a little house with free exhibitions inside. It was basically some "interactive art" stuff installed by some people, and we got to go inside and just sit around for a bit in a cool environment.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Surrey.jpeg")} alt="Surrey" />
                    <figcaption>Surrey!</figcaption>
                </figure>
                From there, we decided to rent a large surrey, which is basically a 6 person carriage that 4 people can pedal on and that one person steers. Since we have 7 people total, we got 1 large surrey and an individual pedal bike, which is a little cart that lies close to the ground, with the driver kind of leaning back and pedaling forwards.
                <br/><br/>
                We took turns driving the surrey, each of us rotating seats every 10 minutes, since we only wanted to rent the surrey for an hour. At first, we thought 1 hour might not be a lot of time, but as we drove on, we all got really tired haha. It takes a lot of power to pedal a bike holding 6 people haha. Aziz drove ahead of us in the pedal bike, and we got to cycle around the island, with a beautiful view of the Statue of Liberty, Manhattan, and Brooklyn.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Carts.jpeg")} alt="Carts" />
                    <figcaption>Aziz in Front</figcaption>
                </figure>
                On one rotation, I traded with Aziz because his little pedal bike looked like less work, more fun, and faster. And it was all that! It's fun dashing around on the little bike and riding down the bumpy roads. Feels a bit rougher than a bike, but also relatively safe since you lie so close to the ground.
                <br/><br/>
                I rotated back to our surrey, and when I got my turn to drive, everyone was very scared haha. I don't know why! They were so scared I was going to crash into people and do unpredictable things, but I'm so responsible!
                <br/><br/>
                We returned our carts and then went around to buy some lunch. The island has a lot of little outdoor food places that are actually pretty good. You would think that when people are stuck on an island and need food, the food places would want to make the prices way too high since we have no other options. But the food was decent (I had a ban mi on Chinese buns) and the price was fine (like $15). I even bought a strawberry lemonade glass bottle from a different shop since it was only like $3 and it sounded good haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Hamoc.jpeg")} alt="Hamoc" />
                    <figcaption>A Hammock</figcaption>
                </figure>
                After lunch, we walked around a bit and found some hammocks to lie down in on Governor's Island. The sun was scorching us since the hammocks had no shade, but it was a nice little break to lie down and sit around.
                <br/><br/>
                After that, we just walked around and did a bit more exploring, appreciating the beauty of the island, before heading back to relax in the exhibition room until we had to go on our ferry back to Manhattan.
                <br/><br/>
                Back on Manhattan, we headed straight back to the hotel, and we all parted ways. I decided to finally take care of my Metro card problem (two Thursdays ago when I swiped my card, it said "See agent" and there's just something wrong with it ahaha). I've just been following people in when it's travel unrelated to work, and paying myself when I have to go to work. It's been working fine, so I don't think I'll actually end up getting another MetroCard haha.
                <br/><br/>
                Anyways, I went to Staples because the EHS RA on duty told me Staples mails stuff, but it turns out Staples only mails PACKAGES, not envelope mail ahaha. Very confusing. Packages seem so much harder to mail than envelopes ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/GovFerry.jpeg")} alt="GovFerry" />
                    <figcaption>Ferry Back!</figcaption>
                </figure>
                So, I asked them for the nearest post office, and it turns out there's a post office right in Moynihan Train Station, which is right across the street from The New Yorker. I waited in a short line and the post office person helped me out very nicely. I asked what else I needed to put on my envelope (it's postage paid by the MTA), and he told me to write my name and return address, and was very patient with me ahaha. It was actually kind of fun.
                <br/><br/>
                Then, I headed back to the hotel and got right to cooking dinner. I have the same 5 ingredients to cook with, but I wanted to get something new for dinner, so I decided to make tomatoe eggs with noodles. It was pretty fun to cook, since the tomato juice smells really good haha. Also, I put some sesame oil on my pan and threw my wet tomatoes in (I rinsed them), and the oil started splattering everywhere which was dangerous but nice after I put the lid on the pan. Oh, and sesame oil causes a lot of smoke to come up, which was fine since I just had to turn the fan on ahaha.
                <br/><br/>
                I had dinner with Josh since he was cooking while I was eating, and Sanjana came in a bit after I finished eating. We all hung out for a bit and then waited for Arielle to show up, who came and started cooking while we were all done with dinner.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/GovSelfie.jpeg")} alt="GovSelfie" />
                    <figcaption>Selfie on Governor's Island</figcaption>
                </figure>
                We decided to play photo roulette, which is an app where random pictures from someone's camera roll gets shown, and we all have to guess who's picture it is. Very dangerous but very fun game to play ahaha. By the end of it, we all knew very well what types of pictures we all take. The consensus is that I take a lot of pointless pictures ahaha.
                <br/><br/>
                I've played photo roulette in high school before, but back then, literally ANY picture could show up. Now, there's a pre-screening phase where you get shown a ton of pictures that MIGHT get shown, and you can reject the panel of pictures or accept a new one.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/EggTomato.jpeg")} alt="EggTomato" />
                    <figcaption>My Dinner</figcaption>
                </figure>
                After that, we all got cleaned up and now a few of us are reunited in the kitchen area on the 15th floor. We're now making plans for tomorrow, but it looks like we're all kind of leaning towards doing nothing too much besides going out for fireworks at night. We're all pretty tired since we haven't had a quiet day to sleep in and just relax in a while haha. Tomorrow will be a nice day.
            </div>
    },
    {
        title: "Day 22: Independence Day",
        date: "07/04/2022",
        tags: ["Columbia", "Cooking", "Riverside Park", "Pool", "Fireworks"],
        content:
            <div>
                Last night we were planning on not doing much, but towards 2 AM, Arielle, Mitchell, and I decided to go out. I set my alarm for 10:30 AM since I got to sleep around 2:30, but I ended up waking around 10 and couldn't go back to sleep haha. So I got up and ate some of the free food I got when I moved into EHS (fig newtons and Cheez-Its). I was also a little stressed since my Fitbit doesn't work anymore, but I found out the problem I have is pretty common, and I think I'm still under the warranty. I'll probably buy a regular watch sometime now haha.
                <br/><br/>
                I decided to go shopping because I don't have any vegetables anymore (well, besides cilantro, but Mom doesn't count that as a vegetable). I went to HMart in the morning after calling to make sure they're open today (thank God they don't celebrate the Fourth!).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Columbia.jpeg")} alt="Columbia" />
                    <figcaption>The Quad @ Columbia!</figcaption>
                </figure>
                Mitchell came along since he really likes the layout of HMart. I got oyster sauce, canola oil, green onions, big tomatoes, minced garlic, and black bean garlic sauce. Enough stuff to actually make some food that tastes like something!
                <br/><br/>
                After our HMart trip, we headed back and cooked up lunch. I made some of the same crap I've been eating every day - eggs and tomatoes, but this time with chow mein that tasted good! A lot of garlic, some of the black bean sauce, and some oyster sauce. Very yummy!
                <br/><br/>
                Sydney and Aziz were in the kitchen area when I was eating lunch, so we had a nice little hang out while I was eating, and then it was like 2 PM by the time I finished cleaning up. We were leaving for Columbia and Riverside Park in the upper west side of Manhattan right after lunch.
                <br/><br/>
                The group of us (Aziz, Sydney, Arielle, Mitchell, and me) took the train ride up, which kind of takes a while. Columbia is not that far, but also not that close. On the train ride there, Arielle actually saw someone that goes to Caltech, which is kind of insane because she was just saying like 5 minutes ago that she always sees people she knows around in NYC haha.
                <br/><br/>
                We made it to our subway stop, and we headed to a Hungarian Pastries cafe before going to Columbia. Arielle said that coffee shop has some of the best coffee she's ever had, so she and Sydney were both excited since Sydney drinks a ton of coffee. The shop ended up being closed, which we didn't fully expect since Hungarians don't need to celebrate the Fourth!
                <br/><br/>
                But I guess we didn't make the detour for no reason, because there's a really nice castle-like cathedral across the street that we got to see on our walk over to Columbia. It's a nice neighborhood, peaceful like the area MSK is in. But it also was a bit depressing because it's just a couple of huge Mount Sinai hospital buildings and other buildings that don't look that nice.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Riverside.jpeg")} alt="Riverside" />
                    <figcaption>Riverside Park!</figcaption>
                </figure>
                Before long, we got to a couple gates welcoming us to Columbia. I was excited to see what Columbia looked like, but I was a bit let down inside. We were in the main quad, an area with nice garden fields where you have campus buildings on all 4 sides of you (hence, the "quad"). It was nice, but I don't feel like I was on an actual college campus. You can see the city like 2 blocks distance away from you, and I just didn't like how it didn't feel like a closed bubble.
                <br/><br/>
                I guess I'm too used to how UCLA feels, like you're not actually in Los Angeles. I don't really like how the Columbia campus is integrated into the city, but it's also nice because it's convenient to get anything you need outside of campus.
                <br/><br/>
                We ddin't explore too much since Arielle has been there before and the famous steps were closed off for construction, so we headed off to a cafe to get some food for those who hadn't eaten lunch.
                <br/><br/>
                The cafe was a nice little cafe that I'm only talking about because they have free sugar (of course), which I stole to cook with. But what surprised me is they had free salt and pepper packets too, as well as these nice, strawberry preserve packets from France. Fancy stuff. I guess they have that there because they also sell quite a bit of food.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Riverside2.jpeg")} alt="Riverside2" />
                    <figcaption>The Path With Trees!</figcaption>
                </figure>
                It was time to head to Riverside Park. My immediate impression of Riverside Park was that I liked it better than Central Park. Central Park feels a bit dirty at some places, because it's so crowded and just feels like it's thrown into the middle of Manhattan.
                <br/><br/>
                Riverside Park, on the other hand, is next to a river, and has a really long path that's bordered by trees along the whole walkway. It's a really beautiful place, and it's a bit downwards from the main city, which makes it a bit tucked away and more peaceful.
                <br/><br/>
                We walked down the path a bit before going down towards river level a bit more, where they have a skate park, basketball courts, workout areas, and all sorts of sports stuff. The only thing that was annoying is that there's a highway running along parallel to the river, in between the park the and the river view. So we didn't actually get to go next to the river, but at least we could see it! The NYC skyline is always so cool to see.
                <br/><br/>
                We were just walking around the park, so it wasn't too exciting haha. I wanted to find bikes, but Aziz didn't want to bike. There were no Citi bike racks within walking distance anyways, so I guess it worked out for Aziz haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BentoBox.jpeg")} alt="BentoBox" />
                    <figcaption>Bento Box Dinner</figcaption>
                </figure>
                We walked a bit more before deciding to go back. We spent about 2 hours outside, and I was already feeling pretty tired. Aziz suggested going to Harlem, which some of us wanted to do, but Arielle wanted to go back, which I was also leaning towards since I just didn't feel that excited or motivated to go out.
                <br/><br/>
                We walked to the subway station, and Mitchell, Aziz, and Sydney went to the uptown track to go to Harlem, while Arielle and I lagged behind a bit. Arielle and I ended up going downtown back to The New Yorker, but there was some miscommunication because Arielle didn't realize the others decided to actually go to Harlem.
                <br/><br/>
                Mitchell called while we were on the subway back, and Aziz was texting us, so we said we were heading back. A little miscommunication, but it was all okay haha. Arielle said she didn't really want to just walk around without a plan, and I agreed because it can get kind of boring.
                <br/><br/>
                We headed back to The New Yorker and just played pool for a while. Josh joined us later on since he wanted to spend the day relaxing, grocery shopping, and cooking. We all played pool for a while (Josh destroyed us), and had a fun time while the others were in Harlem.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoshSunset.jpeg")} alt="JoshSunset" />
                    <figcaption>Josh in the Sunset</figcaption>
                </figure>
                Sanjana, who normally hangs out with us, went out with some other MSK interns (in a different program) to Coney Island, watching the very American hot dog eating competition. She said she didn't have much of a good time and didn't even eat a single meal until like 6 PM haha. Sounds like a terrible day. The hot dog eating competition is so sick ahhaha. But very cool that it happens in NYC. For some reason, I never thought about how it happened in NYC. I might've even gone if I knew, just because it's a big event that ESPN and all sorts of news outlets go to, but I'm kind of glad I didn't go.
                <br/><br/>
                Sanjana joined us later on and we decided to go out to dinner since we didn't have time to cook if we wnated to make it to the fireworks show. We went to a sushi and ramen shop close by The New Yorker, and we had a really nice Asian waiter who was joking with us about Asians after Josh ordered wine. He carded Josh at first, but after talking, said Josh didn't need to show him his ID haha. He was like, "you're going to surprise me, going to be 38!" after talking about how Asians look the same through age.
                <br/><br/>
                The restaurant is kind of confusing, since it's Japanese food and Japenese branded, but the waiters were speaking in Chinese haha. They have a really cool happy hour deal where you get an entire roll of sushi for just $5. It's kind of insane. The 3 others all got 2 rolls of sushi, and I got a pork cutlet bento box.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Fireworks.jpeg")} alt="Fireworks" />
                    <figcaption>Our View of the Fireworks</figcaption>
                </figure>
                The others finished their food pretty fast, and I had a lot of food left, so I just shared it with them haha. Arielle and Josh had a piece of pork cutlet, a piece of the California roll, and some rice. They played rock, paper, scissors for my last fried shumai haha. It was still a lot of food for me, so I didn't mind. Such a good deal. We want to go to that restaurant a lot more since their happy "hour" is 4 PM to 8 PM, which is kind of crazy. Good deals for such a long time.
                <br/><br/>
                After eating, we headed back to The New Yorker, where we hung out on the 15th floor deciding how to get to the fireworks show. The fireworks started at 9:25 PM, and it was already like after 8 PM when we were deciding what to do. We looked at Uber prices, Lyft, and the subways, but realized the subways took too long. The place we had to go to, the very East side of 34th, is kind of far (a 25 minute walk), and we only left at like 9 PM. We decided to just walk, but maybe 10 minutes in, we tried getting an Uber since we didn't know if we would make it.
                <br/><br/>
                The nearest Uber driver cancelled our order, so we just committed to walking fast as hell down the streets. At some points, we were running, and at some points, we were just pushing past people.
                <br/><br/>
                Eventually, we made it there in time (like at least 5 minutes early)! The end of 34th street was absolutely packed with people. The police blocked the viewing area off because it was way too full, so we were forced to other streets. But there were only like 4 viewing places on that side, so we got a little panicked. The second viewing street was also closed off, but there was a street that's not for viewing that had no people.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AfterFireworks.jpeg")} alt="AfterFireworks" />
                    <figcaption>After Fireworks Selfie!</figcaption>
                </figure>
                The police there were only letting residents go in, so Josh looked at the map, found the name of one of the apartment complexes, and told the police we live there. They asked for an ID proving it, and Josh said we didn't have one, so we failed our mission haha.
                <br/><br/>
                We walked up north to higher-numbered streets, and saw a street that wasn't an official viewing place, but was absolutely packed, so we went there. And then the fireworks show started, right when we got there.
                <br/><br/>
                We were so excited that we made it, Josh was jumping up and down, and we were all screaming with the crowd hahaha. It was an absolutely beautiful show, even though we could only see the very left side of the fireworks since the tall buildings were in the way of the rest of the show.
                <br/><br/>
                We still had a beautiful view. You could see really cool fireworks, the sound of the fireworks echoed off all the buildings around us so it was really loud, and we could see the buildings across the water sparkle with the fireworks. The overpass in front of us was absolultely packed with people, which was also a really cool sight to see.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MSK_Joy.jpeg")} alt="MSK_Joy" />
                    <figcaption>Noticed Joy Has a Sign<br/>This Morning Because<br/>I Walked From the Other<br/>Side of the Street Ahaha</figcaption>
                </figure>
                The show lasted for 20 minutes, and then we all headed back to the hotel. A nice, long walk that filled the streets of Midtown. So so so many people, it was kind of insane. There were police officers at all the busy intersections directing traffic as usual, and I walked past one and said "gracias officer." He laughed and said no problem, which surprised me, cuz I thought I would just get ignored haha.
                <br/><br/>
                We got back to the hotel and all cleaned off before heading back down to the 15th to hang out for a bit. We ended up talking till around 1 AM, which is really late for a night before work, but we all had a good time haha. Nice to hang out. I set an alarm for 8 AM, and now I'm writing this blog in the office during a talk on how to create powerful posters. I don't care too much about this presentation and think it's a semi-waste of time, so it's nice to get this done during the presentation haha.
            </div>
    },
    {
        title: "Day 23: Gnocchi and an Early Clock Out",
        date: "07/05/2022",
        tags: ["Work", "Cooking", "Trader Joe's"],
        content:
            <div>
                Started the day a little tired! Woke up at like 8:20 even though I set an alarm for 8, but I still got to work just a bit after 9 AM haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Joy_MSKCC.jpeg")} alt="Joy_MSKCC" />
                    <figcaption>Joy Has a Sign!</figcaption>
                </figure>
                The first thing I did when I got to work was check whether or not the jobs I ran last Friday finished successfully. Unfortunately, only 1 of them didn't crash! And the one that DIDN'T crash still has no useful output, so I am basically back to square 1 ahaha. Well, not technically. I just needed to make some small changes and resubmit the jobs that should have good results for tomorrow.
                <br/><br/>
                The rest of the morning was just a presentation on how to make good posters (not very useful in my opinion), and that was it! I had to head to our lab's learning event from 11:30 to 1:30, so I really didn't have a chance to sit down and do my own stuff (the presentation on posters was from 9:30 to 11:00 ahaha).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Lentil Soup.jpeg")} alt="Lentil Soup" />
                    <figcaption>Lentil Soup With High Fiber Ahaha</figcaption>
                </figure>
                I didn't really follow our lab's event today. I ended up dozing off quite a bit. At least I got a free lunch ahaha! After lunch, I sat around for a couple hours not sure what to do. I ended up very briefly Slacking Wesley and getting some more direction, and edited my program before resubmitting.
                <br/><br/>
                I talked for a bit with the other interns at my desk, and at like 3:15, Sydney was talking about leaving work early and going to Trader Joe's. I wanted to go shopping at HMart to pick up a leek, but I realized I also need to buy breakfast, so I said sure. I had nothing else to do anyways (all I had left to do was wait)! So, I left work early and went shopping on company time. What a good decision!
                <br/><br/>
                Trader Joe's is like two blocks away from Joy (which I noticed has an MSKCC sign today for the first time ahaha), and is an overall very nice shop! I got all sorts of stuff I wasn't planning on buying, like 4 blueberry muffins for $4, some cookies that Mitchell showed me before, gnocchi, and vegetables like arugula and cabbage! Very happy to get some more healthy stuff to cook with. I also got lentil soup since it said high in fiber!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Grocery Cart.jpeg")} alt="Grocery Cart" />
                    <figcaption>Grocery Cart @ Trader Joe's!</figcaption>
                </figure>
                As Sydney and I walked back to the subway station, I was thinking about what to cook for dinner. I want to eat something different from what I've been eating in past days, so I decided to make an Asian-inspired gnocchi with lentil soup.
                <br/><br/>
                When we got back, I relaxed a bit in my room before heading to the 15th floor gym to work out. I want to get stronger muscles in the areas I need to be strong in for tennis, like my shoulders, chest, and back. So, I did a shoulder workout today (and did some cardio on the elliptical, which made my glutes feel really tight haha). Great workout! Definitely going to go more, especially since Sanjana, Aziz, and Josh always go. I went for the first time about last week because Josh encouraged Sanjana and I to join ahaha.
                <br/><br/>
                Then, it was time to cook dinner! I stir fried a BUNCH of arugula and cabbage, and was surprised at how small all the vegetables shrunk after being cooked well haha. I put in too much black bean sauce, so it ended up being pretty salty haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Our Fridge.jpeg")} alt="Our Fridge" />
                    <figcaption>Our Minifridge is Very Full</figcaption>
                </figure>
                Threw in the chicken, and had a pretty hefty portion already! Boiled my gnocchi, ran it through cold water, and set it to the side before heating up some lentil soup. The soup is really thick, so I added some water, then threw in my gnocchi, tasted it, and threw in the rest of my food.
                <br/><br/>
                The end result was pretty damn good! I don't actually like the lentil soup that much, but my whole dish tasted pretty good haha. I was pretty proud of what I made, and it probably took close to an hour. Very fun, I really enjoy cooking.
                <br/><br/>
                Ate with Mitchell, who made a burrito for dinnner, and then got all cleaned up back in my room. Sanjana kept burning her quinoa in her pot, since she has a really large surface area pot that can't cook a single serving of quinoa well, so she went to Target to buy food. But she got absolutely drenched outside since it was raining, so I just offered her the rest of my lentil soup, which she took (thank God). Glad to get that lentil soup off my hands ahaha. I would not want to eat it again!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Home Cooked Dinner.jpeg")} alt="Home Cooked Dinner" />
                    <figcaption>My Home Cooked Dinner!</figcaption>
                </figure>
                Tomorrow, I'll probably make a tomato-egg gnocchi dish. A full Chinese gnocchi dish this time instead of gnocchi with nasty lentil soup! Yay! I'm excited ahaha. Also, tomorrow is CBSP's midpoint lunch, where all of CBSP will get together at a fancy Italian restaurant and eat on company money. I'm really excited, since I don't think we've even had a social event where everyone has gone to yet ahaha. Should be a pretty fun day tomorrow!
                <br/><br/>
                The rest of the night I'll just hang out on the 15th with probably a bunch of the other CBSP kids and Josh, who is honorary CBSP ahaha. Excited for this week!
            </div>
    },
    {
        title: "Day 24: Lunch With Ushma",
        date: "07/06/2022",
        tags: ["Working", "Cooking"],
        content:
            <div>
                Today, I woke up at like 8 AM but I was too tired so I didn't want to get out of bed. I opened Google Translate and used it to tell Mitchell that he could leave without me haha.
                <br/><br/>
                I ended up staying in bed till around 8:20 AM, and then got up and did my morning routine before heading to work (with my bag of breakfast since I bought muffins and cookies yesterday)!
                <br/><br/>
                When I arrived at work, it was just Jaden and I (I actually bumped into Jaden in the subway, which surprised me since he's usually much earlier than me haha). We headed to Joy and I had a very nice start of the day, eating a sugary blueberry muffin and a half-moon cookie that tasted like a donut. I also saw that my job from yesterday crashed because of a dumb error, which was also a strong start to the day :)
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BlueberryMuffin.jpeg")} alt="BlueberryMuffin" />
                    <figcaption>Delicious Breakfast</figcaption>
                </figure>
                Worked at my desk for a bit before heading off to our CBioPortal lecture, which was not all that useful in terms of learnig how to use MSK's CBioPortal haha. We learned more about the history of the portal and about how it's used these days.
                <br/><br/>
                After that lecture came the big event, our CBSP midpoint lunch! We walked over together to Donna Margherita, where all 16 of us (14 CBSP students, Michael Berger (the head of CBSP), and Ushma Neill) sat together in a long row of tables outside. The tables actually weren't together at first, and Ushma asked the waiters to rearrange the tables for us haha.
                <br/><br/>
                I was very happy with the arrangement of seats. I got to sit mostly next to people I normally hang out with, and Ushma was on my diagonal left, pretty much right across from me. I was happy to have the opportunity to talk to Ushma more.
                <br/><br/>
                We took some time to look over the menu, and I was so confused because there were a bunch of Italian words that I did not understand haha. Ushma gave us a rundown of what all the pastas were, and I ordered a $38 dollar dish thinking I would get pasta and fish (SINCE IT WAS UNDER THE PASTA SECTION), but I ended up just getting a long plate with a fish ahaha. It was pretty good though!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FishLunch.jpeg")} alt="FishLunch" />
                    <figcaption>Fish Lunch!</figcaption>
                </figure>
                Ushma asked each of us how we've been doing in the lab, so we all got to know a bit more about how things have been going for each other in the lab. For the most part, it looks like people are getting to the point where we're actually doing something productive in the lab, which is nice.
                <br/><br/>
                I asked Ushma how the PI's get chosen for CBSP, and whether it's just a thing where they can opt in to be an option for CBSP. It turns out that Ushma and Michael screen all the computational oriented cancer research PI's, and ask if they'd like to be a part of CBSP. All of them have said yes, which is pretty damn amazing ahaha.
                <br/><br/>
                Ushma and Michael don't let PI's be a part of CBSP anymore if an intern doesn't have a good experience in their lab, which I respect a lot. That's very responsible of them haha. Ushma said that this year, only 1 of the 45 total interns she's hired had to change labs, and that PI that the person got changed out of will not have a chance to be a CBSP PI anymore because they treated their intern more like a technician than a researcher haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CBSP.jpeg")} alt="CBSP" />
                    <figcaption>A Beautiful CBSP Gathering</figcaption>
                </figure>
                Ushma said we all have something to bring to the labs. She talked a bit more about how we got placed into labs, and said it was impossible to place everyone with someone they ranked in their choices, since like everyone wanted the same people. Since I spurred her to talk about all this, she used my situation as an example, saying I was placed with Wesley Tansey since I was probably the person with the most experience doing that mathy, stats stuff (I told her that it was an adjustment to realize that Wesley comes from a CS background but is also like a Bayesian Statistician, which I don't really know what that means haha). I thought that was funny because I don't think I have all that much experience in that field haha.
                <br/><br/>
                We also learned a bit more about the vetting process for CBSP. Ushma said they get some bullshit applications, so there's an initial screening process where they filter out until they have 200 applications, and then from there, Michael and Ushma read each and every application. Ushma gets a few people from the GSK Graduate School (in MSK) to read the applications and write their opinions, which Ushma and Michael take into full consideration. Very cool!
                <br/><br/>
                She also mentioned how Taykhoom cheated on her by going to Harvard instead of returning to her ahaha. She acted all offended, which was pretty funny haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TomatoEggGnocchi.jpeg")} alt="TomatoEggGnocchi" />
                    <figcaption>A Long Dish to Make</figcaption>
                </figure>
                Ushma talked about what she does outside of MSK, and said she went to a protest the day Roe v. Wade came out since she couldn't focus on work at all that day. She brought her kids to protest, and she said something happened for the first time ever to her - she went viral on TikTok ahaha. Pretty cool!
                <br/><br/>
                After lunch, we hung out in the coffee room for a bit, and then I headed back to Joy to do some work before heading back to The New Yorker. I cooked up a tomato egg gnocchi dinner, which took like an hour and a half, and now I've just been hanging out on the 15th floor like a usual night. Tomorrow, I'm going to a Mets baseball game, which should be pretty interesting although possibly not very exciting haha, and then it'll be Friday! This week is going by so fast!
            </div>
    },
    {
        title: "Day 26: Yankees Suck",
        date: "07/07/2022",
        tags: ["Baseball"],
        content:
            <div>
                It's almost 2 PM on Friday, and I'm now writing the blog for yesterday as I wait for something to run (we stayed up till 2:30 AM last night, so I didn't get a chance to actually write anything haha).
                <br/><br/>
                Yesterday, I started the day working away as normal. Got to work a bit late, maybe around 9:15 AM since we've been sleeping late lately, and got right into things. They finally fixed the coffee machine which is nice, since it wasn't working for two days and I really wanted my daily cappuccino haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/VerticalHair.jpeg")} alt="VerticalHair" />
                    <figcaption>Late Showers Have Consequences</figcaption>
                </figure>
                Worked till around 12:30 PM, when us (the people in Joy) left to walk to ZRC, since we had a pan-internship lecture there. The nice thing was that they provided lunch, so yet another free meal.
                <br/><br/>
                The pan-internship lecture was by Charles Sawyers, who's apparently really famous since he's helped develop some of the best drugs in prostate cancer and leukemia. He's a really nice person, and is very sweet. He asked questions during lecture but people weren't really speaking up, and he just managed things well overall haha. Very patient and happy to be there type of guy.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/OutdoorsSubway.jpeg")} alt="OutdoorsSubway" />
                    <figcaption>Subway Above Flushing</figcaption>
                </figure>
                After the lecture, several of us CBSP people were talking, and Rhea mentioned wanting to go to the baseball game, but not getting a ticket. When we walked to Joy together, Jaden told me about how he doesn't like baseball and is still deciding whether to go, so I asked Jaden if we could buy his ticket, and he just gave it to Rhea ahaha. Nice guy.
                <br/><br/>
                We walked back to Joy together, Jaden, Rhea, and I, and had a nice time. Rhea doesn't really show up to the office a whole lot because her lab is mostly remote, so it was nice to see her around. She talked a lot more than I remember she used to.
                <br/><br/>
                Most of the CBSP people that were going to the baseball game wanted to head back to The New Yorker so we could drop our stuff back off at the hotel before going to the game, so we planned to all leave work early at 4:00.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Stadium.jpeg")} alt="Stadium" />
                    <figcaption>Citi Field!</figcaption>
                </figure>
                The problem is that by the time it was 4:00, I couldn't find Rhea in Joy (we were supposed to leave together). I walked to go use the bathroom, and saw Rhea and her mentor (who usually barely talks to her) in a conference room talking.
                <br/><br/>
                I texted the others asking what I should do, and eventually decided to just go to the surgery center to pick up a nice dinner. I wanted to eat something healthy and filling before going to the game, so it worked out kind of nicely. I also kind of wanted to eat it at Joy since I didn't want to hold a plate of food all the through the subway station, and luckily Rhea's meeting was long enough that I finished all my food.
                <br/><br/>
                The problem was that by the time I finished dinner at like 4:30, Rhea was STILL meeting her mentor. I then decided to just head back myself, since the others at the hotel wanted to leave at 5:15.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/InsideStadium.jpeg")} alt="InsideStadium" />
                    <figcaption>Entrance to The Stadium</figcaption>
                </figure>
                On the way back, Rhea said she finally finished her meeting, and was heading back too. I was pretty panicked since I didn't want to hold up the group, but I arrived a bit before 5:15. Rhea asked if we could wait till 5:25, and Aziz really didn't want to (since he wanted to go to the game with Ushma and the rest of the interns), but we all decided to stay till 5:25.
                <br/><br/>
                While we were waiting in the lobby, we saw Rhea walk in and we were all excited to finally get going. While Rhea was going upstairs to put her stuff away, Sanjana asked Aziz if he had a screenshot of his ticket, since Ushma sent them to our MSK emails, which we can't access on our phones (except Sanjana, who somehow got it to work. No one knows how haha).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TopView.jpeg")} alt="TopView" />
                    <figcaption>View From the Top</figcaption>
                </figure>
                Aziz was like, "oh shit no I don't" and went running upstairs. It was pretty funny since he was the one that really wanted to get going, and he ended up being the last one ready since Sanjana reminded him to get a picture of his ticket ahaha.
                <br/><br/>
                We walked fast over to the subway station, but on the way there, we realized we could just meet Ushma and the rest of the people at the subway station they were walking to, which was conveniently the same station we were heading to directly on the subway.
                <br/><br/>
                I texted Ushma (since she gave me her number at the pride parade in case "we got too drunk" or partied too hard ahaha), and she confirmed the place we could meet her at. We ended up getting there WAY before the main group, and waited at the subway track for at LEAST half an hour. It was insane haha, none of us have ever just stood there in a subway station that long before.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Nachos.jpeg")} alt="Nachos" />
                    <figcaption>Nachos Hat!</figcaption>
                </figure>
                They finally arrived, and we got on our way to the game in an absolutely packed subway. There were delays for the train we needed to take (one of them left right before the main group arrived at the track unfortunately haha), so it was already a bit tighter than normal. But it was fun seeing so many people in a train haha.
                <br/><br/>
                We transferred at Roosevelt Station and got on this beautiful subway that's outdoors, above the Flushing area and Queens, another borough of New York. I've now been to 3 boroughs! Manhattan, Brooklyn, and Queens. Anyways, we had a beautiful view of buildings and the view until we finally arrived at Citi Field, our stadium.
                <br/><br/>
                While we walked up the steps out of the subway station, people were making a lot of noise and yelling "Yankees suck!" Apparently Yankees and Mets are both NY baseball teams, and people only support one of them, either Mets or Yankees haha. At least that's what I gathered.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Nightfield.jpeg")} alt="Nightfield" />
                    <figcaption>The Field at Night</figcaption>
                </figure>
                It was pretty exciting. The stadium looked very grand from outside, and the inside was also really nice. And when we finally got to our actual seats - wow. Truly a beautiful view. You could see thousands of people, the sunset in the background, and a really nice baseball field below.
                <br/><br/>
                Arielle, Aziz, and I went around the stadium to pick up food for Arielle and Aziz, which took a while, and we finally got to sit down at around 8 PM, right when the sunset was about to begin. Very nice view of a sunset from high up.
                <br/><br/>
                The baseball game went on, and Arielle was explaining everything to us since her and her Father are huge baseball fans haha. Very fun. There were people in front of us that kept looking back at us, and I was getting really annoyed, so I pointed it out to Arielle, who started getting pissed too ahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BBSelfie.jpeg")} alt="BBSelfie" />
                    <figcaption>I Was There!</figcaption>
                </figure>
                We had a nice time watching the game, seeing some cool plays (but mostly boring since it takes forever between turns in baseball), and participating in the activities they put on the big screen like the 7th inning stretch. Very fun time, but I was disappointed that not more people in the stadium were participating haha.
                <br/><br/>
                Ushma bought people food by giving them her corporate card ahaha, and she got us ice cream too which was really nice. We got ice cream in these little Mets "hats," which makes for a really nice souvenir to take home haha.
                <br/><br/>
                After the game, we all headed back and by the time it was our stop, the train car we were in was empty, so we got some cool pictures of those of us who travelled back together. The CBSP people all went to the 15th floor to clean our hats from the ice cream and food, and we decided to hang out before showering and doing all that.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/EmptySubway.jpeg")} alt="EmptySubway" />
                    <figcaption>Our Very Own Car</figcaption>
                </figure>
                We ended up hanging out till 2:00 AM, just talking, so I showered very late and got to bed at around 2:30 haha. On the bright side, I woke up at like 9:15, so I got enough sleep haha. It's kind of crazy how late we stay up all the time haha. I don't even feel too tired. Last night, it was Aziz, Josh, Julia, Sanjana, Arielle, and I, but Aziz went to bed early at like 12.
                <br/><br/>
                Overall a very fun day. Didn't expect to like being at the baseball stadium so much haha. But it wasn't really the game itself that was fun. It was the people we were with.
            </div>
    },
    {
        title: "Day 27: Planet Fitness",
        date: "07/08/2022",
        tags: ["Gym", "Cooking"],
        content:
            <div>
                Yesterday, I showed up to work at like 10:30 AM because we stayed up till past 2 the night before. The subway was less crowded, of course haha, and it was a nice morning. I don't even have to go in on Mondays or Fridays, and no one ever cares when we actually come in, so there's no pressure to get to the office at any particular time.
                <br/><br/>
                Had my breakfast and then started reading all the messages Wesley sent. We got some results that weren't what we wanted to see, so he was taking a closer look at a lot of stuff and had ideas and things to talk about.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Morning.jpeg")} alt="Morning" />
                    <figcaption>Showered Late Again</figcaption>
                </figure>
                Ater working a bit on that, I headed to lunch and had an otherwise very normal workday. We left the office at 3:30 PM since there was a No Dumb Questions session with Ushma and Michael at 4, a session where CBSP kids (required for CBSP, optional for others) can ask anything they want.
                <br/><br/>
                It ended up being a much more formal session than I expected it to be. People had a lot of questions about how institutions like MSK works, grants, etc. Turns out that Ushma and Michael are paid in part by the grant for CBSP ahaha. Must be a lot of money for this program.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Classroom.jpeg")} alt="Classroom" />
                    <figcaption>Zuckerman Classroom</figcaption>
                </figure>
                After the session, we all headed back to The New Yorker together. It was probably the first time we've all left at the same time, since we never have events at the end of the day. It was raining, which always surprises me, because I never expect it to rain around here haha. Luckily I have my umbrella.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Fitness.jpeg")} alt="Fitness" />
                    <figcaption>Planet Fitness</figcaption>
                </figure>
                On the way back to the hotel, Josh, Sanjana, and I decided to go to Planet Fitness. They have a high school summer pass where teens age 14 to 19 can get a pass to go to the gym for free, but they don't actually verify your age. So, even though Josh is 21, he got a pass, and we got Yutaro and Aziz to come to us. Both of them are 20 or older, but they both got passes anyways haha.
                <br/><br/>
                The Planet Fitness is actually pretty close to us. It's not as far as the subway station we go to to get to MSK, so it's really not a bad walk at all. We were a bit nervous that they wouldn't let us in at first, but we went in, scanned our passes, and everything was just fine haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Chowmein.jpeg")} alt="Chowmein" />
                    <figcaption>My Bussing Dinner</figcaption>
                </figure>
                Josh showed Sanjana and I how to use a couple of the machines for leg exercises, and we had a good workout. I wanted to cook something for dinner instead of eating out, and Josh already had food leftover (and Aziz and Yutaro always buy food haha). But Sanjana needed some more stuff for cooking, so we all went to HMart before going back to the hotel. I got some laoganma since I've been wanting some way to make my food just a little spicy.
                <br/><br/>
                We got back to the hotel and we all went to either eat our dinners or cook. I once again just stir fried a bunch of vegetables together and had some fresh Shanghai noodles from HMart. Very very good! Probably one of the tastiest things I've made, but the bar is low :D
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Nighttime.jpeg")} alt="Nighttime" />
                    <figcaption>Nighttime Hangouts</figcaption>
                </figure>
                After dinner, we cleaned up and then all reunited on the 15th floor's TV room. There, we just talked for a bit before playing codenames until like 2 haha. Nice night and as usual, we stayed up too late. Seems like 2 is the agreed upon cutoff time for hanging out, though we've never explicitly said 2 is when we all go back haha. Nice day overall. Didn't do too much but had a nice day.
            </div>
    },
    {
        title: "Day 28: The Edge",
        date: "07/09/2022",
        tags: ["Cooking", "The Edge", "Pakistani Food"],
        content:
            <div>
                Today I set an alarm for 9:30 AM since I like to have free mornings, but I ended up staying in bed and falling asleep until like 11 AM haha. Nice relaxation time though, so I didn't mind too much. I started my day by eating a maple flavored yogurt I bought from HMart the other day. It was SO GOOD. Brown Cow brand. Never heard of it before!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StudyRoom.jpeg")} alt="StudyRoom" />
                    <figcaption>15th Floor Quiet Room</figcaption>
                </figure>
                After breakfast, I left to go cook lunch. I ended up seeing Aziz, and he said him, Mitchell, and Sydney would come in later to cook eggs and stuff. Basically a little brunch. They had gone out for coffee in the morning together. I didn't go with them because my data was off and I didn't realize Aziz had asked until too late haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/OldFridge.jpeg")} alt="OldFridge" />
                    <figcaption>Bye Bye Fridge</figcaption>
                </figure>
                Anyways, I was asleep when they went out to coffee, at least I think. So, I didn't mind too much. I made a gnocchi wih stir fried vegetables, again using my Laoganma and garlic. Tastes so damn good, but I wonder how long it'll take to get sick of eating the same thing every day haha.
                <br/><br/>
                I had a sausage with lunch which was pretty good. Very convenient way to get some meat in. Will probably eventually buy some ground beef or something to try to cook some real meat haha. For dessert, I made a scrambled egg that had green onion, Laoganma, and garlic beat into it. Pretty good! Probably the tastiest egg I've made for myself haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/EdgeGroup.jpeg")} alt="EdgeGroup" />
                    <figcaption>Going Into the Edge!</figcaption>
                </figure>
                After lunch, I went back to my dorm and was putting stuff in our fridge, but noticed a puddle in front of it since my slide slipped a bit. Last night, I noticed that our fridge might've been leaking, so Mitchell and I said we'd keep an eye on it.
                <br/><br/>
                When I saw a new puddle in the afternoon, I thought it was probably time to report it. I opened the fridge door to make sure one last time that it wasn't something we put in the fridge, but my bottle of oyster sauce fell out and broke haha. My fault for buying a large sized bottle. Oh well, it was only $8, so not too bad.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoshLeaning.jpeg")} alt="JoshLeaning" />
                    <figcaption>Josh Leaning On the Glass!</figcaption>
                </figure>
                I went down to the 15th where Mitchell was to get his help cleaning up, and we also reported the fridge incident. We cleaned up the spill after several trips up and down from our room to the 15th to get paper towels (I told Mitchell he could go do his own thing after the first trip since I felt bad using him to help with my mess haha).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Edge.jpeg")} alt="Edge" />
                    <figcaption>Edge View</figcaption>
                </figure>
                I finally got everything cleaned up, and the RA we talked to came up and gave us a new fridge, taking our old fridge away. He is very very kind. Did everything himself, and was a very kind and patient guy. Joked around with us about cleaning up the oyster sauce mess since we told him it smelled fishy because of the oyster sauce haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Edge2.jpeg")} alt="Edge2" />
                    <figcaption>Edge View</figcaption>
                </figure>
                We have a new fridge now, which is pretty nice. No more leaks, and it just feels cleaner in general haha. After that whole fiasco, I brought my laptop downstairs to the quiet room to finish up my engineering scholarships apps. Nice, relaxing time inside.
                <br/><br/>
                Josh, Julia, and I were headed to The Edge, yet another EHS event. The problem is that it's like 11 minutes away from the hotel, but EHS was leaving at 5 for some reason, so we decided not to go with the EHS group haha. No one else came because the $30 to go didn't seem worth it to them, and Sanjana was out with her high school friend who's college roommates with someone we met here at The New Yorker ahaha. Crazy how connected the world is.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Edge3.jpeg")} alt="Edge3" />
                    <figcaption>Edge View</figcaption>
                </figure>
                When I got to the lobby, I was surprised to see Sanjana and her friends haha. I thought they weren't going to make a trip back to The New Yorker, but they did to drop their stuff off. It was a nice walk together going to The Edge, which is in Hudson Yards (very close, fancy place for shopping and walking).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Edge4.jpeg")} alt="Edge4" />
                    <figcaption>Edge View</figcaption>
                </figure>
                The walk into The Edge was insane. It felt like an entire experience, like you're going onto a roller coaster ride. A long path through cool visuals, signs, and TVs showing random stuff about NYC. Very cool!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MeAtEdge.jpeg")} alt="MeAtEdge" />
                    <figcaption>Me at the edge!!!</figcaption>
                </figure>
                The Edge itself was absolutely amazing. It's on Floor 101 of the building, which I didn't think much about until someone mentioned how high that is compared to the floors we're normally on haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/GroupAtEdge.jpeg")} alt="GroupAtEdge" />
                    <figcaption>Julia, Josh, and Kyle</figcaption>
                </figure>
                I thought we had a ton of great views of the city already, but The Edge truly is something else. You can see so far that you can see both bodies of water on the East and West side of Manhattan. You can see the Statue of Liberty, Brooklyn, anything and everything! Now that I think about it, I wish I tried looking for MSK haha. Missed opportunity! But at least I saw The New Yorker, which looks like a skyscraper from the street, but a little tiny building from The Edge haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BiggerGroupAtEdge.jpeg")} alt="BiggerGroupAtEdge" />
                    <figcaption>Shannon (Sanjana's HS Friend)<br/>and Ivory, Our EHS Friend</figcaption>
                </figure>
                The Edge is called The Edge because it's a huge platform with huge, glass walls that lean angles a bit over the city. I leaned on them, but that was so scary haha. There's also a section where you have glass floors like the Grand Canyon, but you can see all the way down to the streets and the tiny, tiny, yellow taxis below. It was SO SO SO SCARY BUT WE WALKED ON AND SAT ON THAT GLASS SECTION.
                <br/><br/>
                Sanjana had to leave early since she won lottery tickets to go see Shakespeare in the Park, which she's been entering for weeks now haha (if you don't win the lottery, you have to donate $300 to get a seat ahaha).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoshDrinking.jpeg")} alt="JoshDrinking" />
                    <figcaption>Josh Drinking and Hating<br/>On The New Yorker</figcaption>
                </figure>
                Josh, Julia, and I joined Sanjana's other friend's in a long line to take pictures at the actual edge of the platform, which took forever since big families were spending tons of time taking pictures there. The people behind us were crap talking a specific family that was taking forever, and later we realized they're also from EHS, but in a different building ahhaa (there are several hotel-like buildings that host EHS). Kind of crazy! I don't even know who mentioned EHS, but someone realized that we were both in EHS ahaha.
                <br/><br/>
                Sanjana's friends left soon after we took pictures there, so me, Josh, and Julia headed to the inside area where there were equally beautiful views. We ended up hanging around there for at least an hour to see part of sunset. Absolutely beautiful views of the city and the Hudson.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/NewYorker.jpeg")} alt="NewYorker" />
                    <figcaption>The New Yorker From Above</figcaption>
                </figure>
                When we left, Julia left to see her other friends, and Josh and I headed to dinner at Kashmir 9, a Pakistani restaurant that Aziz likes going to and that Mitchell wanted to try. It's one of EHS's recommended restaurants that Mitchell saw today when we were in the EHS office asking for help for our fridge haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/InsideEdge.jpeg")} alt="InsideEdge" />
                    <figcaption>Inside the Edge!</figcaption>
                </figure>
                Josh and I met Mitchell, Aziz, and Sydney at Kashmir 9, and we had a nice dinner together. I liked the food more than I expected to. The naan was especially good. Would definitely go back since the portions are very heavy and not that expensive (it was $14 total for me).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/EdgeSunsetSelfie.jpeg")} alt="EdgeSunsetSelfie" />
                    <figcaption>Sunset Selfie!</figcaption>
                </figure>
                After dinner and after cleaning up, I went down to the 15th as usual and saw Sydney there. We talked a bit before joining someone for pool, someone from Purdue we previously met. Arielle and Sanjana finally came back from Central Park (Sanjana got two tickets and gave the other to Arielle), and we went out to get pizza since Arielle was hungry (she is ALWAYS hungry it's kind of insane ahaha).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/PakistaniDinner.jpeg")} alt="PakistaniDinner" />
                    <figcaption>Pakistani Dinner</figcaption>
                </figure>
                After that, we all went back to our rooms. Everyone has stuff to do. Sanjana is going out with her friends again, Arielle is going home, and Josh is going to Brooklyn to play soccer again. I'll probably grocery shop in the morning tomorrow and then see what the rest of the day brings. Looking forward to having a relatively relaxing and chill weekend. Nice to not go out too much on the weekend, but I also want to get out more to places like Governor's Island, which is probably one of the most fun things we've done here haha.
            </div>
    },
    {
        title: "Day 29: Lying on My Bed",
        date: "07/10/2022",
        tags: ["Cooking", "Chinatown"],
        content:
            <div>
                I set my alarm for 9:30 AM once again, and ended up getting out of bed a bit after 10! Not too bad if I do say so myself! First thing I did in the morning was poo. Oh my lord my stomach hurt and I realized it was probably because of last night's Pakistani food haha. After that, I went shopping at Target to see what type of groceris I could find. I wanted to cook something new, so I didn't really have a plan besides just browsing around.
                <br/><br/>
                On the way to Target, my stomach started hurting again, and I knew it was the Pakistani food. I went shopping anyways since I wanted to go to Target. I had a nice time there. The Target here is really nice and just feels fancy for some reason haha. Maybe it's so white and clean everywhere, and that's just very different from the rest of the Midtown we're used to.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Lunch Noodles.jpeg")} alt="Lunch Noodles" />
                    <figcaption>My Homemade Lunch</figcaption>
                </figure>
                I walked through the produce section and bought ground pork since I wanted to try cooking raw meat, and got some celery since I was thinking of the qin cai rou dish that Dad cooks at home. I ended up also getting some other assorted veggies, and then asked one of the staff members where the knives were since I wanted to cut my celery, but they only had kitchen sets of knives (boxes of like 24 knives haha).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Bug.jpeg")} alt="Bug" />
                    <figcaption>Sanjana Made Me Catch<br/>A Bug in Her Room</figcaption>
                </figure>
                I got back to my room and the first thing I did was go poo again. I felt all good again after that! And then it was time to cook. I headed down and started putting together my lunch. Got some ground pork and marinated it with some garlic and laoganma. I forgot my soy sauce and didn't want to walk back up to the 18th floor to get it, so that was it haha.
                <br/><br/>
                Cooked my noodles, set them aside, and cooked the meat. Then I stir fried everything together (a TON of arugula, 3 celery sticks, and some green onion), and had a nice lunch. Pretty proud of myself! Had a peaceful lunch before going back to my room and chilling by myself.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Subway Selfie.jpeg")} alt="Subway Selfie" />
                    <figcaption>Subway Selfie!</figcaption>
                </figure>
                I watched some videos for a while, catching up on some channels I haven't seen in a while, and then I ended up just spending like an hour and a half just lying on my bed on my phone. It was a nice way to relax and calm down. I realized I should probably do something and go somewhere more comfortable to sit, so I brought my laptop down to the 15th floor's quiet room where I finally filled out my travel reimbursement form.
                <br/><br/>
                Soon after that, I saw Josh through the glass walls from the room, and I gathered my stuff and went outside. He came back from his weekly soccer games in Brooklyn, so he was pretty tired haha. We played pool for a bit before deciding to go get a haircut today, since we were talking about getting a haircut on Sunday. But, the place we wanted to go to was closed by then, so we didn't get a haircut ahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DuckSoup.jpeg")} alt="DuckSoup" />
                    <figcaption>Duck Soup for $10!</figcaption>
                </figure>
                We played pool for a bit longer, and then Sydney, Sanjana, and Ivory came along. We all hung out for a while and then decided to go to Chinatown for dinner, even though Sanjana already bought dinner with Ivory and her friend (who got on a train back to Philadelphia). We convinced Sanjana to freeze her food for lunch tomorrow, so we could all go out to Chinatown together.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Noodle Roll.jpeg")} alt="Noodle Roll" />
                    <figcaption>Very Good Noodle Rolls<br/>For $3.50</figcaption>
                </figure>
                Julia joined us, and we headed over to Chinatown. We walked around for a bit as usual, with no plan of where to actually eat, and ended up eating at Ming Wong Restaurant, a Cantonese place. It turns out most of Chinatown here is either Shanghainese or Cantonese. Luckily, Josh is from Hong Kong, so he is always our translator ahaha. All the times we've been to Chinatown though, the waiters also know Mandarin, so Julia and I talk to them in Mandarin.
                <br/><br/>
                The prices were very good! I got a duck, wonton, and noodle soup for only $10. It was SO FILLING. I also got a pork "crepe" (steamed rice noodle rolls). The waiter we had was really nice, asking us if we're students, where we go, and things like that. He told us about his son, who studies CS at Michigan, and who got waitlisted at Stanford ahaha. Must be a very smart guy!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Chinatown_Lanterns.jpeg")} alt="Chinatown_Lanterns" />
                    <figcaption>Lanterns in Chinatown!</figcaption>
                </figure>
                He talked to us in Cantonese and Mandarin, so Sanjana and Aziz were left out of the conversation most of the time ahhaa, but they seemed to get the gist of it. Also, it was really funny when Aziz ordered, since he wanted to specify a certain type of noodle to get, and the waiter was talking to him in a REALLY LOUD voice wondering why he would get that type of noodle haha. Aziz thought he was mad at him, but it seemed just like one of those Chinese person things hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CT.jpeg")} alt="CT" />
                    <figcaption>Street Off of Chinatown</figcaption>
                </figure>
                We had a nice walk through Chinatown, and then all headed back to The New Yorker. A few of us are chilling here now on the 15th, Sanjana, Aziz, and Sydney. Tomorrow is Sanjana's birthday, so we'll have a dinner celebration which should be pretty fun! Nice weekend relaxing. Nice to finally get some downtime ahaha.
            </div>
    },
    {
        title: "Day 30: Critical Thinking and Sanjana's Birthday",
        date: "07/11/2022",
        tags: ["Work", "Birthday"],
        content:
            <div>
                Monday! The start of a new week. I got up bright and early at 8:15 AM and got ready for work. Told Mitchell he didn't have to wait for me since I still had to poo, so he went off on his way to work.
                <br/><br/>
                The commute was smooth as usual, and I took a while to settle down, have my breakfast, and just relax a bit before getting into doing anything. I finally checked my job results after sitting around for a bit, and saw the same thing I've been seeing for the past couple weeks. Our model doesn't seem to be actually learning anything, which is strange since Wesley is convinced that the model he built a while ago is perfect for this problem. 
                I thought about the results for a while before sending them to Wesley.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BlurryCupcakes.jpeg")} alt="BlurryCupcakes" />
                    <figcaption>Blurry Cupcake Shop</figcaption>
                </figure>
                Wesley seemed to be having a busy day, because he said he'd look at them and didn't respond for a while. I browsed the web for a bit, looking through scientific Twitter feeds and Pellegrini's recent papers before going to lunch. I ended up going to lunch with Jaden today, who refuses to go to the surgery center for whatever reason. He eats the halal cart food, since NYC has a ton of mobile carts that serve that type of food.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SKBDayWalk.jpeg")} alt="SKBDayWalk" />
                    <figcaption>Walking to Dinner</figcaption>
                </figure>
                It's a bit of walk to get there. I think it's on 64th or 65th street, so it's a bit of a walk from Joy, which is on 61st street (and 1 or 2 avenues down). I wasn't really looking forward to it, but when we brought the food back to Joy's basement (where Jaden usually eats), I was pleasantly surprised. It looked good, and tasted pretty good too. I was mainly worried my stomach would hurt from eating their food since today is Sanjana's birthday so a bunch of us are going out to eat, but I feel fine right now (it's 6:37 PM).
                <br/><br/>
                A bit after lunch, Wesley responded and said the results looked weird and asked me to run the program on a subset of our data. I did that on my own laptop after a while since I locked myself out of the development environment I use on our HPC servers (need to figure that out soon haha), and got weird results once again. Sent them over to Wesley who thought it was really weird since it didn't match what he previously got, and then I clarified the difference between what we were doing.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SKBDayDinner.jpeg")} alt="SKBDayDinner" />
                    <figcaption>Dinner Selfie!</figcaption>
                </figure>
                He was confused and asked me why I did that, so I told him I thought that's what we were supposed to do based on our initial conversations about this step. In my mind, I thought it was kind of weird but did it anyways, so I guess I should've just trusted my instinct and asked more questions about why we would do it that way. Turns out, we don't want to do it that way haha.
                <br/><br/>
                I edited the scripts and resubmitted them, and didn't have much anything else to do for the rest of the day. Just waiting again. It's a common theme to have stuff running and not have anything to do in this work, it seems haha. Jaden is constantly working on other stuff while his programs run, so I'm not the only one facing the problem.
                <br/><br/>
                Anyways, since it's Sanjana's birthday, she wanted to go pick up cupcakes for everyone. I left work a bit early to go with her, and we walked to a subway station that's at least 10 streets north and a few avenues West of Joy. A long, long walk! When the subway finally arrived, it was completely packed.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHBegins.jpeg")} alt="MHBegins" />
                    <figcaption>Manhattenhenge Begins</figcaption>
                </figure>
                There have been delays lately on that line, and the subway was so packed that we couldn't even get into it. It was literally like the videos you see of Japan subways where people are crammed right next to each other, with no breathing room.
                <br/><br/>
                We decided to leave the subway and just take the 22 minute walk all the way down south to Buttercup Bake Shop, on the 52nd Street Location, which is far from Joy on 61st, but even farther from the subway station up north we walked from ahaha. Overall a very long walk. Too bad I don't have my Fitbit to keep track of it anymore!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHMochi.jpeg")} alt="MHMochi" />
                    <figcaption>Mochi Break!<br/>Look at the Sunlight!</figcaption>
                </figure>
                We picked up the cupcakes and had a nice subway trip home that actually fit us, thank God. We even got seats on that subway actually haha. Back at The New Yorker, we had like half an hour to rest before going to our dinner reservation.
                <br/><br/>
                I was a bit nervous for time, since today was Manhattanhenge, one of only two times each year that the sun sets perfectly aligned with the streets of Manhattan. It's completely serendipitous! Manhattan wasn't designed with the sun's path in mind, but it just so happens that twice a year, the sun sets perfectly over the Hudson in a way that shines directly down the streets.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Manhattenhenge.jpeg")} alt="Manhattenhenge" />
                    <figcaption>A Beautiful Picture<br/>With Beautiful People</figcaption>
                </figure>
                During Manhattanhenge, it's recommended to go to one of several "big" streets (two way streets), of which 34th street is one. 34th Street is conveniently where The New Yorker is located, and is also where the Empire State Building is located. A big, popular street indeed! The problem was that our dinner reservation was for 7 PM, and Manhattenhenge's peak observation time was 8:20 PM, right when the sun sets perfectly down. Zen Ramen and Sushi, our dinner location, is close to The New Yorker and is on 36th Street, so it's not a far walk from where we wanted to be.
                <br/><br/>
                We got everyone together in the lobby by around 6:50, but one person, Rhea, was nowhere to be found. She wasn't responding to any of our messages, so we just left without her after a while. Had a nice stroll over to our restaurant, where the reservation was ready for us outside, in one of those classic New York style outdoor seating areas (with the long boxes on the street they put tables and chairs in). Julia and Josh met us there, since they were both coming directly from work.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHEnds.jpeg")} alt="MHEnds" />
                    <figcaption>And the Sun Shall Set</figcaption>
                </figure>
                Our waitress's name is Wenti. She was very nice, and told us she's from Taiwan. A few of us ordered fruit alcohol drinks (very good), and then we all gave our orders. No one ordered appetizers, so she told us what they had and said they're all very good. I told her we didn't need appetizers since we were all full. I don't know why I said that, but it just came out ahaha. The others started laughing, so it was all okay.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHEnd2.jpeg")} alt="MHEnd2" />
                    <figcaption>Manhattenhenge Comes to a Close</figcaption>
                </figure>
                Wenti brought us some complementary edamame to snack on while they prepared our happy hour sushi rolls ($5 each). Very nice, and very good since they were hot! Aziz bit the skin of the edamame and was eating it, and then all of a sudden, he asked, "wait, are we not supposed to eat the skin?" It was so funny ahahaha. Apparently he's never had edamame like this, and neither has Mitchell. Mitchell only ate it correctly, he admitted, because he saw the way we were all eating it.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TakingaSelfie.jpeg")} alt="TakingaSelfie" />
                    <figcaption>A Beautiful Picture by Sydney</figcaption>
                </figure>
                Our food soon arrived and we all ate pretty quickly, trying to make time for Manhattenhenge. Sanjana even paid early, and got a nice 10% discount for using cash. Wenti made sure to show us what our price would've been if we used card, and then showed us our new cash price. Good marketer ahaha.
                <br/><br/>
                We were all ready to leave after we ate dinner, but we were expecting some sort of surprise since when Sanjana made the reservation, they asked if it was for a special occasion, so they knew it was her birthday.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHSelfie.jpeg")} alt="MHSelfie" />
                    <figcaption>Selfies to End the Sun Show</figcaption>
                </figure>
                Actually, one of the watiers came out while we were waiting for food, and asked who's birthday it was. He said something like, "I see ya girl!" to Sanjana, and said he was the one she talked to on the phone to make the reservation haha. That guy had good energy.
                <br/><br/>
                Anyways, Wenti came back later and asked if we had a Yelp. I said yes, and she told us we can get free matcha ice cream if we check in on Yelp, so we all got Yelp and checked in. Then, she went around the table, making sure we checked in, and then made us leave a 5 star review with her name in it. Ahaha she probably wants a raise, but she's really nice so she deserves it!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHSelfie2.jpeg")} alt="MHSelfie2" />
                    <figcaption>Another Selfie!</figcaption>
                </figure>
                We all did that, worrying about whether we would make it out on time, so we asked if we can have the ice cream in take out cups. They don't have take out cups, so she told the few of us who had left reviews to show the others how to do it, so she could run and get the ice cream as fast as possible.
                <br/><br/>
                She ran off and came back quickly with a BUNCH of mochi ice cream things in a bag, and showed Sanjana, telling her, "I got you guys the special stuff. Don't tell my boss." Ahaha. Wenti really knows how to leave a good impression. We were all very happy, and left very excitedly. On the speedwalk over to 34th street to see Manhattenhenge, Sanjana was like, "see? That's why you leave a good tip. They give you good stuff afterwards" ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MHSun.jpeg")} alt="MHSun" />
                    <figcaption>The Sun Perfectly Aligns,<br/>Piercing the Streets of Manhattan</figcaption>
                </figure>
                We got to 34th street at like 8:12 PM, and it was already looking beautiful. During Manhattenhenge, people line the streets, blocking traffic, all taking pictures of the beautiful sun setting in. Actually, Ushma was the one who told us about Manhattenhenge, and she told us to go block the traffic since everyone does that during this event ahaha.
                <br/><br/>
                We got honked a lot, and had to move around a lot, but we were able to crowd the streets and get some really nice pictures in. It was actually really beautiful. During the times where you can cross the street of the intersection, we would go out onto the street and just snap all the pictures we could. Very nice time!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CupcakeSelfie.jpeg")} alt="CupcakeSelfie" />
                    <figcaption>Cupcake Selfie!</figcaption>
                </figure>
                Tomorrow, Manhattenhenge happens again, but it's perfectly half of a sun. We really wanted to make it to today's full sun, so we were all really happy we made it to see the spectacular event.
                <br/><br/>
                We had a nice walk back to The New Yorker, where we hung out, played pool, and ate the cupcakes Sanjana bought us all. We hung out a lot, playing around, doing dumb crap, sharing pictures, and overall just having a good time. Very nice way to spend the day, and probably a very happy birthday for Sanjana. Excited to see what the rest of this week brings!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Manhattenhenge.jpeg")} alt="Manhattenhenge" />
                    <figcaption>My Favorite Picture of the Day</figcaption>
                </figure>
                Tomorrow, I'm going to a Broadway show, Dear Evan Hansen, with Sanjana. Arielle might come, but she had to go home yesterday, so she doesn't know if she can make it yet. Hopefully we can have the whole Broadway group reunite for tomorrow's show, but if not, it should still be very fun. And I'll probably finally have some results that aren't BS tomorrow! So, overall, a lot to do and a lot to look forward to! Till then... it's KP!
            </div>
    },
    {
        title: "Day 31: Staff Appreciation and Broadway",
        date: "07/12/2022",
        tags: ["Staff Appreciation Week", "Broadway"],
        content:
            <div>
                Today we started the day with a journal club at 9 AM. It was online, so I decided to stay in the New Yorker for the call even though I woke up at 8:15 AM, early enough to go to work. I sat down in the 15th floor lounge area and logged on to the Zoom meeting. Turns out our journal club leader today is in Washington right now, so it was 6 AM for her haha. Insane.
                <br/><br/>
                We talked over the most recent paper, which was pretty interesting. Towards the end, she asked what we thought about the paper, and I said it was the first paper I read so far because it was actually interesting and relevant to what we're doing. She laughed haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FreeStuff.jpeg")} alt="FreeStuff" />
                    <figcaption>Free Stuff!</figcaption>
                </figure>
                After the meeting, I met up with Julia and Sanjana in the lobby to travel to work together since we all stayed at the hotel. Actually, even Mitchell stayed. He woke up around 8:30 and asked if the meeting was online today, and when I said yes, he went back to sleep for a bit haha.
                <br/><br/>
                Anyways, we were on the subway to work, and Julia and Sanjana convinced me to go to Saint Catherine's Park in the afternoon to get ice cream instead of getting it at the surgery center like I planned. It's Staff Appreciation Week, and today's event was free ice cream. I was fine with that, since I wanted to hang out and see the park anyways haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Hat.jpeg")} alt="Hat" />
                    <figcaption>I LOVE Hats</figcaption>
                </figure>
                Worked for a bit in the morning and sent Wesley the results of the job I ran properly this time, so I didn't have much else to do before lunch. I still have a problem of not being able to open or create Jupyter Notebooks on HPC, so I pestered one of the HPC architects some more so he would fix my storage problem.
                <br/><br/>
                Then, I headed off to lunch. The surgery center had a lot more people than normal today because of staff appreciation. I got the chef's special, which was a really good beef burrito with salad. Then, I headed over to the lounge area of the 14th floor, where the MSK staff cafeteria is, and saw they had a free stuff booth!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StCatherinesPark.jpeg")} alt="StCatherinesPark" />
                    <figcaption>St. Catherine's</figcaption>
                </figure>
                There were plastic water bottle type of things, bandannas, hats, and card wallets. They told us we could have 1, so I chose the hat since it looked the most expensive. I really wanted the bandanna since it's branded with the entire MSK logo and name, while the hat only has the MSK logo. But the hat won out! They said it would be very big at first, but that it's resizable so it'll fit us.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WelcomePark.jpeg")} alt="WelcomePark" />
                    <figcaption>Welcome to MSK Employees</figcaption>
                </figure>
                Unfortunately, the hat was nowhere near big enough to fit my head once again. Still have yet to find a comfortable fitting hat for myself haha. I got my free ice cream (chose a moose tracks flavor which is just espresso coffee with toffee and chocolate bits) and enjoyed it in the surgery center. SO GOOD! They really treat us well during staff appreciation week ahaha. I love how it's a whole week.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ParkSelfie.jpeg")} alt="ParkSelfie" />
                    <figcaption>Aziz and I!</figcaption>
                </figure>
                After lunch, I walked over to Saint Catherine's Park with Aziz. The park is near Zuckerman, so it's a good half mile walk away from where I am. But oh boy was it worth the walk. Once we got near the park, we could hear music, see tents, and see all sorts of tables around. We got to the side with the entrance to the park, and got in the line, where there were MSK security guards and signs saying you needed an MSK ID to enter. How cool.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Appreciation.jpeg")} alt="Appreciation" />
                    <figcaption>Tables Setup</figcaption>
                </figure>
                We got in and immediately got to the free stuff table, which had all the same stuff as surgery center, but these people said WE COULD HAVE TWO ITEMS! Since I already had the hat, I grabbed the bandanna. Looking back, I wish I hid the hat and got another hat, but it's okay haha. That's one hat for someone else that I probably didn't need anyways.
                <br/><br/>
                They had the same ice cream flavors at different tables in the park, and had all sorts of booths. One with fresh bananas, peaches, apples, oranges, and MSK cookies, one with info about some MSK admin departments, one with an amazing photobooth, and one with all sorts of drinks and boxed water. Overall just a very happy ambience since the park's center was filled with tables covered with blue tablecloths, in perfect locations to relax, eat, and listen to the music.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FreeFruit.jpeg")} alt="FreeFruit" />
                    <figcaption>Free Fruit!</figcaption>
                </figure>
                Sanjana and Julia joined Aziz and I at our table, and we hung out until almost 2 before heading back to our workplaces. It was hard walking back, since it's getting hot and stuffy around NYC lately. But it's nice. I enjoy the weather here since it's nowhere near as uncomfortable as in California during this time hahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FreeStuff2.jpeg")} alt="FreeStuff2" />
                    <figcaption>My Haul for the Day</figcaption>
                </figure>
                When I finally got back to work after my 2 hour lunch break, I saw that Wesley was also happy to see good results, and he asked for my interpretation. It's nice that he asks me what I think about it, since it makes it feel like he wants me to feel like a researcher and not just a worker doing stuff and giving him the results.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Pics.jpeg")} alt="Pics" />
                    <figcaption>Photobooth Results!</figcaption>
                </figure>
                I asked a question about our methodology since I was wondering how it can affect our results, and he said it's a good thing to consider and it'll be our next step. I'm happy I was able to make a comment that led to some direction in our project, since I've never really talked about ideas and led the direction of a project. Always just listened and did what I was told to do, which I'm really used to now.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DEH.jpeg")} alt="DEH" />
                    <figcaption>Dear Evan Hansen!</figcaption>
                </figure>
                Spent some time at work working on our next steps, and got out just a bit before 5. Sanjana and I ended up being the only people interested or able to go to Broadway's Dear Evan Hansen today, so we got back to The New Yorker and went to get dinner before seeing the show.
                <br/><br/>
                Sanjana wanted tacos, so we found a taco shop near Wok to Walk, the shop we ate at last time before our first Broadway show here. We ate on the ledge outside Wok to Walk like true New Yorkers, just eating out on the sidewalk on a little counter top.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/DearEvanHansenOpening.jpeg")} alt="DearEvanHansenOpening" />
                    <figcaption>The Opening Set</figcaption>
                </figure>
                Then we saw that there were thunderstorms forecasted, so we walked back to the hotel, picked up umbrellas, and left for the theater. Turns out we didn't actually need them, but it was nice to have them in case.
                <br/><br/>
                When we got to our seats, I noticed Lauren was a few seats down, so we got her to sit next to us. The people that had the seat Lauren sat in were also from The New Yorker with EHS, and were really nice. They said she could have the seat if she wanted to sit with us.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DEH_Ending.jpeg")} alt="DEH_Ending" />
                    <figcaption>Selfie! Look at the Orchestra!</figcaption>
                </figure>
                And then it was time to enjoy the show. The stage wasn't as grand as Moulin Rouge, but that's because the themes and topics are completely different. I really enjoyed the show, which had really good actors, singers, and live music (we could SEE part of the orchestra elevated above the stage this time). Really entertaining but messed up show (there's a lot of sad stuff and sensitive topics), but Sanjana didn't like it much haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AfterBroadway.jpeg")} alt="AfterBroadway" />
                    <figcaption>The Streets After the Show</figcaption>
                </figure>
                We got back to The New Yorker and both had meetings with our hackathon teams since we need to brainstorm ideas for tomorrow. I was under the impression that I'd work with Sanjana and Julia, but they both backstabbed me yesterday by forming a team of "California Girls" while we were all hanging out together last night after dinner haha. Their team is now just all girls that have some affiliation with California (either go to school there or, in Julia's case, went to school there (Julia transferred from Berkeley to Columbia after her first year)).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/YutaroSleepy.jpeg")} alt="YutaroSleepy" />
                    <figcaption>Yutaro Sleepy After Brainstorming</figcaption>
                </figure>
                So now I'm in a team with Aziz, Mitchell, Jaden, and Yutaro. We're calling ourselves "Five Guys". What's bad is we split our teams by gender hahaha, but Sanjana said it's okay since it was the girls' decision. Excited to have some fun tomorrow and then get back to work on Thursday!
            </div>
    },
    {
        title: "Day 32: Hackathon",
        date: "07/13/2022",
        tags: ["Hackathon", "Cooking"],
        content:
            <div>
                Today was the CBSP Hackathon day! No work, just hackathon from 10 AM to 5 PM! How great! I woke up a bit after 9 AM and thought I had plenty of time to make it to work, so I took my time to get ready. By the time we were almost to the subway station, I realized it was almost 9:45, and was scared we'd be late. The Q Train, which we take to Zuckerman, didn't arrive for at least 10 minutes, so we were already late by the time we got onto the subway haha.
                <br/><br/>
                Had a nice walk with Mitchell to Zuckerman and found the room we were doing the hackathon in, ZRC-138, the grad school seminar room. Mitchell and I were the last ones there haha, and Ino, the leader (and a bioinformatics engineer at MSK who doesn't have a PhD but has a high h-index because he builds tools comp bio researchers use), was wrapping up his intro presentation. The slides he was showing are public on the cBioPortal repo anyways, so we didn't miss much.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/HackathonTeam.jpeg")} alt="HackathonTeam" />
                    <figcaption>Team Selfie!</figcaption>
                </figure>
                They had breakfast (thank God), so I got to eat a nice piece of cinnamon, a bagel (that wasn't that good because it was apparently from Trader Joe's), and some fruit. We ate as we got together with our hackathon team to discuss our plans for the day.
                <br/><br/>
                Then we dived right into the work. We wanted to develop a network visualizer for showing what genes are commonly mutated with each other in certain tumor types. We split up our responsibilities nicely, so I worked on creating the adjacency matrix for proportions, while Mitchell and Jaden worked on frontend + generating the actual network, Aziz worked on the adjacency matrix with statistical tests, and Yutaro worked on getting the actual data from cBioPortal.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/AzizExplaining.jpeg")} alt="AzizExplaining" />
                    <figcaption>Aziz Passionately Explaining<br/>Our Methodology As We Begin</figcaption>
                </figure>
                cBioPortal is one of the most popular tools at MSK that Ino has worked on, so the hackathon is centered around building a project using data from cBioPortal.
                <br/><br/>
                We spent our time working away, and eventually ate lunch before working more until around 4 PM. I ended up not having too much to do after lunch since I finished my part before then, and by then it was only generating the network that was the most vital part leftover anyways. I ended up starting the presentation, and I wanted to make it fun, so I found a cool template from online we could use, and then made a few jokes and put some dumb stuff on it.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Yu_taro.jpeg")} alt="Yu_taro" />
                    <figcaption>Yutaro Working on the Floor<br/>(To Charge His Laptop)</figcaption>
                </figure>
                I put Ino's face on some slides, took his Twitter bio and used it in a quote at the end of our presentation, and made some dumb jokes related to our team name "Five Guys" and science. Aziz was making a big deal out of it, telling Ino we would have some unconvential slides and that it'd be a standup show haha. Ino said it would be okay as long as it doesn't result in an HR case. We ended up having a really good time presenting, and the staff that were there really liked our presentation.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/AfterHackathon.jpeg")} alt="AfterHackathon" />
                    <figcaption>After-Hackathon Selfie!</figcaption>
                </figure>
                Turns out there used to be a feature in cBioPortal like 10 years ago that's similar to what we did today, but they got rid of it because the networks that get shown are uninterpretable simply because of how much info they contain. I guess it makes sense, but the staff there said we got pretty deep into it given the limited amount of time we had today. Overall, we had a great experience and it turned out better than we expected. We all had something to show for the day at the end of it.
                <br/><br/>
                On one of the slides, I wrote Ino's Twitter bio, "building stuff for cancer genomics," and used that slide to say that we had a good time at the hackathon, even though we tend to come into these types of things not sure if we have the skills to actually make something. I said we all had something to show for the day that we were proud of, and that you end up learning your friends are smarter than you thought haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Bussin.jpeg")} alt="Bussin" />
                    <figcaption>I Made Way Too Much Dinner<br/>Tonight Ahaha</figcaption>
                </figure>
                After the hackathon, we all went back to the hotel, and I broke off the group to go to Target to buy some Miralax and some breakfast for the next few days. And then I went back, made a really good dinner, and am now relaxing for the night!
            </div>
    },
    {
        title: "Day 33: Rice Cakes",
        date: "07/14/2022",
        tags: ["Cooking"],
        content:
            <div>
                Today I woke up a bit before 8:30 AM. It's so nice to not work in finance like all the other kids in this hotel. They have to dress up all fancy every day and go to work on time, just to stay all the way till like 10 PM until they can come home. Us, on the other hand, get to go whenever we want, dress in whatever we want, and leave whenever we feel like it haha. The freedom is nice!
                <br/><br/>
                I got to work at 9:20 AM and got settled in with a nice, double caffe latte (two shots of espresso). It tastes so good! The free coffee at work is the best thing ever. I'm gonna get addicted to coffee soon haha. I had a couple date bars I bought from Target yesterday (I also bought some more Miralax), and they were pretty good. A nice way to start the day with something healthy and packed with fiber.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Breakfast.jpeg")} alt="Breakfast" />
                    <figcaption>Healthy Breakfast</figcaption>
                </figure>
                I soon got to work, fleshing out one of my to do's, asking a couple questions to Wesley, and submitting jobs to run so I'll have the results soon. That filled up most of my morning. In the morning, I also saw Ino sent a message on Slack to all of us, saying if we bring our hackathon notebooks to the next level so that computational oncologists can understand them very well, he can add them to the cBioPortal data sharing repo and add us as co-authors on the manuscript he's writing! That is so nice of him. If I were in his position, I think I'd just mention the undergrads in the Acknowledgements section, not let them on as co-authors. What a guy!
                <br/><br/>
                We all met at the surgery center for lunch, our hackathon team. I got a nice salad and really rich clam chowder, and we ended up eating and talking for an hour and not doing much actual work even thought some of us brought laptops with the intention to actually work. We ended up spending a few minutes at the end of lunch just planning how we're going to clean our work up, and then we called it a day.
                <br/><br/>
                We all went our separate ways, but since Aziz's lab went to a conference without him, he came to Joy with us after lunch (he works in a lab in Zuckerman). He scanned his card at Joy and it got rejected of course, so he went up to the security guy at the desk entrance and the guy just said, "don't worry I got you," and unlocked the gate for him. What a nice guy haha.
                <br/><br/>
                Jaden, Aziz, and I went up to the sixth floor to see if there's a nice view (there wasn't really a view haha), and just to see the floor layout. The sixth floor looks pretty packed, and feels a lot tighter than the second floor where I work.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Veggies.jpeg")} alt="Veggies" />
                    <figcaption>So Many Veggies to Eat!</figcaption>
                </figure>
                We went back to our actual work place and I had some downtime before going to our lab's reading group. The other undergrad in the lab was leading the reading group today, and she did a pretty good job. Created a lot of discussion, had an organized explanation, and just seemed to know what was going on. I did not understand what was going on, once again, getting lost in the equations and stats stuff. I just don't understand anything hahaa. Gets so boring sitting there not knowing what's going on but not even really knowing what questions to ask.
                <br/><br/>
                The other undergrad asked when I'm going to be the presenter, and I said I'm not on the schedule because I think they made the schedule when I wasn't yet there. She said that was BS hahaha. Was very salty that I don't have to go. But honestly I think that if I went, I would just be wasting everyone's time since I don't know anything related to what they do hahhaha.
                <br/><br/>
                After the reading group, I worked on my other to do for a bit before going back home. Before going back to The New Yorker, I took a trip to HMart since I'm getting sick of the Shanxi noodles I have (they really aren't that good haha). The wide noodles are good for the first couple times, but I get tired of it fast.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/RiceCakes.jpeg")} alt="RiceCakes" />
                    <figcaption>Rice Cakes for Dinner!<br/>So Good!</figcaption>
                </figure>
                Got some rice cakes, green onions, and bok choy so I can enjoy a new meal. Came back to the hotel, cooked a dinner I was very proud of, and now I'm relaxing as usual. Feeling pretty tired because it's just been a long day, but I had fun for the most part. Work can be a bit boring sometimes because we tend to wait around, either just feeling burnt out or waiting for our models to train haha. But it's meaningful work and we end up all enjoying our time here, so it's great haha. Excited for Friday! Will have a nice, peaceful work day without any events to go to thank God hahaha.
            </div>
    },
    {
        title: "Day 34: My First Barber",
        date: "07/15/2022",
        tags: ["Work", "Haircut"],
        content:
            <div>
                Today, I woke up bright and early at 8:15 AM. Mitchell told me that EHS had another free breakfast event, but I was too tired to go with him. I ended up sleeping a bit more, and he came back later with a muffin for me. How nice of him! I got to fill up my stomach before going to work.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/EmpireMorning.jpeg")} alt="EmpireMorning" />
                    <figcaption>Empire State and Clouds</figcaption>
                </figure>
                There were delays on the subway line I was taking today, so I didn't make it to work as early as I had hoped. There was some train between the 59th and Lexington Station (the station I normally go to to get to Joy now) and Queensborough that had its emergency brakes activated, so there were trains waiting at all the stations in between 34th Street Herald Street to that stop.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Muffin.jpeg")} alt="Muffin" />
                    <figcaption>Muffin Breakfast</figcaption>
                </figure>
                I ended up waiting a bit on the R or W train (I forgot, either works), and walked across the platform to an N train that came later since the N train also goes to the stop I need to go. There were multiple lines that were stuck today, since Mitchell left on the Q train before me, and yet he got to work in his Cornell Med School building later than I got to Joy ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/WorldsLargest.jpeg")} alt="WorldsLargest" />
                    <figcaption>Macy's!</figcaption>
                </figure>
                I got to work early in the morning. Wesley is on vacation till next Friday, so I don't really have much to do right now, at least I don't think haha. I finished up the todo's I have to take care of and submitted jobs, but they were pending and not running. Not sure why they were pending the whole day, but hopefully they start running over the weekend haha. The details of the job say they won't start until at earliest next Wednesday, but our HPC platform has terrible documentation, so I wouldn't be surprised if these log details are wrong too haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/OutsideMacys.jpeg")} alt="OutsideMacys" />
                    <figcaption>Outside Macy's!</figcaption>
                </figure>
                I went to lunch after a while of sitting around doing nothing, and got a really nice steak lunch from the surgery center. I ate outside on the balcony for a while, but it was too hot, so I brought the rest of my food back to Joy and ate in the basement. Jaden joined me later on since he usually eats street food there.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Steak.jpeg")} alt="Steak" />
                    <figcaption>Lunch Break</figcaption>
                </figure>
                We talked about how our lab's are structured and analogies to job titles in industry. We were wondering what Ushma's equivalent would be in industry, and settled on something like she's basically an executive since she's the VP of an entire office of MSK.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/OutsideMacy.jpeg")} alt="OutsideMacy" />
                    <figcaption>Macy's Decorations</figcaption>
                </figure>
                Anyways, we got back after lunch and I didn't have much to do, so I posted our Manhattenhenge selfie and sunset picture on my science twitter. I spent a lot of today just surfing Twitter, oragnizing some things from the hackathon, and cleaning up some of my notebooks so they're clearer to read.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/KyleOnChair.jpeg")} alt="KyleOnChair" />
                    <figcaption>Me Sitting Down On the Street</figcaption>
                </figure>
                I bumped into Ino in the break room and talked with him for a bit. He asked if I was Jaden (the Chinese guy), and I said no, I'm Kyle hahaha. He said he was bad with names. Really nice guy. I like Ino and hope he is at more CBSP events haha. He's the bioinformatics engineer who led the hackathon. He works in Joy on the 2nd floor, so I'll probably see him around a lot now that I know who he is haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BeforePic.jpeg")} alt="BeforePic" />
                    <figcaption>Before Picture</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/BeforePic2.jpeg")} alt="BeforePic2" />
                    <figcaption>Another Before Picture</figcaption>
                </figure>
                We ended the work day and I left to go back to our home station, 34th Street Herald Square. I was planning on meeting Josh and Sanjana there because I was getting a haircut today, and they were coming along. The whole day we were coordinating when we were going to meet, and the plan was to meet back at the subway station a bit after 5.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SideHair.jpeg")} alt="SideHair" />
                    <figcaption>Side Haircut</figcaption>
                </figure>
                I arrived around 5:20, but Sanjana and Josh were just getting on the train back up at 72nd street (I was on 34th), so I went outside the Macy's (biggest store in the world) and sat on the street, where they have these lawn chairs set up for people to sit, eat, and just relax.
                <br/><br/>
                It was nice to sit on one of the busiest streets in Manhattan for a while. Watching everyone walk by, and just people watching haha. I soon saw Josh and Sanjana, and also Aziz, who bumped into them on the way back.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FifthAve.jpeg")} alt="FifthAve" />
                    <figcaption>My Haircut on 5th Avenue!</figcaption>
                </figure>
                We headed to the barbershop from there, where Josh got a haircut yesterday haha. 5th Avenue Barbershop! I was kind of nervous, but Josh assured me that he would tell the barber what to do. I literally had no clue what was going on haha. We all went in, and then they asked the others that weren't getting a haircut to leave since it made the barbershop look busy, meaning people wouldn't come in.
                <br/><br/>
                I sat around waiting for my turn, and then I texted Josh so he came in to help. I sat down, and Josh told the barber he'd tell him what I want since I've never been to a barbershop. The barber asked where I usually go, so I told him my mom. He told me I wouldn't go back to my Mom for a haircut after this haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Selfie.jpeg")} alt="Selfie" />
                    <figcaption>Haircut Selfie!</figcaption>
                </figure>
                Josh explained some stuff, and the barber asked other stuff, trying to decide how to do my haircut. We settled on a part and a slight fade, and the barber got to work. At one point, Josh said he might need to put water in my hair to lessen the resistance, and the barber said no it would make it more resistant haha. I got scared Josh would make the barber mad by telling him what to do ahhaa.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Selfie2.jpeg")} alt="Selfie2" />
                    <figcaption>It's a New Me!</figcaption>
                </figure>
                Then Josh told me to tell the barber that I wanted my front hair cut shorter so it's not in my face on my eyebrows, but he said that in Chinese haha. So I soon told the barber, and then the barber said something like, "don't worry I haven't gotten there yet, step by step."
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/KitchenSelfie.jpeg")} alt="KitchenSelfie" />
                    <figcaption>Selfie in the Kitchen!</figcaption>
                </figure>
                Oh, and I thought the barber would make my take off my mask, but he used some sort of clip so I could keep my mask on during the haircut. I thought that was pretty nice haha. Anyways, we finished up the haircut and the barber asked me what I thought about it. I told him I couldn't see without my glasses haha, so he did some last changes, put product in my hair, and then took my blanket thing off and let me see.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BussingDinner.jpeg")} alt="BussingDinner" />
                    <figcaption>My Dinner With Real Pork</figcaption>
                </figure>
                I thought it was weird at first, but fine. Then I turned to see out the glass window, and Sanjana was laughing and Aziz said, "I think it looks great!" Hahaha. The barber told me it was $25, but $29 with card, so Josh paid in cash for me and we tipped $5. The barber was saying stuff like oh yeah you'll come back to a barbershop now, no more Mom haha. He didn't talk to us much except for jokes like that. I thought he was going to be a mean New Yorker, but he was a decent person haha. We took some pictures outside and overall I thought it was a pretty good haircut!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Watch.jpeg")} alt="Watch" />
                    <figcaption>Getting My Watch Ready!</figcaption>
                </figure>
                Went to Target after and I bought some pork, and then we all headed back to The New Yorker where I cooked up another dinner. This time I had real pork pieces instead of ground pork, so it was harder to cook with, but fine. Ended up cooking all the meat through fine. It was just rough since I stir fried it before boiling it in like a soup. I feel like a common thing with my food is that it looks a lot tastier than it actually is haha. Maybe I need MSG!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Pork.jpeg")} alt="Pork" />
                    <figcaption>Pork Selfie</figcaption>
                </figure>
                We ate dinner, and then we went off to relax for the night. I finally adjusted my watch by taking some links off so it's a proper size, so now I have the time on my wrist again which is nice haha. Excited to go to Bronx Zoo tomorrow, crossing off yet another borough on my NY list. Going to the Bronx means I've officially visited all 5 boroughs of New York - the Bronx, Brooklyn, Manhattan (where I live), Queens (for the Mets game), and Staten Island (just a shopping area there, it's mostly a boring, residential area haha). Great!
                <br/><br/>
                Oh, and my Twitter post got hundreds of views and Ushma responded haha!
            </div>
    },
    {
        title: "Day 35: Bears are Bruins, Not Golden",
        date: "07/16/2022",
        tags: ["Bronx Zoo"],
        content:
            <div>
                The Bronx Zoo! This past week at MSK was Staff Appreciation Week, and we ended it with a corporate free ticket event to the Bronx Zoo! Sanjana and I got 5 tickets combined. Each ticket lets you bring in 3 guests, and Sanjana interpreted that as meaning she should buy 4 tickets total haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Coffee.jpeg")} alt="Coffee" />
                    <figcaption>Sydney Spilled Her Coffee</figcaption>
                </figure>
                Mitchell, Sanjana, Sydney, and I left bright and early around 9:15 AM to go get breakfast at Market Crates, a restaurant close by The New Yorker. Market Crates is a nice little cafe with a ton of good looking pastries and breakfast items like avocado toast, croissants, and bagels. I got a turkey ham, egg, and cheese croissant for $6. Quite filling and pretty good!
                <br/><br/>
                We got back to The New Yorker a bit before 9:30, and Aziz and Josh joined us since they both already had stuff to eat. And we got going! To get to The Bronx, we have to take the 1 train from Penn Station. It's not far of a walk from The New Yorker, just slightly closer than Herald Square, where we go to leave for work.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BronxZoo.jpeg")} alt="BronxZoo" />
                    <figcaption>The Bronx Zoo!</figcaption>
                </figure>
                We got on the 2 Express train (express lines skip a bunch of stops) and headed on our way to West Farms, the stop in The Bronx where you can get to the zoo from. Our entrance time was from 10 AM to 11 AM, and by the time we got to the stop, it was already 10:55 AM haha.
                <br/><br/>
                We didn't think they would actually care, but still wanted to make it on time, so we jogged a bit before getting to the zoo. The guy at the entrance scanned my ticket (no line at the entrance we went in on), and I asked to bring in 3 guests but he didn't know if we could actually do that with corporate tickets. He asked me to go around to the ticket box and talk to them, after he confirmed with them himself.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Bear.jpeg")} alt="Bear" />
                    <figcaption>Found a Bruin!</figcaption>
                </figure>
                We got all our tickets for free, and then we went into the zoo! We walked around a bit just getting adjusted to the new world. It's really like you're stepping into a natural land with lots of trees and plants all around. Very beautiful part of NYC.
                <br/><br/>
                We wanted to go on a mono rail, but turns out our passes didn't let us go into the special exhibits or rides, so we were stuck only looking at animals haha. We wanted to go on some cool walks, but a lot of it is gatekeeped by the "star tickets" that cost $7, so we stuck to the public ones haha.
                <br/><br/>
                We walked around, enjoying the nature, seeing animals like giraffes, a camel, flamingos, and a whole lot of peacocks running around everywhere. The place wasn't crowded, but was definitely busy with activity.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Bear2.jpeg")} alt="Bear2" />
                    <figcaption>Found Yet Another Bruin</figcaption>
                </figure>
                Had lunch at a cafe, probably the busiest part in the whole zoo, and then got on our way walking around more. My favorite animal came next - the bear! They had two brown bears, in separate ponds, that were just swimming around in the water. They had a pretty big encolosure that was bordered by a tall mountain-like rock, so it looked like a nice place for them to walk around.
                <br/><br/>
                There was a Dippin Dots stand, so Aziz, Sanjana, and I got Dippin Dots. Aziz and I never had Dippin Dots before, and Sanjana said she usually gets them whenever she sees it because htey always have them at like amusement parks and stuff. Pretty good. Like these little beads of ice cream that tastes like ice cream, but is way too easy to overeat. We got the kid sizes, but even that was way too big for us haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BronxRock.jpeg")} alt="BronxRock" />
                    <figcaption>Our Rock</figcaption>
                </figure>
                While we looked at the bears, Josh was borrowing Sanjana's and my phone because he's trying to get an apartment in San Diego. He wrote a program that sends his phone a notification every time a new apartment opens up in a good area in San Diego. He runs it on an MSK computer haha.
                <br/><br/>
                We walked through other building exhibits, like a reptile building and a bird building. The bird building was funny since there were several open-air exhibits where there's no net between the visitors and the birds. You can just stick your hand forward, but not far enough that you could actually touch a bird.
                <br/><br/>
                By the time we finished the bird building, it was probably around 2 PM and we got to a side of the zoo that had a big rock next to another entrance. The 6 of us sat on the rock for a while, just resting since we were all so tired from walking around so much.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Rain.jpeg")} alt="Rain" />
                    <figcaption>Rain Along the Highway</figcaption>
                </figure>
                We heard some thunder and realized it was going to start raining, but we rested there for a good while, probably close to 20 minutes. Saw all sorts of kids climb up and down the rock, and various types of parents. One mom told her kid "you better not cry if you fall," and other ones were helping their kids down after they got up ahaha.
                <br/><br/>
                We finally decided to get up and go see the Madagascar exhibit before leaving, so we did some exploring to find the Madagascar place. It started drizzling as we looked at seals in a large pool area near the Madagascar building, and within 5 minutes it started absolutely pouring.
                <br/><br/>
                The Madagascar building was a nice break from the rain, since it was indoors and had some cool animals like lemurs. We toured around a bit before finding our way back to The New Yorker, which involved a 20 minute walk through the rain, along a busy highway-like area which was kind of scary haha. There was a sidewalk though, so we were allowed to walk there.
                <br/><br/>
                We got on the subway back, and it was freezing cold. The subways have AC, and having wet clothes on just made it really cold. I tried hugging myself to make it warmer, so I was so excited when I finally got back to the station which was nice and warm.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SubwayOutside.jpeg")} alt="SubwayOutside" />
                    <figcaption>Another Outdoor Subway!</figcaption>
                </figure>
                We walked back to The New Yorker and put our stuff down before leaving to Kashmir 9 for some chai. Mitchell stayed at the hotel because he was too tired, so we went on our way. We ended up deciding just to eat since it was around 5 at that point. Had a nice dinner eating new Pakistani food, and then decided to go back to the hotel.
                <br/><br/>
                Sanjana originally wanted to go to 5th Avenue to return some clothes and also buy a laptop charger, since she broke her charger, but we decided to just go tomorrow.
                <br/><br/>
                Got back to the hotel, cleaned up, and watched Rush Hour 1 in the TV room. I got there around 7, which is insane, since usually I finish showering and everything at around 10 PM. Nice to get done with everything early and just have a long night to relax.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/RushHour.jpeg")} alt="RushHour" />
                    <figcaption>Rush Hour Watch Party!</figcaption>
                </figure>
                I don't remember a lot of Rush Hour, so it was like watching a new movie haha. Very exciting and just an overall very well made movie. We talked for a bit before all going our separate ways, and now we're all just winding down on our own. Long and tiring day, but very happy we got to go to The Bronx Zoo for free. Definitely worth the trip (40 minute subway ride North) and all the walking around.
            </div>
    },
    {
        title: "Day 36: Smorgasburg",
        date: "07/17/2022",
        tags: ["Brooklyn", "Smorgasburg", "Movies"],
        content:
            <div>
                Today I woke up at 5:30 AM to Mitchell's snoring haha. Got back to sleep and woke up around 9 AM because my stomach hurt so bad. I went poo and my butt burned SO BAD because of the Pakistani food last night. It isn't spicy, but somehow it messes up my insides so bad haha. I felt tired and disoriented, so I went back to sleep till like 10:30.
                <br/><br/>
                I wanted to cook lunch since it was too late to eat breakfast, so I headed down and thought about what I could make. I don't want to eat the same thing every day, but I have a very limited set of ingredients, so I searched up Chinese pork dishes and realized I could make a fake hong shao rou using my pork, so I got to it.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BraisedPork.jpeg")} alt="BraisedPork" />
                    <figcaption>Salty Lunch</figcaption>
                </figure>
                Braised the pork for like an hour and then mixed it in with my regular vegetable stir fry and noodles. Pretty decent, but the meat turned out pretty rough and it was too salty haha. Too much soy sauce! But a pretty good and filling lunch.
                <br/><br/>
                We didn't really have a plan for today, but Sydney wanted to go to Brooklyn to Prospect Park, where Smograsburg (not the original) happens every week. Smorgasburg is a huge food festival that's basically a farmer's market, but for all sorts of food vendors to come out and make delicious stuff.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Smorgasburg.jpeg")} alt="Smorgasburg" />
                    <figcaption>Smorgasburg!</figcaption>
                </figure>
                We took a long subway ride to Brooklyn, and I was surprised at how nice it is over there. Brooklyn seems to be a lot more peaceful than Manhattan. For some reason, tourists know NYC only as Manhattan, but I feel like there's so much more to see in other boroughs haha.
                <br/><br/>
                Before we went to Smorgasburg, we went to a cafe since Sydney likes drinking coffee. I asked the first cafe we went to if they had a bathroom since I didn't feel too good, and ended up diarrhea'ing there haha. They were just about to close, so I was very thankful they let me use the bathroom.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Lemonade.jpeg")} alt="Lemonade" />
                    <figcaption>The Lemonade I Got</figcaption>
                </figure>
                We walked a bit to Prospect Park afterewards. Smorgasburg itself was wonderful. A lot of places were barbequing stuff, so it smelled really good. I didn't plan on getting anything, but I ended up buying an Apple Cider Fresh Raspberry lemonade drink since it looked so good haha. Ended up not tasting like much, so it was basically water. They put too much ice in that crap! Pretty good business steal. A 20 ounce cup full of ice, and just a bit of lemonade for $6.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ProspectParkLake.jpeg")} alt="ProspectParkLake" />
                    <figcaption>Prospect Park Lake</figcaption>
                </figure>
                We (Sydney, Aziz, Mitchell, Sanjana, and I) relaxed around the grass at the center of Smorgasburg for a while before heading off to explore Prospect Park. The park has a huge lake at the center which reminded me of Almaden Lake Park. We sat down near the lake and ended up spending a good amount of time there. Felt like close to an hour just sitting there and hanging out.
                <br/><br/>
                It was about time to go back to the hotel after that (like 5 PM), but we didn't actually see much of the park, so we decided to make a circle around before heading back. Prospect Park is a really nice nature filled area, and it's crowded with people having picnics and parties, but doesn't feel overwhelmingly crowded like Manhattan does.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ProspectPark.jpeg")} alt="ProspectPark" />
                    <figcaption>Prospect Park Nature</figcaption>
                </figure>
                We got back to the hotel and it was time to make dinner. Sanjana, Josh, and I planned on going to see Top Gun: Maverick at 8 PM, so we had about an hour and a half to cook and eat. I normally cook without following a recipe, but I searched up a rice cakes recipe today since I didn't want to eat the same, dark brown sauce I've been making. Wanted to eat a lighter colored sauce closer to what we eat at home.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AMC.jpeg")} alt="AMC" />
                    <figcaption>Movie Theater!</figcaption>
                </figure>
                I borrowed some of Josh's cooking wine, and made a more traditional looking rice cakes dish that had a nice, thick sauce at the end of it. When I was cooking, I thought I had enough food, but when I put it on a plate I was shocked at how little I had haha.
                <br/><br/>
                Tasted pretty good and was happy eating it, but I felt like I would be hungry later in the night, and I really am starving right now (it's 12:09 AM right now as I write this haha). We all rushed to clean up and get to the lobby by 7:55, but only one elevator was working, so Sanjana and Josh ended up coming several minutes after I got down.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/TopGun2.jpeg")} alt="TopGun2" />
                    <figcaption>Top Gun Selfie!</figcaption>
                </figure>
                We ran over to the AMC theater on 34th Street, which is luckily literally right across the street from where we live. Bought tickets and used our EHS vouchers to get discounts, and headed right into the theater. On a side note, I didn't actually pay anything for this movie since I had a free ticket from EHS. They gave me a ticket since I went on The Met trip a few weeks ago, and on that trip, the RA leading the trip was kind of mean while we got tickets since there was a miscommunication with The Met staff. To make up for the situation, they gave the people that went free AMC tickets haha.
                <br/><br/>
                Anyways, we got to the theather and didn't think many people would be there for the movie since it's been released for a bit of time now, but all the good seats were taken so we had to sit at the second row from the front.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Empire.jpeg")} alt="Empire" />
                    <figcaption>Empire State Building at Night</figcaption>
                </figure>
                But honestly, it was a pretty good seat! And it was so comfortable. The chairs recline and the footrest goes up a bit. I could definitely sleep in one of those haha. Top Gun ended up being a really, really good movie. It's a war type of thriller movie, and it's produced really nicely. And I finally know who Tom Cruise is haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RiceCakesTraditional.jpeg")} alt="RiceCakesTraditional" />
                    <figcaption>Dinner Rice Cakes</figcaption>
                </figure>
                Even though we got to the movie theater a bit late, the actual movie didn't start until probably closer to 8:20 because of all the ads for other movies at the beginning of the show. That meant we got out of the theater at like 10:30, so it wasn't too late when we got back to the hotel. But much later than normal! I got cleaned up and now I'm about ready to go to bed. Not really anyone hanging out on the 15th today. Just Aziz and Sydney listening to music in the TV room, so I'll head to bed very soon now. Good night!
            </div>
    },
    {
        title: "Day 37: Networking? More Like Free Dinner!",
        date: "07/18/2022",
        tags: ["Starbucks", "Weill Cornell"],
        content:
            <div>
                Today, I woke up, bright and early at 8:20 AM. But Mitchell was taking a shower, so I decided to go back to sleep. I slept for a good bit, probably half an hour more, before finally getting up and getting ready to go back into the office.
                <br/><br/>
                Because of that, I ended up getting to work just a bit after 9:45 AM. The office was mostly empty, as usual, and I didn't feel any urgency to get to work. Except when I realized that our posters and abstracts are due in less than 3 weeks! I'm not too sure what I'll actually have on my poster, since I don't understand the details of the actual methods we're using haha. I literally don't know what's going on in regards to what Wesley's software package is actually doing, and that's literally the core of what the project is based on haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Subway_Selfie_Haircut.jpeg")} alt="Subway_Selfie_Haircut" />
                    <figcaption>Selfie on the Subway!</figcaption>
                </figure>
                I spent some of the morning checking results and trying to run more programs, but the HPC system is being very slow. Seems like there's a lot of activity on it, so one of the jobs I submitted last Friday is still pending and not running.
                <br/><br/>
                I went to lunch early since I didn't have much to do, but I wasn't hungry. I went cuz Sydney suggested going around 11:30 AM, and I didn't know what else to do. Had a nice, quick lunch, but Sydney wanted to get coffee, so we went to the Starbucks near Joy.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Umbrella.jpeg")} alt="Umbrella" />
                    <figcaption>Sydney Took a Beautiful<br/>Picture of Me At Lunch</figcaption>
                </figure>
                I ended up deciding to order a cookie cream frappuccino since I wanted to get something to drink, but I substituted regular milk for soy milk, which made my drink cost at least a dollar more. So sad haha. I didn't realize it would cost more! But anyways, the drink was really good. It tasted like a dessert, and was very very cold.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Frap.jpeg")} alt="Frap" />
                    <figcaption>A $7 Dessert</figcaption>
                </figure>
                When we got back to the office, we only had a couple hours left before we had to go to our CBSP VR event, where one of the researchers from Joy was showing us the VR system their team has to visualize 3D cell data.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Yutaro.jpeg")} alt="Yutaro" />
                    <figcaption>Yutaro in VR</figcaption>
                </figure>
                I spent some time looking at past ICML talks to get a feel for what ICML would be like this year, and searched up Ushma to see what she's done in the past. Interesting to see that Ushma has writtemn posts in all sorts of online publications, including opinion posts that made it on CNN. Very impressive haha. Ushma is probably one of the most well connected people I've ever met, maybe in large part because she's a Marshall scholar.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/KyleVR.jpeg")} alt="KyleVR" />
                    <figcaption>Kyle in VR!</figcaption>
                </figure>
                Anyways, I went to the 2nd floor pantry around 2 to wipe my mouth after I finished my sweet Starbucks drink, and bupmed into Armaun, the CSIP intern in my lab who's the only person I actually talk to. We talked a bit about what we're up to, and then about what we think ICML will be like. Had a nice convo, but I told him I had to leave since I realized it was 2:15 and I had to head over to Zuckerman with my CBSP friends haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SM.jpeg")} alt="SM" />
                    <figcaption>Summer Poster Without Me!</figcaption>
                </figure>
                We (Jaden, Rhea, Zhiyue, and Sydney) walked over to Zuckerman together and headed up to the 6th floor Fishbowl conference room and got settled in for our VR session. I couldn't focus much since I didn't feel too good after having my coffee, but luckily I didn't need to pay attention. It was mostly setup and watching what the person in the VR headset saw since they show the view on the big TV screen.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CornellMed.jpeg")} alt="CornellMed" />
                    <figcaption>Sanjana's Building</figcaption>
                </figure>
                We sat around for a while and we each got a turn to use the VR headset. Pretty cool, but I honestly can't enjoy it too much since it's really blurry without glasses. I think I can actually wear the headset with my glasses on, but I'm too scared to break my glasses frame to do that right now haha.
                <br/><br/>
                The presenter, Ignacio from the Shah Lab, gave a presentation in the middle of it, but I really didn't pay much attention haha. Overall a nice demo, but the actual VR setup doesn't seem very practical. I don't see why pathologists would ever use a VR headset to interact with 3D imaging data when we could develop better visualizations on a desktop platform. It just seems like overkill haha, but there are many labs in a consortium funded by the NCI that are working in this area, so it seems like there's something in the future they want to eventually get out of this.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Sanjana_Desk.jpeg")} alt="Sanjana_Desk" />
                    <figcaption>Sanjana's Desk</figcaption>
                </figure>
                After the VR meeting, it was 5, but we had a networking event some of us wanted to attend at 5:45. We went over to one of the Weill Cornell Med School buildings where Sanjana works since she needed to pick her stuff up before heading to Belfer Terrace across the street (where the networking event was).
                <br/><br/>
                The building Sanjana works in is very nice. The environment is pretty nice and the actual building itself feels very fancy. But the actual workspace she's in is a very exposed area that feels too corporate and boring haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Belfer.jpeg")} alt="Belfer" />
                    <figcaption>Belfer Terrace</figcaption>
                </figure>
                We left quickly to the other Cornell building where the networking event was, and hung out there since we were like 20 minutes early. It was nice to sit there and hang out before everyone arrived.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Kyle.jpeg")} alt="Kyle" />
                    <figcaption>My Name Tag</figcaption>
                </figure>
                We went into the conference room instead of outside to Belfer Terrace since there have been storms today. We were all hoping there was food at the event, and when we went in, we were all very happy to see there was lots of food haha.
                <br/><br/>
                It was just MSK people for a good 15 minutes, so we were sitting around having a good time, laughing at how no one else was there. We talked to one of the Cornell Med school admin people, and I told her I'm from San Jose since she lived in San Mateo. She used to work at UCSF, and now she's worked here for half a year, leading the equity and diversity unit for Cornell Med.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FreeFood.jpeg")} alt="FreeFood" />
                    <figcaption>Free Food at Networking Event</figcaption>
                </figure>
                After that, a ton of people started flowing in. Grad students, med students, all sorts of people. Mitchell asked what we would do at a networking event, and I joked that we would just talk to people we knew.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FreeFoodVeggie.jpeg")} alt="FreeFoodVeggie" />
                    <figcaption>Free Vegetarian Food</figcaption>
                </figure>
                That ended up being pretty true. The only person I actually met was someone that was behind me and Sanjana in one of the food lines, but she left quickly after talking to us. We got our food (which was pretty damn good), and then all went onto the outdoor terrace area where us CBSP kids stuck with CBSP kids. It seemed like everyone just got a free dinner and hung out with people they already knew haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CBSPNetworking.jpeg")} alt="CBSPNetworking" />
                    <figcaption>Networking With Ourselves</figcaption>
                </figure>
                Rhea talked more today and said she's not the type of person to go talk to new people. I feel like she's a CBSP person I don't know very well yet, so it was good we had a little event that all the CBSP people that work in Joy actually showed up for haha.
                <br/><br/>
                We all went back to the Hotel, but Rhea, Sanjana, and I made a pit stop at the Duane Reade outside The New Yorker to buy some hand soap since Sanjana ran out. Duane Reade seems to be a drug store that's everywhere in NYC haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AzizKyle.jpeg")} alt="AzizKyle" />
                    <figcaption>Aziz Ur Rehman Zafar</figcaption>
                </figure>
                While Sanjana was checking out, Rhea took some golden dots that were decorating some display at the front of the store and asked if she should throw them on Sanjana. I said yes but I didn't expect her to actually do it, since there was a security guard sitting right behind us haha. Sanjana walked over and Rhea threw it over Sanjana, and they all glided to the ground haha. Then we left. Some dumb crap haha. We really are menaces.
                <br/><br/>
                When we got back to the hotel, it was only a bit after 7 PM. Very confusing to go back to the hotel, already full from dinner, and ready to shower so early. I went to get cleaned up, and decided to do my laundry since I can't do it later in the week like I normally do because of ICML. I realized I leave for ICML Thursday afternoon and get back Saturday night, which sucks since I miss half of my weekend. But oh well. Free trip haha. I'll try to stick around Armaun as much as possible since he actually talks and we can relate over living in the Bay.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Everyone.jpeg")} alt="Everyone" />
                    <figcaption>I Am Everyone</figcaption>
                </figure>
                Now a few of us are relaxing in the TV room. Aziz was saying Yutaro and Josh are gentlemen, and I'm like what? What about me?!? And Aziz said I'm sweet inside but I say out of pocket stuff every now and then ahhaha. He wanted me to write this in the blog, so here I am, granting his wish. What a nice guy I am!
            </div>
    },
    {
        title: "Day 38: Getting Started",
        date: "07/19/2022",
        tags: ["Work", "Staten Island"],
        content:
            <div>
                Today is the first day in a long time I got up as soon as my alarm went off. I was awake at 8:20 AM and quickly got prepared for the day. Finished my entire morning routine much before Mitchell, which has never happened before haha. I had a lot of motivation because I realized I have quite a bit left to do to have some cool stuff for my abstract and poster.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LeavingForWork.jpeg")} alt="LeavingForWork" />
                    <figcaption>Leaving For Work</figcaption>
                </figure>
                I stayed up till about 1:30 with Josh and Sanjana in the TV room last night, but I didn't feel too tired. We said we'd leave for work together, so I went down to the 15th to get some light reading done before leaving for work (they were both ready much later than me). I realized I should read the paper Wesley wrote on Bayesian Tensor Filtering in much more detail, since that's the entire mathematical framework behind the model we're using for my summer project.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TacoBurrito.jpeg")} alt="TacoBurrito" />
                    <figcaption>Dinner Location</figcaption>
                </figure>
                Got started on that in the morning before joining Sanjana and Josh to leave for work around 9:15 AM. Mitchell was also with us because I gave him a heads up that I was heading to the 15th before going to work.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SeaportView.jpeg")} alt="SeaportView" />
                    <figcaption>The Seaport View</figcaption>
                </figure>
                Had a nice little commute to our subway station before getting to Joy. I kind of dread Tuesdays because of our probabilistic modeling groups, which I don't find all that useful. I did some more reading in the morning before checking some results, and then saw that we didn't actually have a meeting today! Thank the Lord!
                <br/><br/>
                I asked Armaun to chat, since I wanted to hear about why he wants to shift to a more stats direction after he finishes his Master's, and he gave me some good advice about what types of courses to take and to talk to more people to find out about the various fields in CS out there. Very helpful and nice chat.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/GovIslandTerm.jpeg")} alt="GovIslandTerm" />
                    <figcaption>Aziz @ Governor's Island Terminal</figcaption>
                </figure>
                Shortly after that, I went to lunch. Just the regular ol' routine. I spent much of my afternoon preparing for my abstract. I have a pretty good idea of the direction I want to take my abstract in, since it's really just an overview of the project motivations, methods, results, and relevance to the field. All of that is very high level, and I probably could've given a general framework for the abstract a couple weeks ago.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/StatenFerry.jpeg")} alt="StatenFerry" />
                    <figcaption>Ferry Group!</figcaption>
                </figure>
                I'm just now starting to flesh out the details of the abstract, so I was doing some research on past papers in the field and other details related to the model we're using. Overall very helpful process. I feel like I have a better understanding of what we're doing here now, so I'm pretty satisfied with the workday.
                <br/><br/>
                In regards to the actual work, I'm still just waiting for my programs to run. I don't want to message Wesley while he's on vacation, so it's good that ICML is this week. There won't be too much time of not having him around, so I don't feel like too much time is lost. He planned a good vacation time haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StatenSunset.jpeg")} alt="StatenSunset" />
                    <figcaption>The Sun Begins Setting</figcaption>
                </figure>
                Today was supposed to be the Staten Island ferry trip with Ushma, who was also going to take us to a carousel ride in lower Manhattan, but she can't show up anymore because she has to give a talk around the time we were supposed to get going. She also hasn't been feeling well, and tested positive for covid this morning according to Josh, who reached out to her to have a meeting about what he should do as a rising senior. Seems like there's several people that want to have individual meetings with Ushma. If I have some more detailed questions, I'll probably reach out to her too.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ManhattanSunset.jpeg")} alt="ManhattanSunset" />
                    <figcaption>Manhattan in Sunset</figcaption>
                </figure>
                It's also getting to that time that I need to get started on my efforts to make a newsletter for our year. Ushma mentioned at the beginning of our program how she'd love if someone wanted to make a newsletter for 2022, since the most recent one is from 2019 on the website. I definitely want to try getting something going, since it'd be a cool little record to have that sums up our time here. I was going to email her about it today, but I'll hold off till tomorrow since I don't want to bother her while she's having a stressful day figuring out how to manage covid.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StatueSun.jpeg")} alt="StatueSun" />
                    <figcaption>The Statue of Liberty and the Sun</figcaption>
                </figure>
                During work, Rhea, Sanjana, and I planned a Staten Island outing since Ushma said we should still go if we wanted to, and she'd reimburse us if we went. Aziz ended up being the only other person that could come along, so we got a good plan going and set off once 6:30 came along and we were all back at The New Yorker.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FerrySkyline.jpeg")} alt="FerrySkyline" />
                    <figcaption>Skyline Selfie</figcaption>
                </figure>
                The first place we went was a Taco shop near Seaport, which is like a 15 minute walk from the Staten Island Ferry Terminal (next to the Whitehouse Subway Terminal). We ordered these big, fat burritos that felt like at least a couple pounds. Could not believe how heavy they were haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FerrySunset.jpeg")} alt="FerrySunset" />
                    <figcaption>Ferry Sunset Selfie!</figcaption>
                </figure>
                We wanted to catch the 8 PM ferry to Staten Island so we could watch the sun set while we were on the water, so we walked directly to the terminal instead of eating. We made it a few minutes before the 8 PM ferry set off, and we got going.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FerryTrail.jpeg")} alt="FerryTrail" />
                    <figcaption>Trail in the Water</figcaption>
                </figure>
                I don't think I'll ever get tired of the Manhattan skyline view from the water. It's just stunning. Looks so peaceful and beautiful, such a difference from how it feels when we're actually on the city streets.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StatenDinnerView.jpeg")} alt="StatenDinnerView" />
                    <figcaption>Our Dinner View</figcaption>
                </figure>
                Once we were on Staten Island, we sat on the steps going up to the shopping plaza, and just ate our burritos there until it got dark. We were all talking and hanging out, not doing anything other than sitting directly across the water from Manhattan. The tallest building is the World Trade Center, just a towering building that overlooks the rest of Manhattan.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/StatenDinner.jpeg")} alt="StatenDinner" />
                    <figcaption>Dinner Selfie!</figcaption>
                </figure>
                When it was dark, you could see all the building lights turn on, golden lights brightening the surrounding waters. Really just amazing realizing that next time I see this area on TV or anywhere else, I'll know that I literally lived there, and for free! When we were walking back to the hotel earlier in the day, I asked Rhea if she liked it here, and she said she didn't know, but could appreciate that we probably won't ever be able to live here for free again. And I felt like that was just so true.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StatenSelfie.jpeg")} alt="StatenSelfie" />
                    <figcaption>Staten Island Selfie!</figcaption>
                </figure>
                We had to get going back to Manhattan, and we planned on going on the 9:30 ferry back. The ferry ride itself is just over 15 minutes, maybe closer to 20 minutes, and the ferries  operate 24/7, so there was no rush. We just didn't want to make it back too late.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ManhattanNight.jpeg")} alt="ManhattanNight" />
                    <figcaption>Manhattan Skyline at Night</figcaption>
                </figure>
                We had a smooth walk back to the hotel, and now a few of us are gathered on the 15th like usual. But tonight, we all have our laptops out doing our own work. It's getting to that point in the program where we're realizing we actually have so much left to do before posters and abstracts are due haha. It kind of sucks we're getting stressed while the program is coming to a close, and our time in NYC comes to a close. But will definitely enjoy the rest of this summer as much as we can!
            </div>
    },
    {
        title: "Day 39: Last Day of Work",
        date: "07/20/2022",
        tags: ["Work", "Cooking"],
        content:
            <div>
                I woke up early, once again, at 8:10 AM. Got up right away and got out the door by 8:30. Nice to get into the office early again. There's a lot left to do before these 10 weeks finish up haha.
                <br/><br/>
                Spent the morning working on my abstract. I have a pretty good framework set up now and a few papers to support what I'm saying, but I'm sure there'll be a lot of changes between now and when the abstract is actually do. Just good to flesh out some of it while I have time since I'm waiting for long jobs to finish running on the cluster.
                <br/><br/>
                The area of research this is in is pretty cool, but I don't know if I'd enjoy building the actual machine learning methods since it's so stats heavy. Will take Armaun's advice and talk to more professors in the general comp bio area to see if I actually want to do this type of stuff. I don't mind doing what I'm doing now, which is applying an existing ML model to a new area, but that's not too exciting since it's mostly just setting up pipelines, managing data, and that type of stuff.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WorkingHard.jpeg")} alt="WorkingHard" />
                    <figcaption>Working Hard at Work</figcaption>
                </figure>
                We had a joint lecture on Nanotech for Research, Detection, and Treatment of Cancer and Allied Diseases, but I decided to skip that for my lab meeting since I've been missing too many meetings recently. Plus, I was sure they would talk a bit about our plans at ICML, which I didn't want to miss since I should probably know where everyone will be at haha.
                <br/><br/>
                ICML's computational biology workshop is happening on Friday, so that's why we're going on Thursday afternoon and coming back Saturday night. Mostly going there just for the comp bio stuff, which is organized by quite a feel people from the Sloan Kettering Institute. Cool to see that the comp bio workshop at such a huge conference is run by MSK people.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/DigInn.jpeg")} alt="DigInn" />
                    <figcaption>I Love Free Lunches!</figcaption>
                </figure>
                After lab meeting, I did some more work to set up some stuff to run while we're at ICML so I'll hopefully have all the results for the to do's from last week. Need Wesley back from vacation to talk to him about the results and where to go from here.
                <br/><br/>
                I'm starting to get excited at going to Maryland. ICML should be a great way to meet some people and learn about some cool things. It's cool to have the opportunity to get exposed to all sorts of new ideas and work that I've never seen before. Should be exciting! And it's in Maryland! Next year it's in Seoul, South Korea. Too bad I don't get to go to South Korea this year!
                <br/><br/>
                I emailed Ushma during the workday about writing the newsletter for this year (there hasn't been one since 2019, how sad!), and she had a really positive response! Liked the dumb joke I made and said it was the only thing that made her smile in the last 2 days since testing positive for covid. Hope she gets well soon.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RiceCakesDinner.jpeg")} alt="RiceCakesDinner" />
                    <figcaption>I Can Eat Rice Cakes<br/>All Day Long</figcaption>
                </figure>
                I left work a bit early to make a big dinner for myself. I was hungry since I had a small free lunch from Dig Inn for lab meeting (some salad bowl again with BBQ chicken and watermelon that was honestly pretty good). My goal was to finish all the pork and veggies I have left, since I'm leaving for ICML tomorrow and won't be back till Saturday night.
                <br/><br/>
                And I was successful! Made a pretty good nian gao dish that finished all my veggies and pork. Now I only have rice cakes and noodles in the fridge, and those should sit there fine (at least I think haha). I also finally figured out that I can make a thick sauce by pouring water into the pan, mixing in a packet of sugar, and boiling off all the water until there's just a little sauce left over. Too bad I don't have corn starch to make a slurry!
                <br/><br/>
                Other than all this, today was a relatively relaxing and uneventful day. Really didn't do too much. Just getting really excited to take a trip to Maryland. Sanjana is going to New Jersey through Saturday to see her cousins one more time, so I won't be the only one gone for half this weekend. Excited for tomorrow!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SpiceRack.jpeg")} alt="SpiceRack" />
                    <figcaption>Made a Spice Rack For Myself<br/>Out of My Finished Bok Choy Box!</figcaption>
                </figure>
                On a side note, I miss playing tennis. Pretty much all the courts I've found around Manhattan are ridiculously expensive to play at, not to mention I don't even have a racket here haha. Excited to get back to school to play tennis with my friends.
            </div>
    },
    {
        title: "Day 40: Baltimore",
        date: "07/21/2022",
        tags: ["Baltimore", "ICML"],
        content:
            <div>
                What a day! I woke up bright and early today around 8 AM, earlier than Mitchell! Did my morning routine and decided I wanted to get something hearty to eat for breakfast since I had a long day ahead of me. I went to Market Crates and got a turkey bacon, egg, and cheese combo on a sesame bagel. It was SO DAMN GOOD. Such a filling breakfast, and it just tasted so good haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Bagel.jpeg")} alt="Bagel" />
                    <figcaption>Breakfast Bagel!</figcaption>
                </figure>
                Before going to Market Crates, I stopped at CVS to buy some breakfast for the next couple days in Baltimore. I also bought a travel toothbrush with a small toothpaste tube. Very convenient, and only $2.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CVS.jpeg")} alt="CVS" />
                    <figcaption>My CVS Haul</figcaption>
                </figure>
                After breakfast, I finished packing up, checked my eyelashes to see if I should take care of anything that might cause me trouble at the conference, and ordered Chipotle online so I could eat lunch. A relatively uneventful morning. I did send an email to the other two people that want to write a newsletter so they know I'm out of town and can't meet in the next few days.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/OffIGo.jpeg")} alt="OffIGo" />
                    <figcaption>Packed Up and Ready to Go!</figcaption>
                </figure>
                I picked up lunch, relaxed some more, and then made one last round to check that I had everything I needed for my trip to Maryland. And I was off! I set off to the Moynihan Train Station a bit before 1 PM even though my train was at 2 PM, since I thought the train would have security check in and things like that.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Moynihan.jpeg")} alt="Moynihan" />
                    <figcaption>Moynihan Train Station at Penn</figcaption>
                </figure>
                It was only at the train station that I realized there is absolutely no reason to go early to the trains. You don't even which track your train will arrive on until like 10 minutes before your train departs, and there's no process whatsoever in regards to checking in. You just show up and go to the appropriate track haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MoyUpper.jpeg")} alt="MoyUpper" />
                    <figcaption>Moynihan 2nd Floor</figcaption>
                </figure>
                I took the opportunity to walk around the train station and explore a bit. It's a really beautiful station since it was only very recently built. I also noticed it was raining hard outside, with lightning, thunder, and storm things all around.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MoyLower.jpeg")} alt="MoyLower" />
                    <figcaption>Moynihan Main Floor<br/>Look at the Clock!</figcaption>
                </figure>
                The main train station waiting area has a large, glass tile ceiling, so you could see flashes through the entire station as the storm raged on. Pretty cool haha. There's also a giant clock suspended over the station that just looks really nice - it looks like the Big Ben.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Amtrak.jpeg")} alt="Amtrak" />
                    <figcaption>Amtrak View!</figcaption>
                </figure>
                Our train was delayed, so I stood around the station doing nothing for a while until they finally released which track we were on. I got in line and while I was in line, saw Haoran and Jacqueline, two other people in the lab. It was reassuring to see some people I actually knew there haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BaltPenn.jpeg")} alt="BaltPenn" />
                    <figcaption>Penn Station in Baltimore</figcaption>
                </figure>
                After a bit, we finally got to the train. I didn't join my lab mates since I didn't want to interrupt their vibes, since they seem like friends, and I don't really talk to them in Joy. The actual Amtrak train was pretty cool. The train looks like a train, the seats are big and comfy, and there is plenty of leg room, even in coach class.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Baltimore.jpeg")} alt="Baltimore" />
                    <figcaption>First View of Baltimore!</figcaption>
                </figure>
                I was walking along the cars trying to find an empty window seat, but eventually decided to just sit next to someone. The person I sat next to was an older white man learning Spanish on Duolingo. Seemed like a nice person.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sheraton.jpeg")} alt="Sheraton" />
                    <figcaption>The Sheraton Lobby</figcaption>
                </figure>
                After a bit, we started talking. He asked where I'm from, and turns out he's lived in California around the Bay for quite a while. He knows the area well! He has an econ PhD and taught for over 30 years, so he actually knows what big data and machine learning are. He said conferences are great because you get to talk to people in a way you can't talk to most people. You get to exchange ideas and just talk about your work, and other people understand.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Hotel.jpeg")} alt="Hotel" />
                    <figcaption>My Bed</figcaption>
                </figure>
                He programmed in Fortran and I think COBOL before, and said his dissertation was on how to calculate the inverse of some huge matrix. He said that type of stuff is so easy nowadays that it wouldn't even be a footnote in a paper haha.
                <br/><br/>
                He said the job he had in California didn't pay well, but then later on he said he just bought $150,000 worth of Apple a couple weeks ago and it's doing well haha. He's a big Apple fan and he hates Microsoft. Anyways, he got off at Philadelphia since he lives there, and that was the end of our conversation.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Baltimore2.jpeg")} alt="Baltimore2" />
                    <figcaption>Area Near the Sheraton</figcaption>
                </figure>
                I had about an hour and a half left on the train, and I kind of just sat there, looking out the window. Beautiful views through New Jersey, Pennsylvania, and Delaware. Feels like there's so much history on this side of the United States ahaha.
                <br/><br/>
                The train finally arrived in Baltimore like an hour after scheduled, and I sat in the station for a bit putting on sunscreen and waiting to see if my labmates would come up the same way. I planned on walking the 1.7 miles to the hotel since I wanted to see Baltimore, but ended up taking a Lyft with the two others I saw and Shounak, who happened to be on the same car.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BaltSelfie.jpeg")} alt="BaltSelfie" />
                    <figcaption>Baltimore Selfie With Armaun and Chilam</figcaption>
                </figure>
                I told them I think Uber only takes 3 people right now, at least for the regular rides, so I could walk, but they were like no, we can bring 4 people. So I eventually decided to go on a Lyft with them.
                <br/><br/>
                We made it to the hotel, which is pretty nice. Armaun was in the lobby with Chilam, and he asked if we wanted to get dinner together, so that's what we planned on doing. The other people in the lab arriving also wanted to get dinner, so we ended up all going out to dinner together (Jacqueline, Shounak, Armaun, Haoran, Chilam, and Casey). Karl joined us later on, since he was at a beer garden haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BaltimoreWalk.jpeg")} alt="BaltimoreWalk" />
                    <figcaption>Area Near Hotel</figcaption>
                </figure>
                We had a nice dinner together. Probably the first time I've actually gotten to know these lab people better, so it was really nice. We walked back to the hotel and Armaun, Chilam, and I decided we wanted to go walk out to the pier to see the water.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BaltNightSelfie.jpeg")} alt="BaltNightSelfie" />
                    <figcaption>Pier Selfie!</figcaption>
                </figure>
                Armaun and I went up to our rooms to brush our teeth and take care of some stuff, and Chilam waited in the lobby since our hotel is full and she has to stay at a different hotel ahaha. By the time we came back down, Annelise was in the lobby talking to Chilam, so we stayed there for a bit and then decided we still wanted to go out and walk a bit.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/HardRock.jpeg")} alt="HardRock" />
                    <figcaption>The Hard Rock Cafe</figcaption>
                </figure>
                We walked around the neighborhood, which is surprisingly nice (it feels like a very peaceful and beautiful place), before going back to the hotel. We spent maybe half an hour outside, and it was already pretty dark. Chilam's hotel is a 20 minute walk away, so we decided to walk her back. I was so thirsty because of how hot it is around here, and luckily the hotel Chilam is staying at has free lemon water in the lobby. So good.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FreeWater.jpeg")} alt="FreeWater" />
                    <figcaption>Free Water!</figcaption>
                </figure>
                We walked back to our hotel shortly after, and I cleaned off and am now ready to go to sleep. Excited to go to the actual conference tomorrow and learn a few things!
            </div>
    },
    {
        title: "Day 41: ICML",
        date: "07/22/2022",
        tags: ["Baltimore", "ICML"],
        content:
            <div>
                7 AM, rise and shine. Well, not really. I woke up around 6 because the alarm clock on our nightstand went off. Armaun shut it down and I fell back to sleep until around 7:15. Haven't woken up so early in so long haha.
                <br/><br/>
                We got ready and down to the lobby by 8 AM, at which point we met up with Anneliese and took the skywalk from the 2nd floor over to the Baltimore Convention Center. The convention center is literally like a 1 minute walk away from our hotel, which is reidiculously nice.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ICML.jpeg")} alt="ICML" />
                    <figcaption>ICML ID!</figcaption>
                </figure>
                We checked in and went to the room where the computational biology workshop was being held. Room 310. I didn't know what to expect, but realized that the computational biology workshop was literally just that room at ICML. I imagined it to be much bigger than it actually was, so I was slightly disappointed that it was only one room of computational biology haha.
                <br/><br/>
                The day began with some talks. I'll be honest - I cannot sit through a single talk, even the 15 minute ones, without falling asleep. It's just so hard to sit there and listen to talks and to understand the specifics of what they're doing. I don't think I like listening to talks haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/TalkRoom.jpeg")} alt="TalkRoom" />
                    <figcaption>Room 310<br/>Home of The Comp Bio Workshop</figcaption>
                </figure>
                But the poster sessions were really fun. I love walking around talking to people and hearing about the stuff they're doing straight from there. It's just so much better when you can talk face to face with someone, especially when their work is relevant to yours.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/PosterSession.jpeg")} alt="PosterSession" />
                    <figcaption>Poster Session in Progress</figcaption>
                </figure>
                Armaun and I went around the convention center together the entire day, joined by Anneliese from time to time. We met some really cool people. One Chinese guy at the Mayo Clinic left an impression on me since I asked if he was a PI since his poster only had one author on it - himself. He said it was just because he was rushing to get the poster printed and it was all kind of last minute hahaha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Convention.jpeg")} alt="Convention" />
                    <figcaption>One Balcony of the Convention Center</figcaption>
                </figure>
                That guy actually came up to us as we were looking at his poster and said to tell him if we had questions since it was his poster, and he was just talking to other people near his poster instead of standing at his own poster hahaha. He was talking to us, and Haoran (one of the PhD students in our lab for the summer) came over. I had just finished asking the Mayo Clinic guy if he was a PI, so he asked me if I was a postgrad at MSK. Haoran laughed really hard, and I told him I was an undergrad haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/10AM.jpeg")} alt="10AM" />
                    <figcaption>10 AM, Got Sweater From<br/>Hotel Room Because It was<br/>Freezing Inside</figcaption>
                </figure>
                His actual project was cool too. He did some sort of work adversarial machine learning, which is basically how machine learning algorithms get attacked by malicious input that's, for example, not supposed to pass quality control, but passes the QC system because the machine learning algorithm messed up and classified it as okay.
                <br/><br/>
                It really put into context a different poster we saw earlier at some random workshop room we went into, where there was a poster about how common image recognition machine learning algorithms are susceptible to making mistakes about classifying what's in an image when certain images, like of dogs, are translated or rotated in certain ways.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Press.jpeg")} alt="Press" />
                    <figcaption>I Am the Press</figcaption>
                </figure>
                I thought that specific poster was really weird since I didn't really believe the findings. Data augmentation is really common in training the types of neural networks used for image recognition, where augmentation basically means rotating or reflecting an image and preserving its training label. Like if you have a picture of a dog, you can reflect the picture along the vertical axis and it's still a dog. But now you have 2 images, original and reflected, that can both be fed in as training data to show your machine learning model what a dog looks like.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Balcony.jpeg")} alt="Balcony" />
                    <figcaption>Balcony Views</figcaption>
                </figure>
                Wentao, some random guy from Cornell, came up later and said the same thing I said about the data augmentation, and we all had a nice convo. I got Wentao's LinkedIn and kept seeing him around the convention center later thru the day haha.
                <br/><br/>
                We saw other cool posters, like another Chinese fella was presenting work on spatial transcriptomics that's really similar to what Armaun is doing in our lab. Armaun had a great time chatting with him, especially since he's also from Stanford.
                <br/><br/>
                The Mayo Clinic guy later told me he was going with the other Chinese guy to dinner and invited me to come. I told him I couldn't, since I had a lab dinner. Kind of unfortunate that we couldn't join them since they both seem like cool people. I got their WeChat's, and Haoran did too (Haoran added me too haha).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ArmaunSelfie.jpeg")} alt="ArmaunSelfie" />
                    <figcaption>Balcony Selfie</figcaption>
                </figure>
                The Stanford guy works with James Zou, who's a Stanford PI that Armaun was literally talking to me about a few days ago before we came to ICML. We had no idea James would be here. He went up to give a short talk, and Armaun got really excited and turned to me saying that was literally the guy we were talking about haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BaltimoreSelfie.jpeg")} alt="BaltimoreSelfie" />
                    <figcaption>At the Pier Near the Hotel</figcaption>
                </figure>
                Later in the day, James was walking around, so we intercepted him and introduced ourselves. He's a really nice guy that asked what we were doing and asked some more details like where the data I'm using is coming from. Hopefully he doesn't steal our research haha! He told Armaun to reach out to him back at Stanford, and said it was nice meeting me. He's actually going back up to MSK in September to give a talk there. How cool is that!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BaltArmaun.jpeg")} alt="BaltArmaun" />
                    <figcaption>Pier Selfie! Walking to Dinner</figcaption>
                </figure>
                Oh, and for lunch, Karl, Anneliese, Shounak, Armaun, and I went to a food court like a 10 minute walk away from the center and got some Vietnamese food. I had really good pho, and a Vietnamese coffee for the first time. The coffee was so damn good. Super cold, sweet, and honestl it tasted just like a dessert haha. Karl said it's definitely a once in a while type of thing.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Concert.jpeg")} alt="Concert" />
                    <figcaption>People Sitting Strategically<br/>Near a Concert</figcaption>
                </figure>
                Anyways, back to the Comp Bio workshop. I met someone from industry that uses Bayesian Tensor Factorization, the same method I'm using, on some other genetics work. I don't really understand what he was doing, since we spent more time talking about his career journey than his actual work haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BaltConcert.jpeg")} alt="BaltConcert" />
                    <figcaption>The Concert<br/>(Jackson Browne, Whoever That Is)</figcaption>
                </figure>
                He said he really likes working in industry and said he'd be happy to connect us around. He actually knows Matteo Pellegrini, but didn't tell me how he knew him haha. I later saw on Twitter that UCLA's QCBio (Institute for Quantitative and Computational Biosciences) actually follows him. Overall a very cool guy that I will definitely keep in touch with. He's also Turkish like Armaun, so they bonded over that haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/PierSelfie.jpeg")} alt="PierSelfie" />
                    <figcaption>Pier Sunset Selfie!</figcaption>
                </figure>
                And that pretty much sums up my ICML experience. I don't like listening to talks, but I like the poster sessions. Very fun when you don't actually have to do anything but walk around and be a visitor of the conference haha. Armaun later told me that there's a very famous guy in Math who says he hates talks because they aren't relevant to his work, and he never understands the details of the talk since it's so specific to the speaker's work. He said that guy said he walks out of talks, and only likes talking to people face to face. That made me feel better.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sun.jpeg")} alt="Sun" />
                    <figcaption>The Pier and The Sun</figcaption>
                </figure>
                There's also another young PI from UChicago that went to UCLA SEED, some lab school elementary school, who Armaun had heard of before coming here, and that happens to be Jeff's (from our lab) good friend from high school. Kind of insane how connected the world is haha. That guy, Aaron Schein, said he doesn't even attend talks either (after I said I didn't really like sitting there through the talks). He only goes to poster sessions haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Pier.jpeg")} alt="Pier" />
                    <figcaption>Yachts Near Our Dinner @ Lebanon Tavern</figcaption>
                </figure>
                Overall a fun day. And we had a nice lab dinner. Getting to know the people in the lab and just overall had a very nice time since this area of Baltimore is very beautiful. Tomorrow's plan is to walk around ICML some more, and then visit Johns Hopkins (hopefully) before heading back to NYC. Excited for more conference, and excited to get back to New York!
            </div>
    },
    {
        title: "Day 42: Baltimore -> NYC",
        date: "07/23/2022",
        tags: ["Baltimore", "ICML", "Johns Hopkins", "NYC"],
        content:
            <div>
                Today I woke up later. Around 8 AM, which was later than what Armaun and I had planned (a 7:30 wakeup haha). It was nice getting some more rest in though!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/1.jpeg")} alt="1" />
                    <figcaption>Bye Bye Hotel</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/2.jpeg")} alt="2" />
                    <figcaption>Convention Center View</figcaption>
                </figure>
                We started the day by checking out, since we were supposed to be out of our rooms by 11 AM. We left our bags with the ICML baggage check in (thank God they offered that. Did not want to drag around my luggage). On a side note, Armaun and I both brought a suitcase. Makes us both look like newcomers to the conference scene since none of the PhD students brought suitcases hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/3.jpeg")} alt="3" />
                    <figcaption>Convention Selfie</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/4.jpeg")} alt="4" />
                    <figcaption>The Pier</figcaption>
                </figure>
                Anyways, there were a few cool workshops going on today. AI in Science, Interpretable Machine Learning for Healthcare, and other sessions unrelated to health and medicine. Armaun and I sat in on the AI for Science workshop, but honestly the talk we saw was so damn boring. I was just waiting for it to finish so we could leave respectfully, but it took so long to finish. I really don't like sitting for talks hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/5.jpeg")} alt="5" />
                    <figcaption>JHU Residence</figcaption>
                </figure>
                We walked around some more and headed to the ML for Healthcare session, where James happened to be presenting! We keep seeing him everywhere haha. Earlier in the morning we were talking about James and happened to see him walk across the same hall we were walking across (and we waved and he waved back)! Armaun said the talk he was giving in the workshop today is the same talk he gave to a class at Stanford a while ago haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4315.jpeg")} alt="IMG_4315" />
                    <figcaption>JHU Nature</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4334.jpeg")} alt="IMG_4334" />
                    <figcaption>JHU Study Floor</figcaption>
                </figure>
                A lot of cool research from James. We walked around the poster session for the ML in Healthcare session and talked to a graduated PhD student of James about her research. Some cool stuff having to do with how to better choose patients for clinical trials. Asked some questions and moved on from that.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4346.jpeg")} alt="IMG_4346" />
                    <figcaption>I Touched a JHU Sign</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4358.jpeg")} alt="IMG_4358" />
                    <figcaption>JHU Campus</figcaption>
                </figure>
                Nothing too major happened at the conference today, except that I saw there was someone else named Haoran Zhang (the same name as someone in our Tansey Lab). This one is from MIT, but I was shocked to see that the second author on his poster was Quaid Morris, a PI at MSK. Armaun and I ran into Haoran outside, so we told him that we found another Haoran Zhang, so we went back to talk to the other Haoran.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4377.jpeg")} alt="IMG_4377" />
                    <figcaption>Me at Cool JHU Building</figcaption>
                </figure>
                He gave us a rundown of his poster and I did not understand what was going on haha. So confusing and he talked so fast. At the end, our Haoran Zhang told him, "我也叫张Haoran!" And then I asked how he knew Quaid Morris, and turns out that Quaid was this guy's PhD mentor or something at University of Toronto! And now this guy is at MIT, and Quaid is at MSK! How cool is that hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4378.jpeg")} alt="IMG_4378" />
                    <figcaption>JHU Tree</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4386.jpeg")} alt="IMG_4386" />
                    <figcaption>Cool Buildings Near JHU</figcaption>
                </figure>
                Afterwards, Armaun and I wandered some more and then some random girl came out of a room we were standing near and gave us tote bags from their company. It had ICML 2022 on it, the workshop name, and their company. Don't know what it was about, or what the company does, but Armaun and I were really excited to get tote bags since we both went into the conference hoping there were tote bags haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4404.jpeg")} alt="IMG_4404" />
                    <figcaption>Coolest Room in JHU</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4408.jpeg")} alt="IMG_4408" />
                    <figcaption>A Beautiful Library</figcaption>
                </figure>
                Shounak, Jacqueline, Anneliese, and I then went to lunch. We ended up going to Shake Shack even though our lunch budge is $40 per person haha (and dinner is $70 per person). How generous! We got a burger and a drink and ate along the Cheesecake Factor at the pier there.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4430.jpeg")} alt="IMG_4430" />
                    <figcaption>Cool Building, Reminds Me of UCLA</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4438.jpeg")} alt="IMG_4438" />
                    <figcaption>Another Library</figcaption>
                </figure>
                Shounak and Anneliese went along their way back to the conference, and Jacqueline, Armaun, and I went on our way to Johns Hopkins University! I was so excited to finally visit JHU, since I like touring around college campuses haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4454.jpeg")} alt="IMG_4454" />
                    <figcaption>Broader View of Cool Bell Building</figcaption>
                </figure>
                My initial impressions of JHU were very good. It's a very nature-filled campus, with trees everywhere and greenery all over campus. The buildings are similar to UCLA in that pretty much all the buildings are made of red brick.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4473.jpeg")} alt="IMG_4473" />
                    <figcaption>Lively Part of Campus</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4476.jpeg")} alt="IMG_4476" />
                    <figcaption>Ames Hall</figcaption>
                </figure>
                We got dropped off at the residential halls, and made our way through various campus buildings. So many of the buildings are open on the weekend. So surprising, especially since there seemed to be like no one at campus. It was so eerily quiet.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4483.jpeg")} alt="IMG_4483" />
                    <figcaption>A Calm Courtyard</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4512.jpeg")} alt="IMG_4512" />
                    <figcaption>Acoustic Room</figcaption>
                </figure>
                JHU has a TON of teaching laboratories, which are just labs meant to host classes. Strange to see so many. We concluded that they must be really focused on teaching undergraduate sciences haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4524.jpeg")} alt="IMG_4524" />
                    <figcaption>Another Cool Building</figcaption>
                </figure>
                We saw many impressive buildings, but probably my favorite was a little section that comes right before a library in the main building on campus, the one featuring the bell that rings every 15 minutes. It just felt very grand, as did many of the library buildigs and common spaces in campus.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4606.jpeg")} alt="IMG_4606" />
                    <figcaption>Sunset on the Train Ride Back</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4607.jpeg")} alt="IMG_4607" />
                    <figcaption>Another Sky View</figcaption>
                </figure>
                There were a couple rooms that were BIG, open spaces with a piano along one of the walls. The acoustics were just amazing there. We heard one person playing, and Jacqueline asked me if he was good. I said "no, he has bad technique," but felt bad for roasting him since it was pretty good. He just plays too fast without properly practicing some of the portions of the piece he was playing!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4627.jpeg")} alt="IMG_4627" />
                    <figcaption>Beer Pong. Me and Julia Won on<br/>The Last Cup Redemption!</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4632.jpeg")} alt="IMG_4632" />
                    <figcaption>Us and Eitan, the Host</figcaption>
                </figure>
                Armaun, Jacqueline, and I sat down for a bit at an outdoor area and talked. I found out Armaun got into Stanford, Princeton, and Caltech! Jesus Christ! Very smart guy haha. It's so insane that this guy literally comes from the same community as me and we just happened to meet in the same damn 13 person lab at an organization across the damn country hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4640.jpeg")} alt="IMG_4640" />
                    <figcaption>Columbia!</figcaption>
                </figure>
                By the time we were ready to leave JHU, it was 4, and Jacqueline and I had train tickets for 5. We were trying to decide whether to get food before, and ended up not doing it since we were rushing for time.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4646.jpeg")} alt="IMG_4646" />
                    <figcaption>Us On Top of Columbia</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4653.jpeg")} alt="IMG_4653" />
                    <figcaption>One of My Favorite Pictures of the Summer<br/>Sanjana Copied My Pose</figcaption>
                </figure>
                We rushed back to the hotel, picked up our bags, and got a Lyft to Penn Station Baltimore. We arrived like 10 minutes before our train was departing, so we were very relieved. Haoran and Shounak were supposed to travel with us, but Jacqueline told them to go ahead without us since we were so far behind haha. But lo and behold, when we got to the train platform, we saw them there! No point for them to leave so fast haha!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4663.jpeg")} alt="IMG_4663" />
                    <figcaption>The Other Side of the Quad</figcaption>
                </figure>
                We got nice train seats and I wanted to read a paper for journal club, but ended up falling asleep for like an hour. I kept waking up every now and then, but had a good nap before just staring out the window until we got back to NYC.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4665.jpeg")} alt="IMG_4665" />
                    <figcaption>Us At a Fountain</figcaption>
                </figure>
                I felt really happy to be back at Moynihan, back right next to The New Yorker. It felt a little like when I was moving in, except now I didn't feel scared and alone. I felt happy we made it back safely and excited to see my besties.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4673.jpeg")} alt="IMG_4673" />
                    <figcaption>Sanjana Running to the Bathroom</figcaption>
                </figure>
                Got some Chipotle and hung out with Julia, Josh, and Sanjana. Julia got invited by a school friend to an apartment party near Columbia, and we couldn't decide what to do. Sanjana decided to be decisive and said we'd go to the party, so off we went. I was really hesitant at first since I was so tired, but then I thought, "when will I get another chance to go to a Columbia party?"
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4683.jpeg")} alt="IMG_4683" />
                    <figcaption>We Finished Peeing!</figcaption>
                </figure>
                When we got to the party, there weren't many people. It was kind of quiet, and we ended up talking to someone who graudated from Columbia and is doing a comp bio PhD at Princeton starting in the Fall. Crazy to have so many comp bio researchers at a party haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4692.jpeg")} alt="IMG_4692" />
                    <figcaption>Beautiful Columbia Building</figcaption>
                </figure>
                They were all drinking (I didn't drink just cuz I didn't feel like getting tipsy tonight), and we played beer pong and Rage Cage, both drinking games that're at all parties haha. They played good music, and it got pretty loud as more and more people joined us.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4694.jpeg")} alt="IMG_4694" />
                    <figcaption>Another Building!</figcaption>
                </figure>
                Eitan, the host, and his brother Gill, said they loved my Seek Discomfort shirt. They both also watch Yes Theory, and so they knew really well where the shirt came from haha. I was so excited, and they told me about how they took a trip to the Middle East inspired by Yes Theory. And when one of their friends dropped out of going, they got a stranger to go with them! How cool is that haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4706.jpeg")} alt="IMG_4706" />
                    <figcaption>Lying on the Grass</figcaption>
                </figure>
                We spent about an hour at the party before leaving to go to Columbia's campus. Julia showed us around, but we were too occupied trying to find a place to pee since we all had to go really bad.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_4718 2.jpeg")} alt="IMG_4718 2" />
                    <figcaption>Goodbye Columbia!</figcaption>
                </figure>
                We ran around, looking at locked building after locked building, and tried convincing Sanjana to just piss in a bush since she had to go the most haha. She said she respected Columbia too much to do that even though they rejected her hahaha. She seemed the most drunk out of all of them since she was talking more than usual and overall just really excited haha.
                <br/><br/>
                We eventually got to a library and asked someone outside of it to scan in for us. He did! What a nice guy! We ran down to the bathroom, and Julia told us that Sanjana had so much pee that when she used toilet paper, she dipped her hand in the pee hahahahaha.
                <br/><br/>
                Afterwards, we walked around campus more messing around, and found a nice patch of grass on the side of campus and sat there. It was probably 12:30 AM by this point, and we just lied down on the grass for a bit, talking, before running around, playing Sharks and Minnows, an elementary school game Sanjana suggested since she makes her parents play, and other dumb crap. We were just playing around there for a good hour and a half late at night. Such good memories that will honestly probably be most valuable to Julia since she'll always think of them as she walks past that place on campus during school haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_4722.jpeg")} alt="IMG_4722" />
                    <figcaption>Josh at the Subway</figcaption>
                </figure>
                We got back after 2 AM, and I got in bed by 3-ish. I was really happy I decided to go. Overall just an incredibly fun time, even though I didn't drink. Josh was like, "see? It was so worth it. No one peer pressured you to drink or take off your mask" (I was worried people would judge me for still wearing a mask haha). Overall a very fun time. Would definitely do it again!
            </div>
    },
    {
        title: "Day 43: Just Kyle Being Kyle. Hyped About Life",
        date: "07/24/2022",
        tags: ["Brooklyn", "Thrifting", "Cooking"],
        content:
            <div>
                Today I woke up at 10:50 AM. Got to bed arund 3 AM, so I got almost 8 hours of sleep! I quickly got ready since we planned on leaving to Brooklyn at around 11:20.
                <br/><br/>
                We (Sanjana, Mitchell, and I) got on the subway to head to Brooklyn, where we were meeting up with Josh, Aziz, and Sydney at some thrift store. That area of Brooklyn felt very much like the city part of LA, with graffiti all over the place but an overall peaceful feeling during the day.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Cricket.jpeg")} alt="Cricket" />
                    <figcaption>NYPD Cricket</figcaption>
                </figure>
                We made our way to L Train Vintage, the largest thrift store in either Brooklyn or NYC (I don't remember), and got on our way to looking through all the clothes. There was a lot of cool stuff there, but I didn't go in expecting to buy anything. Nothing looked too cool, but I saw a rack of athletic clothes that I thought would be really comfy to wear during tennis. I ended up getting a yellow and green NYPD Cricket shirt I thought was funny, and a blue Adidas shirt that says "Frenship" on it haha. Frenship is apparently a sports team, but I've never heard of them before.
                <br/><br/>
                We spent at least an hour in there, and at one point, we decided to all get outfits for each other. I was assigned Sydney, and got together an outfit I thought would look semi-decent, but it was way too big for her and I also got a Jamaican themed shirt, so they thought I was profiling her hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Shirt.jpeg")} alt="Shirt" />
                    <figcaption>The Shirt I Got Sydney</figcaption>
                </figure>
                After the thrift store, we headed to a nearby Popeyes to eat lunch. I think that was the first Popeyes I've ever had! Pretty good, but nothing too crazy. Literally just a piece of fried chicken in between two buns haha.
                <br/><br/>
                We went to another, smaller thift store after lunch a small distance away, and walked around there for a while. I realized I need something nice to wear to Friday's dinner cruise, since there'll be professional photography there, and it's a fancy event I have to look good for. I kind of regret not bringing my dress clothes here, but it's okay. I'm gonna go shopping tomorrow for some nice clothes.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sydney.jpeg")} alt="Sydney" />
                    <figcaption>Sydney is Shocked</figcaption>
                </figure>
                We didn't spend too much time at this second thrift store I can't even remember the name of, and headed back to the hotel. We dropped our stuff off, and Josh, Sanjana, and I went on our way to go shopping. I didn't have any food other than noodles and rice cakes, so I needed to make a trip to HMart to get some Asian groceries.
                <br/><br/>
                We got to HMart by bus, which Josh showed us how to take (we just walked on the back without paying haha), and had a nice little trip there. We walked over from KTown to the Target nearby so Sanjana could get some ground turkey, but they didn't have any! That Target is bad with restocking their meat. At least I got some ground beef!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Frenship.jpeg")} alt="Frenship" />
                    <figcaption>My Frenship Shirt</figcaption>
                </figure>
                We took the same bus in the opposite direction to a Whole Foods nearby Hudson Yards, and Sanjana got her ground turkey there. Hudson Yards is a fancy shopping center near The New Yorker, but it's still a good 10 minute walk or so away. We wanted to take the bus, but it was too far away so we ended up walking back with all our stuff.
                <br/><br/>
                Once back, we were all too tired and full to cook, so we took a break until about 6:30 before we started cooking. It was a nice time in there with Josh and Ivory sitting with us as we cooked (since there are chairs and counters next to the stoves), and we had a peaceful time cooking up our dinners.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DenimThrift.jpeg")} alt="DenimThrift" />
                    <figcaption>Denim Jacket I Did Not Get</figcaption>
                </figure>
                While we were eating, some random girl came in and asked us if we were using the stovetops Sanjana was cooking at, so we cleaned up and let her use the one right in front of the counter we were eating at.
                <br/><br/>
                She said my noodles looked fire and she said she wished she could cook that hahaha. What a nice thing to say. Little does she know that it's easy as hell to make! She just needs to watch Dad cook for a while and then she can do it herself. I got some new veggies from HMart like bean sprouts and a different kind of bok choy. It's nice to have some variety. I also only have one more noodle bundle left, thank God. I'm getting kind of sick of the Shanxi mian hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MacysSelfie.jpeg")} alt="MacysSelfie" />
                    <figcaption>Waiting in Macy's For the 34th Street Bus!</figcaption>
                </figure>
                After dinner, we hung out in the TV room and just talked there till like 12:30 before heading to bed, so I'm writing this blog at work. It's 10 AM at work right now. I tried waking up at 8, but accidentally slept through my alarm till 8:10. I was supposed to leave for work with Josh and Sanjana at 8:20, but ended up going myself at around 8:30. Got to work just a bit after 9, which honestly is pretty good for me these days! Excited to get this week of work done, and see where all this brings us.
                <br/><br/>
                It's almost time to come home, which I am both very very sad about but also excited for. It also means I can finally be on a tennis court again, although I'll have to find someone to play with. Till then!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FireNoodles.jpeg")} alt="FireNoodles" />
                    <figcaption>Brown</figcaption>
                </figure>
                Two asides: the title, and being profiled as a Korean. Let me explain...
                <br/><br/>
                First, the title! One of the snacks they put out at ICML was some brown thing in a jar. Armaun, Anneliese, and I didn't know what was in it, but I expressed my strong feelings for wanting a jar since it's a free glass jar! Anneliese seemed VERY annoyed at me for wasting my time and talking about the jar, but I don't give a shit. I wanted a free jar, so I was going to get my free jar haha.
                <br/><br/>
                Anyways, I brought it back and finally was able to open it last night after dinner. My suspicion was that it was a cake in a jar, and lo and behold... it was chocolate cake! And not just ANY chocolate cake. It was literally the fluffiest, most delicious, and creamy chocolate cake I have ever had. It was SO good. I was like, "this is SO BUSSING!" And then Sanjana wanted to try, so I wasted half of it sharing some to Aziz, Sanjana, Josh, and Ivory, but I was able to enjoy enough that I was very satisfied haha. And now I have a free little jar!
                <br/><br/>
                So, why the title?!? Well, Sanjana said she thought the cake wouldn't be that good, since she thought it was just "Kyle being Kyle, hyped on life." Hahaha. But they all also thought it was really good.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Bean.jpeg")} alt="Bean" />
                    <figcaption>Looked Better While Cooking</figcaption>
                </figure>
                Second, being profiled as a Korean! When I walked out of HMart, there was a white man with a hat standing under a part of the building that water was dripping off of. I told him to be careful since he was being rained on, and then he replied in some foreign language I didn't hear. I suspect it was Korean since his hat had Korean characters on it, but either way it was funny and weird.
                <br/><br/>
                HMart is a Korean store, so I can understand why he mistook me for a Korean. But that was NOT the only incident yesterday. Aziz, Mitchell, Sanjana, and I were walking to Song Tea last night to pick up milktea for them. Now picture this. Aziz standing in front, Mitchell to his right, me on the left in the back, and Sanjana to my right.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CakeJar.jpeg")} alt="CakeJar" />
                    <figcaption>Cake Jar!</figcaption>
                </figure>
                We're walking along 36th street, and a big 黑 man comes up to us, shouting, "hey hey that's what I like to see! Indian (referring to Aziz, who is indeed not Indian), Korean (referring to me, who apparently has a Korean haircut according to friends), uh uh uh, beauty queen (referring to Sanjana), and American Therapist who should give his friends weed (referring to Mitchell, who is indeed American, but not a therapist, nor a weed owner for that matter)." Hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AirPods.jpeg")} alt="AirPods" />
                    <figcaption>I Believe I've Lost My Airpods At ICML Hahaha</figcaption>
                </figure>
                They were all avoiding him but I thought he was funny, so I was like "hahaha hell yeah!" And then he was all excited and happy as we walked away.
            </div>
    },
    {
        title: "Day 44: A Korean and a New Shirt, The Legend of Zara",
        date: "07/25/2022",
        tags: ["H&M", "Zara", "Duane Reade"],
        content:
            <div>
                Today I woke up bright and early around 8 AM. I wanted to get to work early to start the week off strong, and I did just that! Arrived in the office just minutes after 9, so I was off to a good start.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CloudyRoosevelt.jpeg")} alt="CloudyRoosevelt" />
                    <figcaption>View From Surgery Center</figcaption>
                </figure>
                I ended seeing that Wesley had sent a message to our channel yesterday saying we should make this week a work from home week just to be extra safe about covid since we all came back from a business trip. What an excellent idea. I'll still be going into work this week since I have so many mandatory events, but I'll wear my mask at usual when I'm walking around the building.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Storms.jpeg")} alt="Storms" />
                    <figcaption>Storm Clouds Incoming...</figcaption>
                </figure>
                I didn't talk to Wesley today, but I plan on reaching out to him tomorrow about some updates as well as about one specific poster I saw at ICML that's related to our research. Excited to talk to him and get some feedback, since I need to start putting my poster together.
                <br/><br/>
                That was pretty much all of work today. Just getting set up for the week and compiling some more results together into a more organized format. I got lunch with Armaun since he's never been to the surgery center, and we had a nice time talking about CS classes and just enjoying the beautiful view of Roosevelt Island and the bridge.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CEO.jpeg")} alt="CEO" />
                    <figcaption>CEO and President of MSK<br/>In the Same Room As Us!</figcaption>
                </figure>
                We could see dark clouds moving in over Joy from the surgery center balcony, and we knew what was coming. Storms! When we were walking back to work, it started pouring out of nowhere, but we luckily made it back inside before the torrents started.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Zara.jpeg")} alt="Zara" />
                    <figcaption>Shopping At Zara On 34th Street</figcaption>
                </figure>
                All the MSK summer interns had an event today at 2 PM. The President and CEO, Craig Thompson himself, was scheduled to give us a talk. I walked over to the ZRC auditorium and settled in, ready for our talk.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SoybeanMilk.jpeg")} alt="SoybeanMilk" />
                    <figcaption>Black Soybean Drink From HMart</figcaption>
                </figure>
                Craig Thompson is a really, really good speaker. He sounds very put together, and knows a LOT. He went to medical school and also runs a research lab at MSK with his wife, so he's extraordinarily busy. He's actually stepping down in September so he can focus more on research haha.
                <br/><br/>
                He didn't have any slides or anything. He just gave us a talk on MSK, cancer, and research in general. His main goal was to help us have good answers for our parents when we go home and they ask us about what we did over the summer haha. I feel like his talk was honestly more interesting than all the ICML talks combined, mostly because I actually knew what was going on this time ahha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FiveSenses.jpeg")} alt="FiveSenses" />
                    <figcaption>Five Sense With Five Of My Besties!</figcaption>
                </figure>
                After the talk, I stayed in the ZRC lobby to run some programs and read the paper for tomorrow's journal club. I didn't want to walk back to Joy, and I was supposed to meet Sanjana and Josh to go shopping after work anyways, so I stayed close by.
                <br/><br/>
                Work finally ended and we went on our way to go clothes shopping. I wanted to get something nice for our dinner cruise on Friday, since we have professional photography there and I don't really have any fancy clothes here. I also need something good to wear to our poster session haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FiveSenses2.jpeg")} alt="FiveSenses2" />
                    <figcaption>Walruses</figcaption>
                </figure>
                We first went to H and M, didn't find much of anything good (I wanted something that's not too boring and not too outstanding), so we went to Zara. All of this is on 34th street right outside the Herald Square subway stop we always get off on.
                <br/><br/>
                We shopped around at Zara, and I finally found this elastic and very comfy dress shirt that I like. It was overpriced at $50, but I needed something so I just got it ahaha. Now I have an extra cool shirt! So excited for the dinner cruise.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/NightManhattan.jpeg")} alt="NightManhattan" />
                    <figcaption>KTown at Night</figcaption>
                </figure>
                We had a dinner planend with Yutaro and Julia at Five Senses in KTown, so we ended up walking around the area since it was already 7 PM by the time we finished shopping. We first tried to get into a nice apartment complex Josh's friend has a place in, but couldn't go since his friend is in Hong Kong right now.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/KimchiFriedRice.jpeg")} alt="KimchiFriedRice" />
                    <figcaption>Kimchi Fried Rice For Dinner</figcaption>
                </figure>
                We ended up going to Nordstrom Rack and shopped there for a while before heading to Five Senses. Does Five Senses sounds familiar to you? If it does, that's because that's where I ate dinner with Jaden WAYYYY back then and got the TASTELESS OX bone SOUP. I told Josh about that and he said he's had ox soup that tasted like nothing before, which made me feel better about the restaurant hahaha. Maybe it's just a terrible order. I DO remember Jaden's food tasting good last time we went.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AirPodsFound.jpeg")} alt="AirPodsFound" />
                    <figcaption>Treasure Located!</figcaption>
                </figure>
                So we went in, and I ended up ordering a kimchi fried rice with seafood. It was SO DAMN GOOD. It comes on a big stone plane that's ridiculously hot. They have a mountain of kimchi fried rice with cheese on top, and crack an egg over it before mixing it all up right in front of your very eyes. It's pretty satisfying to watch haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/DressedUp.jpeg")} alt="DressedUp" />
                    <figcaption>All Dressed Up!</figcaption>
                </figure>
                We all had a good time at dinner and got back to the hotel safe and sound around 9:30 PM. I remembered I wanted to buy a comb since people have been telling me to get a comb, so Sanjana and I went over to Duane Reade to get a comb and hand soap since I ran out. And now, after having showered and combed my hair, I am again at the 15th floor, writing my blog, relaxing with a couple friends, and we'll probably be off to bed soon since we have to wake up bright and early tomorrow for our journal club at 9 AM. Good night!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Combed.jpeg")} alt="Combed" />
                    <figcaption>I Combed My Hair!</figcaption>
                </figure>
                Oh, and I found my AirPods! They were literally just in my suitcase haha. Weird bug!
            </div>
    },
    {
        title: "Day 45: Making Progress in the Sunset",
        date: "07/26/2022",
        tags: ["Work"],
        content:
            <div>
                Today I woke up at around 7:30 AM since I thought I would need extra time for my stomach and my local to settle down since I had spicy Korean fried rice for dinner last night. I ended up finishing my morning routine in 20 minutes, and felt pretty good. No trouble at all except some minor burns!
                <br/><br/>
                Since I was so early, I waited around in my room for a bit before waiting for the others in the lobby. CBSP had our second to last journal club today at 9 AM, so Sanjana, Josh, Rhea, and I left together (Josh just went to work since he's in ESP).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Yuncai.jpeg")} alt="Yuncai" />
                    <figcaption>Clouds On a Comfy Day!</figcaption>
                </figure>
                Sanjana saw the train was coming in like 5 minutes and we were still at the hotel, so at one point (probably around where the Crocs store is), we ran down 34th street towards the Herald Square Subway Station. Good workout. People walking the other direction were laughing at us ahhaa.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RockefellerUni.jpeg")} alt="RockefellerUni" />
                    <figcaption>I Love the Walk From<br/>ZRC to Joy<br/>On The Rockefeller Uni Side</figcaption>
                </figure>
                We made it just in time! Actually a bit early. We got on our subway ride and made our way to journal club, arriving a solid 15 minutes before 9. Journal club was fun. We read a paper on biologically informed neural networks that are much more interpretal than traditional deep learning architectures, and I got some cool ideas out of the talk.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MaskOnHead.jpeg")} alt="MaskOnHead" />
                    <figcaption>Mask on His Head - That's a First!</figcaption>
                </figure>
                The speaker was Aziz's mentor, and she's a former CBSP intern that's now a 4th year PhD student in the Tri-I program. Some of the general ideas about machine learning explainability actually helped me better frame my work with Pellegrini. I realized we were really focused on interpretable machine learning. We just never said that term during our meetings hahaha.
                <br/><br/>
                After journal club, we headed on our way to Joy (Jaden, Rhea, and I). When Rhea was trying to get through security, her card wasn't scanning since she keeps it in a phone wallet on the back of her phone. Her hands were full, so I scanned in and then leaned back over to scan myself in again to open the door for her.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Knife.jpeg")} alt="Knife" />
                    <figcaption>Got a Free Knife in the Pantry</figcaption>
                </figure>
                I thought I did something quite nice, but all hell broke loose. The security guard started yelling at me, something along the lines of: "YOUNG MAN! DID YOU JUST SCAN AGAIN? DO NOT DO THAT! DO NOT DO THAT!" He was insanely mad at me and that honestly pissed me off since he has no business having such an outburst. I understand it's his job, but Rhea has a badge, clearly works there, and literally comes in every morning just like the rest of us. Anyways, it wasn't too big of a deal.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/GreenOnions.jpeg")} alt="GreenOnions" />
                    <figcaption>Free Cutting Board and Green Onion!</figcaption>
                </figure>
                Work itself went really well today in terms of making progress. I sent Wesley some thoughts about ICML and some updates with regards to what we found in our next steps. He sent back a long response explaining what we can try next, and also some science that explains what we're seeing.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Niangao.jpeg")} alt="Niangao" />
                    <figcaption>Niangao For Dinner Again<br/>Can You Believe I'm<br/>Not Sick Of It?!?!?</figcaption>
                </figure>
                For lunch, Rhea and I went to the surgery center and enjoyed the view of the balcony. Today was one of the first days in a few weeks that Manhattan was as cool as it was the first several weeks I moved here. It felt SO nice, not like summer at all. There were also a lot of clouds and it felt a bit breezy. Similar to what Berkeley might feel like over the summer, which was a nice break from the extreme heat we've been having.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Moynihan_Sky.jpeg")} alt="Moynihan_Sky" />
                    <figcaption>Moynihan and What Looks Like<br/>A Fake Sky</figcaption>
                </figure>
                I also finally asked Ino to have a meeting with him about his job, along with a few other CBSP interns. I'm really interested in Ino's job since he's a software engineer without a PhD, but he works at MSK and works on such high impact projects that he has almost 20000 lifetime citations on Google Scholar. That is absolutely insane.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/NightsunViews.jpeg")} alt="NightsunViews" />
                    <figcaption>Next to Moynihan on 34th Street</figcaption>
                </figure>
                I think what he does is something I'd probably really enjoy doing since I get to do more CS oriented stuff while also working in a really cool area. Wesley also mentioned he has a lot of opinions on working in industry and academia, and he'd love to chat with me about that sometime. I'll probably schedule that chat for next Friday after posters are due, since I see it taking longer and being a bit more formal than our meeting with Ino.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Pink.jpeg")} alt="Pink" />
                    <figcaption>The Beautiful Sunset in Manhattan<br/>From Close to Hudson Yards</figcaption>
                </figure>
                Either way, I'm happy I can take advantage of getting to know the people that work here at MSK. So many cool things going on!
                <br/><br/>
                After work, I headed right back to the hotel and cooked up dinner. Afterwards, Sanjana and I walked to Nordstrom Rack to return some things Sanjana doesn't want anymore, and then walked to Hudson Yards to explore the Muji store. The sunset reminded me a bit of Manhattenhenge today. It was absolutely beautiful!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Muji.jpeg")} alt="Muji" />
                    <figcaption>Resting at Muji</figcaption>
                </figure>
                And the Muji store has so much cool Japanese home, school, and stationery items. Overall a very fancy store that also has some nice bean bags that we could rest on. The entirety of Hudson Yards is insanely fancy. It's like a rich people area that's maintained very well and very clean.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MitchellDonuts.jpeg")} alt="MitchellDonuts" />
                    <figcaption>Mitchell Got So Many Donuts<br/>From an Ice Cream Shop<br/>On Too Good to Go</figcaption>
                </figure>
                The rest of the night has been pretty standard - clean up and rest in The New Yorker's 15th Floor. Counting down the days till I return to the West Coast! See you guys soon!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MirrorSelfie.jpeg")} alt="MirrorSelfie" />
                    <figcaption>Mirror Selfie in My Room<br/>After I Showered Tonight!<br/>I Look Stupid :D`</figcaption>
                </figure>
                Also, explaining the title: I saw a cool sunset today and made progress at work. But! It's also an analogy for how I feel like we're making good progress as we near the end of CBSP. There of course still feels like we have miles and miles ahead of us in terms of pushing a fully formed project to the world, but I'm happy progress isn't slowing!
            </div>
    },
    {
        title: "Day 46: Working Not From Home",
        date: "07/27/2022",
        tags: ["Work"],
        content:
            <div>
                I got to work a bit later today, I think around 9:15. The train ahead of us just stopped working, but luckily it failed at the platform so everyone could get out. That meant my train had to wait at our station for longer than usual, which was slightly annoying.
                <br/><br/>
                When I got to work, I had a nice double latte with some of the snacks I bought for breakfast before ICML. A very filling meal, and plenty of fiber! I soon got to work and had a pretty normal workday trying to get things done.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ConferenceRoom.jpeg")} alt="ConferenceRoom" />
                    <figcaption>Going to Lab Meeting in Person</figcaption>
                </figure>
                At around 11 AM, I headed over to the other side of our floor to talk to Armaun, and saw that Chi-lam was also there. We all just come in anyways even though it's a work from home week haha. Armaun talked to us a bit about the project he's working on, and then I brought them over to the surgery center to pick up lunch for our lab meeting.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Bridge.jpeg")} alt="Bridge" />
                    <figcaption>Overpass Near Joy</figcaption>
                </figure>
                We all got salads and headed back to Joy. Since our lab meeting is remote this week, the conference room was going to be empty during the time we usually use it. So we decided to just go in anyways, and debated about whether to use the conference room's TV and camera to video call in.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BeanSprouts.jpeg")} alt="BeanSprouts" />
                    <figcaption>So Many Brean Sprouts</figcaption>
                </figure>
                We ended up deciding not to do that, since we were scared Wesley would get mad we ruined the whole point of work from home week by being in a room together, eating lunch during our lab meeting like we normally do when we're all in person haha.
                <br/><br/>
                Amy, a PhD student co-mentored by Wesley also went into the room, not expecting us, so we were all laughing and putting up virtual backgrounds on our Zooms before Wesley joined the meeting haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Arugula.jpeg")} alt="Arugula" />
                    <figcaption>Bed of Arugula</figcaption>
                </figure>
                The lab talked a bit about how ICML was before we dived into Jeff's talk today. Jeff gave a talk on Docker and containerization, which I was excited about since he comes from a software background and the talk is CS type of stuff. I unfortunately did have to miss a really cool joint scientific talk on metastatic plasticity that was scheduled for the summer students today. I heard it was a really really good talk, which I'm a bit sad about having to had miss haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/AlcoholStore.jpeg")} alt="AlcoholStore" />
                    <figcaption>Alcohol Store!</figcaption>
                </figure>
                I was pretty tired after our lab meeting and took a quick break before starting to work on my project again. Wesley suggested a new direction to try out, so I was just working on that and testing things out. Should be able to see the results tomorrow!
                <br/><br/>
                After work, I headed to Trader Joe's with Zhiyue and ended up getting two beef tomatoes, a sweet onion, and arugula. I was walking all over the place since I couldn't decide what veggies to get, and Zhiyue said she never walks around like that when she shops haha. She says she walks by each thing only once hahhaa. Had a smooth commute back as usual. Lately, the N, R, or W always shows up right when I get to the 59th and Lexington station, so the commute is very fast.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StrawberryBaileys.jpeg")} alt="StrawberryBaileys" />
                    <figcaption>Strawberries and Cream Baileys Looks SO Good</figcaption>
                </figure>
                For dinner, I cooked the rest of my bean sprouts and quite a bit of arugula. Such a healthy dinner. And then I put laoganma and the black bean garlic sauce with my noodles while stirring it into all my veggies, and it actually tasted good. I've eaten too many noodles that don't taste like much these past few weeks haha. And I'm finally done with my Shanxi noodles thank God. Will buy some new types of noodles to try for next week!
                <br/><br/>
                Cooked up a dinner and then went shopping for drinks with Sanjana, Julia, and Josh. There's a really nice liquor store on 36th street that we got some drinks from so we can get drunk before our dinner cruise. Just kidding! I won't actually get drunk haha. But a lot of us probably will.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Josh.jpeg")} alt="Josh" />
                    <figcaption>Josh in the 15th Floor<br/>Conference Room</figcaption>
                </figure>
                The rest of the night has been calm. Doing laundry, cleaning up, and the normal night routine. Tomorrow is our meeting with Ino which I'm excited about, and we'll probably go to Roosevelt Island after work on the tram. Should be a fun day ahead of us!
            </div>
    },
    {
        title: "Day 47: Not Working",
        date: "07/28/2022",
        tags: ["Work"],
        content:
            <div>
                Today I woke up at 8:30, getting about 7 hours of sleep! By the time I got out, I thought Sanjana and Josh were just getting out, so I texted them and met up with them (they were one street away). The next train was 20 minutes away, so we decieded to go get breakfast at Market Crates. I got the french toast combo which ended up costing $15, but it was a lot of food, tasted great, and I got two containers out of it. I can probably cook lunch and bring it to work starting now.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MarketCrates.jpeg")} alt="MarketCrates" />
                    <figcaption>Expensive Breakfast</figcaption>
                </figure>
                I decided to go to Zuckerman in the morning today, since I didn't want to walk from Joy to that area in the afternoon for our time management talk. I sat on the sixth floor, trying to get some reading done, but ended up not being able to do much in the morning. Sydney joined me, and we each did a bit of work before I left.
                <br/><br/>
                Julia works in Zuckerman on the 11th floor, so she showed me around Dana's Lab before we headed to the main hospital cafeteria to eat lunch with Sanjana. The main campus hospital is much more crowded than the surgery center, but there's way more options. BUT the food honestly isn't that good haha. I definitely prefer the surgery center, but I'm glad I got to try out the main hospital food. Got an employee discount there.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SoHot.jpeg")} alt="SoHot" />
                    <figcaption>The Subway is So Hot</figcaption>
                </figure>
                After lunch, we attended the time management lecture in Rockefeller Research Labs (RRL). The lecture itself was very well put together and our speaker was really enthusiastic, but I didn't find the actual content that good. Felt kind of boring, and I ended up dozing off a ton of times, taking many many mini naps during the talk.
                <br/><br/>
                I followed the speaker's Twitter, and she followed me back! She's an admin who works near Ushma, so at least now I have a new Twitter follower out of it haha. I logged into my lab's reading group at 2:04 PM, realizing that I was 4 minutes late. I wanted to walk back to Joy, but decided to just stay in RRL.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/HotSubway.jpeg")} alt="HotSubway" />
                    <figcaption>Getting Out of the Subway</figcaption>
                </figure>
                There's a really cool medical library near the entrance of RRL, so I stayed in there during our journal club. I realized about 10 minutes later that our journal club actually started at 1 PM today, an hour earlier than normal, so I was over 1 entire hour late to the meeting haha. Oh well. Not like I would've understood it anyways hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/HospitalLunch.jpeg")} alt="HospitalLunch" />
                    <figcaption>Peruvian Chicken, Collard Greens, and Beets<br/>For About $7.50</figcaption>
                </figure>
                I had a nice, peaceful walk to Joy after the meeting. Sydney showed up a bit after me with a bag full of Trader Joe's goodies. She had animal crackers, which I haven't had since I was probably in elementary school. They were so good haha. Sydney, Zhiyue, and I were hanging out on our side of our row of cubicles, and Rhea came to join since she sits across a gap from us and wanted to see what it was like on our side.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RRL.jpeg")} alt="RRL" />
                    <figcaption>Rockfeller Research Labs</figcaption>
                </figure>
                We all sat around and chatted for a bit, with Jaden joining us soon after a meeting of his. I didn't do much after that, since Ino came over to where we work (he works maybe 3 cubicles in front of us). He's a really funny, kind of awkward guy haha. He was like, "so what questions do you have for me? Why do I wear socks and sandals? Because it's comfortable!"
                <br/><br/>
                There were no open conference rooms on our floor, so we went up to the sixth floor, which has a really big pantry that has the entrance to their conference room. The layout is so different from the 2nd floor where I work haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/RRLAquarium.jpeg")} alt="RRLAquarium" />
                    <figcaption>Aquarium in the RRL Medical Library!</figcaption>
                </figure>
                We all sat around and it felt really formal, and then we started our meeting. Everyone in Joy ended up coming since they all wanted to be part of the meeting, and Sanjana came to Joy to join us. We were getting started with questions, and Ino was like, "so what questions? Is this meeting hybrid?" hahaha.
                <br/><br/>
                No one had anything prepared, but I guess we kind of expected that since I organized the meeting so I was supposed to have questions haha. I started by asking him how he found his way to MSK, and then it all went smoothly from there. He talked to us about how it's hard to get a green card, gave advice to the others that are in this program and trying to get green cards eventually, and talked about the pros and cons of working at MSK in his software role.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SydneyLib.jpeg")} alt="SydneyLib" />
                    <figcaption>Sydney in the Library</figcaption>
                </figure>
                He's actually working on a PhD right now, which surprised all of us haha. He seems to want to be a PI eventually, so it makes sense. He enjoys MSK since it's not a for profit organization, and a ton of cancer researchers actually use the tools he works on. So cool. Our meeting ended up lasting like an hour, but it was probably one of the most interesting and entertaining meetings any of us have had here haha. He actually said that one of the things he didn't like about working at MSK was all the meetings he has to do since he juggles around so many projects.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Ino.jpeg")} alt="Ino" />
                    <figcaption>Ino Meeting!</figcaption>
                </figure>
                After our meeting, it was time to go home. Sanjana and Sydney wanted to buy nice shoes for tomorrow's cruise though, so we went to Designer Shoe Warehouse on 34th street, and browsed through a ton of shoes. At one point, Sanjana and I dropped our stuff back at the hotel since she had to return something to FedEx, and then we met up with Sydney again before going to Macy's.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MacysSun.jpeg")} alt="MacysSun" />
                    <figcaption>Sunset and Macys</figcaption>
                </figure>
                Sydney bought shoes, and her and I were both exhausted, so we sat down while Sanjana shopped for shoes. Sydney was a bit disappointed that Ino is pursuing a PhD, since it's not what she wanted to hear ahaha. Sydney is also considering just going into SWE. We had a nice break while Sanjana shopped before we headed back to the hotel to cook and enjoy our night.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Rice.jpeg")} alt="Rice" />
                    <figcaption>Huge Dinner</figcaption>
                </figure>
                My ground beef turned grayish brown, so I just threw it away and cooked a ton of vegetables to make up for the lost food. I had a nice, huge dinner, and now I'm full, really tired, and excited for tomorrow's cruise. Till then!
            </div>
    },
    {
        title: "Day 48: Dinner Cruise",
        date: "07/29/2022",
        tags: ["Dinner Cruise"],
        content:
            <div>
                Note: I'm putting so many pictures in this blog that there's no way I'm manually going to add them in and caption them. I instead generated the image components automatically, so the captions will just be numbers. Thank you :D.
                <br/><br/>
                <figure className="right"><img src={require("./NY_Images/IMG_5431.jpeg")} alt="IMG_5431.jpeg" /><figcaption>1</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5866.jpeg")} alt="IMG_5866.jpeg" /><figcaption>2</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5542.jpeg")} alt="IMG_5542.jpeg" /><figcaption>3</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5407.jpeg")} alt="IMG_5407.jpeg" /><figcaption>4</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5304.jpeg")} alt="IMG_5304.jpeg" /><figcaption>5</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5580.jpeg")} alt="IMG_5580.jpeg" /><figcaption>6</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5938.jpeg")} alt="IMG_5938.jpeg" /><figcaption>7</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_6007.jpeg")} alt="IMG_6007.jpeg" /><figcaption>8</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5797.jpeg")} alt="IMG_5797.jpeg" /><figcaption>9</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5426.jpeg")} alt="IMG_5426.jpeg" /><figcaption>10</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5563.jpeg")} alt="IMG_5563.jpeg" /><figcaption>11</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5332.jpeg")} alt="IMG_5332.jpeg" /><figcaption>12</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_6041.jpeg")} alt="IMG_6041.jpeg" /><figcaption>13</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5456.jpeg")} alt="IMG_5456.jpeg" /><figcaption>14</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5712.jpeg")} alt="IMG_5712.jpeg" /><figcaption>15</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5591.jpeg")} alt="IMG_5591.jpeg" /><figcaption>16</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5749.jpeg")} alt="IMG_5749.jpeg" /><figcaption>17</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5925.jpeg")} alt="IMG_5925.jpeg" /><figcaption>18</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5725.jpeg")} alt="IMG_5725.jpeg" /><figcaption>19</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5601.jpeg")} alt="IMG_5601.jpeg" /><figcaption>20</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5553.jpeg")} alt="IMG_5553.jpeg" /><figcaption>21</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_6001.jpeg")} alt="IMG_6001.jpeg" /><figcaption>22</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5284.jpeg")} alt="IMG_5284.jpeg" /><figcaption>23</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5953.jpeg")} alt="IMG_5953.jpeg" /><figcaption>24</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_6034.jpeg")} alt="IMG_6034.jpeg" /><figcaption>25</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5823.jpeg")} alt="IMG_5823.jpeg" /><figcaption>26</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5634.jpeg")} alt="IMG_5634.jpeg" /><figcaption>27</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5822.jpeg")} alt="IMG_5822.jpeg" /><figcaption>28</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5658.jpeg")} alt="IMG_5658.jpeg" /><figcaption>29</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5785.jpeg")} alt="IMG_5785.jpeg" /><figcaption>30</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5910.jpeg")} alt="IMG_5910.jpeg" /><figcaption>31</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5253.jpeg")} alt="IMG_5253.jpeg" /><figcaption>32</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5896.jpeg")} alt="IMG_5896.jpeg" /><figcaption>33</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5537.jpeg")} alt="IMG_5537.jpeg" /><figcaption>34</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5720.jpeg")} alt="IMG_5720.jpeg" /><figcaption>35</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/68088043002__A4146796-C706-498D-873E-1037E9794166.jpeg")} alt="68088043002__A4146796-C706-498D-873E-1037E9794166.jpeg" /><figcaption>36</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5393.jpeg")} alt="IMG_5393.jpeg" /><figcaption>37</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5691.jpeg")} alt="IMG_5691.jpeg" /><figcaption>38</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5644.jpeg")} alt="IMG_5644.jpeg" /><figcaption>39</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5490.jpeg")} alt="IMG_5490.jpeg" /><figcaption>40</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5886.jpeg")} alt="IMG_5886.jpeg" /><figcaption>41</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5275.jpeg")} alt="IMG_5275.jpeg" /><figcaption>42</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5760.jpeg")} alt="IMG_5760.jpeg" /><figcaption>43</figcaption></figure>
                <figure className="left"><img src={require("./NY_Images/IMG_5737.jpeg")} alt="IMG_5737.jpeg" /><figcaption>44</figcaption></figure>
                <figure className="right"><img src={require("./NY_Images/IMG_5824.jpeg")} alt="IMG_5824.jpeg" /><figcaption>45</figcaption></figure>
                I'm in the office right now, on Saturday, since I need to catch up on work. But I need to start off my day with yesterday's blog!
                <br/><br/>
                I got into the office around 9:30 yesterday morning, and Wesley scheduled a meeting so we could discuss how to wrap things up. I got some stuff cleaned up before heading into the meeting. It was nice seeing him since I haven't talked to him in so long, even though it was over Zoom.
                <br/><br/>
                We had a good meeting. He told me what types of studies and results I should have on my poster, but that means I have a lot to get in order now haha. It should mostly just be making some edits to my existing programs and running a bunch of jobs on HPC. Hopefully I can finish today.
                <br/><br/>
                He said after I leave, someone will hopefully pick up the project since we've seen that it has a lot of potential. He said if it ever becomes a paper, my name will be on it. I took that to also mean, though, that I'll be leaving after this summer haha. Makes sense though. The lab itself has to pay the intern to stay if they choose to stay during the school year.
                <br/><br/>
                I got lunch soon after. A chef's special AND a side salad. The special was very American - two hot dogs, macaroni salad, and corn on the cob. Pretty good, but way too much haha. My goal was to start working while I was eating lunch, but that didn't happen. I brought my food back to my desk and ended up taking a one and a half hour lunch break anyways haha. God damn it!
                <br/><br/>
                It was almost 2 PM when I started working on one thing, and I felt a lot of pressure since Rhea and I wanted to leave at 3 PM to get ready for the dinner cruise. Rhea got dragged into a couple meetings though, so we ended up deciding to leave at 4. But at 4, Rhea was STILL in a meeting, so Sydney and I just left before her. We considered knocking on the conference room door and saying Rhea had to leave, but she texted us to just go first.
                <br/><br/>
                We got back to the hotel and got dressed up. I had to go down to Sanjana's room to borrow her scissors since I still had the price tag on my dress shirt, but everything else was smooth. Literally just changing my shirt.
                <br/><br/>
                Then, we all met in the 15th floor kitchen area to pre-game (drink before an event haha) for the cruise. We bought peach vodka the other day, so we all took a shot. More and more people gathered with us as it was getting closer to 6 PM, the time we were planning to leave, and we in total ended up drinking 3 shots before heading on our way.
                <br/><br/>
                We took the subway heading downtown for one or two stops, and then took the bus to the pier area. Boarding was from 6:30 PM to 7:00, so we had some spare time. Several of us split off from the main group to go to a liquor store to buy more drinks before heading to the pier.
                <br/><br/>
                When we finally got to the pier, I was surprised at how big the boat was. We checked in and tried getting graduate student wristbands so we could buy alcohol, but they checked and saw that we were undergrads. I gave the lady putting on my wristband my left hand, and she told me "right hand." I asked why, and then she paused, looked me in the eye, and said in a really aggressive tone: "why?!? So the bartender can see it when you get a drink!" What an asshole. Like I understand that there are other things on my left wrist, but it's not like they make the wristband invisible. Anyways, I was pissed at her since I hate rude people.
                <br/><br/>
                So, the dinner cruise didn't start off on a bright note. My first impression upon standing on the boat was that there were way too many people. SOOO many people in the lower deck area. We quickly found out that the boat was not only just for MSK people haha. There were all sorts of normal people there that just pay to have a dinner cruise night.
                <br/><br/>
                MSK was seated on the upper deck area, but by the time we got there, Josh, Julia, Sanjana, and I didn't have a set of 4 seats to sit with the MSK people. All the seats were taken up in a way that we couldn't sit next to each other, so we asked a waiter if we could sit on the other side, which they luckily let us do.
                <br/><br/>
                We were sad some of our closer friends were separated from us, but luckily Rhea left her seat and joined us without us having to ask her to haha. We had 5 people at our table for 4, so it was crowded, but we made it work.
                <br/><br/>
                They even opened the buffet to us earlier than the rest of the MSK people! Our side got to go first, and when the CBSP people got up to get food too, the waiters told them to sit back down and wait since it wasn't their turn yet hahahaha.
                <br/><br/>
                The buffet itself was nice. All sorts of meats like chicken and beef cooked nicely, but it was all honestly just okay food. Nothing amazing at all. I don't think I would pay for that food given the choice haha.
                <br/><br/>
                After the 5 of us finished eating, we headed out to the outside deck of the cruise ship. THAT part was amazing. Inside, the views were nice because there's glass windows all around the ship, but it felt very rocky and very crowded inside.
                <br/><br/>
                But outside, we had a clear view of the Manhattan skyline, the Brooklyn Bridge, and everything around us in the water. Truly a beautiful view, and it was great being on the water not on the Staten Island Ferry this time haha. Same place, but more beautiful, and closer to the direction of the Statue of Liberty.
                <br/><br/>
                We took a TON of pictures and had a great time out there. We all broke off into our friend groups and had fun, took pictures together, and just hung out enjoying the vibes. We saw a few people got champagne, what they called "sparkling wine," so I wanted to get some too. Josh and I went to the bar, and the bartender didn't even ask us for our IDs haha. Truly beautiful.
                <br/><br/>
                Once we had our drinks and were outside enjoying the view, it really felt like we were just a bunch of rich people on a yacht haha. As day turned to night, the Manhattan high-rises all lit up in their city colors. A brilliant reminder of the beautiful looking borough we get to live in.
                <br/><br/>
                It started pouring after a bit, so we headed inside to the lower deck where they had a dance floor. I didn't know a lot of the songs, but the songs we did all know, we all got very excited for, jumping around, and having fun. I was being a dumbass and caring my champagne with me, so at one point Aziz came in and pushed me a bit, so I got champagne all over my shirt and a couple girls' dresses I don't know.
                <br/><br/>
                They were apparently very mad, but I didn't notice, so oh well haha. I said sorry! As it got closer to 9:30, we were getting sad that it was over, but also really happy about all the fun times, pictures, and memories we had.
                <br/><br/>
                No one was too drunk except for Sanjana, who was just very drunk haha. Some of us had headaches (me included), some felt dizzy, but Sanjana was pretty much the only one acting crazy haha.
                <br/><br/>
                We got off the boat, and as we walked down the walkway of the pier, some staff were wondering what we were all there for. He said it looked like a college graduation, so he asked, and we explained we were interns at MSKCC. He then said something like, "oh y'all doing something good for the world!" And then I'm pretty sure he made fun of us haha. Anyways, I didn't hear too much of what happened, but it was a fun time.
                <br/><br/>
                Some people wanted to go clubbing afterwards, so we split off in our separate groups. Those that wanted to go back could go back, and some of us went to the Chelsea clubs. There are a few bars known to let interns in (don't check ID's), but we went and they wanted to card us, probably because of how huge our group was haha.
                <br/><br/>
                We ended up going to a restaurant called The Standard or something, and at that point I was just really tired. We got sat at a big table after a little wait, since we had 11 people, and then got to start ordering stuff. Thank GOD they served us a ton of water haha. I was so thirsty.
                <br/><br/>
                Many of us didn't want to eat, so we just hung out there while the others ate appetizers and little snacks. In total, we spent $200, but I only paid $3 of it since I ate a few fries, which were $9 each. We had a smart waiter. After he took the order, he asked if we wanted fries, and one person just said, "uh, yes please!" haha.
                <br/><br/>
                Aidan felt bad we only ordered appetizers with such a large crowd, so he apparently left a large tip and wrote a note apologizing for what we did hahaha. The waiter came over after seeing the tip and said thanks to us, telling Aidan he's very kind.
                <br/><br/>
                Julia ordered an espresso martini or something like that, but she was like sleeping, so the waiter told her she can't pass out on the table if he gets the drink for her. Apparently they have some really expensive liquor license and they can't have anything happen to people they serve alcohol to. He did get it for her, but she ended up just sleeping on Arielle, so the drink got passed around and everyone had some haha.
                <br/><br/>
                After the restaurant, we all finally headed back to The New Yorker, with some of us walking, and some of us taking the subway. I took the subway with the remaining CBSP people we had, and was happy with that decision since we could get back and just relax on the 15th floor.
                <br/><br/>
                We met up with the other CBSP people that made it back earlier, and hung out before most went to bed. Sanjana, Josh, and I hung out a bit after to put some pictures together into a TikTok they wanted to make, and then we finally all went to our rooms a bit after 1. I got to bed just a bit after 1:30 and woke up at 9, so I got more sleep then I've gotten in a long time haha.
                <br/><br/>
                Last night was overall just a really fun and exciting night. Probably some of the best memories we'll have made in our time here. We all really enjoyed the dinner cruise, and the pictures we took will forever be some of the best pictures we've ever taken here haha.
            </div>
    },
    {
        title: "Day 49: Working on the Weekend",
        date: "07/30/2022",
        tags: ["Work", "Bryant Park"],
        content:
            <div>
                Today I woke up bright and early for a Saturday morning - 9 AM. I got up early since I wanted to go to work. There's some stuff to clean up, and I don't see how I'd be able to do it comfortably if I start up again on Monday haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Professional.jpeg")} alt="Professional.jpeg" />
                    <figcaption>I'm a Professional<br/>(Featuring My Tote Bag)</figcaption>
                </figure>
                I put my laptop and sweatshirt in my ICML tote bag and got going. It was the first day I didn't bring a backpack, and honestly it felt so much comfier than bringing my whole backpack. I might switch over to just my tote bag now after discovering how nice it is haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Desk.jpeg")} alt="Desk.jpeg" />
                    <figcaption>Working on Classified Information</figcaption>
                </figure>
                When I got to Joy, it was completely empty. I had to sign in on a fire safety sheet because there's some NYC law that high rises need to keep track of employees that are there after hours in case of emergency. There was only one person on the sheet before me on a different floor.
                <br/><br/>
                My floor was completely empty, so it was kind of creepy, but nice to have the entire office to myself. I quickly got to work, and honestly got a lot done. It's probably the most productive I've been in a long long time haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Coco.jpeg")} alt="Coco.jpeg" />
                    <figcaption>Coconut Noodle Soup</figcaption>
                </figure>
                The cleaning crew came in and I got to see how thorough they are with everything. They wipe every chair, every desk, all the conference room glass walls, and even the cubicle dividers and things on the ceilings. One of them also plays Latin American music pretty loudly, but the music isn't too bad, so I didn't mind.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Street.jpeg")} alt="Street.jpeg" />
                    <figcaption>Cool Street on the Walk<br/>To The Hilton</figcaption>
                </figure>
                I went to the surgery center for lunch a bit after 1:00 since I had a big breakfast (coffee and some snacks) but found out the cafeteria there is closed! That's so mean! How are the doctors and nurses supposed to get an easy meal in on the weekends!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ParkCentral.jpeg")} alt="ParkCentral.jpeg" />
                    <figcaption>My 8th Grade Hotel</figcaption>
                </figure>
                I tried looking on Google for good places to go, but didn't find much, so walked around just a bit until I saw a Thai place that seemed reasonable. I ordered a coconut noodle soup to go, and it was pretty expensive (almost $18 without tip), but tasted pretty damn good. The portion size is small but filling, so I was fine with it.
                <br/><br/>
                After lunch, I had like 3 hours left to finish everything up. I built out the remaining portions of what I needed to get done, and fleshed out the scripts I need to run over the rest of the weekend. At some point around 5:20, I heard the security guard say over our PA system (which I didn't even know existed) that Joy was closing in 10 minutes, so I was rushing to finish. The programs I scheduled to run ended up crashing, so I was trying to fix bugs until the very last minute until I decided I had to leave.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Anya.jpeg")} alt="Anya.jpeg" />
                    <figcaption>Paige and Anya<br/>From High School!</figcaption>
                </figure>
                I went to the bathroom, and when I came out, I saw the security guard and he said he was looking for me. It's the same guy that got mad at me for scanning Rhea in the other day haha. I left and got on my way back to the hotel.
                <br/><br/>
                But! Anya texted me an hour or two before I left Joy, saying she was 1 mile away from me. I told her I was at work. I didn't know how I would be able to see her, but I realized the train I was taking was heading her direction, just a couple streets and avenues over, so I got off early.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Pho.jpeg")} alt="Pho.jpeg" />
                    <figcaption>Pho Selfie!</figcaption>
                </figure>
                I walked around and saw some nice streets, and even the hotel I stayed at on our 8th grade DC trip! I didn't realize that hotel was just right there in Manhattan haha. What a crazy experience to see it again. I forgot what it was called, but when I saw the entrance and the name, I immediately recognized it. Must be an expensive place to stay at!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/HellsKitchenArea.jpeg")} alt="HellsKitchenArea.jpeg" />
                    <figcaption>Hells Kitchen (The Neighborhood)</figcaption>
                </figure>
                I walked over to the Hilton Anya was staying at (for an event to prep for their Model UN conference), and saw her and Paige in the lobby. We talked for a bit, them asking me what I've been up to, and me asking them how they've been. It was nice to see some people I know in NYC haha. Kind of crazy that they're the first people I know from elsewhere, and I didn't even accidentally meet them haha.
                <br/><br/>
                The nice thing about that pit stop was that there's a downtown bound E train right outside the hotel, so I walked a block over and got to take a train that goes directly to the intersection outside The New Yorker.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Seat.jpeg")} alt="Seat.jpeg" />
                    <figcaption>A Cool Bench</figcaption>
                </figure>
                I worked for just a bit more at The New Yorker, fixing my bug and resubmitting jobs before meeting up with Sanjana and Arielle in the lobby at 7:20 to go to dinner. We didn't plan where to go, and decided to go get pho at a place Arielle said was highly rated on Google.
                <br/><br/>
                We debated whether we should take the subway or walk there (it's up on 50th street or something), but I said we should take the subway there and walk back since we needed to walk off the pho.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SeatPointFive.jpeg")} alt="SeatPointFive.jpeg" />
                    <figcaption>0.5x Pic On the Bench!</figcaption>
                </figure>
                We got on our way, just two stops northbound. The area there is so much nicer than the area we live in. It's so crazy how fast Manhattan changes when you move down just a few streets ahaha. 34th street, where we live, is ridiculously busy.
                <br/><br/>
                The pho place is a nice, small shop that feels enclosed and high end. It's a nice, peaceful retreat from the outside world. We waited maybe 10 minutes before getting seats (it's a popular place), and enjoyed our pho as we talked about what we've been up to, our labs, and other people in the program.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Fountain.jpeg")} alt="Fountain.jpeg" />
                    <figcaption>Fountain Selfie!</figcaption>
                </figure>
                After dinner, we walked to an Insomnia Cookies, which according to Arielle, is a very popular chain that doesn't have many locations in California. There's an Insomnia Cookies near UCLA, so she said I'm very lucky haha. I told her I've never even gotten those cookies since I just get the ones from Diddy Riese.
                <br/><br/>
                We were in the Hells Kitchen area of Manhattan, and we decided o walk over to Bryant Park to see if there was a place we could enjoy the night. We also wanted to use the bathrooms there, since they're apparently really fancy.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Bryant.jpeg")} alt="Bryant.jpeg" />
                    <figcaption>Bryant Park!</figcaption>
                </figure>
                When we got there, there were rats running across the park perimeter, so Sanjana was really creeped out. We got in line for the bathroom, and I was pleasantly surprised to see that it was actually really clean and beautiful. It feels like a palace inside the little bathroom. Classical music is playing, and there's a tree at the entrance. They even have staff that let people into the bathroom when it's their turn, so they can make sure it's not too crowded inside.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BryantRest.jpeg")} alt="BryantRest.jpeg" />
                    <figcaption>Bryant Park Restroom</figcaption>
                </figure>
                The problem with that is they were taking FOREVER to let the girls line move, so I came out and waited with Sanjana and Arielle, seeing like at least 50 men use the bathroom while the women's line didn't even move. It was insane haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Biao.jpeg")} alt="Biao.jpeg" />
                    <figcaption>Milktea!</figcaption>
                </figure>
                We tried finding a dessert place after we finished up, and ended up going to a milktea place (Biao Sugar or something) in KTown before heading back to the hotel. Overall a very good night, and my brown sugar ube milk drink was delicious!
                <br/><br/>
                We hung out for a bit with Aziz on the 15th floor before heading our own ways and relaxing for the night. I found out that the two girls I spilled champagne on last night on the dance floor were actually really pissed at me, which is unfortunate, but oh well. Accidents happen! 
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Ube.jpeg")} alt="Ube.jpeg" />
                    <figcaption>My Drink</figcaption>
                </figure>
                One of them got really excited last night when they played a Punjabi song last night, and Arielle actually knows the chorus of the Punjabi song even though she has no idea what it means haha. She impressed Aziz with a little performance tonight haha.
                <br/><br/>
                I'm now pretty tired, so I'll head to bed soon. Several of us are planning on going to the Central Park Free Boating event tomorrow EHS is hosting, so that should be fun!
            </div>
    },
    {
        title: "Day 50: Rowing Through the Park",
        date: "07/31/2022",
        tags: ["Central Park", "Rowing"],
        content:
            <div>
                I woke up bright and early at 8:50 AM by accident. Just completely natural, so I tried going back to sleep for just a bit until realizing I should just wake up when I was feeling refreshed. I got off the bed, got ready for the day, and went down to the 15th to check on the progress of my HPC jobs. Turns out they crashed, but I found the bug since it was dumb and fixed it for proper running now. Should have results tomorrow, so I can get started on my poster!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Filet.jpeg")} alt="Filet.jpeg" />
                    <figcaption>Big Fish</figcaption>
                </figure>
                The 4 of us (Sanjana, Julia, and Josh) were supposed to go get brunch at Skylight Diner on 34th Street this morning, but Josh was in Brooklyn playing soccer as usual on Sundays, and Julia was probably still sleeping. So, Sanjana and I went. I got a MASSIVE fish filet that was probably one of the best meals in NYC, and Sanjana got a chicken on a baguette, but her parents were calling the whole time since they need to get boosters for their Barcelona vacation after NYC.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ElevatorStuck.jpeg")} alt="ElevatorStuck.jpeg" />
                    <figcaption>Stuck in the Elevator!</figcaption>
                </figure>
                We finished brunch close to 12, but EHS was meeting at 12 to go to Central Park,. so we rushed back to the hotel. Once we got ready and met them on the 15th floor, they were still there (they tend to leave 10 minutes late just so people have time to arrive), so we were relieved.
                <br/><br/>
                The 11 of us (including the RA) couldn't fit on the partially filled elevator that came down to us, so Chloe (Sanjana's finance friend), Ivory, Sanjana, the RA, and I went on a different elevator down. As we were going down, the elevator suddenly dropped fast (for less than a floor) and jolted to a stop. We didn't realize what was happening for a second, but then we all got really scared. The elevator said we were on the 2nd floor, then changed to showing that we were on the 5th floor.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CP_Selfie.jpeg")} alt="CP_Selfie.jpeg" />
                    <figcaption>Walking in Central Park!</figcaption>
                </figure>
                Chloe immediately pressed the emergency button, and we were connected to security who responded extremely quickly. They got our details, asked who we were, where we were, and sent security people up to talk to us from outside the doors.
                <br/><br/>
                They tried resetting the elevator, but when we clicked the open doors button, it still wasn't working. They had a security guard talking to us from outside, and our RA said that specific security guard was really nice, so I felt relieved. I was still really scared. I remember grabbing onto Ivory when the elevator started dropping, just as a knee-jerk reaction haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Lake.jpeg")} alt="Lake.jpeg" />
                    <figcaption>The Water</figcaption>
                </figure>
                Half the elevators at The New Yorker have just never worked in our time here, so they don't have a good reputation with elevators haha. The elevator was getting warm, but we had water and some food, so we were ready to spend some time there if we had to.
                <br/><br/>
                At some point, the security guard warned us that we might start moving, and we were scared that the elevator would move up and just drop all the way down. Not long after, we heard some noise and we started to rise. We rose to two digit floors, past the 15th, and we were panicking all the way until we got on the 17th floor and the doors opened.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/OnTheWater.jpeg")} alt="OnTheWater.jpeg" />
                    <figcaption>On the Water!</figcaption>
                </figure>
                We all ran out, and we shouted at a girl walking in to not take that elevator. We definitely saved her so much trouble haha. After all that, we were finally on our way to Central Park. We walked through beautiful nature-filled areas that were more peaceful than many other parts of the park and got to the water area where the boats were.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MeRowing.jpeg")} alt="MeRowing.jpeg" />
                    <figcaption>Row, Row, Row Your Boat</figcaption>
                </figure>
                The line was kind of long which was annoying, but at least we could hang out while we waited. Boat rentals turn out not to be free, but EHS covered it for us thank God. What a good deal for us haha. Josh, Julia, and I took a boat while Sanjana, Ivory, and Chloe took the other boat. The boat guy asked who was rowing, so I volunteered myself, and we got on our way.
                <br/><br/>
                I really like rowing haha. Really fun and we got around pretty fast. I think I'm a pro at rowing since we watched so much rowing on TV during the Summer Olympics. The other boat was going nowhere, which was pretty funny haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CPView.jpeg")} alt="CPView.jpeg" />
                    <figcaption>The Walk to the Water</figcaption>
                </figure>
                We got around the lake and chased some ducks, trying to pet them. There were turtles in the lake too! We eventually settled under a bridge to get some rest in the shade, and then Julia and Josh both had a turn rowing as we headed back. Julia seemed to have more fun being faster than the other boat than doing the actual rowing itself. She makes everything a competition haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CPWalk.jpeg")} alt="CPWalk.jpeg" />
                    <figcaption>More Central Park Views</figcaption>
                </figure>
                We got off the water pretty quickly since the other boat's people didn't enjoy it that much, and we walked to a place about 20 minutes away near Columbus Circle that sold Korean Corndogs. They all got something to eat since no one ate anything big before that except me, and then we headed back.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BoatGroup.jpeg")} alt="BoatGroup.jpeg" />
                    <figcaption>Our Boat Group!</figcaption>
                </figure>
                In the subway back, there was a guy wearing a UCSB shirt, so I asked if he went there, but he didn't. His Mom went there though haha. Sanjana, Josh, Julia, and I hung out on the 15th floor for a bit after getting back, and then played 2v2 chess (Josh and Sanjana against us).
                <br/><br/>
                We all eventually quit since the game was going too slow haha. While we were just sitting there after the game, Sanjana was talking about her old apartment in Martinez and how there's a huge window into People's Park. I told her Dude had the same huge window there, and then she was wondering if it was the same room.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/FountainPic.jpeg")} alt="FountainPic.jpeg" />
                    <figcaption>Fountain Picture!</figcaption>
                </figure>
                I started describing the way you get to Dude's apartment, and she literally had the same exact way to get to the room. The corner room at the very end of the hall with 5 rooms instead of the usual 4. And on the second floor!!! AND THE ROOM SANJANA HAD IN THE APARTMENT IS THE SAME ROOM DUDE HAD. HOW INSANE IS THAT! Sanjana said she's always wanted to know who lived in her apartment before her hahaha. That is so insane.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Exercise.jpeg")} alt="Exercise.jpeg" />
                    <figcaption>Exercise!</figcaption>
                </figure>
                Sanjana and I went to HMart to get groceries, and then I started cooking with Josh. Had a nice dinner with them, and we just spent the night during our usual hanging out. Overall had a very relaxing weekend which was nice. There's just one weekend left in NYC now! How sad!
            </div>
    },
    {
        title: "Day 51: Working Extra Hard",
        date: "08/01/2022",
        tags: ["Work"],
        content:
            <div>
                Today I got up at 8:30 since I went to bed pretty late last night. Got ready for the morning and headed straight to work. I don't want to get used to drinking coffee every morning, so I got Devonshire English Breakfast tea this morning. I added a couple half and half's and a chocolate shot from the coffee machine, and basically had myself a little fake milktea. It was honestly pretty good haha. Will probably make one every day.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoyMilktea.jpeg")} alt="JoyMilktea.jpeg" />
                    <figcaption>Officemade Milktea</figcaption>
                </figure>
                We had an event at 12 to 1 and from 1 to 2, so I didn't want to eat any real food for breakfast (so I had room for early lunch). I checked the status of my jobs on HPC, and saw that they all crashed. What an unfortunate way to start the day haha. I ran them again, this time with 2 or 4 times more memory allocated, so hopefully they don't crash anymore.
                <br/><br/>
                I didn't have much else to do, so I sent out the email asking MSK summer interns to contribute to this year's newsletter. Ushma had a very positive response, saying she was "grinning from ear to ear" haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MainHospital.jpeg")} alt="MainHospital.jpeg" />
                    <figcaption>Today I Overheard Who I Think<br/>Is a PI Tell a New Hire<br/>"The Main Hospital is Worth<br/>A Visit, But It's Not That Nice.<br/>The Research Buildings Next<br/>To It Are Beautiful Though"</figcaption>
                </figure>
                I wasn't sure if it was a good time to send the email out, since we just got notification from Romina that someone on the dinner cruise tested positive for COVID (I've known since Saturday though because Mitchell talked to him on Friday). But I decided to send it anyways since people need time to consider writing a piece and actually writing it up.
                <br/><br/>
                Shortly after that, I headed on my way to the main hospital to have lunch with Sanjana before going to Ushma's talk on grad school personal statements. I want to stop eating at the cafeteria haha. It was so BAD! The steak was tasteless, they gave me way too much quinoa, the quiche had CINAMMON of all things for some reason, and the beef empanada was just not great. THey don't treat their patients well I guess haha! At least employees get a discount there (but my lunch was $11 today so I don't even think they gave me the discount).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/HospitalFood.jpeg")} alt="HospitalFood.jpeg" />
                    <figcaption>Nasty Hospital Food</figcaption>
                </figure>
                We ran over to the Zuckerman auditorium right after that to hear Ushma's talk. Ushma had slides for her presentation on how to write a good personal statement, and she said she'd only send the slides to us to reward the people who show up. How nice of her haha.
                <br/><br/>
                The overall session was pretty good in terms of hearing the perspective of someone that screens applications for things like MSK grad school stuff and the Marshall Scholar, so I was happy that I went.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/UshmaUCLA.jpeg")} alt="UshmaUCLA.jpeg" />
                    <figcaption>Ushma Has a Slide Showing<br/>UCLA PhD Program Prompts<br/>As An Example!</figcaption>
                </figure>
                Right after that, there was a grad school panel of people currently doing a PhD in one of the three Tri-I institutions, and that honestly wasn't too interesting haha. Maybe it's because my attention span was taken up by Ushma's presentation. It was cool to get to know the PhD students and to hear their perspectives though. Definitely think it was worth going to both.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SydneyPod.jpeg")} alt="SydneyPod.jpeg" />
                    <figcaption>Sydney in a Meeting</figcaption>
                </figure>
                Tomorrow, CBSP has an ice cream mixer with the CBM (Computational Biology and Medicine) PhD students. It's a PhD program in the Tri-I that I think CBSP people should have a relatively easy time getting into just because we're in CBSP. It's also such a cool program. Being a program under Tri-I and not one particular institution, you can take classes from anywhere. It's still cool even though classes are a very very small part of the first year of PhD.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoyWesleysDirection.jpeg")} alt="JoyWesleysDirection.jpeg" />
                    <figcaption>Wesley's Side of The Second Floor</figcaption>
                </figure>
                Quickly got back to work after the presentation, and finally started thinking about what to put on my poster. I outlined, in my notebook, what type of stuff I want to put on my poster, and thought hard about how to make it a visual presentation as much as possible. I don't want to have a lot of text on the poster, which is easy to do in a structural biology lab where all your results have to do with visualizing small molecules (like my poster at the NIH last year), but it's just slightly harder with my current project.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Roose.jpeg")} alt="Roose.jpeg" />
                    <figcaption>Roosevelt Island View</figcaption>
                </figure>
                Then, I finally started working on my poster! I fleshed out the intro section, which is actually a methods section now that I think about it. It includes info on the data we have, and what we did with that data. I just realized, while writing this, that I need to add in an actual intro section outlining the purpose and background of the project haha. Noted!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SurgerySeats.jpeg")} alt="SurgerySeats.jpeg" />
                    <figcaption>Beautiful Surgery Balcony</figcaption>
                </figure>
                I wanted to get something substantial done in terms of my poster today, so I ended up staying at work till around 7 PM. Sanjana stayed late too, so we ended up going to the surgery center to get dinner instead of going back to The New Yorker and cooking. I didn't really want to cook because of how late it was, so it was nice to be able to get dinner out of the way early.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SurgeryBalcony.jpeg")} alt="SurgeryBalcony.jpeg" />
                    <figcaption>Balcony Selfie!</figcaption>
                </figure>
                After dinner, I came back, and now I'm on the 15th blogging and relaxing. It seems that everyone is busy working on their stuff now haha. Sanjana will come soon and also work on her poster since she's spending a lot of tomorrow shadowing a doctor. Josh and Julia have already shadowed doctors here, since they're all interested in medical degrees. It's cool to see so many CBSP and summer students in general are interested in medical school instead of PhD haha. No one feels strongly about pursuing an MD PhD, but Arielle and Julia are considering it.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoyToday.jpeg")} alt="JoyToday.jpeg" />
                    <figcaption>I Love Joy</figcaption>
                </figure>
                My goal for tomorrow is to finish my abstract and poster so Wesley can review it on Wednesday (and then I'll have a bit of time to edit it). Really pushing things with how last minute is going haha. Pray that my jobs don't crash and I have my final results by tomorrow!
            </div>
    },
    {
        title: "Day 52: Ice Cream for Dinner",
        date: "08/02/2022",
        tags: ["Work", "Cornell"],
        content:
            <div>
                Today is August 3rd, but I'm writing yesterday's blog since I stayed up till 2 AM doing work with several other people haha. Here it goes!
                <br/><br/>
                Yesterday, I woke up bright and early at 8 AM, left at around 8:25 AM, and got to work by 8:55 AM. I can't believe my commute is like 25 minutes on a good day. It really doesn't feel that long haha. I enjoy the walk and the subway ride, but I won't miss it when it's over haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CornellBuilding.jpeg")} alt="CornellBuilding.jpeg" />
                    <figcaption>A Weill Cornell Med School Building</figcaption>
                </figure>
                Getting to work before 9 AM is so motivating. I feel like I have a long morning to work away, and I got started on my poster right away. Ran some jobs since I really needed to get my stuff together for the poster, and thought more about how I want to structure my poster. I don't really remember what else I did in the morning. All I remember is feeling really busy but laser-focused on getting some actual results.
                <br/><br/>
                I went to lunch at the surgery center around 1 PM - a late lunch because I had a lot of bugs I caught and last-minute changes in my programs I needed to make. It was the first day in a while I was going to lunch myself, and I was just thinking that when I heard "Kyle!". Someone calling my name! I turned around and saw it was Rhea, who also just happened to come to lunch late. She was apparently working in the Joy basement since she doesn't like staying at her desk all the time haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WeillSocial.jpeg")} alt="WeillSocial.jpeg" />
                    <figcaption>Ice Cream!</figcaption>
                </figure>
                We got lunch together and were talking about our labs when I thought I saw her mentor. I told her to look, and when she did, she dropped some chicken on her white pants, so they got stained because of me. This isn't even the first time I got something of hers dirty. At the networking event a week or two ago, I dropped a chicken wing on her Stanford tote bag, but she wasn't upset about it haha.
                <br/><br/>
                We determined that it wasn't her mentor, and got back to our own business, when I looked up again and thought I saw her mentor again. Except this time it was actually her mentor, so I kept telling her to look!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/WeillIceCream1.jpeg")} alt="WeillIceCream1.jpeg" />
                    <figcaption>My Order</figcaption>
                </figure>
                Her mentor, Ignacio, was actually there this time. And he saw us, so he got his food and asked if he could join us. Of course we let him. Ignacio asked what my name was since he forgot. CBSP has had several events with him already (journal club, the VR event, and just seeing him around Joy on our floor), but I haven't really personally talked to him too much.
                <br/><br/>
                We talked about what we wanted to do after college, and he talked about his time here at MSK. He's a postdoc here, so he's really just getting himself established in science and it seems like he wants to eventually wants to become a PI. He's a really nice guy and it was fun to talk to him, so I'm glad I ended up going to lunch late yesterday.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WeillIceCream2.jpeg")} alt="WeillIceCream2.jpeg" />
                    <figcaption>Some Toppings!</figcaption>
                </figure>
                We all walked back to Joy, and I got back to working on my poster and submitting more jobs. It's kind of insane how many changes I made in just these past two days. It almost feels like my project could've been done in 3 weeks haha. It just took so much time to get up and running, which seems to be the experience for everyone else too.
                <br/><br/>
                At around 4:45, the CBSP people who work in Joy all got on over to Weill Cornell's Faculty Club. We had an ice cream social planned with CBM, some of the Computational Biology and Medicine PhD program students.
                <br/><br/>
                The faculty club is SOO nice. There's literally a bar, fancy seating areas, and ballrooms. We were in a small ballroom type of facility, and there was a guy serving us all kinds of ice creams. They had a bunch of those little Haagen-Dazs cups we get at the business center back at home but different flavors (like dulce de leche). I ended up just getting the regular ice cream from a big container.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Cornell.jpeg")} alt="Cornell.jpeg" />
                    <figcaption>The Entrance to The Building</figcaption>
                </figure>
                I asked how many scoops we could get, and he said however many we wanted, so I got a chocolate, vanilla, and mango sorbet scoop. They had caramel, strawberries, bananas, animal crackers, and all sorts of stuff on the side. It was SOOOO damn good haha. Nice way to end a long workday.
                <br/><br/>
                The actual CBM students were cool too. They did a good job of getting nice and talkative people. I spent some time just talking to CBSP kids until I eventually started talking to a CBM person who went to Duke and is originally from Texas. She actually used to live in California since she worked on healthcare technology for Apple in San Francisco after her undergrad in CS.
                <br/><br/>
                She worked in tech for 3 years until deciding to come here to do a comp bio PhD. She said after three years, she realized that she didn't want to sell her soul anymore haha. She said she learned a lot working in the same team at Apple, but eventually the learning plateaus and you end up becoming a manager where you don't even work on the actual tech anymore.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WeillYutaro.jpeg")} alt="WeillYutaro.jpeg" />
                    <figcaption>Yutaro, Aziz, and I</figcaption>
                </figure>
                It was nice to talk to someone who did CS and went into tech before coming to a PhD. This and the meeting with Ino have really helped me give perspective on whether doing a PhD in comp bio would be the right thing for me. From what I can tell right now, I haven't really found a field within comp bio I truly enjoy, but I do like the idea of working in industry research since it seems like researchers are always learning, and industry can provide a comfortable work-life balance with a good salary.
                <br/><br/>
                Ushma came to the ice cream social for just a bit of time, but I didn't get the chance to talk to her since I was talking to Dipthya (I think that's how you spell it) for so long haha. Ushma did indirectly talk to me by sending an email out sharing some slides on her personal statements talk, and adding in that she hopes everyone is rushing to help Kyle, Liz, and Casey on the newsletter! Anyways, I joined another group and talked to another CBM student who said she plays tennis on Roosevelt Island!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/WeillBar.jpeg")} alt="WeillBar.jpeg" />
                    <figcaption>A Fancy Bar</figcaption>
                </figure>
                Oh! I was talking to Dipthya about Wesley's lab and she said that a CBM student she knows did a rotation in Wesley's lab, and said he was intense haha. I was happy to hear that I'm not the only one that thinks this stuff is way over the top of my head haha. Dipthya herself was invited to go to Wesley's reading groups (our journal clubs), but she said she didn't go because she thinks it'll be way too much for her haha. I told her I have to go every week!
                <br/><br/>
                After the ice cream social, we all headed back to The New Yorker. I cooked a TON of food, ate it all, and Sanjana, Josh, Aziz, and I spent some time hanging out before getting to our nightly routines and coming back down to work.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SoMuchFood.jpeg")} alt="SoMuchFood.jpeg" />
                    <figcaption>Huge Dinner For Myself</figcaption>
                </figure>
                Wesley responded to a message 6 hours after I sent it today, which is out of character for him. He said he had a crazy day haha. His message made me feel like I had a lot to do. Luckily, Sanjana, Sydney, Josh, Julia, and Yutaro were all here on the 15th working away on their posters too.
                <br/><br/>
                We're all in different places. Sanjana had a good draft done and was writing her abstract, Sydney was tidying up her poster, Josh was working on his poster, and Julia, Yutaro, and I didn't even have our results yet ahahaha. It's relieving to know we're all working last minute.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/LateNight.jpeg")} alt="LateNight.jpeg" />
                    <figcaption>Late Night Working!</figcaption>
                </figure>
                It's getting to a point in time where my programs just wouldn't finish running in time if I ran them serially, so I put in some minimal effort to parallelize some programs that could be parallelized. I wish I would've done that earlier in the program, since I can just get my results back so much faster haha.
                <br/><br/>
                I worked on my programs, saw them crash, debugged, and repeated the cycle probably 10 times until like 2 AM when I went to bed. Josh and Sydney left a bit earlier, but Julia and Yutaro stayed up till 3. I saw that some of my programs worked, so I got to bed praying that I would have results this morning.
            </div>
    },
    {
        title: "Day 53: Working For 3 Weeks",
        date: "08/03/2022",
        tags: ["Work", "Ktown"],
        content:
            <div>
                I wanted to get at least 6 and a half hours of sleep, but Mitchell's alarm went off at 8:00 AM so I decided to get up. I fell back asleep unintentionally but woke up again at 8:10 since Mitchell's alarm rang again. Got ready and got to work before 9 AM again!
                <br/><br/>
                I checked my jobs and saw that a couple of them crashed. So sad! But! They crashed at the last step, so I knew that with some minor edits, I'd have my results today! Very encouraging! I sent Wesley some bad results, and he said it looked like a bug I had earlier when I was doing some dumb crap, so I fixed it up and have some sensible results now.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Working.jpeg")} alt="Working.jpeg" />
                    <figcaption>The CBSP Interns of Joy</figcaption>
                </figure>
                So relieving to see that I actually had some results. Before lab meeting, I sent Wesley my poster without results just so he could review everything else. He corrected one of my citations and told me to include some plots that I didn't have yet, and that was it. It seems that the PI's probably don't care too much about the summer student posters since we're not actually doing anything professional haha.
                <br/><br/>
                We got notification that someone from the ice cream social yesterday tested positive, so I told Wesley I would just Zoom into our lab meeting today. It was nice to eat my free Sweetgreen at my desk haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoyWorking.jpeg")} alt="JoyWorking.jpeg" />
                    <figcaption>Joy Selfie!</figcaption>
                </figure>
                After the lab meeting, I was formatting things on my poster and adding in my new results. It's really starting to come together. I asked Romina for an extension since Sydney told me she got one, so now I have until Friday morning instead of Thursday noon to submit. I think I'll still be able to submit my poster on time tomorrow though! Let's hope that's true!
                <br/><br/>
                Overall a very happy day since I have everything I need to finish my poster. Jaden, Rhea, Sydney, Zhiyue, and I (everyone on the 2nd floor of Joy) were all very focused haha. We're all scrambling to get our posters in, but we still made time to talk and just hang out for a bit while we were all in the office.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/KtownThing.jpeg")} alt="KtownThing.jpeg" />
                    <figcaption>Some Popup Thing in KTown</figcaption>
                </figure>
                Sanjana and I were leaving work at the same time, so I walked over to the Greenberg buliding and we headed back on our way. Stopped at Target to get my much needed Miralax, and then we went to BCD Tofu House in KTown since I've been to BCD in LA and wanted to see how different it is in Manhattan.
                <br/><br/>
                The one here feels a bit smaller, but is definitely livelier. Maybe it's just because this one is during the summer in Manhattan, and I went to the one in LA on a school night if I'm remembering right haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SoonTofu.jpeg")} alt="SoonTofu.jpeg" />
                    <figcaption>A Very Okay Dinner</figcaption>
                </figure>
                I tried ordering a chardonnay, but they said they didn't have any left and asked if I wanted the other type of wine instead. I said no haha. Just wanted to know if they would card me, but I guess I'll never find out!
                <br/><br/>
                After dinner, we did some shoe shopping since Sanjana wants to have nice shoes for the poster presentation, and then we got back and had a normal night. Now need to just work on my poster and hopefully get everything done for tomorrow!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/PingPong.jpeg")} alt="PingPong.jpeg" />
                    <figcaption>EHS Set Up Nice Events<br/>Since It's The Last Week<br/>Of The Summer Rent</figcaption>
                </figure>
                I won't have too much to do after the poster is done. Probably will just focus on cleaning up the code so whoever hopefully picks up the project in the future has a good place to start.
            </div>
    },
    {
        title: "Day 54: Posters",
        date: "08/04/2022",
        tags: ["Work", "Poster"],
        content:
            <div>
                Last night, I got to bed around 12:15 AM. Probably one of the earliest nights here in a long,long time. I wanted to go into work early today since our abstracts and posters were due at 12 PM noon, so I needed a longer morning to tidy things up.
                <br/><br/>
                I got up bright and early at 7 AM and was on the subway by 7:35, meaning I got to Joy before 8 AM! How responsible of me! No one was in the office. All the lights on the second floor were off. Both the right and left side of the elevator were dark. It was nice to see and nice to feel like I had the whole floor to myself again.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MorningNYC.jpeg")} alt="MorningNYC.jpeg" />
                    <figcaption>Early Morning NYC</figcaption>
                </figure>
                I immediately got started updating my results. I had a few plots that finished generating last night, so I got them organized onto my poster and sent it over to Wesley. I didn't think Wesley would actually have a lot to say, since I sent him the basic structure a day or two ago and he didn't really have any comments.
                <br/><br/>
                He had a lot of comments, so I felt like I fell behind a lot haha. I was pretty much ready to submit it at that point, but I had to rerun my stuff since my old results didn't save properly, so I couldn't just edit the plots like I needed to.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MorningSubway.jpeg")} alt="MorningSubway.jpeg" />
                    <figcaption>Empty Morning Subway!</figcaption>
                </figure>
                It was almost noon, but luckily I have an extension till Friday morning, so I stopped working on the poster to work on my abstract a little. Made some last minute modifications before sending it to Wesley, who only had a small suggestion to make before I submitted it.
                <br/><br/>
                At that point, it was almost 11:50, so Zhiyue and I ran over to Zuckerman since we had a talk from Christina Leslie on the Computational Biology and Medicine PhD program. Zhiyue told me she was scared of running since she doesn't have insurance here, and I told her I don't either haha. She said nothing can happen to her right now since she can't financially defned herself. That is sad haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/EmptyOffice.jpeg")} alt="EmptyOffice.jpeg" />
                    <figcaption>Empty Office!</figcaption>
                </figure>
                We got to ZUckerman and realized there were like 5 CBSP people there total. People just didn't show up for this event haha. I ended up not paying much attention anyways, since I was editing scripts and running jobs to get my updated plots in time. The few times I did pay attention, I felt like the info we learned is mostly just public info that we could find by looking it up online.
                <br/><br/>
                After the talk, Sanjana and I went to Two Wheels, since we bumped into Julia and Yutaro, who were just coming back on the way from there. I got a Vietnamese sandiwch, which I realized I haven't gotten in SO long. It wasn't that good, since the bread was super hard, but it's okay haha. At least it's not hospital food for today.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/YutaroJulia.jpeg")} alt="YutaroJulia.jpeg" />
                    <figcaption>Accidental CBSP Reunion!</figcaption>
                </figure>
                I had to walk back to Joy after lunch, and it so damn hot - like 90 degrees Fahrenheit hot! By the time I got back to my desk, I was literally dripping with sweat. So uncomfortable, so it's good that Joy literally always has their AC on to make it like 60 something degrees inside.
                <br/><br/>
                I got pretty stressed when I got back to work since the heatmaps didn't generate right. For some reason, my old heatmaps were being stretched to a ridiculous size, so I needed to adjust the font to account for that, but this time the plots shrunk back to what I used to expect. I realized I probably just wasn't closing old figures before making the heatmaps, so I fixed that up and resubmitted my jobs.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Sandwich.jpeg")} alt="Sandwich.jpeg" />
                    <figcaption>Sandwich. Not Worth It!</figcaption>
                </figure>
                I did a few rounds of this editing and resubmitting before finally knowing that I would have proper plots, and I got on my way out of work around 6:00 after getting results back for two jobs and seeing that my other one would probably run later in the night.
                <br/><br/>
                I could see that the subway station I was taking was absolutely packed with people. Never before have I seen everyone lined up along the station from one end to the other at Lexington and 59th, since there's usually a high volume of trains that pass through there.
                <br/><br/>
                I took it to mean a train would be coming very soon, but I ended up spending 30 minutes just standing there, seeing nearly 10 northbound trains come by while we all stood there waiting for a southbound train.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Mian.jpeg")} alt="Mian.jpeg" />
                    <figcaption>Very Big Dinner!</figcaption>
                </figure>
                I didn't mind standing there too much since I was just listening to music and taking in all the details of the subway, but it was just too damn hot and I was getting too excited to step into the cool, air conditioned subway.
                <br/><br/>
                Never have I been so relieved to see a train as I was today, when I could finally head back to the hotel. When I got back, I cooked up pretty much everything I have left. Unfortunately, the bok choy leaves turned gray, so I threw most of those away, keeping the white stalk part since that still looked fresh.
                <br/><br/>
                I'm trying to use everything up, so I washed my garlic jar and poured all the remaining minced garlic into the pan. I usually can't taste the garlic in any of my food, but today my noodles actually tasted a lttle garlicky which was nice haha. Probably the tastiest meal I've had here since I threw so much crap into it.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Croissant.jpeg")} alt="Croissant.jpeg" />
                    <figcaption>Sydney Heating Up a Croissant</figcaption>
                </figure>
                Sanjana was around while I cooked and ate, and Mitchell was there for a bit too. Them, Aziz, Sydney, and I hung out for a bit after I finished dinner since we all happened to be on the 15th floor.
                <br/><br/>
                I went back to do my nightly routine, and then got my final results, threw it on my poster, sent it to Wesley, and hoped that I would get a response tonight. Wesley was online on Slack for just a bit of time but went inactive again, so I just sent my poster to Romina with a heads up that I might send her an updated version before 10:30 AM tomorrow.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/TVRoom.jpeg")} alt="TVRoom.jpeg" />
                    <figcaption>TV Room Selfie</figcaption>
                </figure>
                If I don't hear back before then from Wesley, I'll just go with the poster I sent Romina since there can't be anything too drastic that Wesley wants to change (at least I hope so haha). A lot of last minute work around here!
            </div>
    },
    {
        title: "Day 55: A Long and Fun Day",
        date: "08/05/2022",
        tags: ["Zuckerman", "Roosevelt Island", "The Pit"],
        content:
            <div>
                Today I woke up at 9:45 AM. So late! I went to bed at 3 AM since I stayed up with Arielle, Josh, Julia, and Yutaro. We were talking in the TV room for hours while Arielle worked on her Fulbright application.
                <br/><br/>
                We talked a lot about school, med school, grad school, and things like that. I'll miss this a lot haha. A group of friends that can have fun, even when talking about serious stuff like this. Really probably some of the best people I could've ever met. Sometimes I think I should've tried to work in industry, but every time I always think about how these types of programs are really the only place I can actually meet such smart and cool people.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MattoBreakfast.jpeg")} alt="MattoBreakfast.jpeg" />
                    <figcaption>Free Breakfast</figcaption>
                </figure>
                I went poo this morning as usual, but ran out of toilet paper while I was wiping my ass. I tried calling Mitchell, but he was still sleeping at the time (about 10:10 AM), so I had to get up and get the toilet paper myself (which is literally next to my desk outside the bathroom). What an experience.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BigPizza.jpeg")} alt="BigPizza.jpeg" />
                    <figcaption>Way Too Much Food<br/>(I Gave The Garlic Rolls<br/>To Arielle)</figcaption>
                </figure>
                I went to work with Julia in the morning a bit after 10:30, and we stopped at Matto near Zuckerman so I could get breakfast and Julia could get a coffee. We were talking about how we were starving while we were on the subway, so Julia said we could go to Matto and she would buy me breakfast there if I went with her. Of course I can't say no to a free breakfast! Plus, Julia is rich so I don't feel bad :D Everything at Matto is $2.50 which is really nice.
                <br/><br/>
                Now I'm working in Zuckerman on the 11th floor where Dana Pe'er's lab since Julia works on this floor. I don't really have much else to do. I have to clean up my code, work on the newsletter, and wrap up my hackathon project, but today will be a day to relax.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/CoffeeBreak.jpeg")} alt="CoffeeBreak.jpeg" />
                    <figcaption>Coffee Break In New Shirts</figcaption>
                </figure>
                I sent Wesley my updated poster last night at 10:30, but he still hasn't responded, so I told Romina that the poster was my ready-to-print final version haha. If Wesley gets upset, oh well. That is entirely my fault for doing this so last minute haha.
                <br/><br/>
                But in my defense, Julia and Yutaro still don't have posters haha. They're both just paying the $50 to print it out late. Arielle was still working on her poster last night too. Julia's and Arielle's PI's are covering their late fee for them. I'm just too scared to ask Wesley since I don't want to feel more like a liability haha. He's not a huge, established lab like Julia's and Yutaro's, so I'd feel bad about using his start up funds.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Matto.jpeg")} alt="Matto.jpeg" />
                    <figcaption>Matto is Elite</figcaption>
                </figure>
                In that sense, I think it's really cool that I get to be directly mentored by my lab's PI, but it's also a little unfortunate since I never got over that feeling of being intimidated by him. It's a little easier to communicate with someone younger - a grad student or maybe even a postdoc. In that sense, I also feel like we don't have a social lab which makes things a little harder. I wish we would've had more social events.
                <br/><br/>
                ICML was a nice experience in that it gave us the chance to actually talk to people in the lab, but I wish our lab had fun events outside of work hours. That'd bring things together nicely, I feel.
                <br/><br/>
                Also, in the morning, Romina emailed the events for next week and I sent her back an email about how I appreciated her for keeping us on top of our schedules. And she sent a really wholesome email back haha. She was sad that this time has ended so fast, and she also said she appreciated the note because sometimes she feels like "seargent reminder Romina" ahahaha.
                <br/><br/>
                Sanjana was the only person besides Julia who came into work that I knew of, so we headed to lunch. We couldn't decide where to, so we chose a restaurant a list Romina gave us during orientation. We went to a pizza shop near St. Catherine's park, which was nice but made me realize I really don't understand New York pizza. I only got two slices, which ended up being a whole $10. And I found out why - the servings are just absolutely humongous. It was just way too much to eat, so I didn't eat the dough of one of the deep dish slices haha.
                <br/><br/>
                After lunch, I headed back to Zuckerman and hung out on the sixth floor until I heard some grad students crap talking their underground. Talking about how she did the entire poster for her undergrad and how a precious intern in past years did it themselves, saying they were kind of advanced. She said she wishes it was more of a learning experience than an “oh here’s your poster.” But she talked in a condescending way so I was annoyed haha.
                <br/><br/>
                I moved to the coffee room and tried to think of how to respond to Wesley. He gave me some feedback in the afternoon but said if I have time I can make those changes. And I said it was too late since it was due in the morning. And he said that was okay and it looked great.
                <br/><br/>
                I was in the coffee room for a bit until Arielle came. Arielle didn't have much work to do, so she stayed in the break room and chatted with me. She just needed to meet with her mentors to go over her poster, so we decided to go to the MSK gift shop in the main hospital since she's been wanting to get MSK merch.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BridgeFromIsland.jpeg")} alt="BridgeFromIsland.jpeg" />
                    <figcaption>The Bridge Over Roosevelt Island</figcaption>
                </figure>
                We both got the same T-shirt, which ended up being less than $20. We were both pretty happy with it since they don't actually post the prices on the clothes, and we only found out how expensive it was when we got to the register haha.
                <br/><br/>
                When we got back to the coffee room, we were talking about our experience this summer, and asked I told her I didn't want Ushma to walk in since we were talking about how we would miss this summer, but how I wouldn't come back to CBSP since I would want to do something else (and how Ushma would feel betrayed).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Island.jpeg")} alt="Island.jpeg" />
                    <figcaption>Island Views</figcaption>
                </figure>
                And then Ushma walked in! Ahaha. Arielle was like, "Kyle was just talking about how sad he is to leave!" She wanted to see what conversation we would have, and we had the exact conversation I was scared of haha. Ushma was like, "welll, you can always come back!" And then she talked about how Taykhoom betrayed her and how she hopes a group of us does come back for grad school and things like that.
                <br/><br/>
                Oh, we actually had this conversation before we went to the gift shop. I remember now since when we got back, Mitchell was there and I talked to him for a bit while Arielle went off to her mentor meeting. Mitchell starts school like a week after going back, so he doesn't have much downtime before getting back into things haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IslandSelfie.jpeg")} alt="IslandSelfie.jpeg" />
                    <figcaption>Island Selfie!</figcaption>
                </figure>
                At about 4:30, Sanjana's lab meeting finally finished, so Mitchell and I walked over and we got on our way to the tram station! Roosevelt Island! We've been wanting to go to Roosevelt Island for a while, and we finally got around to doing it today.
                <br/><br/>
                Josh met us there since he spent the day at The New Yorker, and we made our way onto the tram, which is a little car that goes up in the air like a ski lift haha. It's a nice view of the upper east side of Manhattan, Roosevelt Island, and Queens on the other side of the Hudson.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/GroupBike.jpeg")} alt="GroupBike.jpeg" />
                    <figcaption>Biking Along the Hudson</figcaption>
                </figure>
                Once we got to the island, I saw bikes, so I suggested we all go biking. After some debate, we did just that! I love biking so it was great to finally get on a bike in NYC, and we couldn't have done it at a better location.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RooseveltSelfie.jpeg")} alt="RooseveltSelfie.jpeg" />
                    <figcaption>Mirror Ball Selfie</figcaption>
                </figure>
                Roosevelt Island, being an island, has beautiful all-around views of Manhattan and the water. It's also not very densely populated, so it was nice to get some peace and clean air. It felt exactly like if you plopped a suburb onto an island. There was even a Costco!
                <br/><br/>
                As we biked, I took some videos, which was extraordinarily dangerous considering there were cars and trucks on the road, but we all made it back safely haha. The views are truly just incredible. Very happy riding along the island and finally checking out that little area so close to where we work.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TheOtherSide.jpeg")} alt="TheOtherSide.jpeg" />
                    <figcaption>The Other Side of the Island</figcaption>
                </figure>
                We rode around for probably almost an hour in the absolutely terrible sun. I was sweating so much haha. My pants felt heavy as we walked around, and I was sticky all over. There's actually a subway (the F line) that goes from Roosevelt Island back to 34th street, so we took that all the way back to the hotel.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/KyleRoosevelt.jpeg")} alt="KyleRoosevelt.jpeg" />
                    <figcaption>Me On an Island</figcaption>
                </figure>
                Josh, Sanjana, and I went to the 15th floor and Mitchell went back to the room. Mitchell had food he had to eat, so he decided not to join us for dinner. Josh brought his laptop to the 15th to watch a soccer game, and Sanjana went to shower. I kind of just sat there, a bit tired from the week but feeling fine overall since I had a niced ice coffee from Matto in the afternoon. Matto is a really nice coffee shop (the same one I went to in the morning to get breakfast), where everything is $2.50. Even the damn large coffees are $2.50, the same price as the smaller coffees haha. Makes no sense.
                <br/><br/>
                Anyways, we decided to go to a Thai curry shop that Josh has gone to before. It's a nice little shop called Kati Shop, and it's basically Chipotle but for Thai curry. I didn't think it was much of anything special, and I also didn't feel hungry. I was just forcing myself to eat since I knew I needed to eat dinner haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Tram.jpeg")} alt="Tram.jpeg" />
                    <figcaption>Tram On the Way There</figcaption>
                </figure>
                The actual shop has a nice inside bar-like seating area, where you just have a long ledge on the wall you can eat at. It's a very NYC-esque type of vibe, and I enjoy it except for the fact that it makes it hard for everyone to talk since you only have the wall in front of you haha.
                <br/><br/>
                After dinner, Josh wasn't feeling too good. He said he just felt very bloated, and the cold water somehow made him feel uncomfortable. We crossed a street, he said "hold on," and started throwing up at the end of the intersection behind a row of flowers haha. Such a Midtown NYC sight to see. Hearing it was disgusting, and it was sad to see him regurgitate his entire dinner haha.
                <br/><br/>
                As we left, people that didn't see him came up, pointed at it, and were all laughing haha. It's just such a non-crazy thing to see in Midtown, which is nasty and sad. We got back to the hotel a bit after 9 PM, and Josh was absolutely exhausted. He napped on teh 15th floor couch, and Sanjana and I hung out before she went to call some family and I went to clean up a bit and blog.
                <br/><br/>
                It's 3:18 AM right now as I write this haha. This blog is really long since I've been writing stuff down throughout the day. Anyways...
                <br/><br/>
                Mitchell wanted to go to a comedy club, so he forwarded along the details for the show at 11 PM. We all got tickets and took the 12 minute walk over to the club. It's a really weird entrance that seems like it's just going up to someone's home, and the entire lobby area is literally just like a living room.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoshPuke.jpeg")} alt="JoshPuke.jpeg" />
                    <figcaption>Josh and His Dinner</figcaption>
                </figure>
                At some point, they shouted about the theater opening, and we went into this nice space with about 40 open seats. The theater wasn't packed when the show started, so it felt very informal and open.
                <br/><br/>
                The comedy place is called The Pit, and the show is the Not Late Late Night Show. It's just put on by some comedians, and the entire show is 3 comedians with some breaks in between for the hosts to have their own segments.
                <br/><br/>
                The entire show was very mid (not good, not bad). I definitely wouldn't pay again to go see it, since I didn't laugh much (the jokes just weren't that amusing to me haha). But it was nice seeing a live action theater. It basically felt like what SNL was if SNL was put on by some mature high schoolers who have a good sense of what's going on in the world today.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MitchellCandid.jpeg")} alt="MitchellCandid.jpeg" />
                    <figcaption>Candid Mitchell Picture</figcaption>
                </figure>
                We walked back to the hotel, and there was a group of girls singing happy birthday to some random guy, so I shouted happy birthday to him too. Nice to have some community bonding in Midtown Manhattan.
                <br/><br/>
                It was around 12:30 and we were still going to hang out on the 15th haha. Arielle came up to join us, and we eventually decided to go outside since Arielle wanted a change of environment. After a search for ice cream shops, we eventually decided to go to the McDonald's across the street. Mitchell went back to the room since it was 1:30 and he was tired, and we got on our way to Mickey D's.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Comedy.jpeg")} alt="Comedy.jpeg" />
                    <figcaption>The Pit</figcaption>
                </figure>
                The environment there is terrible. Weird people, unorganized serving staff, and just a not great place haha. We spent probably half an hour trying to get our food before going back to the hotel and hanging out in the TV room until 3 AM. Now, it's time to go to bed. I'll add pictures tomorrow morning and you'll have a long blog to read. Well, I guess you're done reading at this point. Anyways, it's 3:25, time to go to bed!
                <br/><br/>
                I'll wake up late tomorrow and probably do lots of things to celebrate the last weekend here.
            </div>
    },
    {
        title: "Day 56: 3 Boroughs",
        date: "08/06/2022",
        tags: ["Brooklyn", "MoMA", "Queens"],
        content:
            <div>
                Today, I woke up at 10:30 but got up closer to 11. We slept so late last night, so we had to get a good amount of sleep haha. We were planning on going to The Met in the afternoon and the Queens Night Market at night, but plans quickly changed!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Brooklyn.jpeg")} alt="Brooklyn.jpeg" />
                    <figcaption>Brooklyn Farmer's Market</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/Library.jpeg")} alt="Library.jpeg" />
                    <figcaption>Inside The Library</figcaption>
                </figure>
                Arielle, Sanjana, and I headed out to get coffee, but couldn't decided where to go. We were going to get bagels, but then decided we wanted to do something more fun than walking across the street for breakfast. Blue Bottle Coffee was closed, so we walked back to Starbucks and just got some iced coffees before Josh and Ivory joined us.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Library2.jpeg")} alt="Library2.jpeg" />
                    <figcaption>Entrance to Library</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/LibrarySelfie.jpeg")} alt="LibrarySelfie.jpeg" />
                    <figcaption>Library Lunch</figcaption>
                </figure>
                Arielle wanted to go to a farmer's market in Brooklyn, since there's a tent there that sells really good sweet potato cinammon rolls according to her. We made the trip to Brooklyn, making this yet another weekend we've gone to Brooklyn haha. The area we were in is super nice. Brooklyn just has a much more natural vibe, and it's less crowded and more peaceful than Manhattan.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Turnover.jpeg")} alt="Turnover.jpeg" />
                    <figcaption>Turnover. Not Very Good</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/LibSelfie.jpeg")} alt="LibSelfie.jpeg" />
                    <figcaption>Outside the Library</figcaption>
                </figure>
                The farmer's market wasn't too packed, and it was right outside a gigantic public library which I was excited about. The public libraries in NYC are really fancy, so I've always wanted to check one out.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WaterSubway.jpeg")} alt="WaterSubway.jpeg" />
                    <figcaption>Subway Ride Back to Manhattan</figcaption>
                </figure>
                There unfortunately weren't cinammon rolls left when we got there, so we got these black bean and cilantro turnovers before heading into the library to find a bathroom. While some people used the bathroom, I walked around and explored the libray. The space is wide open, with really tall ceilings much like UCLA has. I can't believe the public libraries here are just so nice.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Flower.jpeg")} alt="Flower.jpeg" />
                    <figcaption>Flowers Outside the Library</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/BrooklynLib.jpeg")} alt="BrooklynLib.jpeg" />
                    <figcaption>The Brooklyn Library</figcaption>
                </figure>
                We got tables near the entrance and sat down to eat our lunch. I think the UCLA libraries are better just because I'm used to them, but I'm sure I would love these public libraries if I spent more time here haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoshMatisse.jpeg")} alt="JoshMatisse.jpeg" />
                    <figcaption>MoMA!</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/MomaSelfie.jpeg")} alt="MomaSelfie.jpeg" />
                    <figcaption>MoMA Selfie!</figcaption>
                </figure>
                After lunch, we headed back to Manhattan. We decided to go to MoMA (The Museum of Modern Art) instead of The Met, since a few of us have already been to The Met, and none of us particularly liked it haha. MoMA is back in Midtown, right in the middle of all the big places like Radio City Music Hall and Rockefeller Center.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/TouchedSculpture.jpeg")} alt="TouchedSculpture.jpeg" />
                    <figcaption>Josh and I Touched a Sculpture</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/Campbell.jpeg")} alt="Campbell.jpeg" />
                    <figcaption>I Saw This in Beatles Class!</figcaption>
                </figure>
                I didn't especially like MoMA because I don't understand how some of the art is even considered art haha. I can't believe people make this stuff and convince other people to care about it hahaha. I'm just not an artist! I don't understand.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/StarryNight.jpeg")} alt="StarryNight.jpeg" />
                    <figcaption>Starry Night!</figcaption>
                </figure>
                Arielle and I were starving because none of us had a real meal, so we headed off to find some food. First, we got distracted at the MoMA Design store, shopping around and looking at all the expensive stuff they have there.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/PeacefulManhattan.jpeg")} alt="PeacefulManhattan.jpeg" />
                    <figcaption>Peaceful Selfie!</figcaption>
                </figure>
                We finally found a Le Pain Quotidien, which is a chain we see all the time around NYC, so we went in and got some snacks. I got a lox croissant sandwich which wasn't that good, but was good enough to keep me less hungry for a bit of time. We sat outside near a fountain to eat and relax for a bit, which was one of the most peaceful parts of Midtown I've ever seen haha. There were still lots of people, but it felt like a nice enclosed area of the side of the streets.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/QueensClouds.jpeg")} alt="QueensClouds.jpeg" />
                    <figcaption>Queens Clouds</figcaption>
                </figure>
                We headed back to MoMA since we wanted to see Van Gogh's Starry Night, and then we all left to go back to the hotel. A lot of people had plans tonight, and Sanjana, Mitchell, and I took the time to go to the Queens Night Market, a place Ushma has been recommending for a while haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/QueensSubway.jpeg")} alt="QueensSubway.jpeg" />
                    <figcaption>Queens Subway Station!</figcaption>
                </figure>
                Sydney's and Aziz's girlfriends are both visiting this weekend, so we were planning on meeting up with them, but we ended up not being able to all intersect at the market haha. Mitchell, Sanjana, and I got on our way to Queens a bit before 7. The commute is a good 40 minutes, so it was dusk by the time we got to the market.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Queens.jpeg")} alt="Queens.jpeg" />
                    <figcaption>Dinner in Queens</figcaption>
                </figure>
                My first impression was that it was much nicer than Smorgasburg. There were rows and rows of tents, and just a HUGE crowd of people walking through, but it was a very happy vibe. There are lots of good food, and the price cap there is $6, which means the portion sizes are smaller haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/QueensSelfie.jpeg")} alt="QueensSelfie.jpeg" />
                    <figcaption>Besties in Queens!</figcaption>
                </figure>
                We walked around considering our options, and I saw a Hong Kong shop selling homemade soy milk, so I had to buy a bottle. $3 for a pretty big bottle of DELICIOUS soy milk. So worth it! I was just talking about how I still haven't seen anyone in NYC that I know of completely unplanned and how today I was wearing my UCLA basketball shirt and still saw no one else, when I saw someone that looked my RA from last year. I shouted "Andrea!" And she turned my way, and we were like OH MY GOD IT'S ACTUALLY YOU hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/QueensSelfie2.jpeg")} alt="QueensSelfie2.jpeg" />
                    <figcaption>Another Selfie!</figcaption>
                </figure>
                I went over to talk to her and her friend, and her friend went to talk to Sanjana and Mitchell while I caught up with Andrea. Just a wholesome time overall. Andrea is here for a few days just for vacation haha. Can't believe I bupmed into her in Queens of all places.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/QueensSelfie3.jpeg")} alt="QueensSelfie3.jpeg" />
                    <figcaption>ANOTHER Selfie!</figcaption>
                </figure>
                After the meetup, we walked through the rest of the rows and didn't find anything too interesting. We eventually settled on a Persian place, since the food didn't look bad and it was something new to try for all of us.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Andrea.jpeg")} alt="Andrea.jpeg" />
                    <figcaption>ANDREA MY RA!!!</figcaption>
                </figure>
                There's a huge field and live performances at the night market, so we found a section of the hill in the field to sit on and eat while taking in the night. It was really nice taking in Queens and the night market area. It's like in a naturey area that has some spaceships and aviation structures around, so it's a cool area to be in.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/NightMarket.jpeg")} alt="NightMarket.jpeg" />
                    <figcaption>Queens Night Market</figcaption>
                </figure>
                Sanjana got boba at a tent after dinner, and then we got on our way back to The New Yorker. A lot of time on the subways today! I actually like the subways a lot since they feel safe and I'm just so used to the convenience of traveling on them now haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/QueensSelfie4.jpeg")} alt="QueensSelfie4.jpeg" />
                    <figcaption>Queens Selfie!</figcaption>
                </figure>
                We got back to The New Yorker, cleaned up, and now we're just relaxing on the 15th as normal. Don't know what we're doing tomorrow yet, but it should be fun!
            </div>
    },
    {
        title: "Day 57: The Best Sunday Ever",
        date: "08/07/2022",
        tags: ["Brooklyn", "Chinatown", "Washington Square Park"],
        content:
            <div>
                Today I woke up bright and early a bit past 8 AM. I just woke up naturally, but I tried going back to sleep since I got to bed around 1:30. Fell asleep for quite a bit before getting up around 9.
                <br/><br/>
                Sanjana and I were going to see Josh's soccer game in Brooklyn this morning, so we got breakfast at Dunkin Donuts and made it on our way to Brooklyn. The breakfast there has bigger portions and some more options in terms of actual food, so it was nice to get an actual meal. I got a sourdough sandiwch with two eggs, bacon, and cheese. Super salty but it was filling!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/BrooklynWalk.jpeg")} alt="BrooklynWalk.jpeg" />
                    <figcaption>Brooklyn</figcaption>
                </figure>
                Brooklyn is kind of far, but it's a much better environment than all of Manhattan. The part we went to today is the area near the piers (since the soccer field is at Pier 5), and the area around there is pretty residential and very calm.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/BrooklynWalk2.jpeg")} alt="BrooklynWalk2.jpeg" />
                    <figcaption>Walking to the Pier</figcaption>
                </figure>
                There's about a 15 minute walk from the subway station to the pier which is just full of nice townhouses and trees. Feels very suburban in that area. The actual pier is also really beautiful. There's a clear view of the Manhattan skyline, and it's just right over the water so it feels fresh and relaxing.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Pier5.jpeg")} alt="Pier5.jpeg" />
                    <figcaption>Pier 5</figcaption>
                </figure>
                We walked on over to the soccer field Josh was playing on and sat on a bench at the end of the field to eat and watch him play. He plays pickup games with a group that meets every Sunday morning. It's cool he found a soccer community here to play with so he could keep up with his hobby. We saw him score twice! He's very good and played on his varsity team back in Hong Kong.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Soccer.jpeg")} alt="Soccer.jpeg" />
                    <figcaption>Josh's Soccer</figcaption>
                </figure>
                We relaxed on the soccer pitch for a bit before heading to a shop nearby to pick up drinks. We had to plan out the rest of the day, so we found a bench near the water and sat there to talk for a bit.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/PierViews.jpeg")} alt="PierViews.jpeg" />
                    <figcaption>View From the Pier</figcaption>
                </figure>
                The times we can just sit there together and hang out in a beautiful enviroment are probably some of my favorite. I feel like I don't always have the energy to go out and do crazy things, so it's nice to find the space and time to just relax with the others.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/PierSelfie2.jpeg")} alt="PierSelfie2.jpeg" />
                    <figcaption>Pier Selfie!</figcaption>
                </figure>
                Before leaving Brooklyn, we walked over to the other piers to check out the pickleball courts since I wanted to see them. Pickleball is kind of like tennis, but with small paddles and a smaller court. As we were walking, it started absolutely pouring on us. We knew there would probably be rain since we checked the forecast and could see the storm clouds, but we didn't expect it to start so suddenly like that haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RainyPier.jpeg")} alt="RainyPier.jpeg" />
                    <figcaption>Rainy Pier</figcaption>
                </figure>
                Besides our heads, we were pretty soaked since we only had 1 umbrella among the 3 of us. We sat in Pier 3 (or Pier 2, I don't remember haha) for a bit under the large metal tent-like structure they have over the pickleball courts before heading over to Chinatown for lunch.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CTown.jpeg")} alt="CTown.jpeg" />
                    <figcaption>Crossing the Street in Chinatown</figcaption>
                </figure>
                We invited Aziz and Mitchell over to Chinatown for lunch with us since Aziz is free for the rest of the weekend, so we walked around in the rain looking through a popular jewelry shop while we waited for the rest to come. We joined a wait list for Joe's Shanghai, which is apparently a Michelin-recommended restaurant (so it's always packed every hour of the day).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoesShanghai.jpeg")} alt="JoesShanghai.jpeg" />
                    <figcaption>Joe's Shanghai Selfie</figcaption>
                </figure>
                I also got a plum juice from a shop earlier since I wanted to remember the flavor. I feel like we haven't had plum juice since Beijing haha! And that powder we used to have that we made plum juice out of. I don't know how to describe the plum juice taste other than it tasting like barbeque sauce haha. Pretty good!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/WSP.jpeg")} alt="WSP.jpeg" />
                    <figcaption>Washington Square Park</figcaption>
                </figure>
                We finally got our seats at Joe's Shanghai and Sanjana, Josh, and I ordered stuff for family-style lunch. We got drunken chicken and Sichuan Fish, which were both VERY good. It was very spicy but not as spicy as I thought it'd be haha. Very manageable, so I was able to eat a lot.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/WSP2.jpeg")} alt="WSP2.jpeg" />
                    <figcaption>More Washington Square Park</figcaption>
                </figure>
                After lunch, we didn't want to head back to The New Yorker, so we rode up 1 station to Washington State Park and sat on the grass there for a while, playing charades and just relaxing. This group of people really is something else. We're doing the nerdiest things like charading a p-value, DataFrames, and various faculty members of MSK haha. Really can't imagine doing this kind of thing with anyone else.
                <br/><br/>
                It's a small-ish park that doesn't have much other than a bunch of vendors selling weird crap like weed and little souveniers. There's some nice trees and nature areas, and it's right outside a few of the NYU buildings. Overall a nice space, but too crowded like all of the parks in Manhattan haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/WSPSelfie.jpeg")} alt="WSPSelfie.jpeg" />
                    <figcaption>Washington Square Park Selfie!</figcaption>
                </figure>
                We walked across the park and all sat on a bench for a while, talking and taking in the environment since we couldn't decided what else to do. I feel like we got to see a lot of beautiful scenes today, so I was pretty satisfied with our last weekend day here.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Insomnia.jpeg")} alt="Insomnia.jpeg" />
                    <figcaption>Outside Insomnia!</figcaption>
                </figure>
                We decided to go back to the New Yorker, where Josh worked on finding an apartment, Sanjana looked for bags to travel home with, and I blogged a bit (it's Monday around 10:45 AM and I'm in Joy right now finishing it up haha). We got dinner around 9. Josh had some instant noodles he found in the pantry (which is completely full since everyone moved out), and Sanjana had food from her aunt. I got Chipotle haha. I don't have any more food I can cook, since I wanted to finish it last week.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/InsomniaPic.jpeg")} alt="InsomniaPic.jpeg" />
                    <figcaption>Inside Insomnia!</figcaption>
                </figure>
                The pantry has all sorts of cool stuff since last week was the last week for summer EHS housing. MSK interns just have an extended week since we end a week later than everyone else, which is nice. The 15th floor is a lot quieter nowadays.
                <br/><br/>
                Arielle wanted to get gelato today at some Italian place, but they closed at 10 and by the time we finished dinner, it was almost 10:30. Arielle still wanted ice cream, so Sanjana suggested we go to Insomnia Cookies, the place we went to when we got pho that one night but didn't actually end up getting any cookies.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/NightParty.jpeg")} alt="NightParty.jpeg" />
                    <figcaption>Night Time Party</figcaption>
                </figure>
                Josh, Sanjana, Arielle, and I got on our way to Insomnia, which is just two subway stations uptown on the ACE line. When we got there, I tried opening the door by pulling it, but it didn't work. There was a lock on the handle, so I assumed the door was locked even though the lock wasn't locked around anything but the handle itself.
                <br/><br/>
                We decided to order on the app since the whole point of Insomnia is to order delivery, and we set the location to the store's address so they would deliver it right there hahaha. So dumb. We were looking at the menu, choosing what to get, and then some man walked into the Insomnia. Arielle was like, "wait what! How the fuck did that guy get in!" And then she went up to the door, pushed it, and then called me a dumbass since it was unlocked the whole time hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/AsianMen.jpeg")} alt="AsianMen.jpeg" />
                    <figcaption>East Asian Men Going to Sleep</figcaption>
                </figure>
                We went in, all laughing, and ordered our desserts. I got an ice cream sandwich (their moon tracks flavor and a white chocolate macadamia nut cookie with a chocolate mint cookie on the other side). It was so good, and they serve the cookies hot.
                <br/><br/>
                We ate there for a while, and Arielle heard a song she said they commonly listen to while they do certain drugs at Caltech haha. We went to a shop nearby to buy some stuff and got back late at night, around midnight, and my stomach hurt kind of bad. I took a dump and felt better, and then joined Arielle, Josh, and Sanjana in Arielle's room since her roommate Sydney is still gone. Julia and Yutaro came to join us at around 1:30 after getting back from work (they're both working on their posters), and we hung out for just a bit after that before all going to bed haha.
                <br/><br/>
                A great Sunday, and a great way to spend our last weekend here!
            </div>
    },
    {
        title: "Day 58: A Beautiful Day",
        date: "08/08/2022",
        tags: ["Work", "Hudson", "NYC", "Koreatown"],
        content:
            <div>
                Today I woke up at like 9:15 and got headed to work, arriving at Joy a bit past 10 AM. I didn't have much to do, but I wanted to start cleaning up my code and getting it ready for whoever picks up the project next. All I ended up getting done was downloading my Jupyter Notebook off the cluster and onto my laptop before I headed to the main hospital to take a covid test and wait for our lunch event.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/68167548491__D9AA3F98-A2A7-4A1F-8117-9D9AFABE7D79.jpeg")} alt="68167548491__D9AA3F98-A2A7-4A1F-8117-9D9AFABE7D79.jpeg" />
                    <figcaption>Poster Kyle!</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7845.jpeg")} alt="IMG_7845.jpeg" />
                    <figcaption>Julia Arrives at ZRC</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7853.jpeg")} alt="IMG_7853.jpeg" />
                    <figcaption>Waiting in the ZRC Lobby</figcaption>
                </figure>
                Dana Pe'er was giving a CBSP Faculty Talk today. She presented on single-cell research and spatial transcriptomics, which are the major focuses of her lab. I didn't really understand what was going on, but it seemed that others didn't really know either, so it's fine haha. At least we got a free lunch.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7842.jpeg")} alt="IMG_7842.jpeg" />
                    <figcaption>COVID Test! I'm Negative</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7913.jpeg")} alt="IMG_7913.jpeg" />
                    <figcaption>Pier View</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7907.jpeg")} alt="IMG_7907.jpeg" />
                    <figcaption>The Pier's Platform</figcaption>
                </figure>
                After lunch, a few of us headed to the sixth floor of Zuckerman to pick up our posters! I was pleasantly surprised by how the posters came. They're in a nice cardboard cylinder, wrapped in plastic, and the poster is printed on fabric! I was hoping it was a fabric poster since that makes it so much easier to bring home haha. I can literally fold it up, put it in my suitcase, and iron it at home to make it look brand new. What a great concept.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7905.jpeg")} alt="IMG_7905.jpeg" />
                    <figcaption>Pier 76</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7899.jpeg")} alt="IMG_7899.jpeg" />
                    <figcaption>The Pier</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7889.jpeg")} alt="IMG_7889.jpeg" />
                    <figcaption>The Edge of Manhattan</figcaption>
                </figure>
                Josh, Sanjana, and I hung out in the coffee room for a couple hours, with Aziz joining briefly and Yutaro coming after about half an hour. We were just talking about random crap and hanging out. Being in the coffee room always makes me feel zoned out since I never get anything productive done in there haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7884.jpeg")} alt="IMG_7884.jpeg" />
                    <figcaption>Classic NYC Scaffolding<br/>On the Way to the Pier</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7953.jpeg")} alt="IMG_7953.jpeg" />
                    <figcaption>Sanjana Thinking at the Pier</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7949.jpeg")} alt="IMG_7949.jpeg" />
                    <figcaption>Falling Into the Hudson!</figcaption>
                </figure>
                After a while, we went back to our offices to get our stuff and then went to the Zuckerman lobby to do our own thing before the MD-PhD panel. I'm not even interested in MD-PhD programs haha. Mostly going just to support my friends (Josh, Sanjana, Julia, and Arielle all came) and to learn about something I'm never going to pursue in life.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8010.jpeg")} alt="IMG_8010.jpeg" />
                    <figcaption>Roomies at the Pier</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8003.jpeg")} alt="IMG_8003.jpeg" />
                    <figcaption>Mitchell the Model</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7985.jpeg")} alt="IMG_7985.jpeg" />
                    <figcaption>Looks Like an Album Cover</figcaption>
                </figure>
                In the ZRC lobby, I didn't want to do real work, so I read a paper on recreational use of a new drug I learned about which was probably the most interesting paper I've read this summer haha. Skimming papers is a great way to learn about new things.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7941.jpeg")} alt="IMG_7941.jpeg" />
                    <figcaption>Manhattan! I've Found It!</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7938.jpeg")} alt="IMG_7938.jpeg" />
                    <figcaption>NYC is Mine</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7935.jpeg")} alt="IMG_7935.jpeg" />
                    <figcaption>Never Follow the Rules</figcaption>
                </figure>
                We went to the MD-PhD panel at 5, hosted in the same seminar room our hackathon was in. It was a panel of 6 speakers, and the moderator went to UCLA! She was talking about how everyone who knows her knows about how much she loves UCLA hahaha. I unfortunately didn't get a chance to talk to her, but it doesn't matter too much since we would've just talked about how much we love UCLA haha. I'll probably reach out to her on LinkedIn and ask her advice on her favorite things she did at UCLA.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7929.jpeg")} alt="IMG_7929.jpeg" />
                    <figcaption>My Beautiful Friends</figcaption>
                </figure>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7927.jpeg")} alt="IMG_7927.jpeg" />
                    <figcaption>Another Angle</figcaption>
                </figure>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7925.jpeg")} alt="IMG_7925.jpeg" />
                    <figcaption>Yet Another Angle</figcaption>
                </figure>
                After the panel, Arielle and I talked to a first-year MD-PhD student who did CBSP twice! He's in Sanjana's lab, and he understands the CBSP dynamic well, so he was able to talk very openly with us about CBSP and his current situation. I didn't have many questions, but Arielle learned a bit since she used to be interested in MD-PhD and recently has been thinking about whether she really wants to go that route.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7917.jpeg")} alt="IMG_7917.jpeg" />
                    <figcaption>Mitchell Takes on Manhattan</figcaption>
                </figure>
                We talked about that for a while, and he talked to us about how his current lab isn't great since the PI is really rude to her students haha. He's on his first rotation of about 3, and he's been trying to find a computational lab where the people are actually friends. Arielle said her lab, John Chodera's, has really great community (they hang out, get drinks every week, etc.), so he said he'd rotate into that lab next then haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/FFAC6D45-4A24-47EC-9CA6-CFA1C3F19533.jpeg")} alt="FFAC6D45-4A24-47EC-9CA6-CFA1C3F19533.jpeg" />
                    <figcaption>Appreciation Post for NYC</figcaption>
                </figure>
                We went back to The New Yorker after the converations, and since we were already fed (there was salad and pizza at the panel), we thought about what to do for the rest of the night. I wanted to go see the sunset, but Josh had a call until 9 PM (we got back around 7), so Sanjana and I headed off to the West side of 34th street along the Hudson River to get the nice view.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8044.jpeg")} alt="IMG_8044.jpeg" />
                    <figcaption>Grace Street</figcaption>
                </figure>
                Aziz and Mitchell joined us near the pier, and since we've been there once before, I suggested going to a huge platform over the water that wasn't too far from us. We were happy to see the area was open, and we were greeted with an absolutely stunning view of the Hudson, the Manhattan skyline behind us, and the island in front of us, whatever that place is haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8017.jpeg")} alt="IMG_8017.jpeg" />
                    <figcaption>Sunset @ The Pier</figcaption>
                </figure>
                We snapped a lot of pictures, and I got some really cool ones by jumping over the barricade to the ledge hanging right over the water. I even sat down at the ledge, feet hanging over the Hudson, to take a few quick snaps with the water.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_7976.jpeg")} alt="IMG_7976.jpeg" />
                    <figcaption>Framing Pier 76 In a Perfect Shot</figcaption>
                </figure>
                Last night was one of the moments I felt really content about my time here in NYC - content in a way where I feel like I've done the most I could've and made some of the coolest and smartest friends I'll ever have. Just taking a moment to appreciate the view of Manhattan from the outside was very calming, and I still can't believe how much I know about this city now.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_7962.jpeg")} alt="IMG_7962.jpeg" />
                    <figcaption>Selfie</figcaption>
                </figure>
                After hanging there for quite a bit, we went to Koreatown via bus, but Mitchell went back to the hotel to call his girlfriend. Aziz, Sanjana, and I wanted to go to a dessert shop, but we got rerouted since we found an ice cream shop in the same food court that looked better than the taiyaki we were originally going to get. I went up to order, and the guy said they had no more ice cream, so we decided to walk around and find a different shop.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9501.jpeg")} alt="IMG_9501.jpeg" />
                    <figcaption>Law Abiding Citizen</figcaption>
                </figure>
                Sanjana wanted to go to a place called Grace Street on Thursday (since all the 21+ interns are going to go clubbing), but we walked past it and decided to just try going in. There was empty seating inside (it's a weird dessert shop that has a huge sit-in area), so we ordered and got on our way inside.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9484.jpeg")} alt="IMG_9484.jpeg" />
                    <figcaption>Posing on the Pier</figcaption>
                </figure>
                Aziz got a Thai Tea, and Sanjana and I got some toast thing with ice cream, popcorn, strawberries, and boba to share. That dessert is easily one of the best things I've had in NYC. Probably one of the best desserts I've ever had in all haha. It was so damn good. I don't know how they make the bread taste so good. It's literally just a huge cube of bread, but it's so fluffy, light, and tasty.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8071.jpeg")} alt="IMG_8071.jpeg" />
                    <figcaption>Walking!</figcaption>
                </figure>
                After dessert, we walked on our back to The New Yorker. We all got cleaned up, and then went to the 15th to hang out. Yutaro was there playing pool, which surprised us since he still doesn't have results and usually comes back at 1:30 AM these days haha. Turns out he came back since Julia, who also works late with him, had to do her laundry at night.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8059.jpeg")} alt="IMG_8059.jpeg" />
                    <figcaption>I Said Something Inappropriate<br/>To Aziz (I Don't Remember What)</figcaption>
                </figure>
                Yutaro, Josh, and Aziz finished up a game of pool and all got on their separate ways, so Sanjana and I went to the TV room and thought of what 2 player games we could play. Sanjana suggested Fireboy and Watergirl, a computer game we all used to play way back in elementary school haha, so we did that while waiting for Josh to finish his shower.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8056.jpeg")} alt="IMG_8056.jpeg" />
                    <figcaption>Grace Street Selfie!</figcaption>
                </figure>
                It was a surprisingly fun game, and it was cool to see how advanced the game was in terms of how accurate some of the physics is haha. You never realize that type of stuff when you're playing as a kid, but it's so impressive how the game developers made the physics so realistic.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8052.jpeg")} alt="IMG_8052.jpeg" />
                    <figcaption>Tonight's Dessert</figcaption>
                </figure>
                Josh came back and sorted things out for his apartment with his apartment-mates while we played, and Mitchell later joined, sketching in his sketchbook as usual. It was a really fun night, and we all got to bed relatively early (1:30 AM). I'm now at work, writing this blog at 11:30 AM, since I just got here like half an hour ago haha. Excited to take these next few days and do the most we can with them!
            </div>
    },
    {
        title: "Day 59: India",
        date: "08/09/2022",
        tags: ["Work", "Jackson Heights"],
        content:
            <div>
                Today I woke up bright and early at 10:00 AM haha. I didn't want to go to work too early since I don't have much to do, but I also wanted to go to work since I needed to finish cleaning up my code for whoever picks up my project next.
                <br/><br/>
                I got to work by 11 AM after eating some chocolate and getting ready in my dorm, and I was ready for the workday! I spent most of the morning blogging and looking at some of the new newsletter submissions. Pretty relaxing morning! Literally no one cares if and when I come into work, so it's a very relaxing job, especially this week haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JoyWorkers.jpeg")} alt="JoyWorkers.jpeg" />
                    <figcaption>Going to Lunch!</figcaption>
                </figure>
                Sanjana and I organized a lunch party in the surgery center since the food there is good and affordable. It was easily the biggest lunch we've had ever haha! Mitchell, Sanjana, Aziz, Rhea, Josh, Jaden, and I were all there! The special today was steak tacos, and they were probably the best steak tacos I've ever had haha. So juicy and so tender! Can't believe they make that there for MSK staff.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/SurgeryCenter.jpeg")} alt="SurgeryCenter.jpeg" />
                    <figcaption>Surgery Center Lunch!</figcaption>
                </figure>
                After lunch, I got back to my desk in Joy and spent some time reading the news. I found out Serena Williams retired, so I was reading about that, and saw that Ushma follows Serena Williams and other tennis stars on Twitter! Ushma must also be a tennis fan.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LunchParty.jpeg")} alt="LunchParty.jpeg" />
                    <figcaption>Lunch Party</figcaption>
                </figure>
                I realized I needed to actually get some work done today, so I pulled all my relevant code off the cluster onto my local computer and got to work. The Jupyter Notebook containing everything I've done this summer is a huge file, filled with tons of code. Luckily, I write a lot of comments and documentation as I develop my programs, so I didn't actually have a whole lot of work to do in terms of code cleaning.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/RheaSword.jpeg")} alt="RheaSword.jpeg" />
                    <figcaption>Rhea Holding Sydney's Poster Tube</figcaption>
                </figure>
                I just better organized the code, wrote some clarifications, and got rid of anything unnecessary. I did some quick testing just to make sure everything runs, and I set everything up nicely in a GitHub repo before sending it to Wesley.
                <br/><br/>
                I got Wesley's seal of approval, so now I'm basically done with my job here at MSK! I'm not actually going to prepare a presentation for my poster since I feel like I understand the BS I did well enough that I can make it up on the spot. So tomorrow, I'll come into the office, go to lab meeting, talk to Wesley, and probably just work on the newsletter. Nothing else to do in terms of actual work which is absolutely amazing!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/RheaPoster.jpeg")} alt="RheaPoster.jpeg" />
                    <figcaption>Joy Workers Having Fun</figcaption>
                </figure>
                After work, I got back to The New Yorker and tried finding an I Love NY shirt at a shop near The New Yorker since Tiffany's sister wants one from NYC. I couldn't find one, so mission failed!
                <br/><br/>
                Rhea mentioned during lunch that she went to Jackson Heights yesterday and saw all sorts of Indian stuff, so we decided to go there for our after-work adventure today. It turns out a lot of people had other plans, so it ended up being just Sanjana, Mitchell, and I. Yet again, the 3 of us going to Queens by ourselves!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Charlotte.jpeg")} alt="Charlotte.jpeg" />
                    <figcaption>Catching Up With Charlotte</figcaption>
                </figure>
                We met on the 15th floor a bit before 6. On that floor, I saw a guy I've talked to a few times about what we're doing still here. He works in finance, and he was saying he's a dumbass. He asked what I was doing here, and was like, "oh, so you're actually smart?" And then I started talking about how competitive it is to get into finance things in NYC, and he said it was, but that he's not like the other kids. He said they all go to like Wharton and he goes somewhere small haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Mudd.jpeg")} alt="Mudd.jpeg" />
                    <figcaption>Mudd in an Arc</figcaption>
                </figure>
                I originally met the guy way back when the fire happened. We started talking a bit then, and then I saw him again one night early on when I was talking to Julia, and Josh invited us to go clubbing with them, this other ESP kids Brian and Amanda. Even after all this, today he was like, "oh shit today is the first time I've seen you without a mask!" Ahaha.
                <br/><br/>
                Sanjana, Mitchell, and I got on our way to the Queens soon after that. Jackson Heights is about a 30 minute subway ride away on the E line. Pretty smooth commute! When we got there, I was a little surprised since I thought it would be a nicer area haha. It feels similar to Manhattan in terms of the cleanliness of the streets, but it's much less dense, so it's relaxing.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/MangoLassi.jpeg")} alt="MangoLassi.jpeg" />
                    <figcaption>Mango Lassi</figcaption>
                </figure>
                We made our way to a vegetarian Indian restaurant that we found online, and got seats inside. The overall environment was really nice. Plenty of open space and nice staff. The waiters were East Asian which was a little weird haha.
                <br/><br/>
                Sanjana explained what all the different foods were, and we all got some food. We got dosas since it was actually Mitchell's idea to go to Jackson Heights, just to get dosas. Dosas are these Indian crepe-like dishes that you dip in certain sauces. Pretty damn good!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IndianFood.jpeg")} alt="IndianFood.jpeg" />
                    <figcaption>Indian Food!</figcaption>
                </figure>
                Sanjana and I also got this Indo-Chinese dish called gobi manchurian. It was actually pretty good! Tasted kind of like orange chicken but spicy and with cauliflower. Would definitely get that dish again actually haha. I also ordered a mango lassi since Aziz and Sanjana have always talked about them, and I've been wanting to try one. It was so good! Basically a thick, yogurt drink.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Dessert.jpeg")} alt="Dessert.jpeg" />
                    <figcaption>Mudd and I Enjoying Nastiness</figcaption>
                </figure>
                After dinner, we got headed to an Indian dessert shop. Sanjana got something which she said Costco sells better versions of, and Mitchell and I got something called malai kulfi. It was NOT good hahaa. I mean it wasn't disgusting, but it didn't taste like much and the texture was basically like sandy cheese. Both Mitchell and I would not get it again, and neither of us would recommend it to any of our friends haha.
                <br/><br/>
                The dessert shop was nice since there's a big seating area with one section that has a fake grass wall that reminds me of the types of decorations they have in rich areas of LA. We snapped a few photos there before heading back to The New Yorker, where we got back a bit before 9. Didn't spend too much time outside, and now I'm just on the 15th floor relaxing!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/TheNewYorker.jpeg")} alt="TheNewYorker.jpeg" />
                    <figcaption>Arrival Back At The New Yorker</figcaption>
                </figure>
                We shall see what the rest of the week brings! I'm meeting with Ushma tomorrow morning and with Wesley tomorrow afternoon, so it should be a day full of good learning opportunities haha. On that note, I shall go prepare some questions for Ushma!
            </div>
    },
    {
        title: "Day 60: Last Day of Joy",
        date: "08/10/2022",
        tags: ["Work", "Joy", "Seaglass Carousel", "Oculus", "Hudson Hotel"],
        content:
            <div>
                Today I woke up bright and early at 8:45 AM since I had a meeting with Ushma in the morning, and I knew I would have delays due to the spicy dinner last night haha. I got out of the dorm in a regular amount of time, but once I got to Zuckerman, I had to use the bathroom since my stomach hurt so bad. I ended up getting to Ushma's office just a minute or too late though!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/MuddZRC.jpeg")} alt="MuddZRC.jpeg" />
                    <figcaption>ZRC With Mudd</figcaption>
                </figure>
                Ushma asked how I was doing, and I said, "well, my gastrointestinal system did not like what I had for dinner last night" haha. We got into our meeting, and we first talked about grad school and how I'm not sure what to do right now. She talked about some various faculty under the Tri-I, and she also said to pay attention at the poster ceremony tomorrow to the other CBSP posters to see if anything looked especially interesting to me.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/LastCoffee.jpeg")} alt="LastCoffee.jpeg" />
                    <figcaption>Cafe Mocha - My Last Coffee in Joy</figcaption>
                </figure>
                She talked about exploring more and how it's good these summer programs can help guide us and help us find out what we like and don't like to do. After about half an hour of talking about this stuff, we moved onto my CBSP application since I wanted to talk about that.
                <br/><br/>
                She pulled up my info, and as she was looking through, I was trying to see who I picked as my faculty choices haha. I saw that my first choice was some guy named Oliver or something, and Ekta Khurana (Sanjana's PI) was my second choice, followed by Wesley Tansey! I didn't see who the fourth and fifth picks were haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LastDeskPic.jpeg")} alt="LastDeskPic.jpeg" />
                    <figcaption>Last Selfie At My Desk</figcaption>
                </figure>
                She talked about how just looking at the basic facts, I had the right major, a lot of research experience, and stuff like that. She was like "you have a 4.0?!?" She skimmed my rec letters and said Pellegrini's was shallow (as in it was too short) but enthusiastic. She also said the second rec letter was the right length, but was a bit overly colorful. Ushma said they understood once they saw it was written by a PhD student, and said not to do that again haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/CleanDesk.jpeg")} alt="CleanDesk.jpeg" />
                    <figcaption>My Cleaned Desk</figcaption>
                </figure>
                She looked at what the grad student reviewers had to say about me, and said one rated me 2 out of 5 (where 1 is the best), and one rated me 1, grades which got me through to the stage where Ushma and Michael Berger review my application.
                <br/><br/>
                One grad student talked about how I had diverse comp bio research projects and still had a 4.0 GPA, which is insane given UCLA's reputation of grade deflation. How nice is that comment haha. Ushma also saw I was a National Merit Scholar which is good.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/LastJoySelfies.jpeg")} alt="LastJoySelfies.jpeg" />
                    <figcaption>Last Joy Selfies</figcaption>
                </figure>
                Ushma said my personal statement was good. It began in a cute way that made it seem like I might be entertaining, which they want in a summer cohort. She looked at some of the stuff I described, and said she didn't even know what that stuff was.
                <br/><br/>
                She also commented on one of my lines about how what I did in Pellegrini's lab didn't have immediate clinical relevance but benefited our knowledge about the biology, and she said it was really good since it shows I know that not everything we do is immediately relevant to patients, but is important for our fundamental understanding.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoyPantry.jpeg")} alt="JoyPantry.jpeg" />
                    <figcaption>Joy Pantry</figcaption>
                </figure>
                I didn't even realize she thought so much about this stuff haha. I didn't write it to try to impress her. I was literally just writing what I thought haha. She also said I talked about looking forward to the seminars, which was really good since a lot of people don't even take the time to look through the website and personalize their application to the program.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/PI.jpeg")} alt="PI.jpeg" />
                    <figcaption>Wesley Tansey and Kyle Pu</figcaption>
                </figure>
                She also said a big green flag was saying I wanted to do a PhD in comp bio since that's the whole point of these programs - to attract people to their grad school programs. 
                <br/><br/>
                Overall had a great meeting with her. I got on my way to Joy at around 10:45, but I went to the McDonald's near ZRC first to meet up with Mitchell since he wanted to go to Joy today. We got there, and I told the security guard we had a meeting together and that I work in the building (since Mitchell doesn't have access to Joym, and he didn't even bring his MSK ID today for that matter haha).
                <br/><br/>
                Once we got to Joy, I cleaned up my desk a bit and then went to the surgery center to pick up food for lunch. I forgot to order my lunch for lab meeting yesterday, so no free lunch for me today haha (I also missed the message asking for last-call lunch orders at 9 AM in the morning, so it's really my fault haha). 
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JoySecurity.jpeg")} alt="JoySecurity.jpeg" />
                    <figcaption>Entrance to Joy</figcaption>
                </figure>
                Got back for the lab meeting, where we talked about how the summer students are starting to leave, first with me haha. That's because Casey, one of the grad students, asked how much longer I was here for. It's kind of sad this was my last lab meeting haha.
                <br/><br/>
                After lab meeting, I met with Wesley. I scheduled the meeting to talk about industry vs. academia and his life in general, and it was a great way to get to know him better. He's a really nice guy and I was glad to be able to hear his perspective on grad school and industry and things.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/ByeJoy.jpeg")} alt="ByeJoy.jpeg" />
                    <figcaption>Goodbye Joy</figcaption>
                </figure>
                He asked why I want to do a PhD, and I told him I wouldn't be able to sit down and give him a personal statement (which he laughed at), but how I'd really only be interested since I like the idea of always learning. He said that was a very academic reason and a good reason to pursue one.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Wall.jpeg")} alt="Wall.jpeg" />
                    <figcaption>Heading to Wall Street</figcaption>
                </figure>
                He also said in grad school not to try to be a good TA since it doesn't help you finish your PhD, and it can also trap you in time consuming classes if you're a really good TA (since they like to put good TA's on harder or more time consuming classes haha).
                <br/><br/>
                He talked about how it used to the be the case that a PhD might not be more profitable in the long term (compared to getting a job straight out of undergrad), but how that's been changing in recent years.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Trump.jpeg")} alt="Trump.jpeg" />
                    <figcaption>Trump Tower</figcaption>
                </figure>
                I asked why he did a Master's instead of going straight to a PhD, and he said it was because he did research in his junior year of undergrad and his PI encouraged him to go to the 5 year program where he was at Virginia Tech, and they'd pay for it haha. What a cool thing to do.
                <br/><br/>
                After the meeting, I asked Wesley for a picture since I wasn't going to be back in Joy, and I got Armaun to take a picture of us. It was kind of awkward, especially since a few lab people in the cubicles were watching us, but it was wholesome haha. I'm glad I did it.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/NYSE.jpeg")} alt="NYSE.jpeg" />
                    <figcaption>Stock Exchange</figcaption>
                </figure>
                Oh! And Wesley encouraged me to apply to MSK for grad school, saying he'd support my application!
                <br/><br/>
                After that, I was getting everything off my desk and cleaning it up so it looked brand new. Can't believe I'm already moving out of my desk haha. While I was cleaning up, I noticed something was unplugged and realized it was my desk's light. This whole summer I couldn't use my light (which I don't really need honestly haha), but I finally found out why haha. Can't believe it! I plugged it in and tested it out so the next person to have the desk will have a good, bright time.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Bull.jpeg")} alt="Bull.jpeg" />
                    <figcaption>The Famous Bull</figcaption>
                </figure>
                I left Joy soon after that, and walked to the subway station before deciding to walk all the back to Zuckerman since people weren't going back to the hotel yet. I didn't mind taking the extra walk to ZRC instead of going straight back since I want to take in as much of Upper East Side Manhattan before I have to go haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ArielleCarousel.jpeg")} alt="ArielleCarousel.jpeg" />
                    <figcaption>Arielle @ Seaglass Carousel</figcaption>
                </figure>
                I went to the lobby to sit on the couch and work on my blog a bit, and security yelled at me, asking if I worked there. I didn't realize he was talking to me, so I ignored him for a bit before whipping out my ID to show him I'm an "employee" haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Seaglass.jpeg")} alt="Seaglass.jpeg" />
                    <figcaption>The Carousel</figcaption>
                </figure>
                Oh! And security in surgery center during lunch today gave me an MSK lanyard because I told them I wasn't wearing my ID since I broke my MSK clip haha. It was a nice surprise. They asked why I wasn't wearing my ID, I explained, and the guy asked, "do you want one?" I of course thought he was just going to give me a plain clip, but he gave me a whole lanyard! How nice ahaha! A great souvenir for my time here.
                <br/><br/>
                Anyways, I left Zuckerman a bit before 5 to go back home myself. It's crazy how scattered around people are this last week. I feel like I haven't seen a lot of most people haha. Just walking back myself was kind of nice though. Really can take in all the views of NYC just for myself a bit.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JerseySunset.jpeg")} alt="JerseySunset.jpeg" />
                    <figcaption>Sunset Over Jersey</figcaption>
                </figure>
                I got back, and Mitchell was in the room since he left early to take a nap (his head kind of hurt earlier in the day). Sanjana, Mitchell, and I were all in The New Yorker since we wanted to drop off our stuff before joining Ushma on our trip to tacos and the Seaglass Carousel tonight.
                <br/><br/>
                We met up and were originally planning on meeting everyone at ZRC at 6 PM as planned, but Mitchell didn't want to take the subway Uptown just to take it back Downtown to get to our destination haha. So, we decided to venture off on our own.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/JerseySelfie.jpeg")} alt="JerseySelfie.jpeg" />
                    <figcaption>Jersey City Behind Me</figcaption>
                </figure>
                Sanjana wanted to go to Wall Street, so today was the perfect day to do it. The Taco shop we were going to is in the financial district, and the closest stop is literally the Wall Street Stop.
                <br/><br/>
                We rode over there and got to see some cool views. The financial district (FiDi, pronounced Fye-Die), is a much less crowded place than I expected. The buildings are all high-rises - tall and grand - but they're spaced further apart than the buildings in Midtown.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/JerseyCity.jpeg")} alt="JerseyCity.jpeg" />
                    <figcaption>Mudd and I<br/>In Front of Jersey City</figcaption>
                </figure>
                We got to see some nice closed roads (purely for walking) along the New York Stock Exchange, Trump Tower, and the famous Charging Bull! The buildings honestly don't seem as crazy as I remember them to be when I was in middle school, but that's to be expected haha. I feel like I didn't really understand what was going on back then. Now, I can fully appreciate all the bulidings and the historical locations I get to visit haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/SydneyConcrete.jpeg")} alt="SydneyConcrete.jpeg" />
                    <figcaption>Sydney in NYC</figcaption>
                </figure>
                We met up with Sydney at the taco restaurant since she also went on her own from the hotel, and Amber walked up to us randomly too haha. We all reunited with Ushma and the crew, making a complete team of 16 people at the restaurant. We got two tables of high seats, and enjoyed a nice meal together. I got three different tacos, my favorite being the crispy cod taco. I also got a watermelon drink which was really good haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/OculusEntrance.jpeg")} alt="OculusEntrance.jpeg" />
                    <figcaption>Oculus Entrance</figcaption>
                </figure>
                After dinner, Ushma brought us to the Seaglass Carousel, which we were supposed to do weeks ago when she got covid. It's a really nice little carousel along the water running between Manhattan and Jersey City, so it's in a really beautiful location with plenty of fresh air. We all were excited to ride the carousel because it's like a kiddy little thing where you sit in a circle carved into these big, plastic fish that go up and down while everyone spins around in a circle.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Oculus.jpeg")} alt="Oculus.jpeg" />
                    <figcaption>The Main Mall</figcaption>
                </figure>
                They play what sounds like movie music during the ride, and at the end of it, the staff claps for some reason haha. During the ride, a lot of us were taking pictures and videos and passing around our phones to one another to get different video views. It was a pretty nice time haha. I asked Arielle, "do you think there's every been more intelligence on this carousel?" And she was like, "haha, probably" hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/Lychee.jpeg")} alt="Lychee.jpeg" />
                    <figcaption>Lychee Rum</figcaption>
                </figure>
                We walked along the sea to what's called The Oculus (a Westfield Mall) after our carousel ride. It was an absolutlely beautiful walk, since we can see the entirety of the Jersey City skyline from the place we walked along. It's the same body of water, the Hudson, that we rode on for our dinner cruise. I wouldn't have been able to tell if it weren't for a huge Colgate sign on the other side of the river, the Colgate sign for the school Aziz goes to (Colgate University).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/Alc.jpeg")} alt="Alc.jpeg" />
                    <figcaption>Drunk Selfie!</figcaption>
                </figure>
                We walked through The Oculus and Ushma left us there. Many of us traced our steps back to find the bathroom since most of the bathrooms in the mall there are closed at night. Someone suggested we do something fun since it's our last few days in NYC, so we decided to go to a restaurant bar that's known to not check anyone's ID.
                <br/><br/>
                We walked quite a bit and took the subway to the area, which is just around Hudson Yards. The bar we went to was the Hudson Hotel's, so it's decorated very fancily haha. I got a Frozen Lychee Daiquiri, which is probably the most alcohol I've ever drinken in one sitting. My friends were saying I was drunk, but then I feel like I have a pretty clear mind when I'm drunk. I just felt hotter, light headed, and a bit looser, but I could still think pretty straight haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/ElevatorSelfie.jpeg")} alt="ElevatorSelfie.jpeg" />
                    <figcaption>Elevator Selfie After Drinking</figcaption>
                </figure>
                We finished up our drinks and got headed back To The New Yorker soon after, walking all the way back since it's not too far. And now it's almost 1:15 AM. Sanjana and Josh are in the TV room with me, both preparing their presentations for tomorrow, while I'm finishing up this blog haha. Excited for the poster session tomorrow.
                <br/><br/>
                I actually invited my lab which I didn't plan on doing if it weren't for Armaun encouraging me to haha. He came to my desk a bit after lab meeting and told me he expected me to tell them during the lab meeting, and that I should just send a Slack message, so I did haha. Hopefully some people show up so I can maybe get someone interested in what we accomplished this summer. Only time will tell how things turn out... see you tomorrow!
            </div>
    },
    {
        title: "Day 61: Best Friends",
        date: "08/11/2022",
        tags: ["Work", "Poster Session", "Summer @ MSK", "Koreatown", "Eataly", "Madison Square Garden"],
        content:
            <div>
                It's 2 PM on August 12th. I'm in the Zuckerman lobby sitting across from Sydney Woods. We're waiting for our last CBSP event - No Dumb Questions. And now, I shall write my blog for yesterday.
                <br/><br/>
                I went to Zuckerman early in the morning and hung out on the sixth floor for a bit. We were supposed to set up posters in the lobby at noon, but I arrived a bit over an hour early just to relax and see who was there.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8793.jpeg")} alt="IMG_8793.jpeg" />
                    <figcaption>Rhea and I Messing Around At Sanjana's Poster</figcaption>
                </figure>
                A bit before noon, Sandy told us she set everything up so we could go ahead if we wanted to. Mitchell was with me by this time, so we went down together. The lobby was pretty crowded since they needed room for 40 posters in the little space they have. I found my poster board spot, which was next to Rhea's and Afsana's. On the other side of my board was Sanjana's spot.
                <br/><br/>
                We got set up with the help of each other, since it's pretty much impossible to hang up our giant posters by ourselves. They gave us each a ton of thumbtacks to use, but I only wanted to use 2 since I don't want too many holes in my poster.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8785.jpeg")} alt="IMG_8785.jpeg" />
                    <figcaption>Ice Cream Time!</figcaption>
                </figure>
                Setting up our posters only took like 20 minutes, so several of us went out to get lunch before the actual session. Josh knew of a Thai food cart a few streets away, so we went there. The food looked good, but it took forever for the person to cook it, so it was quite a bit of a wait.
                <br/><br/>
                It was pretty uncomfortable since it was so hot outside haha, but at least we were with our friends and not waiting alone. I got a beef pad see ew, which I was excited about since I haven't had Thai food in a while.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8779.jpeg")} alt="IMG_8779.jpeg" />
                    <figcaption>Jaden and Sanjana!</figcaption>
                </figure>
                We brought our food to eat on the sixth floor coffee room before going to the poster session. We spent about an hour eating in there and just talking, sorting things out for move out, and other things, before heading down back to our poster session.
                <br/><br/>
                Sanjana was really nervous since she never got around to practicing her poster presentation out loud, and we got to the poster session just as people were starting to stream in. Sanjana's side was completely open to the other side of Zuckerman, so there was a lot of visibility for her poster. I peeked around my board and saw that there were pretty much always people at her board.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8771.jpeg")} alt="IMG_8771.jpeg" />
                    <figcaption>Armaun and Anneliese Came!</figcaption>
                </figure>
                Meanwhile, I stood across from Arielle's poster, who also got a lot of crowds around haha. For the first half hour, I didn't get much traffic. People just kind of walked past and were taking a quick look at the overall work haha. But soon enough, I got a lot of people who were interested.
                <br/><br/>
                Some grad students, a high schooler, Ino(!!!), Aziz's mentor, and Michael Berger. A couple of the grad students and actual PhD's asked questions I didn't know how to answer which was intidimating haha. I told Berger I was in Wesley Tansey's beautiful lab, and at the end of my presentation, he joked about Wesley. He was like, "what did you call it? Wesley's amazing lab?" or something like that, and I repeated what I said.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8762.jpeg")} alt="IMG_8762.jpeg" />
                    <figcaption>My Poster</figcaption>
                </figure>
                He asked me about my experience with Wesley, hinting at the fact that Wesley's lab is a little non-traditional in how heavily statistical and machine learning theoretical things are haha. Him and Ushma always joke about how Wesley's labs is very different from all the other labs in that it's not necessarily a very application-driven lab.
                <br/><br/>
                At one point, I was talking to someone for a good 5 minutes and knew there were 2 people behind on my other side, but I was ignoring them. When I finished my spiel, I turned around and noticed it was Armaun and Anneliese! They came all the way from Joy for our poster session! I was so happy to see some familiar faces since Arielle had a lot of familiar faces go to her poster haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8748.jpeg")} alt="IMG_8748.jpeg" />
                    <figcaption>Resting for the Poster Session</figcaption>
                </figure>
                I talked to them for a bit and showed them our research, which was pretty fun. Neither of them really had any questions which was unfortunate, but oh well haha.
                <br/><br/>
                At about 4, the poster session was still going strong even though it was supposed to be over. Berger showed me a text from Ushma saying they would extend the session to 4:30 since they didn't want to kick everyone out, but I left my poster since I felt a little like throwing up. Standing too long and shouting since it was so loud at the poster session made me feel a bit uncomfortable.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8737.jpeg")} alt="IMG_8737.jpeg" />
                    <figcaption>Yutaro and Julia, My Columbia Friends</figcaption>
                </figure>
                I got an ice cream cup with Rhea and went to talk to Sanjana. She was presenting, so we took some selfies while she was being professional, and then we joined her and a couple other people to talk. Adam from the Joy building (the guy that went to UCLA for undergrad) was talking to us about life as a Computational Biology and Medicine PhD student here, and Tona from Sanjana's lab (the MD-PhD student who did CBSP twice before) was also there with us.
                <br/><br/>
                Mitchell and I thought there was a best poster award which made some people nervous, but there ended up being no such thing haha. People didn't believe us at first since no one else had heard of it, but both Mitchell and I were sure we heard of it before haha. Turns out we both have a false memory!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8726.jpeg")} alt="IMG_8726.jpeg" />
                    <figcaption>Poster Fun!</figcaption>
                </figure>
                Oh! And I saw Shounak at the poster session! He's from our lab and he came all the way from Joy to the poster session as well which made me happy. It was so nice to see people from my lab, even if it was only 3 of them haha.
                <br/><br/>
                As the poster sesssion winded down, I realized that it passed much faster than I expected it to. It was a really fun way to recap what we've done this summer.
                <br/><br/>
                We quickly got settled into the Summer @ MSK Celebration, which is just in the big room in the middle of the Zuckerman lobby. They had all sorts of treats and big cupcakes laid out for us, but I ended up just taking some fruit since I couldn't stomach anything else at that point haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8695.jpeg")} alt="IMG_8695.jpeg" />
                    <figcaption>Mitchell and I In the Morning</figcaption>
                </figure>
                Ushma went through a presentation thanking us for joining MSK for the summer, and showing a ton of photos from the entirety of the summer. She had a snap of us at the Pride March visiting her, which was really funny since she's been telling faculty members that the CBSP kids got wild that day. It's not even true haha. She thought we were drunk and we don't know why ahahahaha. That day, she gave me her phone number and told us to make sure to watch our for fentanyl poisioning, which only happens with drugs ahhaa.
                <br/><br/>
                Ushma talked about how she gave her phone number to me that day, and thanked me for not spamming her with panda gifs and using it to text her when we needed to haha. It was really wholesome.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8880.jpeg")} alt="IMG_8880.jpeg" />
                    <figcaption>Nighttime Hangouts</figcaption>
                </figure>
                OH! And in the book of abstracts, they put the picture that Arielle, Rhea, and I aren't in, but they PhotoShopped out the guy that's not in CBSP that's shadowing this year hahaha. I noticed it and was showing it to people. We all thought it was hilarious.
                <br/><br/>
                Anyways, we did a final little celebration at the Summer @ MSK event, cheering with Sparkling Cider to our time here, and all got pictures with our programs. It was a really heartwarming time.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8869.jpeg")} alt="IMG_8869.jpeg" />
                    <figcaption>Fancy Eataly Desserts</figcaption>
                </figure>
                As people started to go their own ways, I got a picture with Casey and Liz for our Editor's Letter in the newsletter. After that, we all started breaking off into our own groups. Yutaro, Rhea, Sanjana, and I went to the gift shop at Koch to get some MSK merch before leaving here. I got a gray crewneck that has the blue MSK logo which is kind of cute.
                <br/><br/>
                Josh was going out with his lab to celebrate the end of his internship (and there's someone else in a different program also in his lab). They were buying them dinner, drinks, and a karoake session. What a nice lab to do that for their interns haha. It seems a lot of the labs have celebrations for their interns but not mine! That's okay though haha. I'd prefer to spend time with my friends than my labmates in all honesty.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8862.jpeg")} alt="IMG_8862.jpeg" />
                    <figcaption>Beautiful Buildings in the Flatiron District</figcaption>
                </figure>
                We got back to the hotel and tried planning a nighttime outing. We were hoping to get all of CBSP to a final dinner for a final hurrah, but that ended up not happening since some people already had dinner, others were leaving, and others just had plans.
                <br/><br/>
                We ended up getting only our core friend group to come, which was honestly very cool with me haha. We didn't know where to go, but Julia suggested a Japanese curry place in KTown that looked good. Julia, Sanjana, Aziz, and I walked over to Ktown from there. We had Arielle on the way back from the gym who was to join us, and Sydney later texted us that she could catch up with us.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8853.jpeg")} alt="IMG_8853.jpeg" />
                    <figcaption>0.5x on a Rock</figcaption>
                </figure>
                We got to the Japanese restaurant, but they didn't have a table for 6 and were kind of ignoring us when we asked them to put some tables together, so we left. We didn't have anywhere to go, so I suggested Udon Lab, a restaurant also in KTown that I went with Mitchell to on one of my first nights here. Oh! Mitchell didn't come last night since he had another migraine and was trying to sleep through it.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8847.jpeg")} alt="IMG_8847.jpeg" />
                    <figcaption>KTown Selfie</figcaption>
                </figure>
                In Udon Lab, we all got settled down after a short wait and I got excited since I wanted to try something new. I ended up ordering a pork katsu curry dish that Julia also got. It was SO DAMN GOOD. Sanjana was going to get a roll, but then wanted to get the curry udon, but then went back to the roll and switched like 2 more times, which was probably confusing for the waiter haha.
                <br/><br/>
                Arielle finished her vegetable udon fast as hell. While Julia and I were finishing our dishes, Arielle was like, "hurry up! I could probably finish both of your bowls in 5 minutes!!" Ahaha she was just joking, she's very patient!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8838.jpeg")} alt="IMG_8838.jpeg" />
                    <figcaption>Udon Lab Dinner</figcaption>
                </figure>
                I noticed Ushma texted me earlier about going out to see the moon since it was extra beautiful today. I showed the group, since she told me to get everyone outside to look at the moon, so we set out to look for the moon oun our walk to Eataly. Eataly is a collection of restaurants and dessert shops that Arielle has been wanting to bring us to, and there's many locations around. We walked over to the Flatiron location since that's the closest to KTown.
                <br/><br/>
                I got like a $30 box of chocolates there for Yutaro since he gave me his pot and pan to use for the second half of the summer. And then I got a cool dessert that has something to do with caramel mousse. I don't know what it was except for the fact that it was absolutely delicious haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8830.jpeg")} alt="IMG_8830.jpeg" />
                    <figcaption>Poster Train With Sydney!</figcaption>
                </figure>
                I unboxed my dessert at a table inside Eataly, but Arielle suggested we go outside since there weren't enough seats for everyone. We headed away to find an outdoor seating area. Madison Square Garden is conveniently right outside, so we headed there. We saw some empty benches around mounds of grass that we went into before realizing it was actually a dog park section haha.
                <br/><br/>
                We just stayed there since it was beautiful, filled with trees, and very calm. The dog park is such a nice place at night! What menaces we are haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8825.jpeg")} alt="IMG_8825.jpeg" />
                    <figcaption>Security Told Me No Photos In Greenberg!</figcaption>
                </figure>
                Josh joined us while we were in Eataly, clearly drunk and happy from his lab outing haha. I sat with Sanjana and Arielle, our Broadway crew! We were talking and hanging out, occasionally blending into the conversations of the rest of the group to our left. Overall a very fun time sitting there with my besties.
                <br/><br/>
                We were trying to decide what to do next, when I mentioned that the newsletter entres were due latest at the end of the night. I knew Sanjana wanted to get a newsletter entry in, and it turns out that everyone else wanted to do one too! So, we all decided to go back to The New Yorker to have a writing session! I was so happy that so many CBSP people decided to finally do a newsletter entry haaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8806.jpeg")} alt="IMG_8806.jpeg" />
                    <figcaption>Yutaro and I At Summer @ MSK Celebration</figcaption>
                </figure>
                Before that, though, Aziz left us. Aziz said it was probably the last time we would see him since he was leaving on Friday. We all had our goodbyes with him. Aziz and I argue a lot, not in mean ways, but just a lot of fundamental differences in our values haha. This relationship is notorius. Everyone knows we piss each other off in ways hahaha. So I told him, "Aziz - I know we have our differences, but I've always appreciated you" and hugged him for my goodbye.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8901.jpeg")} alt="IMG_8901.jpeg" />
                    <figcaption>My Besties Walking On 34th</figcaption>
                </figure>
                Sanjana doesn't even especially like the guy, but she started crying. It was so funny, sad, and wholesome haha. She was like, "this is so silly!" as she was crying. It was honestly pretty sad to see that we're actually probably never going to see some of these people again. It really felt like a movie scene as we all waved goodbye to Aziz while he walked away to the other end of the forest that is Madison Square Park.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8893.jpeg")} alt="IMG_8893.jpeg" />
                    <figcaption>Walking Selfie</figcaption>
                </figure>
                We quickly got on our way. Oh! I got my timelines mixed up haha. We were walking the other way through the park, deciding what to do, when the newsletter stuff came up. That's why Aziz left to the hotel first. We were originally planning on staying out late, which Aziz couldn't do since he had to pack, so he went back. But we quickly decided to also go back to the hotel to do the newsletter stuff haha.
                <br/><br/>
                When we got back to the 15th floor, I saw Aziz and didn't even think about it. He was on a call and later joined us in the TV room, saying "isn't it kind of awkward we all had our goodbyes and now I'm here again?" hahaha. I didn't even think about it till that point. Anyways, we were all reunited and ready to work on our newsletters!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8948.jpeg")} alt="IMG_8948.jpeg" />
                    <figcaption>Arriving at The New Yorker!</figcaption>
                </figure>
                I was working on arranging some things together, telling them what they could write about, and reviewing Aziz's submission which I didn't notice he had written while we were in the park. It was all very wholesome stuff. And it was the first time on the 15th floor that EVERYONE had a laptop out, not just me now haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_8905.jpeg")} alt="IMG_8905.jpeg" />
                    <figcaption>More Walking of my Best Friends<br/>(I Love These Types of Pictures)</figcaption>
                </figure>
                We all got working away which was very fun. We also decided to create superlatives (things like who's the most likely to do or be something) for everyone and vote on the top 3 for each person. That was really, really fun haha. We were coming up with targeted, funny, and out of pocket things about each person haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_8952.jpeg")} alt="IMG_8952.jpeg" />
                    <figcaption>TV Room Hangout</figcaption>
                </figure>
                The superlatives I ended up with are: 1) Most likely to offend someone, 2) Most secretive blogger, and 3) Most wholesome yet creepy photographer. Ahahaha.
                <br/><br/>
                We ended up staying in the TV room till a bit past 2 AM before we went back to our own rooms and cleaned up. I did a bit of light packing and then got to bed around 3 haha.
                <br/><br/>
                Josh sent me his newsletter entry really late after 2 AM, and I was shocked he was still up haha. I was up since I was showering and arranging some things for the newsletter, but I thought he had given up. It turns out that everyone got something submitted except for Sanjana haha. She said she had writer's block, and she just couldn't think of what to write about since so much happened this summer. That is okay haha. If it weren't for Sanjana, I don't think the rest of the crew would have even thought to write something for the newsletter!
            </div>
    },
    {
        title: "Day 62: In Denial of Goodbyes",
        date: "08/12/2022",
        tags: ["Work", "Last Full Day", "Goodbye"],
        content:
            <div>
                I woke up at 9:30 this morning to get some packing done in the morning before heading to Zuckerman. I was gonna get breakfast with Sanjana before leaving with work, but she took too long to get ready. I left a bit after her and tried catching up, but did not make the Q train and had to wait like 20 extra minutes to get on a train. That was so saddening haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9010.jpeg")} alt="IMG_9010.jpeg" />
                    <figcaption>Selfie in Zuckerman</figcaption>
                </figure>
                I went to Zuckerman's sixth floor to change my phone screen (since I already cracked my new one), tidy up things with the newsletter, and start on some blogging. It was already noon by the time I got to Zuckerman, but I wasn't hungry since I had eaten some almonds before coming to work (I got a little pack of salted almonds from somewhere, I don't remember now).
                <br/><br/>
                While I was in the coffee room, Ushma walked by, and when I looked up, she jumped and did like a little tiger growl pose at the door of the coffee room hahaa. It made me laugh and she walked along to go do whatever she had to do. Ushma has such funny energy.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9044.jpeg")} alt="IMG_9044.jpeg" />
                    <figcaption>Sydney Woods and I in Zuckerman</figcaption>
                </figure>
                At around 1, I went to Sophie's Cuban to grab lunch, and was glad to see Sydney in the lobby of ZRC. I ate lunch at the table with her as we waited for NDQ (I'm now starting my blog for today at around 3 PM haha).
                <br/><br/>
                Arielle came back from her lab lunch and wanted to get coffee, so I accompanied her to the Matto on 68th street. Quite a poetic moment since Arielle wrote her newsletter entry on Matto and getting coffee with our intern friends.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9054.jpeg")} alt="IMG_9054.jpeg" />
                    <figcaption>All Smiles in ZRC</figcaption>
                </figure>
                We got back pretty quickly, but Arielle didn't want to sit down, so we decided to walk around the block a bit. Zuckerman (and the entirety of MSK Manhattan buildings), being on the Upper East Side, is in a beautiful part of the city that is wealthy and beautiful. It was a bit sad walking around, knowing it'd be the last time we'd have the opportunity to be there together.
                <br/><br/>
                Arielle changed her mind once we made it to the other side of the building, and we walked back to the lobby haha. We snapped a few selfies in front of Zuckerman to remember our time here, and went back in. While I was taking the selfies, I realized I'd never actually gone to the high floors of Zuckerman. It's a huge high-rise with 21 floors, so I told her I wanted to see the view from the top.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9075.jpeg")} alt="IMG_9075.jpeg" />
                    <figcaption>ZRC Selfie</figcaption>
                </figure>
                We took the elevators to the 21st, which was surprisingly not security protected. I guess it's just like any other floor full of researchers and staff. The from there is absolutely gorgeous. You can see Roosevelt Island, the bridge over the island, and all the way downtown to the World Trade Center.
                <br/><br/>
                We walked around the perimeter of the floor before heading back down to the lobby to rejoin Sydney. It was about 15 minutes away from our last CBSP event of the summer - No Dumb Questions 2. People started to flow in, and we eventually all made it into ZRC-104, the classroom we've held all of our journal clubs in.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9076.jpeg")} alt="IMG_9076.jpeg" />
                    <figcaption>ZRC is Tall</figcaption>
                </figure>
                Mike Berger couldn't make it because he's a busy guy, so it was just Ushma this time around. We got into it, starting it off with a question from me.
                <br/><br/>
                "Do you want your kids to study cancer?"
                <br/><br/>
                People laughed, but I feel like they only laughed since I asked haha. It was actually a kind of serious question hahaha. Anyways, Ushma explained how one of her kids has the attitude that they don't want to do anything related to what their parent does, so that kid is most definitely not studying cancer. Another kid wants to do software, and the other kid doesn't know yet.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9080.jpeg")} alt="IMG_9080.jpeg" />
                    <figcaption>21st Floor View</figcaption>
                </figure>
                I was hoping the session would be a less serious session where we ask actually dumb questions, but Timon started asking questions about MD-PhD programs and things like that. Timon is a guy from ESP that is notorius among the MSK interns. Whenever he talks, it's like he's booming. It's just ridiculously loud haha. He also is not a very socially aware person, and he insulted Mitchell's poster so I think he's an asshole.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9091.jpeg")} alt="IMG_9091.jpeg" />
                    <figcaption>More Views</figcaption>
                </figure>
                Anyways, Timon was asking questions that were deeply personal and only relevant to himself. Ushma, being the wise and socially aware professional she is, was looking around at the CBSP kids and how we were reacting with annoyance and laughter, and handled it very well. She told Timon that the questions he was asking were good for his mentor since they're very personal to him haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9097.jpeg")} alt="IMG_9097.jpeg" />
                    <figcaption>Arielle and I</figcaption>
                </figure>
                Timon was only there, I think, because Ushma invited him after their meeting in the morning (when I was on the sixth floor, I heard Timon tell Ushma, "okay, I'll be there"). My assumption is that they had a meeting and Ushma told him about the No Dumb Questions session going on in the afternoon.
                <br/><br/>
                Anyways, enough negativity! I try not to shit talk in these blogs haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9102.jpeg")} alt="IMG_9102.jpeg" />
                    <figcaption>Carrying Arielle's and Sanjana's<br/>Stuff to Them After Their<br/>Bathroom Trip</figcaption>
                </figure>
                After a bit, Arielle went up to share the superlatives we made for everyone in CBSP + Josh. We all had a good time laughing at what we'd come up for each other. We even staged our appearances to match the superlatives we decided for each other. For example, one of Yutaro's superlatives is "coolest shirts," so he wore a shirt with a cool design on it. Arielle had "biggest water bottle," so she brought her Hydrodrug (which she's sponsored by).
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9107.jpeg")} alt="IMG_9107.jpeg" />
                    <figcaption>Returned My ID Card to Ushma</figcaption>
                </figure>
                When it came to mine, I think it was Arielle that said, "raise your hand if Kyle's ever offended you." Everyone in the room raised their hand except for Afsana (who I've never talked to), and Ushma haha. Actually, I told Afsana it was nice to see her there, and she was like, "you're Kyle right?" Hhahaha. I honestly don't know why Kiara and Zhiyue raised their hands - probably a joke!
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9118.jpeg")} alt="IMG_9118.jpeg" />
                    <figcaption>My Poster!</figcaption>
                </figure>
                A side note: Arielle was really hoping Afsana was showing up to NDQ since she said Afsana seems like a really nice and cool person. Arielle was like, "I want to be her friend, but it's too late for that now!"
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9121.jpeg")} alt="IMG_9121.jpeg" />
                    <figcaption>Dinner With Besties</figcaption>
                </figure>
                Another side note: Zhiyue is really funny. She got a little stuffed dinosaur that she named Grant because she hopes to get many grants later in her life hahaha.
                <br/><br/>
                After NDQ, we headed back to the hotel. Arielle broke off to go work out, Sanjana broke off to go to the MoMA Design Store to get a souvenier for her sister, Mitchell and I broke off to go to the 34th Street NY Souveniers store, and Josh, Julia, and Sydney broke off to buy ingredients for mojitos to drink at night.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9123.jpeg")} alt="IMG_9123.jpeg" />
                    <figcaption>Sidewalk Selfie</figcaption>
                </figure>
                I got a cheap souvenier for myself that says "New York" and "Kyle" on it, which is kind of ugly most definitely not worth it. I got it mostly just as a reminder of the place we live at since that store is really close to The New Yorker haha. I also got a couple shot glasses, one for Garren since he said he'd get me something for Korea.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9125.jpeg")} alt="IMG_9125.jpeg" />
                    <figcaption>New Yorker Elevator Selfie</figcaption>
                </figure>
                By the time we got back to the hotel, Sanjana was already back. Mitchell and I were kind of shocked she got there at the same time as us haha. It was just an extraordinarily fast trip. We were packing and trying to decide where to go for dinner. I mentioned a few places I went to at the very beginning of the program, and Josh was really excited at a pasta place I mentioned.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9127.jpeg")} alt="IMG_9127.jpeg" />
                    <figcaption>Mitchell and The Trash Chute</figcaption>
                </figure>
                We couldn't finalize plans as usual, since it's hard planning over text. We decided to meet up in the lobby and discuss there. I thought Non Solo Piada, the pasta place I mentioned, was closed since Apple Maps said it was, but Josh Googled it and saw it was open. He called the place just to verify, and we got on our way there after considering just eating at the expensive Italian place in the lobby of The New Yorker.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9150.jpeg")} alt="IMG_9150.jpeg" />
                    <figcaption>My Mojito</figcaption>
                </figure>
                After we ordered and got our seats in the outdoors seating area on the street, I told everyone (Sanjana, Julia, Josh, and Mitchell) that it felt like a really full-circle moment for me. I had gone there before in the early days of CBSP by myself, eating alone and trying to absorb NYC myself. And there we were, the group of us, this time getting to eat at Non Solo Piada with my besties. I was really happy.
                <br/><br/>
                We all got our orders except for Josh, who it seemed they forgot the order for haha. Josh told us to go ahead and just eat, so we did. Can't let our pasta get cold! I paid like $30 (including tax and tip) for a pasta with shrimp in it. Not worth it at all haha. I just wanted to get an expensive last dinner, but I feel like I should've just ordered two different dishes at that point haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9155.jpeg")} alt="IMG_9155.jpeg" />
                    <figcaption>Soul Sisters Julia and Arielle</figcaption>
                </figure>
                Josh's dish came pretty late, but they brought a dessert croissant and apologized, saying that the croissant was on the house. How kind! I really like the people at that little restaurant haha. They're always so considerate and explain what the dishes are very patiently.
                <br/><br/>
                We had a nice last supper together and got on our way two blocks north back to The New Yorker. We decided to set aside an hour for cleaning, packing, and showering before meeting up. Of course, no one actually met the deadline, so I sat in the TV room by myself for a bit before Julia showed up with cocktail prepping materials.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9178.jpeg")} alt="IMG_9178.jpeg" />
                    <figcaption>Choking Sanjana and Sydney</figcaption>
                </figure>
                As our besties came in, they brought with them cups, mint, tequila, Bacardi, sugar, and juices. Lots of good stuff! It was early in the night, probabyl around 9:30, so we knew we had a lot of time ahead of us to have fun!
                <br/><br/>
                We made mojitos by giving everyone some mashed up mint, pouring some Bacardi, then adding this fancy sparkling grapefruit water. It honestly didn't taste that good, so I added some of Sydney's blood orange juice and it tasted much better. Bacardi and tequila are just way too strong of hard liquors to drink like that haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9182.jpeg")} alt="IMG_9182.jpeg" />
                    <figcaption>Couch Selfie!</figcaption>
                </figure>
                The rate we drank at made it so that we were all really tired soon, but none of us were really too drunk. We were drinking too slowly haha. We ended up deciding to play Heads Up for a bit which was fun. We spent some time throwing the extra limes we had around, hitting each other and just playing catch in the TV room.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9187.jpeg")} alt="IMG_9187.jpeg" />
                    <figcaption>Nighttime Crew &lt;3</figcaption>
                </figure>
                A couple of times the RA's walked by, and we just gave up on hiding our drinks. They don't seem to actually care, so we just did what we wanted in the open haha. We played tequila or water, a game where someone pours either tequila or water into someone's cup (and no one knows what's been poured), the person drinks the cup, and we have to guess whether it was water or tequila.
                <br/><br/>
                I only played for one round since after the first bit, I couldn't finish my drink (which Arielle poured as half water and half tequila). The stuff is just too strong and nasty haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9193.jpeg")} alt="IMG_9193.jpeg" />
                    <figcaption>Bye Bye Mitchell</figcaption>
                </figure>
                We also had a litte talent show, where everyone went up and did something cool. Sydney is crazy flexible, so she was doing the splits, headstands, and things like that. It was pretty insane. My talent was of course barking. I can't really remember anything else that happened. Must've been a little too zoned out haha.
                <br/><br/>
                We spent a lot of time also just talking. Julia fell asleep, and we were concerned about her a bit since she wasn't responding to anything. Arielle is trained to do some medical response type of stuff, so she was making sure Julia was alright, which she was thank God. Julia just always falls asleep whenever she drinks haha.
                <br/><br/>
                We overall had a very fun night hanging out for one last time on the 15th floor, with everyone we love. At some point, Mitchell said he had to go. It was really sad since it was the last time Mitchell would be hanging out with us. We hugged and I cried. We were all very very sad. Mitchell is probably one of the kindest and gentlest souls I have ever met. He's been the best roommate I've ever had.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9212.jpeg")} alt="IMG_9212.jpeg" />
                    <figcaption>Zhiyue's Empty Bottle<br/>(Zhiyue Gave The Alc to Julia)</figcaption>
                </figure>
                After we all said our goodbyes, we got settled down and Josh started throwing up in the trash can haha. We were all disgusted, plugging our ears, opening the window near us, and we eventually just left the room and went into the conference room where there was no throwup.
                <br/><br/>
                It was hilarious while Josh threw up since Julia wasn't responding to anything we were saying (she was doing that on purpose), but started screaming "EWWWWW! EWWWW!!!" at Josh ahhhaha.
                <br/><br/>
                Arielle, Sanjana, and I hung out there while Josh cleaned up his throw up, Julia slept more, and Sydney helped Josh clean the alcohol, bowls, and stuff. After a bit of time, Arielle left to pack, so I left to get her poster since I put her poster in my plastic bag and tube protector (I didn't want to check in my poster as luggage).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9215.jpeg")} alt="IMG_9215.jpeg" />
                    <figcaption>Going to Bed</figcaption>
                </figure>
                By the time I got back to the 15th, the others were deciding to also go back to our rooms since it was past 2 at that point. We promised to meet up in the morning again so we didn't have to say our goodbyes just then, and we got on our way back to our own rooms. I also missed Josh throwing up again, this time in the conference room trash can hahaha. That makes both rooms marked by Josh!
                <br/><br/>
                I luckily finished packing most of my stuff since it just involved throwing my papers and various things into my little suitcase and throwing my clothes into the big suitcase. I wanted to split my clothes between my big and small suitcase, so I threw clean clothes on top of all my things in the small suitcase and put my dirty laundry and extra clothes in the big suitcase.
                <br/><br/>
                I left a letter on Mitchell's desk earlier in the night thanking him for being kind and a great person, things like that, and saw he left a note for me too. I decided to leave it to read in the morning. He was still up when I got back, so I got some things together and said good night to him one last time before knocking out at 2:30 AM ish (with plans to wake up at 8:30).
            </div>
    },
    {
        title: "Day 63: A Final Hurrah And Accepting Our Goodbyes",
        date: "08/13/2022",
        tags: ["Goodbye"],
        content:
            <div>
                I woke up bright and early at 8:30 today. A good 6 hours of sleep, yet I felt weirdly energetic in the morning. Maybe it was knowing what today meant. Maybe it was the acute awareness of how sad it'd be to say bye to my new best friends. Whatever it was, I felt ready for the day like I haven't in a while.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9239.jpeg")} alt="IMG_9239.jpeg" />
                    <figcaption>Mitchell is Ready to Go</figcaption>
                </figure>
                I got off my bed and took the sheets off. My nights in The New Yorker had expired.
                <br/><br/>
                I got ready, this morning putting everything I used away after I used it one final time. It was strange but satisfying to get everything cleaned up. I was pretty much done packing by the time 9:30 came along. At that point, I was kind of just hanging around my room and watching Mitchell get ready to leave.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9246.jpeg")} alt="IMG_9246.jpeg" />
                    <figcaption>Sending Mitchell Off</figcaption>
                </figure>
                I texted everyone from last night since we decided we wanted to go to Liberty Bagels at 10 AM. Finally getting bagels from a popular bagel place would be an amazing way to end our time in NYC together. Most people were up and ready to go by 10 as planned, which was exciting. I didn't expect most people to actually make it haha.
                <br/><br/>
                We headed to Liberty Bagels and ordered. I got a bagel for Yutaro since he couldn't make it there, but was going to join us later. The place is very close to The New Yorker, and is extremely busy. The inside was just absolutley packed, which is a good sign.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9248.jpeg")} alt="IMG_9248.jpeg" />
                    <figcaption>Mitchell and His Broken Suitcase</figcaption>
                </figure>
                I ordered an egg sandwich on a type of bagel bun that is stuffed with cheese. Once we got our food, Sanjana, Sydney, and I broke off from the group without realizing it. We decided to get coffee while the rest waited to get their food, but the Starbucks was too full. We walked a block or two downtown to Gregory's Coffee, which is a calmer store that's a bit fancier and less crowded.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9261.jpeg")} alt="IMG_9261.jpeg" />
                    <figcaption>Gregory's Coffee Gang</figcaption>
                </figure>
                I was kind of happy we randomly decided to get coffee, since I was just thinking about how it'd be a good idea to get caffeine in so I don't crash after sleeping for 6 hours. And that investment turned out to be a good idea. It's 8:35 PM EST right now in the plane, and our plane took off at 4 PM so I should be in LA soon. Yet, I feel wide awake! Well, I also did just take a nap so that probably helps haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9267.jpeg")} alt="IMG_9267.jpeg" />
                    <figcaption>Goodbye Yutaro &lt;3</figcaption>
                </figure>
                Anyways, we got our coffee and the rest of the crew joined us to also get some drinks. I got a medium hot latte, and was surprised at how big the cup was. Only later did I realize that they gave me a large for whatever reason haha. Maybe it's because they forgot about my order or something since my drink took forever to come out.
                <br/><br/>
                We brought our food back to the hotel and ate on the 15th floor. What an iconic gathering.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9273.jpeg")} alt="IMG_9273.jpeg" />
                    <figcaption>Blurry Picture of How Crowded<br/>The Pantry Is</figcaption>
                </figure>
                Josh and Yutaro were stressing about their MSK covid test from yesterday since they both are traveling internationally and need one. They got their results back, but they didn't include all the info Josh needed to enter China, so he spent quite a bit of time calling MSK and trying to figure things out. They eventually told him to go into Koch (the building he works at) to pick up the physical forms. How dumb is that haha. Good thing he's staying an extra night (over in Julia's room since Julia is staying late too).
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9299.jpeg")} alt="IMG_9299.jpeg" />
                    <figcaption>Arielle and Julia Around Noon</figcaption>
                </figure>
                The bagel I got was absolutely delicious, but HUGE. I couldn't finish by the time Sanjana had to go back to her room to pack, so I just brought my food with me to her room. Her roommate left at 2 AM since she was flying to London, so I sat on her desk as I finished my food.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9307.jpeg")} alt="IMG_9307.jpeg" />
                    <figcaption>Our Final Moments Together in NYC</figcaption>
                </figure>
                I was surprised to see Sanjana still had a crap load of stuff to pack. I helped gather her stuff around that she wanted to donate to the pantry. On that note, the 15th floor pantry is absolutely stocked with goods now. The wave of everyone moving out also brings a wave of everyone dumping food, cooking supplies, etc. into the pantry ahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9312.jpeg")} alt="IMG_9312.jpeg" />
                    <figcaption>Bye Josh</figcaption>
                </figure>
                After a bit of packing, I finished my coffee on Kiara's bed (Kiara is Sanjana's roommate) as I leaned against some sheets and pillows Sanjana was donating away. Yutaro shortly came to join us and say his goodbyes. It was sad seeing Yutaro leave since we all really like Yutaro, but we honestly didn't feel majojrly affected because we didn't get much of a chance to get to know Yutaro this summer. He wasn't really present haha, but the times he was, we always had fun.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9318.jpeg")} alt="IMG_9318.jpeg" />
                    <figcaption>My Turn to Move Out</figcaption>
                </figure>
                Once Yutaro left, it was creeping closer to noon, and Sanjana was finally getting everything packed up. We made a trip back to the 15th floor to drop everything, and I went back to my room so I could take a crap and get all of my stuff out of the room.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9323.jpeg")} alt="IMG_9323.jpeg" />
                    <figcaption>Walking to Penn Station</figcaption>
                </figure>
                We had to move out by 12 PM which we did, and that's when we started to realize we would all actually have to go. We met on the 15th floor a bit after 12 once everyone had their stuff ready.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9350.jpeg")} alt="IMG_9350.jpeg" />
                    <figcaption>Revisiting the Krispy Kreme</figcaption>
                </figure>
                We got in a circle, had a big group hug, and then had our individual goodbyes with each other. It was really sad, especially since I really may never see some of these people again. It was the realization that CBSP and our time at MSK was actually over. The happiness of having had this experience, and the pain that it was done.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9365.jpeg")} alt="IMG_9365.jpeg" />
                    <figcaption>New Jersey Transit Lobby<br/>Goodbye For Now Friends</figcaption>
                </figure>
                Arielle helped me check the train schedule so I knew I had to leave for Penn Station by 12:45. Julia said she'd bring me to the train station which was a big relief since Penn Station is huge and I thought I'd get lost going myself.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9382.jpeg")} alt="IMG_9382.jpeg" />
                    <figcaption>Got On the Train</figcaption>
                </figure>
                I told the group I had to leave, and they went down to the lobby to say goodbye to me. I thought we would all leave at the lobby, but they walked outside with me and to Penn Station. They ended up all coming to the train station, which was really nice.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9387.jpeg")} alt="IMG_9387.jpeg" />
                    <figcaption>Hello New Jersey</figcaption>
                </figure>
                We talked for a bit there since the Sydney and Sanjana were next to leave at 1 PM (they were both going to JFK for flights at 6 PM), and had a nice last few minutes together. We finally said our goodbyes there, with a lot of sadness and tears.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9390.jpeg")} alt="IMG_9390.jpeg" />
                    <figcaption>Made It to Newark</figcaption>
                </figure>
                They said thanks for bringing us together, saying out of pocket stuff to make us chuckle, taking Miralax which helped us bond, and stuff like that haha. When I said bye to Josh, he said next time I see someone that looks like a frat boy, introduce myself and give them a chance. It was a joke since we all told him our first impression of him was that he was like a frat boy, but he turned out to be a really nice guy.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9399.jpeg")} alt="IMG_9399.jpeg" />
                    <figcaption>Newark Liberty International Airport (EWR)</figcaption>
                </figure>
                They left, and I was just there sad by myself for a bit. The gate finally came for our train, so I got off on my adventure back home. The train ride was smooth, and the AirTrain from the Rail Station to EWR was beautiful, just the way I remember it when I first came to NYC through Newark.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9408.jpeg")} alt="IMG_9408.jpeg" />
                    <figcaption>Got My Suitcase Tags!</figcaption>
                </figure>
                I got to Terminal B, Alaska's terminal, and had a bit of a tough time finding where to check in my luggage, but I eventually got there. At some point in line when I was waiting for the attendants to take my luggage, some staff member told us the line was for people that had problems.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9413.jpeg")} alt="IMG_9413.jpeg" />
                    <figcaption>Apple Maps Thinks The New Yorker<br/>Is My Home</figcaption>
                </figure>
                There was a girl behind me who asked what those who already had tagged bags did. I didn't know either, until I saw a sign that said where to drop off bags that were already tagged. Turns out, we could just give it to the staff guy. While we waited for our suitcases, we found out that we were both flying to Cali, and that she goes to USC.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9421.jpeg")} alt="IMG_9421.jpeg" />
                    <figcaption>Airport Friend</figcaption>
                </figure>
                The guy took our suitcases without even weighing them which was kind of weird, and we got on our way to the security lines. I found out she was flying to San Fran to meet her boyfriend before driving back down to LA where she lives, and I was flying to LA to take a layover to NorCal where I live haha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9443.jpeg")} alt="IMG_9443.jpeg" />
                    <figcaption>Goodbye New York City</figcaption>
                </figure>
                She said she grew up in Shanghai but moved to Hawaii when she was 14 since her parents wanted her to go to university in the US. I was like, "oh my god, do you know a Mira?" Mira is just someone from UCLA I know that is from Hawaii, and it turns out this person did know her! She actually tutored Mira in high school haha. What a coincidence. Truly a small world.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9450.jpeg")} alt="IMG_9450.jpeg" />
                    <figcaption>Mirror Selfie in the Plane's Bathroom</figcaption>
                </figure>
                I got her Instagram and it turns out Mira is our only mutual friend haha. Anyways, she was in Jersey this summer interning for Tiffany the jewelry company. She's a ChemE major and worked in the quality department, inspecting expensive items sent back and figuring out what went wrong with them.
                <br/><br/>
                We got through security pretty smoothly, and it helped that there was someone to talk to. Makes it go by faster and makes it less stressful. As the line wrapped around, I saw someone in a UCLA shirt, so I said "Go bruins!" to her. I got ignored... hahaha.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9462.jpeg")} alt="IMG_9462.jpeg" />
                    <figcaption>Made It Halfway Across the Country</figcaption>
                </figure>
                We got through security and filled up our water bottles before finding our gate. Since her flight was an hour later than mine, we just went closer to my gate. But our gates were only 4 apart haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9471.jpeg")} alt="IMG_9471.jpeg" />
                    <figcaption>Touchdown in LA, the Land<br/>I Know All Too Well</figcaption>
                </figure>
                We talked some more about the times we had in NYC and some places we've both been before I boarded my plane. Felt like my airport adventure ended pretty quickly. Oh! While we were talking, Sanjana called and said she realized she's actually flying directly to SJC. She just told her parents to go to San Jose instead of San Francisco hahaha. I was like damn, how dumb of me. I couldn't flown directly from JFK to San Jose, but it's okay. At least I met a new friend.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9482.jpeg")} alt="IMG_9482.jpeg" />
                    <figcaption>Gave Julia A Soy Milk I Found</figcaption>
                </figure>
                And that brings me to where I am now. We're about to land in LA, and it's really settled in that my time in NYC has come to a close. I'm happy. This summer has fundamentally changed me. I want to explore more, I've realized I need to take advantage of going to school in the city of Los Angeles, I love public transit, and I have so much more energy.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9494.jpeg")} alt="IMG_9494.jpeg" />
                    <figcaption>Boarding the Plane to San Jose</figcaption>
                </figure>
                Another highlight of today is the release of our newsletter to Ushma. Right before my plane took off, I saw that Casey and Liz had sent our complete newsletter to Ushma. I'm really happy with the way it turned out. Casey and Liz have ridiculously good eyes for design, and the newsletter looks beautiful. And I'm most proud of having the newsletter come alive in the ways I had imagined it to. Fun filled themes with a focus on how much fun this internship is outside of the lab. My CBSP besties really made it such a memorable newsletter. Something that's very worth printing out!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9497.jpeg")} alt="IMG_9497.jpeg" />
                    <figcaption>Through the Tunnels I Go</figcaption>
                </figure>
                The plane ride from EWR to LAX wasn't bad  at all. Some nice views and a very smooth ride except for a crying baby a few rows in front of me. Once I got to LAX, I was in familiar territory again. I know the Alaska terminal there well since I've traveled exclusively on Southwest and Alaska from LA to SJ.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9504.jpeg")} alt="IMG_9504.jpeg" />
                    <figcaption>The Sunset in LA</figcaption>
                </figure>
                I had some time to burn (about 45 minutes) in LAX, so I ate a bag of pretzels they gave me on the plane, used the bathroom, and sat around. They were upgrading people to first class for $20, so I was tempted, but decided not to do it since there's only 2 seats in each row anyways on these flights.
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9529.jpeg")} alt="IMG_9529.jpeg" />
                    <figcaption>Hello San Jose!</figcaption>
                </figure>
                It felt weird to be taking off from LA. I haven't seen that view in a while of the beaches and the city of LA. There was a beautiful sunset happening over the mountains we could see from the sky, but I spent the majority of the flight deciding what to post on Instagram to recap this summer haha.
                <br/><br/>
                <figure className="left">
                    <img src={require("./NY_Images/IMG_9536.jpeg")} alt="IMG_9536.jpeg" />
                    <figcaption>Celebration at Home!</figcaption>
                </figure>
                When I finally hit the ground in San Jose, I posted to Instagram and got on my way out of the airport. I saw Mom and Dude from outside the glass walls, and we went to pick up luggage after reuniting. Luckily, both of my suitcases made it back safely!
                <br/><br/>
                <figure className="right">
                    <img src={require("./NY_Images/IMG_9553.jpeg")} alt="IMG_9553.jpeg" />
                    <figcaption>Framed My Picture With Junior!</figcaption>
                </figure>
                We got my suitcases and headed on our way back to Dad and the car - my first time back in a car in a long, long time. And now I'm home! What a great summer it's been.
            </div>
    },
]

export default blogposts;

/*
{
        title: "",
        date: "",
        tags: [],
        content:
            <div>
            </div>
    },

*/
