import React, { useState, useEffect } from 'react'
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import 'firebase/compat/firestore';

// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByhGc1OAZRI-atVfbJ4JMs_X1162r8lYo",
  authDomain: "kyle-pu-blogs.firebaseapp.com",
  projectId: "kyle-pu-blogs",
  storageBucket: "kyle-pu-blogs.appspot.com",
  messagingSenderId: "726748452301",
  appId: "1:726748452301:web:4554347e6894e7ef882084",
  measurementId: "G-3RJVK6MT44"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const analytics = getAnalytics(app);

export { db }
